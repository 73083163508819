const SupplierInvoiceType = {
  STANDARDINVOICING: 1,
  MCCONNECT: 2,
  DKVEDERLAG: 3,
  XPAYS: 4,
};

export const SupplierInvoiceTypeDescription = new Map([
  [SupplierInvoiceType.STANDARDINVOICING, 'Standard invoicing'],
  [SupplierInvoiceType.MCCONNECT, 'MC Connect'],
  [SupplierInvoiceType.DKVEDERLAG, 'DK Vederlag'],
  [SupplierInvoiceType.XPAYS, 'X-Pays'],
]);

export default SupplierInvoiceType;
