const getFormTextByValue = (form, value) => {
  const formData = new FormData(form.target);
  let text;
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of formData.entries()) {
    if (pair[0] === value) {
      // eslint-disable-next-line prefer-destructuring
      text = pair[1];
      break;
    }
  }

  return text;
};

export default getFormTextByValue;
