<template>
  <div class="mccclientdataset-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <div class="detail-element-container padding-right">
            Pengeinstitut:
            <dx-textbox
              :value="row.data.cliName"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container">
            Reg. nr.
            <dx-number-box
              :value="row.data.cliRegistrationNumber"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container padding-right">
            Beløb:
            <dx-number-box
              :value="row.data.cliTotalPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="1" />
          <dx-location screen="xs sm" :row="3" :col="0" />
          <div class="detail-element-container">
            Opkrævningsdato:
            <dx-date-box
              :value="row.data.chargingDate"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container">
            Status:
            <p>
              {{ row.data.statusText }}
              <dx-data-grid v-if="row.data.invoices.length > 0"
                ref="invoiceGrid"
                id="mcBCInvoiceGrid"
                :data-source="row.data.invoices"
              >
                <dx-column
                  data-field="invoiceNumber"
                  caption="Fakturanr."
                  :hiding-priority="5"
                  width="auto"/>
                <dx-column
                  data-field="invoiceDate"
                  caption="Fakturadato"
                  data-type="date"
                  format="dd.MM.yyyy"
                  :hiding-priority="5"
                  width="auto"/>
                <dx-column
                  caption="Hent faktura"
                  :hiding-priority="5"
                  cell-template="downloadTemplate"
                  width="120px"/>
                <template #downloadTemplate="{ data }">
                    <i class="dx-icon-download" @click="getBCInvoiceFile(data.data.invoiceFileId)"></i>
                </template>
              </dx-data-grid>
            </p>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="1" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container">
            Fusion:
            <div v-if="row.data.partOfFusion">
              <i class="dx-icon-link" style="color: #2b2b2b; font-size: 18px;" title="Pengeinstitut indgår i fusion" />
            </div>
          </div>
        </dx-item>

      </dx-responsive-box>
    </div>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';
import useBCInvoice from '@/modules/shared/composables/bcinvoice.composable.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  setup() {
    const {
      getBCInvoiceFile,
    } = useBCInvoice();

    return {
      NumberFormatting,
      getBCInvoiceFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.mccclientdataset-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button-large {
      margin-bottom: 5px;
      width: 170px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 20px;
  }
}
</style>

<style>
  #mcBCInvoiceGrid .dx-datagrid {
    background-color: transparent;
  }

  #mcBCInvoiceGrid td {
    padding: 1px;
  }
</style>
