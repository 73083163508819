import {
  ref,
} from 'vue';
import store from '@/store/index';
import useBCRepository from '@/modules/dataTransfer/repositories/bc.repository.js';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useBCImportData(dataGrid) {
  const isLoading = ref(false);
  const bcRepository = useBCRepository();
  const { startFastJobPolling } = useJob();

  const onImportBCInvoiceMetadata = (overrideExisting) => {
    const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();
    const billingPeriodIdList = selectedRowsData
      .map((x) => x.id);

    if (billingPeriodIdList.length === 0) {
      store.dispatch('addInfoNotification', 'Du skal vælge minimum én faktureringsperiode.');
      return;
    }

    isLoading.value = true;

    const data = {
      overrideExisting,
      billingPeriodIds: billingPeriodIdList,
    };

    bcRepository.importBCInvoiceMetadata(data)
      .then((response) => {
        if (response.data.success) {
          isLoading.value = false;

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Import af faktura-data fra BC-Webservice startes');
          return Promise.resolve(response.data);
        }

        isLoading.value = false;
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  return {
    onImportBCInvoiceMetadata,
  };
}
