import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/home';
import Callback from '@/views/callback';
import AccessDenied from '@/views/access-denied';
import Login from '@/views/login';
import Profile from './views/profile';
import PricePeriods from '@/views/prices/price-periods';
import BillingSet from '@/views/billingSet/billingSet';
import MCCBillingSet from '@/views/mccBillingSet/billingset-list.vue';
import DKVBillingSet from '@/views/dkvBillingSet/billingset-list.vue';
import XPBillingSet from '@/views/xpBillingSet/billingset-list.vue';
import Prices from '@/views/prices/prices';
import ProductPriceGroups from '@/views/prices/product-price-groups';
import ManualBillingList from '@/views/billingSet/manual-billing-list';
import ExternalDataSetList from '@/views/billingSet/externaldataset-list';
import MCCExternalDataSetList from '@/views/mccBillingSet/externaldataset-list';
import DKVExternalDataSetList from '@/views/dkvBillingSet/externaldataset-list';
import XPExternalDataSetList from '@/views/xpBillingSet/externaldataset-list';
import ClientDataSetList from '@/views/billingSet/clientdataset-list';
import MCCClientDataSetList from '@/views/mccBillingSet/clientdataset-list.vue';
import DKVClientDataSetList from '@/views/dkvBillingSet/clientdataset-list';
import XPClientDataSetList from '@/views/xpBillingSet/clientdataset-list';
import ClientBillingEntryList from '@/views/billingSet/clientbillingentry-list';
import ManualHandlingList from '@/views/billingSet/manualhandling-list';
import ManualBilling from '@/views/billingSet/manual-billing';
import MasterDataMainProducts from '@/views/masterData/main-products';
import MasterDataUsers from '@/views/masterData/users';
import MasterDataSuppliers from '@/views/masterData/suppliers';
import MasterDataBrands from '@/views/masterData/brands';
import MasterDataCardProductMapping from '@/views/masterData/card-product-mapping';
import MasterDataInvoiceFrequency from '@/views/masterData/invoice-frequency';
import MasterDataBillingPeriods from '@/views/masterData/billing-periods';
import MasterDataClients from '@/views/masterData/clients';
import MasterDataClientsAccounts from '@/views/masterData/client-accounts';
import MasterDataClientsSubclients from '@/views/masterData/client-subclients';
import MasterDataDistributionKeys from '@/views/masterData/distribution-keys';
import MasterDataDistributionKeyPeriods from '@/views/masterData/distribution-key-periods';
import MasterDataClientDistributions from '@/views/masterData/client-distributions';
import MasterDataClientDistributionHistory from '@/views/masterData/client-distribution-history';
import MasterDataClientGroups from '@/views/masterData/client-groups';
import MasterDataBrandGroups from '@/views/masterData/brand-groups';
import MasterDataBiGraphs from '@/views/masterData/bi-graphs';
import MasterDataContactPersons from '@/views/masterData/cms-contact-persons';
import MasterDataGraphTooltips from '@/views/masterData/cms-graph-tooltips';
import MasterDataBIText from '@/views/masterData/cms-bi-text';
import MasterDataPIIText from '@/views/masterData/cms-pii-text';
import MasterDataBillingSetTypes from '@/views/masterData/billingset-types';
import MasterDataAttachments from '@/views/masterData/cms-attachments';
import MasterDataInfoArea from '@/views/masterData/cms-info-area.vue';
import BIPublishData from '@/views/dataTransfer/bi-publish-data';
import BCImportData from '@/views/dataTransfer/bc-import-data';
import AccountMapping from '@/views/accountMapping/accountMapping.vue';
import defaultLayout from './layouts/side-nav-outer-toolbar';
import Roles from '@/enums/roles.js';
import store from '@/store/index';

// eslint-disable-next-line new-cap
const router = new createRouter({
  routes: [
    {
      path: '/home',
      name: 'home',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: Home,
    },
    {
      path: '/callback',
      name: 'Callback',
      meta: {
        requiresAuth: false,
        layout: defaultLayout,
      },
      component: Callback,
    },
    {
      path: '/accessdenied',
      name: 'AccessDenied',
      meta: {
        requiresAuth: false,
        layout: defaultLayout,
      },
      component: AccessDenied,
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        requiresAuth: false,
        layout: defaultLayout,
      },
      component: Login,
    },
    {
      path: '/profile',
      name: 'profile',
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Profile,
    },
    {
      path: '/koersler',
      name: 'BillingSet',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: BillingSet,
    },
    {
      path: '/koersler/:_billingSetId/bokisposter',
      name: 'ManualBillingList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: ManualBillingList,
    },
    {
      path: '/koersler/:_billingSetId/bokisposter/post/:_manualBillingId?',
      name: 'ManualBilling',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: ManualBilling,
    },
    {
      path: '/koersler/:id/leverandoerdatasaet',
      name: 'ExternalDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: ExternalDataSetList,
    },
    {
      path: '/koersler/:billingSetId/leverandoerdatasaet/:externalDataSetId/manuellehaandteringer',
      name: 'ManualHandlingList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: ManualHandlingList,
    },
    {
      path: '/koersler/:billingSetId/pidatasaet',
      name: 'ClientDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: ClientDataSetList,
    },
    {
      path: '/koersler/:billingSetId/pidatasaet/:clientDataSetId/poster',
      name: 'ClientBillingEntryList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: ClientBillingEntryList,
    },
    {
      path: '/mcckoersler',
      name: 'MCCBillingSet',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MCCBillingSet,
    },
    {
      path: '/mcckoersler/:id/leverandoerdatasaet',
      name: 'MCCExternalDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MCCExternalDataSetList,
    },
    {
      path: '/mcckoersler/:billingSetId/pidatasaet',
      name: 'MCCClientDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MCCClientDataSetList,
    },
    {
      path: '/dkvkoersler',
      name: 'DKVBillingSet',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: DKVBillingSet,
    },
    {
      path: '/dkvkoersler/:id/leverandoerdatasaet',
      name: 'DKVExternalDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: DKVExternalDataSetList,
    },
    {
      path: '/dkvkoersler/:billingSetId/pidatasaet',
      name: 'DKVClientDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: DKVClientDataSetList,
    },
    {
      path: '/xpkoersler',
      name: 'XPBillingSet',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: XPBillingSet,
    },
    {
      path: '/xpkoersler/:id/leverandoerdatasaet',
      name: 'XPExternalDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: XPExternalDataSetList,
    },
    {
      path: '/xpkoersler/:billingSetId/pidatasaet',
      name: 'XPClientDataSetList',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: XPClientDataSetList,
    },
    {
      path: '/prisperioder',
      name: 'PricePeriods',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: PricePeriods,
    },
    {
      path: '/prisperioder/:id',
      name: 'Prices',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: Prices,
    },
    {
      path: '/prisperioder/:id/services',
      name: 'PriceProductGroups',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmUser, Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: ProductPriceGroups,
    },
    {
      path: '/stamdata/varer',
      name: 'MasterDataMainProducts',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataMainProducts,
    },
    {
      path: '/stamdata/brugere',
      name: 'MasterDataUsers',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataUsers,
    },
    {
      path: '/stamdata/suppliers',
      name: 'MasterDataSuppliers',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataSuppliers,
    },
    {
      path: '/stamdata/brands',
      name: 'MasterDataBrands',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataBrands,
    },
    {
      path: '/stamdata/card-product-mapping',
      name: 'MasterDataCardProductMapping',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataCardProductMapping,
    },
    {
      path: '/stamdata/faktureringsfrekvenser',
      name: 'MasterDataInvoiceFrequency',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataInvoiceFrequency,
    },
    {
      path: '/stamdata/faktureringsperioder',
      name: 'MasterDataBillingPeriods',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataBillingPeriods,
    },
    {
      path: '/stamdata/pengeinstitutter',
      name: 'MasterDataClients',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataClients,
    },
    {
      path: '/stamdata/pengeinstitutter/:id/konti',
      name: 'MasterDataClientsAccounts',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataClientsAccounts,
    },
    {
      path: '/stamdata/pengeinstitutter/:id/filialer',
      name: 'MasterDataClientsSubclients',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataClientsSubclients,
    },
    {
      path: '/stamdata/fordelingsnoegler',
      name: 'MasterDataDistributionKeys',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataDistributionKeys,
    },
    {
      path: '/stamdata/fordelingsnoegler/:id/perioder',
      name: 'MasterDataDistributionKeyPeriods',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataDistributionKeyPeriods,
    },
    {
      path: '/stamdata/fordelingsnoegler/:id/perioder/:periodId/pi-fordeling',
      name: 'MasterDataClientDistributions',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataClientDistributions,
    },
    {
      path: '/stamdata/fordelingsnoegler/:id/perioder/:periodId/pi-fordeling/historik',
      name: 'MasterDataClientDistributionHistory',
      props: true,
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataClientDistributionHistory,
    },
    {
      path: '/stamdata/pengeinstitutgrupper',
      name: 'MasterDataClientGroups',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataClientGroups,
    },
    {
      path: '/stamdata/brandgrupper',
      name: 'MasterDataBrandGroups',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataBrandGroups,
    },
    {
      path: '/stamdata/bi-graftyper',
      name: 'MasterDataBiGraphs',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataBiGraphs,
    },
    {
      path: '/stamdata/kontaktpersoner',
      name: 'MasterDataContactPersons',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataContactPersons,
    },
    {
      path: '/stamdata/graf-tooltips',
      name: 'MasterDataGraphTooltips',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataGraphTooltips,
    },
    {
      path: '/stamdata/bitekst',
      name: 'MasterDataBIText',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataBIText,
    },
    {
      path: '/stamdata/piitekst',
      name: 'MasterDataPIIText',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataPIIText,
    },
    {
      path: '/stamdata/info-omraader',
      name: 'MasterDataInfoArea',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataInfoArea,
    },
    {
      path: '/stamdata/bilag',
      name: 'MasterDataAttachments',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataAttachments,
    },
    {
      path: '/stamdata/koerselstyper',
      name: 'MasterDataBillingSetTypes',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: MasterDataBillingSetTypes,
    },
    {
      path: '/bipubliserdata',
      name: 'BIPublishData',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: BIPublishData,
    },
    {
      path: '/bcimporterdata',
      name: 'BCImportData',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: BCImportData,
    },
    {
      path: '/piikontomapping',
      name: 'AccountMapping',
      meta: {
        requiresAuth: true,
        authorize: [Roles.BokisAdmAdministrator],
        layout: defaultLayout,
      },
      component: AccountMapping,
    },
    {
      path: '/',
      redirect: '/koersler',
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/home',
    },
  ],
  history: createWebHistory(),
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const { user } = store.state;

    if (!user.isAuthenticated) {
      next({ name: 'Login' });
    } else {
      const { authorize } = to.meta;

      // Check if page requires a special user role
      if (authorize) {
        // Check if user has the role that the page requires
        const hasAccessToPage = user.roles.some((role) => authorize.includes(role));

        if (hasAccessToPage) {
          return next();
        }
        return next({ name: 'AccessDenied' });
      }

      return next();
    }
  } else {
    next();
  }
});

export default router;
