import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useClientRepository from '@/modules/masterData/clients/repositories/client.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const clientRepository = useClientRepository();
  const datasource = ref();
  const showClientFields = ref(false);

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => clientRepository.load(loadOptions)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      update: (key, values) => new Promise((resolve, reject) => {
        clientRepository.update(key, values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      insert: (values) => new Promise((resolve, reject) => {
        clientRepository.insert(values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Pengeinstitut er nu oprettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      remove: (key) => new Promise((resolve, reject) => {
        clientRepository.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Pengeinstitut er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const onInitNewRow = (e) => {
    // Set default value
    e.data.active = false;
    e.data.canBeInvoiced = false;
    e.data.isBokis = false;
    showClientFields.value = true;
  };

  const openFile = (fileContentResult) => {
    // Base64 decode content
    const decodedFileContent = Buffer.from(fileContentResult.fileContents, 'base64');

    const fileURL = window.URL.createObjectURL(new Blob([decodedFileContent], { type: fileContentResult.contentType }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileContentResult.fileDownloadName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  const getClientsReport = () => {
    store.dispatch('showLoadingPanel', true);
    clientRepository.getClientsReport()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        if (response.data.success) {
          const fileContentResult = response.data.value;
          openFile(fileContentResult);
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    datasource,
    getDatasource,
    showClientFields,
    onInitNewRow,
    getClientsReport,
  };
}
