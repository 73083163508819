import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getSupplierList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/suppliers`);

  const getAreaTypeList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/bitext/areatypes`);

  return {
    getSupplierList,
    getAreaTypeList,
  };
}
