import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useExternalDataSetRepository() {
  const appsettings = store.state.appSettings;

  const uploadExternalDatasetFiles = (billingsetId, formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingsetId}/externaldatasets/uploadfiles`, formData);

  const loadDatasetFromApi = (billingsetId, formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingsetId}/externaldatasets/importdata`, formData);

  const getContractList = (supplierid) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/contracts/list/${supplierid}`);

  const uploadMCCDatasetFile = (billingsetId, formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingsetId}/mcfile/uploadfile`, formData);

  const uploadDKVDatasetFile = (billingsetId, formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/dkvinvoicing/dkvbillingsets/${billingsetId}/dkvfile/uploadfiles`, formData);

  const loadDKVDatasetFromApi = (billingsetId, formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/dkvinvoicing/dkvbillingsets/${billingsetId}/dkvfile/importdata`, formData);

  const uploadXPDatasetFile = (billingsetId, formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/xpinvoicing/xpbillingsets/${billingsetId}/xpfile/uploadfiles`, formData);

  return {
    uploadExternalDatasetFiles,
    loadDatasetFromApi,
    getContractList,
    uploadMCCDatasetFile,
    uploadDKVDatasetFile,
    loadDKVDatasetFromApi,
    uploadXPDatasetFile,
  };
}
