<template>
  <XPExternalDataSetList />
</template>

<script>
import XPExternalDataSetList from '@/modules/xpExternalDataSet/components/list.vue';

export default {
  name: 'ExternalDataSets',
  components: {
    XPExternalDataSetList,
  },
};
</script>
