import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getClientList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clients`);

  const getAccountList = (clientId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clients/${clientId}/accounts/list`);

  return {
    getClientList,
    getAccountList,
  };
}
