<template>
  <div class="cms-graph-tooltip datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>CMS</h2></div>
        <div class="subheadline-item">&nbsp; - Graf Tooltips</div>
      </div>
    </div>

    <div class="content-block">
      <div class="supplier-container">
        Supplier:
        <dx-select-box
          :data-source="supplierList.data"
          display-expr="name"
          value-expr="id"
          v-model:value="supplierList.selectedSupplier"
          @value-changed="supplierChanged" />
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="cmsGraphTooltipDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @init-new-row="initNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Graf Tooltips" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="cmsGraphTooltip-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="title" help-text="Du kan indsætte dynamisk indhold med disse tags: [year] = nuværende år, [limit] = procentvise grænse for afvigelse, ex. 25 %">
              <dx-label text="Overskrift" />
            </dx-simple-item>
            <dx-simple-item data-field="text"
              editor-type="dxTextArea"
              :editor-options="textAreaOptions"
              help-text="Du kan indsætte dynamisk indhold med disse tags: [year] = nuværende år, [limit] = procentvise grænse for afvigelse, ex. 25 %">
              <dx-label text="Tekst" />
            </dx-simple-item>
            <dx-simple-item data-field="graphTypeId">
              <dx-label text="Graf type" />
            </dx-simple-item>
            <dx-simple-item data-field="graphLevel">
              <dx-label text="Niveau" />
            </dx-simple-item>
            <dx-simple-item data-field="monthId">
              <dx-label text="Måned" />
            </dx-simple-item>
            <dx-simple-item data-field="brandId">
              <dx-label text="Brand" />
            </dx-simple-item>
            <dx-simple-item data-field="serviceIdentifier">
              <dx-label text="Service" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="title"
          caption="Overskrift"
          data-type="string"
          :hiding-priority="7"
          width="250">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="graphTypeId"
          caption="Graf type"
          :hiding-priority="6">
          <dx-grid-lookup
            :data-source="graphTypeList.data"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="graphLevel"
          caption="Niveau"
          data-type="number"
          :hiding-priority="5">
          <dx-grid-lookup
            :data-source="graphLevelList.data"
            value-expr="id"
            display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="text"
          caption="Tekst"
          data-type="string"
          :visible="false"
          :hiding-priority="7"
          :allow-resizing="true">
        </dx-column>

        <dx-column
          data-field="monthId"
          caption="Måned"
          :hiding-priority="5">
          <dx-grid-lookup
            :data-source="monthTypeList.data"
            :allowClearing="true"
            value-expr="id"
            display-expr="name"
          />
        </dx-column>

        <dx-column
          data-field="brandId"
          caption="Brand"
          :hiding-priority="4">
          <dx-grid-lookup
            :data-source="brandList.data"
            :allowClearing="true"
            value-expr="id"
            display-expr="name"
          />
        </dx-column>

        <dx-column
          data-field="serviceIdentifier"
          caption="Service"
          :hiding-priority="3">
          <dx-grid-lookup
            :data-source="serviceList.data"
            :allowClearing="true"
            value-expr="identifier"
            :display-expr="displayExpr"
          />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDataCompleted" />
          <div v-else>
            <DetailView
              :row="row"
              :brandList="brandList.data"
              :graphTypeList="graphTypeList.data"
              :monthList="monthTypeList.data"
              :serviceList="serviceList.data"
              :graphLevelList="graphLevelList.data"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import DetailView from '@/modules/masterData/cmsGraphTooltips/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/cmsGraphTooltips/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/cmsGraphTooltips/composables/masterdata.composable.js';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';

const supplierFormItem = {
  visible: false,
};

export default {
  name: 'CMSGraphTooltipModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    ContentLoaderList,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      onInitNewRow,
    } = useDatagrid();

    const textAreaOptions = {
      height: 200,
    };

    const {
      supplierList,
      saveSelectedSupplierInStore,
      brandList,
      getMasterData,
      graphTypeList,
      monthTypeList,
      graphLevelList,
      serviceList,
    } = useMasterdata();

    const loadingDataCompleted = computed(() => supplierList.loadingCompleted && brandList.loadingCompleted);

    const supplierChanged = () => {
      const supplierId = supplierList.selectedSupplier;

      saveSelectedSupplierInStore(supplierId);
      getDatasource(supplierId);
      getMasterData(supplierId);
    };

    function displayExpr(item) {
      return `${item.identifier} - ${item.name}`;
    }

    return {
      datasource,
      supplierList,
      brandList,
      loadingDataCompleted,
      supplierChanged,
      supplierFormItem,
      onInitNewRow,
      graphTypeList,
      monthTypeList,
      textAreaOptions,
      graphLevelList,
      displayExpr,
      serviceList,
    };
  },
  methods: {
    initNewRow(e) {
      this.onInitNewRow(e, this.supplierList.selectedSupplier);
    },
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne graf tooltip?<br/><b>${e.data.title}</b>`, 'Slet graf tooltip');
    },
  },
};
</script>

<style lang="scss">
.cms-graph-tooltip {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 0;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .supplier-container {
    padding: 20px;
    background-color: #FFF;
  }
}
</style>
