<template>
  <div class="preset-form-root">
    <div class="row" style="display: flex;">
      <div style="flex-grow: 8;">
        Preset:
        <dx-select-box
          :data-source="presetList"
          display-expr="name"
          value-expr="id"
          :value="selectedPresetId"
          @value-changed="$emit('presetChanged', $event)" />
      </div>
      <div class="row flex" style="flex-grow: 1; padding-top: 19px;">
        <dx-button text="Gem preset" icon="save" @click="$emit('savePreset')" :disabled="!modelValue.isPreset" />
        <dx-button text="Gem preset som" icon="add" class="margin-left" @click="$emit('savePresetAs')" />
        <dx-button text="Slet preset" icon="trash" class="margin-left" @click="$emit('deletePreset')" :disabled="!modelValue.isPreset" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectionPresetForm',
  props: {
    modelValue: Object,
    presetList: Array,
    selectedPresetId: Number,
  },
  emits: ['presetChanged', 'savePreset', 'savePresetAs', 'deletePreset'],
};
</script>

<style lang="scss" scoped>
.preset-form-root {
  padding: 20px;
  background-color: #FFF;

  .row {
    padding-bottom: 15px;
  }

  .flex {
    display: flex;
    justify-content: flex-end;
  }

  .margin-left {
    margin-left: 15px;
  }
}
</style>
