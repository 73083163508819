<template>
  <div class="supplier-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <dx-location screen="xs sm" :row="0" :col="0" />
        <div class="detail-element-container padding-right">
          Navn:
          <dx-textbox
            :value="row.data.name"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container">
          Import format:
          <dx-textbox
            :value="getImportFormatById(row.data.inputFormatId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="2" :col="0" />
        <div class="detail-element-container padding-right">
          Fakturatype:
          <dx-textbox
            :value="getSupplierInvoiceTypeById(row.data.invoicingType)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container">
          Gruppe:
          <dx-textbox
            :value="row.data.groupName"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div>
          <div class="detail-element-container padding-right">
            Beskrivelse:
            <dx-text-area
              :value="row.data.description"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div>
          <div class="detail-element-container padding-top">
            <dx-checkbox
              :value="row.data.active"
              :read-only="true"
              text="Aktiv"
              class="textbox-readonly" />
          </div>
          <div class="detail-element-container">
              <dx-checkbox
              :value="row.data.activeForAccountMapping"
              :read-only="true"
              text="Aktiv for kontomapning"
              class="textbox-readonly" />
          </div>
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    masterdata: Object,
  },
  setup(props) {
    function getImportFormatById(id) {
      const item = props.masterdata.importFormatList.data.find((x) => x.id === id);
      return get(item, ['name']);
    }

    function getSupplierInvoiceTypeById(id) {
      const item = props.masterdata.supplierInvoiceTypeList.data.find((x) => x.id === id);
      return get(item, ['name']);
    }

    return {
      getImportFormatById,
      getSupplierInvoiceTypeById,
    };
  },
};
</script>

<style lang="scss">
.supplier-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 25px;
  }
}
</style>
