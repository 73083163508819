import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useDistributionKeyPeriodRepository from '@/modules/masterData/distributionKeyPeriods/repositories/distributionKeyPeriod.repository.js';
import store from '@/store/index';

export default function useDatagrid(clientId) {
  const mainDistributionKeyPeriodRepository = useDistributionKeyPeriodRepository(clientId);
  const datasource = ref();

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainDistributionKeyPeriodRepository.load(loadOptions)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      update: (key, values) => new Promise((resolve, reject) => {
        mainDistributionKeyPeriodRepository.update(key, values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      insert: (values) => new Promise((resolve, reject) => {
        mainDistributionKeyPeriodRepository.insert(values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Fordelingsperiode er nu oprettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      remove: (key) => new Promise((resolve, reject) => {
        mainDistributionKeyPeriodRepository.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Fordelingsperiode er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const onActivateClick = (row) => {
    mainDistributionKeyPeriodRepository.toggleActivateState(row.key, true)
      .then((response) => {
        if (response.data.success) {
          // Update datagrid with new data
          row.component.refresh();

          store.dispatch('addSuccessNotification', 'Fordelingsperiode er nu aktiveret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onInactivateClick = (row) => {
    mainDistributionKeyPeriodRepository.toggleActivateState(row.key, false)
      .then((response) => {
        if (response.data.success) {
          // Update datagrid with new data
          row.component.refresh();

          store.dispatch('addSuccessNotification', 'Fordelingsperiode er nu inaktiveret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onInitNewRow = (e) => {
    // Set default value
    e.data.active = false;
  };

  return {
    datasource,
    getDatasource,
    onInactivateClick,
    onActivateClick,
    onInitNewRow,
  };
}
