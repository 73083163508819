<template>
  <div class="picture-form-container">
    <form @submit="handleSubmit($event)">
      <div class="row">
        Vælg billede.
      </div>

      <img v-if="picture" class=img-fluid :src="picture" alt="">

      <DxFileUploader
        select-button-text="Vælg billede"
        label-text=""
        accept="image/*"
        upload-mode="useForm"
        ready-to-upload-message="Klar til upload."
        @value-changed="onPictureSelected"
      />

      <div class="validation-row row">
        <dx-validation-summary />
      </div>
      <div class="button-row row">
        <dx-button text="Gem" :use-submit-behavior="true" class="button margin-right" />
        <dx-button text="Annuller" @click="handleCancel" class="button" />
      </div>
    </form>
  </div>
</template>

<script>
import { DxFileUploader } from 'devextreme-vue/file-uploader';

export default {
  name: 'PictureForm',
  props: {
    modelValue: Object,
    picture: String,
  },
  setup(props, context) {
    const handleSubmit = (e) => {
      context.emit('save');
      e.preventDefault();
    };

    const onPictureSelected = (e) => {
      context.emit('onPictureSelected', e.value);
    };

    const handleCancel = () => {
      context.emit('cancel');
    };

    return {
      handleSubmit,
      handleCancel,
      DxFileUploader,
      onPictureSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.picture-form-container {
  display: flex;
  flex-direction: column;

  .row {
    padding-bottom: 15px;
  }

  .padding-right {
    padding-right: 15px;
  }

  .button {
    width: 100px;
  }

  .margin-right {
    margin-right: 15px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;
  }

  .img-fluid {
    width: 360px;
    height: auto;
  }
}
</style>
