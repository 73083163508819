<template>
  <div class="manualhandling-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <div class="detail-element-container padding-right">
            HReg:
            <dx-number-box
              :value="row.data.hregNo"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container">
            Varenummer:
           <dx-textbox
              :value="row.data.productIdentifier"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container padding-right">
            Varenavn:
            <dx-textbox
              :value="row.data.productName"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="1" />
          <dx-location screen="xs sm" :row="3" :col="0" />
          <div class="detail-element-container">
            Supplerende tekst:
            <dx-textbox
              :value="row.data.externalText"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <dx-location screen="xs sm" :row="3" :col="0" />
          <div class="detail-element-container padding-right">
            Fordelt på PI:
            <dx-number-box
              :value="row.data.clientDistributionCount"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="3" :col="0" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container padding-right">
            Antal:
            <dx-number-box
              :value="row.data.quantity"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="4" :col="0" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container padding-right">
            Nets stykpris:
            <dx-number-box
              :value="row.data.netsUnitPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="4" :col="1" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container padding-top">
            Nets pris:
            <dx-number-box
              :value="row.data.netsTotalPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="5" :col="0" />
          <dx-location screen="xs sm" :row="7" :col="0" />
          <div class="detail-element-container padding-right">
            BOKIS stykpris:
            <dx-textbox
              :value="row.data.bokisUnitPrice == null ? 'Trappe' : formatService.formatNumber(row.data.bokisUnitPrice, 2, 2) + ' kr.'"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="5" :col="1" />
          <dx-location screen="xs sm" :row="7" :col="0" />
          <div class="detail-element-container">
            BOKIS markup:
            <dx-textbox
              :value="row.data.bokisUnitMarkup == null ? 'Trappe' : formatService.formatNumber(row.data.bokisUnitMarkup, 2, 2) + ' kr.'"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="6" :col="0" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container padding-right">
            BOKIS totalpris:
            <dx-number-box
              :value="row.data.bokisTotalPriceInclMarkup"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="7" :col="0" />
          <dx-location screen="xs sm" :row="8" :col="0" />
          <div class="detail-element-container padding-bottom">
            Status:
            {{ row.data.entryIsHandled ? 'Håndteret' : row.data.statusText }}
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="2" :rowspan="6" />
          <dx-location screen="xs sm" :row="11" :col="0" />
          <div>
            <div class="action-button-wrapper">
              <dx-button icon="add" text="Håndter manuelt" @click="$emit('onCreateManualClick', row.data.id)" :visible="!row.data.entryIsHandled" class="action-button-large" />
            </div>
            <div class="action-button-wrapper">
              <dx-button icon="check" text="Håndter automatisk" @click="$emit('onClickOnceClick', row.data.id)" :visible="!row.data.entryIsHandled && !row.data.isBokis" class="action-button-large" />
            </div>
            <div class="action-button-wrapper">
              <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row.data.mbeNo)" :visible="row.data.entryIsHandled" class="action-button-large" />
            </div>
            <div class="action-button-wrapper">
              <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row.data.mbeNo)" :visible="row.data.entryIsHandled" class="action-button-large" />
            </div>
          </div>
        </dx-item>
      </dx-responsive-box>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'ManualHandlingDetailView',
  props: {
    row: Object,
  },
  emits: ['onClickOnceClick', 'onCreateManualClick', 'onEditClick', 'onDeleteClick'],
  setup() {
    const formatService = inject('formatService');

    return {
      NumberFormatting,
      formatService,
    };
  },
};
</script>

<style lang="scss" scoped>
.manualhandling-detail-view {
  .header {
    font-size: 18px;
    font-weight: 500;
  }

  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }

    .action-button-large {
      margin-bottom: 5px;
      width: 220px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 35px;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
  }
}
</style>
