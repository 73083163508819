import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useBrandRepository from '@/modules/masterData/brands/repositories/brand.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const mainBrandRepository = useBrandRepository();
  const datasource = ref();

  const getDatasource = (supplierId) => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainBrandRepository.load(loadOptions, supplierId)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      update: (key, values) => new Promise((resolve, reject) => {
        mainBrandRepository.update(key, values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      insert: (values) => new Promise((resolve, reject) => {
        mainBrandRepository.insert(values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Brand er nu oprettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      remove: (key) => new Promise((resolve, reject) => {
        mainBrandRepository.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Brand er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const onInitNewRow = (e, supplierId) => {
    // Set default value
    e.data.supplierId = supplierId;
  };

  return {
    datasource,
    getDatasource,
    onInitNewRow,
  };
}
