import moment from 'moment';

const formatService = {
  formatNumber: (value, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
    if (value) {
      return new Intl
        .NumberFormat('da-DK', { minimumFractionDigits, maximumFractionDigits })
        .format(value);
    }
    return '';
  },
  stringToDate: (value, format = 'DD.MM.YYYY') => {
    if (value) {
      return moment(String(value)).format(format);
    }
    return '';
  },
};

export default formatService;
