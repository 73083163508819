<template>
  <div class="billingset-type-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Kørselstyper</div>
      </div>
    </div>

    <div class="content-block">
      <div class="supplier-container">
        Supplier:
        <dx-select-box
          :data-source="supplierList.data"
          display-expr="name"
          value-expr="id"
          v-model:value="supplierList.selectedSupplier"
          @value-changed="supplierChanged" />
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="billingSetTypeDataGrid"
        :data-source="datasource"
        height="75vh"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :focused-row-key="focusedRowKey"
        @init-new-row="initNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Kørselstyper" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="billingset-types-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="applePay" editor-type="dxCheckBox">
              <dx-label text="Apple pay kørsel" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="8"
          width="100%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="applePay"
          caption="Apple pay kørsel"
          data-type="bool"
          :hiding-priority="10"
          width="0%">
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              :supplierList="supplierList.data"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import DetailView from '@/modules/masterData/billingSetTypes/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/billingSetTypes/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/billingSetTypes/composables/masterdata.composable.js';

export default {
  name: 'BillingSetTypesModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      onInitNewRow,
    } = useDatagrid();

    const {
      supplierList,
      saveSelectedSupplierInStore,
    } = useMasterdata();

    const supplierChanged = () => {
      const supplierId = supplierList.selectedSupplier;
      saveSelectedSupplierInStore(supplierId);
      getDatasource(supplierId);
    };

    return {
      datasource,
      supplierList,
      supplierChanged,
      saveSelectedSupplierInStore,
      onInitNewRow,
    };
  },
  methods: {
    initNewRow(e) {
      this.onInitNewRow(e, this.supplierList.selectedSupplier);
    },
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne kørselstype?<br/><b>${e.data.name}</b>`, 'Slet kørselstype');
    },
  },
};
</script>

<style lang="scss" scoped>
.billingset-type-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .supplier-container {
    padding: 20px;
    background-color: #FFF;
  }
}
</style>
