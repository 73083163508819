import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useInvoiceRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const createInvoices = (clientDataSetIdList) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/invoices`, { list: clientDataSetIdList });

  const getTotalClientBillingReport = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/totalclientbillingreport`);

  const getInvoices = (clientDataSetIdList) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/invoices/export`, { list: clientDataSetIdList });

  const deleteInvoices = (clientDataSetIdList) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/invoices`, { data: { list: clientDataSetIdList } });

  const updateCollectionDate = (clientDataSetIdList, collectionDate) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/setchargingdate`, { dataSetIdList: clientDataSetIdList, chargingDate: collectionDate });

  return {
    createInvoices,
    getInvoices,
    getTotalClientBillingReport,
    deleteInvoices,
    updateCollectionDate,
  };
}
