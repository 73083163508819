<template>
  <PIITextModule />
</template>

<script>
import PIITextModule from '@/modules/masterData/cmsPIIText/components/main.vue';

export default {
  name: 'MasterDataPIIText',
  components: {
    PIITextModule,
  },
};
</script>
