/* eslint-disable no-use-before-define */
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ref } from 'vue';
import useExternalDataSetRepository from '@/modules/billingSet/repositories/externaldataset.repository.js';

export default function useExternalDataSet(billingSetId) {
  const externalDataSetRepository = useExternalDataSetRepository(billingSetId);
  const isLoaded = ref(false);
  const fastPollingIntervalInSeconds = 3000;
  let fastPollingId;

  // Poll every 3 second
  const startDataPolling = () => {
    stopDataPolling();
    fastPollingId = setInterval(() => {
      if (externalDataSetDatasource.isLoaded()) {
        externalDataSetDatasource.reload();
      }
    }, fastPollingIntervalInSeconds);
  };

  const stopDataPolling = () => {
    if (fastPollingId != null) {
      clearInterval(fastPollingId);
      fastPollingId = null;
    }
  };

  const externalDataSetDatasource = new DataSource(new CustomStore({
    key: 'id',
    load: (loadOptions) => externalDataSetRepository.load(loadOptions)
      .then((response) => {
        isLoaded.value = true;
        response.data.data.forEach((item) => {
          if (!item.processed) {
            startDataPolling();
          }
        });

        return {
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        };
      }),
  }));

  return {
    externalDataSetDatasource,
    startDataPolling,
    stopDataPolling,
    isLoaded,
  };
}
