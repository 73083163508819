import { ref } from 'vue';
import useManualBillingEntryRepository from '@/modules/billingSet/repositories/manualbillingentry.repository.js';

export default function useManualBillingEntry(billingSetId) {
  const manualBillingEntryRepository = useManualBillingEntryRepository(billingSetId);
  const manualBillingEntryCount = ref();
  const numberOfEntries = ref();

  const loadManualBillingEntryCount = () => {
    manualBillingEntryRepository.loadManualBilingEntries()
      .then((response) => {
        // filter array to get only the manual billing entries
        const manualBillingEntries = response.data.data.filter((entry) => entry.externalDataFileEntryId === null);
        manualBillingEntryCount.value = manualBillingEntries.length;
        numberOfEntries.value = response.data.data.length;
      });
  };

  return {
    loadManualBillingEntryCount,
    manualBillingEntryCount,
    numberOfEntries,
  };
}
