import { ref } from 'vue';
import useMasterdataRepository from '@/modules/pricePeriods/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const loadingSupplierListCompleted = ref(false);
  const masterdataRepository = useMasterdataRepository();
  const supplierList = ref([]);

  masterdataRepository
    .getSupplierList()
    .then((result) => {
      loadingSupplierListCompleted.value = true;
      supplierList.value = result.data;
    });

  return {
    loadingSupplierListCompleted,
    supplierList,
  };
}
