<template>
  <BIPublishDataModule />
</template>

<script>
import BIPublishDataModule from '@/modules/dataTransfer/components/publish-data.vue';

export default {
  name: 'BIPublishData',
  components: {
    BIPublishDataModule,
  },
};
</script>
