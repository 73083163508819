<template>
  <dx-popup
    :visible="isVisible"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :width="width"
    :height="height"
    :show-close-button="false"
    :title="title">
    <template #content>
      <form @submit="handleSubmit($event)" enctype="multipart/form-data">
        <div class="form-popup-content-root">
          <div>
            <DxScrollView
              width="100%"
              :height="height-170">
              <div class="content-row">
                <dx-form validation-group="mainForm" />
                <slot />
                <dx-validation-summary validation-group="mainForm" />
              </div>
            </DxScrollView>
          </div>
          <div class="button-row">
            <div class="action-button-wrapper">
              <dx-button :text="saveButtonText" :use-submit-behavior="true" validation-group="mainForm" />
            </div>
            <div class="action-button-wrapper">
              <dx-button :text="cancelButtonText" @click="$emit('cancel')" />
            </div>
          </div>
        </div>
      </form>
    </template>
  </dx-popup>
</template>

<script>
import { computed } from 'vue';
import { DxScrollView } from 'devextreme-vue/scroll-view';

export default {
  name: 'FormPopup',
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: 'Gem',
    },
    cancelButtonText: {
      type: String,
      default: 'Annuller',
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 700,
    },
  },
  setup(props, context) {
    const isVisible = computed(() => props.visible);

    const handleSubmit = (e) => {
      context.emit('save', e);
      e.preventDefault();
    };

    return {
      isVisible,
      handleSubmit,
      DxScrollView,
    };
  },
};
</script>

<style lang="scss">
.form-popup-content-root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-row {
    padding: 0 0 10px 0;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;

    .action-button-wrapper {
      margin-left: 15px;
    }
  }

  .padding-bottom {
    padding-bottom: 15px;
  }
}
</style>
