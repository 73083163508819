<script>
import { get } from 'lodash';
import rowStateService from '@/services/rowState.service.js';
import rowRemoveService from '@/services/rowRemove.service.js';

export default {
  name: 'BaseDataGrid',
  data() {
    return {
      currentEditRowKey: null,
      storageKey: null,
      firstTime: true,
      focusedRowKey: null,
    };
  },
  mounted() {
    // Get id attribute of dataGrid
    const dataGridId = get(this.$refs, ['dataGrid', '$attrs', 'id']);

    if (dataGridId == null) {
      this.$log.warning('DataGrid is missing either ref or id attribute');
      return;
    }

    this.storageKey = `${dataGridId}-expanded-rows`;

    this.configureDatagrid();
  },
  computed: {
    dataGridInstance() {
      return this.$refs.dataGrid.instance;
    },
  },
  methods: {
    configureDatagrid() {
      // Attach eventhandlers to datagrid
      this.dataGridInstance.on({
        rowClick: this.onRowClick,
        editingStart: this.onEditingStart,
        saved: this.onSaved,
        editCanceled: this.onCanceled,
        contentReady: this.onContentReady,
        rowInserted: this.onRowInserted,
      });

      // Keyboard navigation settings
      const keyboardNavigationSettings = {
        editOnKeyPress: false,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column',
      };
      this.dataGridInstance.option('keyboardNavigation', keyboardNavigationSettings);

      // Set focused row settings
      this.dataGridInstance.option('autoNavigateToFocusedRow', true);
      this.dataGridInstance.option('focusedRowEnabled', true);

      // Other settings
      this.dataGridInstance.option('errorRowEnabled', false);
      this.dataGridInstance.option('columnHidingEnabled', true);
      this.dataGridInstance.option('showBorders', true);
      this.dataGridInstance.option('remoteOperations', true);
    },
    onRowClick(e) {
      // Click on Detail-row should not collapse the row
      if (e.rowType === 'detail') return;

      // Collapse all row, because we only want one row open at a time
      e.component.collapseAll(-1);

      if (e.isExpanded) {
        this.collapseRow(e.component, e.key);
      } else {
        this.expandRow(e.component, e.key);
      }
    },
    onEditClick(e) {
      // First collapse detail-row (read-only view)
      e.component.collapseRow(e.key);

      const index = e.component.getRowIndexByKey(e.key);

      // Then put row in edit mode
      e.component.editRow(index);
    },
    onDeleteClick(e) {
      const index = e.component.getRowIndexByKey(e.key);
      e.component.deleteRow(index);
    },
    onSaved(e) {
      // Show detail-row after save
      this.expandRow(e.component, this.currentEditRowKey);
    },
    onCanceled(e) {
      // Show detail-row after cancel
      this.expandRow(e.component, this.currentEditRowKey);
    },
    onEditingStart(e) {
      // Set key for current edit-row
      this.currentEditRowKey = e.key;
    },
    onContentReady(e) {
      // We only want to expand rows when grid is loaded the first time
      if (this.firstTime) {
        const rowList = rowStateService.getRowKeysFromStorage(this.storageKey);
        if (rowList == null) return;

        // Expand rows based on users previous visit
        rowList.forEach((key) => {
          e.component.expandRow(key);
        });

        this.firstTime = false;
      }
    },
    clearFilterAndSorting() {
      rowStateService.removeAllKeysFromStorage(this.storageKey);
      this.dataGridInstance.collapseAll(-1);
      this.dataGridInstance.clearFilter();
      this.dataGridInstance.clearSorting();
    },
    onRowInserted(e) {
      // Deselect all so only newly created row is selected
      this.dataGridInstance.deselectAll();

      const id = e.data.data.value;
      this.focusedRowKey = id;

      this.expandRow(this.dataGridInstance, id);
    },
    refreshGrid() {
      this.dataGridInstance.refresh();
    },
    onRowRemoving(e, text, headline) {
      rowRemoveService.onRowRemoving(e, text, headline);
      this.refreshGrid();
    },
    expandRow(dataGridInstance, key) {
      rowStateService.addKeyToStorage(this.storageKey, key);
      dataGridInstance.expandRow(key);
    },
    collapseRow(dataGridInstance, key) {
      rowStateService.removeKeyFromStorage(this.storageKey, key);
      dataGridInstance.collapseRow(key);
    },
  },
};
</script>

<style lang="scss">
.datagrid-root {
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-datagrid-edit-form {
    background-color: #FAFAFA;
  }

  .dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines) > td {
    background-color: #E6E6E6;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    color: #000;
  }

  .dx-gridbase-container {
    padding: 20px;
  }

  .dx-datagrid-rowsview
  .dx-row.dx-group-row:hover,
  .dx-data-row:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 993px) {
    .detail-element-container.padding-right {
      padding-right: 20px;
    }
  }

  .dx-form-validation-summary {
    text-align: right;
  }

  .grid-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    padding-right: 20px;

    .flex-align-right {
      display: flex;
      justify-content: flex-end;

      .dx-button {
        z-index: 100;
      }
    }
  }

  .dx-datagrid-header-panel {
    padding-right: 41px;
  }
}
</style>
