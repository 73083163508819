import { reactive, ref } from 'vue';
import useMasterdataRepository from '@/modules/productPriceGroups/repositories/masterdata.repository.js';

export default function useMasterdata(id) {
  const masterdataRepository = useMasterdataRepository();

  const overviewInfo = reactive({ loadingCompleted: false, data: null });
  const pricePeriod = ref();

  masterdataRepository.getOverviewInfo(id)
    .then((response) => {
      overviewInfo.loadingCompleted = true;

      if (response.data.success) {
        overviewInfo.data = response.data.value;
      } else {
        overviewInfo.data = {};
      }
    });

  masterdataRepository.getPricingPeriod(id)
    .then((response) => {
      pricePeriod.value = response.data.value;
    });

  return {
    overviewInfo,
    pricePeriod,
  };
}
