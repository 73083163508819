import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useSupplierRepository from '@/modules/masterData/suppliers/repositories/supplier.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const mainSupplierRepository = useSupplierRepository();
  const datasource = ref();

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainSupplierRepository.load(loadOptions)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      update: (key, values) => new Promise((resolve, reject) => {
        mainSupplierRepository.update(key, values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      insert: (values) => new Promise((resolve, reject) => {
        mainSupplierRepository.insert(values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Supplier er nu oprettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      remove: (key) => new Promise((resolve, reject) => {
        mainSupplierRepository.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Supplier er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const onInitNewRow = (e) => {
    // Set default value
    e.data.active = false;
  };

  return {
    datasource,
    getDatasource,
    onInitNewRow,
  };
}
