import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getOverviewInfo = (pricePeriodId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${pricePeriodId}/overview`);

  const getPricingPeriod = (id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${id}`);

  return {
    getOverviewInfo,
    getPricingPeriod,
  };
}
