import { reactive } from 'vue';
import useInvoiceRepository from '@/modules/dkvClientDataSet/repositories/invoice.repository.js';
import store from '@/store/index';

export default function useInvoice(billingSetId) {
  const invoiceRepository = useInvoiceRepository(billingSetId);
  const collectionDatePopup = reactive({
    show: false, defaultDate: null, minimumDate: null, onSaveClickHandler: null,
  });
  const confirmPopupModel = reactive({
    show: false, title: null, content: null, saveButtonText: null, cancelButtonText: null, saveClickHandler: null, data: null,
  });

  const getTotalClientBillingReport = (billingSetName) => {
    store.dispatch('showLoadingPanel', true);
    invoiceRepository.getTotalClientBillingReport()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        if (response.data.success) {
          const { data } = response;
          const blob = new Blob([data]);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          const date = new Date();
          // date format yyyyMMddhhmmss
          const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
          // The filename should be "Totaloversigt_{BillingSetName}_{DateTimestamp}.cvs"
          link.download = `Totaloversigt_${billingSetName.replace(/\s/g, '_')}_${dateString}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    collectionDatePopup,
    confirmPopupModel,
    getTotalClientBillingReport,
  };
}
