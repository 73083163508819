<template>
  <dx-button :text="buttonText" :use-submit-behavior="true" :disabled="isLoading">
    <template #content>
      <span class="dx-button-content">
        <DxLoadIndicator
          :visible="isLoading"
          class="button-indicator"
          :height="20"
          :width="20"
        />
        <span class="dx-button-text">{{ buttonText }}</span>
      </span>
    </template>
  </dx-button>
</template>

<script>
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';

export default {
  name: 'ButtonWithLoadingState',
  components: {
    DxLoadIndicator,
  },
  props: {
    buttonText: String,
    isLoading: Boolean,
  },
};
</script>
