import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useBillingSetTypeRepository() {
  const appsettings = store.state.appSettings;
  const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

  const load = (loadOptions, supplierId) => {
    let params = '?';

    [
      'filter',
      'group',
      'groupSummary',
      'parentIds',
      'requireGroupCount',
      'requireTotalCount',
      'searchExpr',
      'searchOperation',
      'searchValue',
      'select',
      'sort',
      'skip',
      'take',
      'totalSummary',
      'userData',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    return ApiService
      .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingsettypes/overview/${supplierId}${params}`);
  };

  const update = (key, values) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingsettypes/${key}`, { values: JSON.stringify(values) });

  const insert = (values) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingsettypes`, { values: JSON.stringify(values) });

  const remove = (key) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingsettypes/${key}`);

  return {
    load,
    update,
    insert,
    remove,
  };
}
