import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/cmsPIIText/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const pageLocationTypeList = reactive({ loadingCompleted: false, data: null });

  const loadPageLocationTypeList = () => {
    masterdataRepository.getPageLocationTypeList()
      .then((response) => {
        pageLocationTypeList.loadingCompleted = true;
        pageLocationTypeList.data = response.data;
      });
  };

  return {
    pageLocationTypeList,
    loadPageLocationTypeList,
  };
}
