<template>
  <BrandGroupModule />
</template>

<script>
import BrandGroupModule from '@/modules/masterData/brandGroups/components/main.vue';

export default {
  name: 'MasterDataBrandGroups',
  components: {
    BrandGroupModule,
  },
};
</script>
