<template>
  <div class="client-distribution-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Fordelingsperiode</h2></div>
      </div>
      <div><u @click="onBackClick" class="link">Fordelingsnøgler</u> > <u @click="onBackToSecondLevelClick" class="link">Fordelingsperioder</u> > Fordelingsperiode</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onBackToSecondLevelClick"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>

      <dx-data-grid
        ref="dataGrid"
        id="clientDistributionDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @editing-start="initFormFields"
        :column-auto-width="true"
        column-resizing-mode="widget">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Fordelingsperiode" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="client-distribution-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="false"
          :allow-adding="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="registrationNumber">
              <dx-label text="Reg.nr." />
            </dx-simple-item>
            <dx-simple-item data-field="name">
              <dx-label text="Pengeinstitut" />
            </dx-simple-item>
            <dx-simple-item data-field="value">
              <dx-label text="Værdi" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="registrationNumber"
          caption="Reg.nr."
          data-type="number"
          sort-order="asc"
          :hiding-priority="8"
          :form-item="{ disabled: true }"
          width="130">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="name"
          caption="Pengeinstitut"
          data-type="string"
          :form-item="{ disabled: true }"
          :hiding-priority="7">
          <dx-required-rule />
        </dx-column>

         <dx-column
          data-field="value"
          caption="Værdi"
          data-type="number"
          sort-order="asc"
          :hiding-priority="6"
          width="130">
          <dx-required-rule />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              @onHistoryClick="router.push({ name: 'MasterDataClientDistributionHistory', params: { id: id, periodId: $event.key } })"
              @onEditClick="onEditClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import DetailView from '@/modules/masterData/clientDistributions/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/clientDistributions/composables/datagrid.composable.js';

export default {
  name: 'MainClientDistributionModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  props: {
    id: String,
    periodId: String,
  },
  setup(props) {
    const {
      datasource,
      getDatasource,
      initFormFields,
    } = useDatagrid(props.id, props.periodId);

    getDatasource();

    const router = useRouter();

    function onBackClick() {
      router.push({ name: 'MasterDataDistributionKeys' });
    }

    function onBackToSecondLevelClick() {
      router.push({ name: 'MasterDataDistributionKeyPeriods', params: { id: props.id } });
    }

    return {
      datasource,
      onBackClick,
      onBackToSecondLevelClick,
      router,
      initFormFields,
    };
  },
};
</script>

<style lang="scss">
.client-distribution-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }
}
</style>
