import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/cmsBIText/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const supplierList = reactive({ data: null });

  const loadSupplierList = () => {
    masterdataRepository.getSupplierList()
      .then((response) => {
        supplierList.data = response.data;
      });
  };

  const areaTypeList = reactive({ loadingCompleted: false, data: null });

  const loadAreaTypeList = () => {
    masterdataRepository.getAreaTypeList()
      .then((response) => {
        areaTypeList.loadingCompleted = true;
        areaTypeList.data = response.data;
      });
  };

  return {
    supplierList,
    loadSupplierList,
    areaTypeList,
    loadAreaTypeList,
  };
}
