<template>
  <div class="card-product-mapping-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Mapning af Card Product Id</div>
      </div>
    </div>

    <div class="content-block">
      <div class="supplier-container">
        Supplier:
        <dx-select-box
          :data-source="supplierList.data"
          display-expr="name"
          value-expr="id"
          v-model:value="supplierList.selectedSupplier"
          @value-changed="supplierChanged" />
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="cardProductMappingDataGrid"
        :data-source="datasource"
        height="75vh"
        :allow-column-resizing="true"
        :allow-grouping="true"
        column-resizing-mode="widget"
        :focused-row-key="focusedRowKey"
        @init-new-row="initNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="CardProductMapning" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="true" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="card-product-mapping-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="supplierId">
              <dx-label text="Leverandør" />
            </dx-simple-item>
            <dx-simple-item data-field="productId">
              <dx-label text="Product id" />
            </dx-simple-item>
            <dx-simple-item data-field="cardProductName">
              <dx-label text="Cardproduct navn" />
            </dx-simple-item>
            <dx-simple-item data-field="brandId">
              <dx-label text="Brand" />
            </dx-simple-item>
            <dx-simple-item data-field="productName">
              <dx-label text="Product navn" />
            </dx-simple-item>
            <dx-simple-item data-field="cardOrgCode">
              <dx-label text="Org. kode" />
            </dx-simple-item>
            <dx-simple-item data-field="credit" :editor-options="creditEditorOptions">
              <dx-label label="Credit" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="productId"
          caption="Product id"
          data-type="string"
          :hiding-priority="8"
          width="20%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="cardProductName"
          caption="Card product navn"
          data-type="string"
          :hiding-priority="7"
          width="20%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="cardOrgCode"
          caption="Org. kode"
          data-type="string"
          :hiding-priority="7"
          :visible="false"
          width="20%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="brands"
          :visible="false"
          caption="Brands"
          data-type="object"
          edit-cell-template="brandSelector">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="productName"
          caption="Product navn"
          data-type="string"
          :hiding-priority="6">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="brandId"
          caption="Brand"
          :hiding-priority="5"
          width="20%">
          <dx-grid-lookup
            :data-source="brandList.data"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="credit"
          :form-item="creditEditorOptions"
          caption="Debit/credit"
          data-type="boolean"
          false-text="Debit"
          true-text="Credit"
          :showEditorAlways="false"
          :hiding-priority="4">
        </dx-column>

        <dx-column
          data-field="supplierId"
          caption="Leverandør"
          :hiding-priority="3"
          :visible="false"
          data-type="number"
          :form-item="supplierFormItem"
          :allow-editing="false">
          <dx-grid-lookup
            :data-source="supplierList.data"
            value-expr="id"
            display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDataCompleted" />
          <div v-else>
            <DetailView
              :row="row"
              :supplierList="supplierList.data"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';
import DetailView from '@/modules/masterData/cardProductMapping/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/cardProductMapping/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/cardProductMapping/composables/masterdata.composable.js';

const supplierFormItem = {
  visible: false,
};

const creditEditorOptions = {
  text: 'Credit',
};

export default {
  name: 'MainCardProductMappingModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    ContentLoaderList,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      onInitNewRow,
    } = useDatagrid();

    const {
      supplierList,
      saveSelectedSupplierInStore,
      brandList,
      loadBrandList,
    } = useMasterdata();

    const loadingDataCompleted = computed(() => supplierList.loadingCompleted && brandList.loadingCompleted);

    // SupplierChanged eventhandler
    const supplierChanged = () => {
      const supplierId = supplierList.selectedSupplier;

      saveSelectedSupplierInStore(supplierId);
      getDatasource(supplierId);
      loadBrandList(supplierId);
    };

    return {
      datasource,
      supplierList,
      supplierChanged,
      saveSelectedSupplierInStore,
      onInitNewRow,
      supplierFormItem,
      loadingDataCompleted,
      brandList,
      creditEditorOptions,
    };
  },
  methods: {
    initNewRow(e) {
      this.onInitNewRow(e, this.supplierList.selectedSupplier);
    },
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne cardproduct mapping?<br/><b>${e.data.productId}</b>`, 'Slet cardproduct mapping');
    },
  },
};
</script>

<style lang="scss">
.card-product-mapping-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .supplier-container {
    padding: 20px;
    background-color: #FFF;
  }
}
</style>
