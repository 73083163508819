<template>
  <div class="distribution-key-period-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Fordelingsperioder</h2></div>
      </div>
      <div><u @click="onBackClick" class="link">Fordelingsnøgler</u> > Fordelingsperioder</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onBackClick"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="clientDistributionKeyPeriodDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        :column-auto-width="true"
        @init-new-row="onInitNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Fordelingsperioder" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="client-distribution-key-period-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Periode" />
            </dx-simple-item>
            <dx-simple-item data-field="startDate">
              <dx-label text="Startdato" />
            </dx-simple-item>
            <dx-simple-item data-field="endDate">
              <dx-label text="Slutdato" />
            </dx-simple-item>
            <dx-simple-item data-field="active" :editor-options="activeEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Periode"
          data-type="string"
          :hiding-priority="7"
          width="25%">
          <dx-required-rule />
        </dx-column>

       <dx-column
          data-field="startDate"
          caption="Startdato"
          data-type="date"
          :hiding-priority="6"
          format="dd.MM.yyyy"
          width="25%"
          sort-order="asc">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="endDate"
          caption="Slutdato"
          data-type="date"
          format="dd.MM.yyyy"
          :hiding-priority="5"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="active"
          caption="Aktiv"
          data-type="boolean"
          :hiding-priority="5">
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <CopyForm v-if="showCopyForm" v-model="copyModel" @save="onCopySave(row, storageKey)" @cancel="showCopyForm = false;" />
            <DetailView
              v-else
              :row="row"
              @onDistributionsClick="router.push({ name: 'MasterDataClientDistributions', params: { id: id, periodId: $event.key } })"
              @onActivateClick="onActivateClick"
              @onInactivateClick="onInactivateClick"
              @onCopyClick="onCopyClick"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import DetailView from '@/modules/masterData/distributionKeyPeriods/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/distributionKeyPeriods/composables/datagrid.composable.js';
import useCopy from '@/modules/masterData/distributionKeyPeriods/composables/copy.composable.js';
import CopyForm from '@/modules/masterData/distributionKeyPeriods/components/copy-form.vue';

const activeEditorOptions = {
  text: 'Aktiv',
};

export default {
  name: 'MainDistributionKeyPeriodModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    CopyForm,
  },
  props: {
    id: String,
  },
  setup(props) {
    const {
      datasource,
      getDatasource,
      onActivateClick,
      onInactivateClick,
      onInitNewRow,
    } = useDatagrid(props.id);

    getDatasource();

    const {
      onCopyClick,
      showCopyForm,
      copyModel,
      onCopySave,
    } = useCopy(props.id);

    const router = useRouter();

    function onBackClick() {
      router.push({ name: 'MasterDataDistributionKeys' });
    }

    return {
      datasource,
      activeEditorOptions,
      onBackClick,
      onActivateClick,
      onInactivateClick,
      onCopyClick,
      showCopyForm,
      copyModel,
      onCopySave,
      onInitNewRow,
      router,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne fordelingsperiode?<br/><b>${e.data.name}</b>`, 'Slet fordelingsperiode');
    },
  },
};
</script>

<style lang="scss">
.distribution-key-period-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }
}
</style>
