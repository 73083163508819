<template>
  <div class="externaldataset-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <div class="detail-element-container padding-right">
            Fil:
            <a href="#" @click.prevent="onDownloadFileClick(row.data.id, row.data.fileName)">{{ row.data.fileName }} </a>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container">
            Dato for indlæsning:
            <dx-date-box
              :value="row.data.created"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item v-if="row.data.statType !== 2">
          <dx-location :row="1" :col="0" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container padding-right">
            Totalbeløb:
            <dx-number-box
              :value="row.data.totalSpend"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithEightDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" v-if="row.data.statType !== 2"/>
          <dx-location :row="1" :col="0" v-else-if="row.data.statType === 2"/>
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container padding-right">
            Antal transaktioner:
            <dx-number-box
              :value="row.data.totalTransactions"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.number" />
          </div>
        </dx-item>

        <dx-item v-if="row.data.statType !== 2 && row.data.statType !== 3">
          <dx-location :row="2" :col="1" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container">
            Antal DPANs:
            <dx-number-box
              :value="row.data.totalDPANs"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.number" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="3" :col="0" />
          <dx-location screen="xs sm" :row="10" :col="0" />
          <div class="detail-element-container">
            Advarsler:
            <div v-if="!row.data.parsed">
              (under indlæsning)
            </div>
            <div v-else-if="numberOfWarnings > 0">
              <a @click="showWarnings(row.data.warningList)">Der er {{ numberOfWarnings }}  {{ numberOfWarnings === 1 ? 'advarsel' : 'advarsler' }}</a>
            </div>
            <div v-else>
              Ingen
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="3" :col="2" :rowspan="6" />
          <dx-location screen="xs sm" :row="11" :col="0" />
          <div>
            <div class="action-button-wrapper">
              <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button-large" />
            </div>
          </div>
        </dx-item>
      </dx-responsive-box>
    </div>
    <Popup
      :visible="showWarningsPopup"
      title="Advarsler"
      :content="warningsPopupContent"
      cancelButtonText="Luk"
      :showSaveButton="false"
      :showCancelButton="true"
      :showCloseButton="false"
      :dragEnabled="true"
      :width="600"
      :height="400"
      @onCancel="showWarningsPopup = false"
    />
  </div>
</template>

<script>
import { inject } from 'vue';
import NumberFormatting from '@/constants/number.formatting.js';
import Popup from '@/components/common/popup.vue';
import useWarnings from '@/modules/xpExternalDataSet/composables/warning.composable.js';
import useFileExport from '@/modules/xpExternalDataSet/composables/fileexport.composable.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  components: {
    Popup,
  },
  emits: ['onDeleteClick'],
  setup(props) {
    const formatService = inject('formatService');

    const {
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      getNumberOfWarnings,
    } = useWarnings();

    const numberOfWarnings = getNumberOfWarnings(props.row.data.warningList);

    const {
      onDownloadFileClick,
    } = useFileExport();

    return {
      NumberFormatting,
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      numberOfWarnings,
      formatService,
      onDownloadFileClick,
    };
  },
};
</script>

<style lang="scss">
.externaldataset-detail-view {
  .header {
    font-size: 18px;
    font-weight: 500;
  }

  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }

    .action-button-large {
      margin-bottom: 5px;
      width: 170px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 31px;
  }

  .publish-date-field-root {
    display: flex;

    .padding-right {
      padding-right: 20px;
    }
  }

  .red-text {
    font-weight:700;
    color: red;
    font-size: 18px;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
  }

  .nets-diff-color {
    color: #DDBB00;
  }

  .nets-no-diff-color {
    color: #00BB00;
  }

  .margin-right {
    margin-right: 5px;
  }
}
</style>
