<template>
  <div class="client-account-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Konti</h2></div>
      </div>
      <div><u @click="onBackClick" class="link">Pengeinstitutter</u> > Konti</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onBackClick"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="clientDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        :column-auto-width="true"
        @init-new-row="onInitNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Konti" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="mainClient-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="registrationNumber">
              <dx-label text="Reg.nr." />
            </dx-simple-item>
            <dx-simple-item data-field="accountNumber">
              <dx-label text="Kontonummer" />
            </dx-simple-item>
            <dx-simple-item data-field="description" editor-type="dxTextArea">
              <dx-label text="Beskrivelse" />
            </dx-simple-item>
            <dx-simple-item data-field="isPrimaryAccount" :editor-options="primaryAccountEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="isPrimaryMCConnectAccount" :editor-options="primaryMCCAccountEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="registrationNumber"
          caption="Reg.nr."
          data-type="number"
          sort-order="asc"
          :selected-filter-operation="selectedOperation"
          :hiding-priority="8">
          <dx-required-rule />
          <dx-string-length-rule
            :min="3"
            message="Kontos registreringsnummer skal angives med mindst 3 cifre."
          />
        </dx-column>

        <dx-column
          data-field="accountNumber"
          caption="Kontonummer"
          data-type="number"
          :selected-filter-operation="selectedOperation"
          :hiding-priority="7">
          <dx-required-rule />
          <dx-string-length-rule
            :min="3"
            message="Kontonummer skal angives med mindst 3 cifre."
          />
        </dx-column>

        <dx-column
          data-field="description"
          caption="Beskrivelse"
          data-type="string"
          :hiding-priority="6"
          :allow-resizing="true">
        </dx-column>

        <dx-column
          data-field="isPrimaryAccount"
          caption="Primær konto"
          data-type="boolean"
          :hiding-priority="5">
        </dx-column>

        <dx-column
          data-field="isPrimaryMCConnectAccount"
          caption="Primær MCC konto"
          data-type="boolean"
          :hiding-priority="4">
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import DetailView from '@/modules/masterData/clientAccounts/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/clientAccounts/composables/datagrid.composable.js';

const primaryAccountEditorOptions = {
  text: 'Primær konto',
};

const primaryMCCAccountEditorOptions = {
  text: 'Primær MC Connect konto',
};

const selectedOperation = 'contains';

export default {
  name: 'MainClientModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  props: {
    id: String,
  },
  setup(props) {
    const {
      datasource,
      getDatasource,
      onInitNewRow,
    } = useDatagrid(props.id);

    getDatasource();

    const router = useRouter();

    function onBackClick() {
      router.push({ name: 'MasterDataClients' });
    }

    return {
      datasource,
      primaryAccountEditorOptions,
      primaryMCCAccountEditorOptions,
      router,
      onBackClick,
      onInitNewRow,
      selectedOperation,
    };
  },
  methods: {
    initNewRow(e) {
      this.onInitNewRow(e);
    },
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne konto?<br/><b>${e.data.accountNumber}</b>`, 'Slet konto');
    },
  },
};
</script>

<style lang="scss">
.client-account-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }
}
</style>
