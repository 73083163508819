<template>
  <div class="card-product-mapping-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Product id:
          <dx-textbox
            :value="row.data.productId"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Product card navn:
          <dx-textbox
            :value="row.data.cardProductName"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="1" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Brand:
          <dx-textbox
            :value="row.data.brandName"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container padding-right">
          Product navn:
          <dx-textbox
            :value="row.data.productName"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container padding-right">
          Org. kode:
          <dx-textbox
            :value="row.data.cardOrgCode"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-top">
          <dx-checkbox
            :value="row.data.credit"
            :read-only="true"
            text="Credit"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container">
          Supplier:
          <dx-textbox
            :value="getSupplierById(row.data.supplierId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    supplierList: Array,
  },
  setup(props) {
    function getSupplierById(id) {
      const supplier = props.supplierList.find((x) => x.id === id);

      return get(supplier, ['name']);
    }

    return {
      getSupplierById,
    };
  },
};
</script>

<style lang="scss">
.card-product-mapping-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 25px;
  }
}
</style>
