<template>
  <div class="clientbillingentry-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <dx-location screen="xs sm" :row="0" :col="0" />
          <div class="detail-element-container padding-right">
            Tekst:
            <dx-textbox
              :value="row.data.text"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container padding-right">
            Varenummer:
            <dx-textbox
              :value="row.data.productIdentifier"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container padding-right">
            Pris:
            <dx-number-box
              :value="row.data.basePrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="1" />
          <dx-location screen="xs sm" :row="3" :col="0" />
          <div class="detail-element-container">
            Markup:
            <dx-number-box
              :value="row.data.markup"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container padding-right">
            Antal:
            <dx-number-box
              :value="row.data.quantity"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.number" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="1" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container">
            Total BOKIS-pris:
            <dx-number-box
              :value="row.data.totalPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

      </dx-responsive-box>
    </div>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  setup() {
    return {
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss" scoped>
.clientbillingentry-detail-view {
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 20px;
  }
}
</style>
