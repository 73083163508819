import {
  DxDataGrid,
  DxFilterRow,
  DxColumn,
  DxPager,
  DxPaging,
  DxButton as DxGridButton,
  DxEditing,
  DxForm,
  DxMasterDetail,
  DxHeaderFilter,
  DxSelection,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxStateStoring,
  DxScrolling,
  DxLookup as DxGridLookup,
  DxFormat,
  DxSummary,
  DxGroupItem as DxGridGroupItem,
  DxLoadPanel,
} from 'devextreme-vue/data-grid';
import {
  DxSimpleItem,
  DxButtonItem,
  DxGroupItem,
  DxPatternRule,
  DxEmptyItem,
  DxLabel,
  DxStringLengthRule,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import DxTextBox from 'devextreme-vue/text-box';
import DxDateBox from 'devextreme-vue/date-box';
import {
  DxResponsiveBox,
  DxItem,
  DxLocation,
  DxCol,
  DxRow,
} from 'devextreme-vue/responsive-box';
import {
  DxCheckBox,
} from 'devextreme-vue/check-box';
import {
  DxLookup,
} from 'devextreme-vue/lookup';
import DxTagBox from 'devextreme-vue/tag-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { locale, loadMessages } from 'devextreme/localization';
import DxValidationSummary from 'devextreme-vue/validation-summary';
import {
  DxValidator,
  DxCustomRule,
  DxRequiredRule,
} from 'devextreme-vue/validator';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTooltip } from 'devextreme-vue/tooltip';
import DxRadioGroup from 'devextreme-vue/radio-group';
import { DxSwitch } from 'devextreme-vue/switch';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import DxAccordion from 'devextreme-vue/accordion';
import daMessages from '@/localization/devextreme.da.json';

export default {
  install: (app) => {
    // Localize all devextreme messages
    loadMessages(daMessages);

    // Localize dates, numbers and currencies for devextreme components
    locale('da');

    app.component('dx-grid-button', DxGridButton);
    app.component('dx-data-grid', DxDataGrid);
    app.component('dx-filter-row', DxFilterRow);
    app.component('dx-column', DxColumn);
    app.component('dx-pager', DxPager);
    app.component('dx-paging', DxPaging);
    app.component('dx-lookup', DxLookup);
    app.component('dx-editing', DxEditing);
    app.component('dx-form', DxForm);
    app.component('dx-simple-item', DxSimpleItem);
    app.component('dx-button-item', DxButtonItem);
    app.component('dx-button', DxButton);
    app.component('dx-group-item', DxGroupItem);
    app.component('dx-master-detail', DxMasterDetail);
    app.component('dx-header-filter', DxHeaderFilter);
    app.component('dx-textbox', DxTextBox);
    app.component('dx-responsive-box', DxResponsiveBox);
    app.component('dx-item', DxItem);
    app.component('dx-location', DxLocation);
    app.component('dx-col', DxCol);
    app.component('dx-row', DxRow);
    app.component('dx-checkbox', DxCheckBox);
    app.component('dx-selection', DxSelection);
    app.component('dx-pattern-rule', DxPatternRule);
    app.component('dx-required-rule', DxRequiredRule);
    app.component('dx-string-length-rule', DxStringLengthRule);
    app.component('dx-export', DxExport);
    app.component('dx-grouping', DxGrouping);
    app.component('dx-group-panel', DxGroupPanel);
    app.component('dx-state-storing', DxStateStoring);
    app.component('dx-scrolling', DxScrolling);
    app.component('dx-tag-box', DxTagBox);
    app.component('dx-empty-item', DxEmptyItem);
    app.component('dx-grid-lookup', DxGridLookup);
    app.component('dx-label', DxLabel);
    app.component('dx-format', DxFormat);
    app.component('dx-number-box', DxNumberBox);
    app.component('dx-date-box', DxDateBox);
    app.component('dx-validation-summary', DxValidationSummary);
    app.component('dx-validator', DxValidator);
    app.component('dx-custom-validator', DxCustomRule);
    app.component('dx-popup', DxPopup);
    app.component('dx-tab-panel', DxTabPanel);
    app.component('dx-text-area', DxTextArea);
    app.component('dx-select-box', DxSelectBox);
    app.component('dx-toolbar-item', DxToolbarItem);
    app.component('dx-summary', DxSummary);
    app.component('dx-grid-group-item', DxGridGroupItem);
    app.component('dx-tooltip', DxTooltip);
    app.component('dx-radio-group', DxRadioGroup);
    app.component('dx-switch', DxSwitch);
    app.component('dx-dropdown-button', DxDropDownButton);
    app.component('dx-accordion', DxAccordion);
    app.component('dx-load-panel', DxLoadPanel);
  },
};
