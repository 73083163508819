import { ref } from 'vue';
import store from '@/store/index';
import useUserRepository from '@/modules/masterData/users/repositories/user.repository.js';
import rowStateService from '@/services/rowState.service.js';

export default function usePassword() {
  const showPasswordForm = ref(false);
  const passwordModel = ref();

  function onChangePasswordClick(row) {
    // Model for new password
    passwordModel.value = {
      id: row.data.id,
      password: row.data.password,
      newPassword: row.data.newPassword,
      repeatPassword: row.data.repeatPassword,
    };

    showPasswordForm.value = true;
  }

  const onPasswordSave = (row, storageKey) => {
    const userRepository = useUserRepository();

    const payload = {
      password: passwordModel.value.password,
      newPassword: passwordModel.value.newPassword,
      repeatPassword: passwordModel.value.repeatPassword,
    };

    userRepository.changePassword(passwordModel.value.id, payload)
      .then((response) => {
        if (response.data.success) {
          const datagrid = row.component;

          // Update datagrid with new data
          datagrid.refresh();

          // Hide Copy form
          showPasswordForm.value = false;

          // Collapse all rows and remove current open row from storage
          datagrid.collapseAll(-1);
          rowStateService.removeKeyFromStorage(storageKey, row.key);

          // Expand new row and add newId to storage
          const newId = response.data.value;
          row.component.expandRow(newId);
          rowStateService.addKeyToStorage(storageKey, newId);

          store.dispatch('addSuccessNotification', 'Adgangskode ændret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    onChangePasswordClick,
    showPasswordForm,
    passwordModel,
    onPasswordSave,
  };
}
