<template>
  <div class="manualhandling-list-root datagrid-root">
    <div class="content-block">
      <Headline title="Eksterne dataposter til manuel håndtering" :contextData="billingSetEntry" />
      <div>
        <u @click="router.push({ name: 'BillingSet'})" class="link">Kørsler</u> >
        <u @click="router.push({ name: 'ExternalDataSetList', params: { id: billingSetId }})" class="link">Leverandør datasæt</u> > Eksterne dataposter til manuel håndtering
      </div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="router.push({ name: 'ExternalDataSetList', params: { id: billingSetId }})"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="manualHandlingDataGrid"
        :data-source="datasource"
        height="80vh"
        :focused-row-key="focusedRowKey">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="ManuelHåndtering" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="manualHandling-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing :confirm-delete="false" />

        <dx-column
          caption=""
          :hiding-priority="9"
          :width="50"
          cell-template="handledTemplate" />

        <dx-column
          caption="HReg"
          data-field="hregNo"
          :width="100"
          :hiding-priority="8" />

        <dx-column
          data-field="productIdentifier"
          caption="Varenummer"
          :hiding-priority="7"
          sort-order="desc"
          :width="100" />

        <dx-column
          data-field="productName"
          caption="Varenavn"
          :hiding-priority="6"
          :width="450" />

        <dx-column
          data-field="externalText"
          caption="Supplerende tekst"
          :hiding-priority="5" />

        <dx-column
          data-field="quantity"
          caption="Antal"
          :hiding-priority="4"
          :width="100" />

        <dx-column
          data-field="clientDistributionCount"
          caption="Fordelt på PI"
          :hiding-priority="3"
          :width="150" />

        <dx-column
          data-field="netsTotalPrice"
          caption="Nets pris"
          :format="NumberFormatting.kronerWithTwoDecimals"
          :hiding-priority="2"
          :width="150" />

        <dx-column
          data-field="bokisTotalPriceInclMarkup"
          caption="BOKIS totalpris"
          :format="NumberFormatting.kronerWithTwoDecimals"
          :hiding-priority="1"
          :width="150"
          cell-template="bokisTotalPriceInclMarkup" />

        <dx-column
          caption="Status"
          :hiding-priority="0"
          :width="250"
          cell-template="statusTemplate" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDatasourceCompleted" />
          <div v-else>
            <DetailView
              :row="row"
              @onEditClick="editManualBilling"
              @onDeleteClick="showConfirmPopup"
              @onClickOnceClick="createManualBillingEntryUsingClickOnce"
              @onCreateManualClick="onCreateManualClick" />
          </div>
        </template>

        <template #bokisTotalPriceInclMarkup="{ data }">
          <div>
            {{ data.data.bokisTotalPriceInclMarkup == null ? 'Trappe' : formatService.formatNumber(data.data.bokisTotalPriceInclMarkup, 2, 2) + ' kr.' }}
          </div>
        </template>

        <template #statusTemplate="{ data }">
          <div>
            {{ data.data.entryIsHandled ? 'Håndteret' : data.data.statusText }}
          </div>
        </template>

        <template #handledTemplate="{ data }">
          <div>
            <i v-if="!data.data.entryIsHandled" class="dx-icon-warning margin-right" title="Ikke håndteret" />
            <i v-else class="dx-icon-check margin-right" title="Håndteret" />
          </div>
        </template>
      </dx-data-grid>
      <Popup v-if="showConfirmDeletePopup"
        :title="deleteModel.title"
        :visible="showConfirmDeletePopup"
        :content="deleteModel.content"
        :saveButtonText="deleteModel.saveButtonText"
        :cancelButtonText="deleteModel.cancelButtonText"
        @onSave="onDeleteSave"
        @onCancel="onDeleteCancel" />
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import DetailView from '@/modules/externalDataSet/components/manualHandling/detail-view.vue';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useManualHandlingDatagrid from '@/modules/externalDataSet/composables/manualhandling.datagrid.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';
import useDeleteManualHandling from '@/modules/externalDataSet/composables/delete.manualhandling.composable.js';
import Popup from '@/components/common/popup.vue';
import useManualBillingEntry from '@/modules/externalDataSet/composables/manualbillingentry.composable.js';
import useSharedBillingSet from '@/modules/shared/composables/billingset.composable.js';
import Headline from '@/components/common/headline.vue';

export default {
  name: 'ManualHandlingList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    ContentLoaderList,
    Popup,
    Headline,
  },
  props: {
    billingSetId: String,
    externalDataSetId: String,
  },
  setup(props) {
    const formatService = inject('formatService');
    const dataGrid = ref();

    const {
      loadingDatasourceCompleted,
      datasource,
    } = useManualHandlingDatagrid(props.billingSetId, props.externalDataSetId);

    const {
      showConfirmPopup,
      showConfirmDeletePopup,
      deleteModel,
      onDeleteSave,
      onDeleteCancel,
    } = useDeleteManualHandling(props.billingSetId, dataGrid);

    const router = useRouter();

    const editManualBilling = (manualBillingEntryId) => {
      router.push({
        name: 'ManualBilling',
        params: {
          _billingSetId: props.billingSetId,
          _manualBillingId: manualBillingEntryId,
        },
        query: {
          referer: 'manualHandling',
          externalDataSetId: props.externalDataSetId,
        },
      });
    };

    const dropDownOptions = { width: 450 };

    const {
      createManualBillingEntryUsingClickOnce,
      createManualBillingEntryManually,
    } = useManualBillingEntry(props.billingSetId, dataGrid);

    const onCreateManualClick = (externalDataFileEntryId) => {
      createManualBillingEntryManually(externalDataFileEntryId)
        .then((response) => {
          if (response.data.success) {
            const manualBillingEntryId = response.data.value;
            editManualBilling(manualBillingEntryId);
          }
        });
    };

    const {
      loadBillingSet,
      billingSetEntry,
    } = useSharedBillingSet();
    loadBillingSet(props.billingSetId);

    return {
      formatService,
      loadingDatasourceCompleted,
      datasource,
      router,
      dropDownOptions,
      NumberFormatting,
      dataGrid,
      showConfirmDeletePopup,
      deleteModel,
      onDeleteSave,
      onDeleteCancel,
      showConfirmPopup,
      editManualBilling,
      createManualBillingEntryUsingClickOnce,
      onCreateManualClick,
      billingSetEntry,
    };
  },
};
</script>

<style lang="scss" scoped>
.manualhandling-list-root {
  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }

  .grid-button-container .report-button {
    margin-right: 46px;
  }

  .recalculate-button, .create-button {
    margin-right: 5px
  }

  .dx-icon-warning {
    font-size: 20px;
    color: red;
  }

  .dx-icon-check {
    font-size: 20px;
    color: green;
  }

  .margin-right {
    margin-right: 7px;
  }
}
</style>
