<template>
  <AttachmentModule />
</template>

<script>
import AttachmentModule from '@/modules/masterData/cmsAttachments/components/main.vue';

export default {
  name: 'MasterDataAttachment',
  components: {
    AttachmentModule,
  },
};
</script>
