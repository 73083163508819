<template>
  <div>
    Du bliver nu logget ind....
  </div>
</template>

<script>
import Oidc from 'oidc-client';
import store from '@/store/index';

export default {
  created() {
    const self = this;

    new Oidc
      .UserManager({ response_mode: 'query' })
      .signinRedirectCallback()
      .then((result) => {
        const roles = result.profile.role;
        const returnUrl = result.state;

        let roleArray = [];
        if (Array.isArray(roles)) {
          roleArray = roles;
        } else if (typeof roles === 'string') {
          roleArray.push(roles);
        }

        const payload = {
          roles: roleArray,
          name: result.profile.name,
          email: result.profile.email,
        };

        // Update user in Vuex store
        store.commit('updateUser', payload);

        // Redirect user
        self.$router.push({ path: returnUrl });
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  },
};
</script>
