import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/cmsInfoArea/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const contactPersonList = reactive({ data: null });

  const loadContactPersonList = () => {
    masterdataRepository.getContactPersonList()
      .then((response) => {
        contactPersonList.data = response.data.value;
      });
  };

  const supplierList = reactive({ data: null });

  const loadSupplierList = () => {
    masterdataRepository.getSupplierList()
      .then((response) => {
        supplierList.data = response.data;
      });
  };

  const pageLocationTypeList = reactive({ data: null });

  const loadPageLocationTypeList = () => {
    masterdataRepository.getPageLocationTypeList()
      .then((response) => {
        pageLocationTypeList.data = response.data;
      });
  };

  return {
    loadContactPersonList,
    contactPersonList,
    loadSupplierList,
    supplierList,
    loadPageLocationTypeList,
    pageLocationTypeList,
  };
}
