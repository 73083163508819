<template>
  <div class="datagrid-root">
    <div class="content-block">
      <h2>Prisperioder</h2>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="prisPeriodeDataGrid"
        :data-source="datasource"
        height="80vh"
        :focused-row-key="focusedRowKey"
        @editor-preparing="onEditorPreparing"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Prisperioder" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="period-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form"
          :form="{
            showValidationSummary: true,
            labelLocation: 'top',
          }" />

        <dx-column
          data-field="name"
          caption="Navn"
          :hiding-priority="8">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="supplierId"
          caption="Supplier"
          :hiding-priority="5"
          :group-index="0">
          <dx-grid-lookup
            :data-source="supplierList"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="startDate"
          caption="Startdato"
          data-type="date"
          :hiding-priority="6"
          format="dd.MM.yyyy"
          :width="150"
          sort-order="asc">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="endDate"
          caption="Slutdato"
          data-type="date"
          format="dd.MM.yyyy"
          :hiding-priority="7"
          :width="150">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="markup"
          data-type="number"
          caption="Markup"
          :visible="false"
          :hiding-priority="2"
          :editor-options="editorMarkup">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="active"
          caption="Aktiv"
          data-type="boolean"
          :hiding-priority="3"
          :width="90"
          :form-item="activeFormItem" />

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDataCompleted" />
          <div v-else>
            <CopyForm v-if="showCopyForm" v-model="copyModel" @save="onCopySave(row, storageKey)" @cancel="showCopyForm = false;" />
            <MarkupForm v-else-if="showMarkupForm" v-model="markupModel" @save="onMarkupSave(row)" @cancel="showMarkupForm = false" />
            <PriceindexForm v-else-if="showPriceindexForm" v-model="priceindexModel" @save="onPriceindexSave()" @cancel="showPriceindexForm = false" />
            <DetailView
              v-else
              :row="row"
              :supplierList="supplierList"
              @onEditClick="onEditClick"
              @onActivateClick="onActivateClick"
              @onInactivateClick="onInactivateClick"
              @onCopyClick="onCopyClick"
              @onDeleteClick="onDeleteClick"
              @onPrislisteClick="onPrislisteClick"
              @onMarkupClick="onMarkupClick"
              @onPriceindexClick="onPriceindexClick"
              @onServicesClick="onServicesClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import DetailView from '@/modules/pricePeriods/components/detail-view.vue';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/pricePeriods/composables/datagrid.composable.js';
import useMasterdata from '@/modules/pricePeriods/composables/masterdata.composable.js';
import CopyForm from '@/modules/pricePeriods/components/copy-form.vue';
import useCopy from '@/modules/pricePeriods/composables/copy.composable.js';
import useMarkup from '@/modules/pricePeriods/composables/markup.composable.js';
import MarkupForm from '@/modules/pricePeriods/components/markup-form.vue';
import usePriceindex from '@/modules/pricePeriods/composables/priceindex.composable.js';
import PriceindexForm from '@/modules/pricePeriods/components/priceindex-form.vue';
import NumberFormatting from '@/constants/number.formatting.js';

const activeFormItem = {
  visible: false,
};

export default {
  name: 'PricePeriodList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    ContentLoaderList,
    CopyForm,
    MarkupForm,
    PriceindexForm,
  },
  setup() {
    const {
      datasource,
      onInactivateClick,
      onActivateClick,
      onEditorPreparing,
    } = useDatagrid();

    const {
      loadingSupplierListCompleted,
      supplierList,
    } = useMasterdata();

    const {
      onCopyClick,
      showCopyForm,
      copyModel,
      onCopySave,
    } = useCopy();

    const {
      onMarkupClick,
      showMarkupForm,
      markupModel,
      onMarkupSave,
    } = useMarkup();

    const {
      onPriceindexClick,
      showPriceindexForm,
      priceindexModel,
      onPriceindexSave,
    } = usePriceindex();

    const loadingDataCompleted = computed(() => loadingSupplierListCompleted.value);

    const router = useRouter();

    function onPrislisteClick(row) {
      const id = row.key;
      router.push({ name: 'Prices', params: { id } });
    }

    function onServicesClick(row) {
      const id = row.key;
      router.push({ name: 'PriceProductGroups', params: { id } });
    }

    const editorMarkup = {
      format: NumberFormatting.percentage,
    };

    return {
      loadingDataCompleted,
      supplierList,
      datasource,
      onPrislisteClick,
      onActivateClick,
      onInactivateClick,
      onCopyClick,
      onEditorPreparing,
      showCopyForm,
      copyModel,
      onCopySave,
      editorMarkup,
      onMarkupClick,
      showMarkupForm,
      markupModel,
      onMarkupSave,
      onPriceindexClick,
      showPriceindexForm,
      priceindexModel,
      onPriceindexSave,
      onServicesClick,
      activeFormItem,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne prisperiode?<br/><b>${e.data.name}</b>`, 'Slet prisperiode');
    },
  },
};
</script>
