<template>
  <dx-popup
    :visible="isVisible"
    :drag-enabled="dragEnabled"
    :close-on-outside-click="true"
    :show-title="true"
    :width="width"
    :height="height"
    :title="title"
    :showCloseButton="showCloseButton"
    @hidden="$emit('onCancel')">
      <div class="popup-content-root">
        <div class="row">
          Navn:
          <dx-textbox v-model:value="selectionName" />
        </div>
        <div class="button-row">
          <div v-if="showSaveButton" class="action-button-wrapper">
            <dx-button :text="saveButtonText" @click="$emit('onSave')" />
          </div>
          <div v-if="showCancelButton" class="action-button-wrapper">
            <dx-button :text="cancelButtonText" @click="$emit('onCancel')" />
          </div>
        </div>
      </div>
  </dx-popup>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Popup',
  components: {
  },
  props: {
    modelValue: Object,
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: 'Gem',
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Annuller',
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    dragEnabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'presetChanged', 'savePreset', 'savePresetAs', 'deletePreset', 'onCancel'],
  computed: {
    selectionName: {
      get() {
        return this.modelValue.selectionName;
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, selectionName: value });
      },
    },
  },
  setup(props) {
    const isVisible = computed(() => props.visible);

    return {
      isVisible,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup-content-root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;

    .action-button-wrapper {
      margin-left: 15px;
    }
  }
}
</style>
