import 'devextreme/dist/css/dx.common.css';
import './themes/theme.base.css';
import './themes/theme.additional.css';
import { createApp } from 'vue';
import axios from 'axios';
import router from './router';
import devExtremePlugin from './plugins/devextreme';
import filterPlugin from './plugins/filters';
import loggerPlugin from '@/plugins/logger';
import App from './App';
import store from '@/store/index.js';
import appInfo from './app-info';
import formatService from '@/services/formatter.service.js';

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
});

// Start by loading config variables
axiosInstance.get('appsettings.json')
  .then((config) => {
    // Add appsettings to Vuex store
    store.commit('updateAppsettings', config.data);

    const app = createApp(App);
    app.use(router);
    app.use(devExtremePlugin);
    app.use(filterPlugin);
    app.provide('appsettings', config.data);
    app.use(loggerPlugin);
    app.config.globalProperties.$appInfo = appInfo;
    app.use(store);
    app.provide('formatService', formatService);
    app.mount('#app');
  });
