<template>
  <div class="deviation-list-root">
    <dx-data-grid
      :data-source="dataSource"
      :column-auto-width="true">

      <dx-export :enabled="false" fileName="Afvigelser" />
      <dx-filter-row :visible="false" />
      <dx-header-filter :visible="false" />
      <dx-scrolling mode="virtual" />
      <dx-paging :page-size="50" />

      <dx-column
        caption="Varenummer"
        :hiding-priority="2"
        :allow-sorting="false"
        data-field="productIdentifier" />

      <dx-column
        caption="Beløb"
        :hiding-priority="1"
        :allow-sorting="false"
        data-field="deviatingAmount"
        :format="NumberFormatting.kronerWithFourDecimals" />
    </dx-data-grid>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'DeviationList',
  props: {
    dataSource: {
      type: Object,
    },
  },
  setup() {
    return {
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss">
.deviation-list-root {
  .dx-datagrid {
    background-color: transparent;
    padding: 0px;
  }

  .dx-row:hover {
    cursor: auto;
  }
}
</style>
