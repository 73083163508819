<template>
  <MCCClientDataSetList />
</template>

<script>
import MCCClientDataSetList from '@/modules/mccClientDataSet/components/list.vue';

export default {
  name: 'ClientDataSets',
  components: {
    MCCClientDataSetList,
  },
};
</script>
