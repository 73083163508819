import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/billingSetTypes/repositories/masterdata.repository.js';
import store from '@/store/index';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();
  const supplierList = reactive({ loadingCompleted: false, data: null, selectedSupplier: null });

  const saveSelectedSupplierInStore = (supplierId) => {
    store.commit('masterData/updateBillingSetTypeSelectedSupplier', supplierId);
  };

  masterdataRepository.getSupplierList()
    .then((response) => {
      const list = response.data;

      supplierList.loadingCompleted = true;
      supplierList.data = list;

      let { selectedSupplier } = store.state.masterData.billingSetTypes;

      if (selectedSupplier == null) {
        // Set 'Nets efter 2020' as the default selected supplier
        const netsAfter2020 = list.find((x) => x.name.startsWith('Nets efter 2020'));

        if (netsAfter2020 != null) {
          selectedSupplier = netsAfter2020.id;
          saveSelectedSupplierInStore(selectedSupplier);
        }
      }

      supplierList.selectedSupplier = selectedSupplier;
    });

  return {
    supplierList,
    saveSelectedSupplierInStore,
  };
}
