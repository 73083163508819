import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useCardProductMappingRepository from '@/modules/masterData/cardProductMapping/repositories/cardProductMapping.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const mainCardProductMappingRepository = useCardProductMappingRepository();
  const datasource = ref();

  const getDatasource = (supplierId) => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainCardProductMappingRepository.load(loadOptions, supplierId)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      update: (key, values) => new Promise((resolve, reject) => {
        mainCardProductMappingRepository.update(key, values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      insert: (values) => new Promise((resolve, reject) => {
        mainCardProductMappingRepository.insert(values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Card product mapping er nu oprettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      remove: (key) => new Promise((resolve, reject) => {
        mainCardProductMappingRepository.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Card product mapping er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const onInitNewRow = (e, supplierId) => {
    // Set default value
    e.data.supplierId = supplierId;
    e.data.credit = false;
  };

  return {
    datasource,
    getDatasource,
    onInitNewRow,
  };
}
