import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/brands/repositories/masterdata.repository.js';
import store from '@/store/index';
import SupplierInvoiceType from '@/enums/supplier-invoice-type.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();
  const supplierList = reactive({ loadingCompleted: false, data: null, selectedSupplier: null });

  const saveSelectedSupplierInStore = (supplierId) => {
    store.commit('masterData/updateBrandSelectedSupplier', supplierId);
  };

  const p1 = masterdataRepository.getSupplierList();
  const p2 = masterdataRepository.getSupplierList(SupplierInvoiceType.DKVEDERLAG);

  Promise.all([p1, p2]).then((res) => {
    const list = [...res[0].data, ...res[1].data];

    supplierList.loadingCompleted = true;
    supplierList.data = list;

    let { selectedSupplier } = store.state.masterData.brands;

    if (selectedSupplier == null) {
      // Set 'Nets efter 2020' as the default selected supplier
      const netsAfter2020 = list.find((x) => x.name.startsWith('Nets efter 2020'));

      if (netsAfter2020 != null) {
        selectedSupplier = netsAfter2020.id;
        saveSelectedSupplierInStore(selectedSupplier);
      }
    }

    supplierList.selectedSupplier = selectedSupplier;
  });

  return {
    supplierList,
    saveSelectedSupplierInStore,
  };
}
