<template>
  <div class="jobs-panel-root">
    <dx-accordion
      :data-source="jobList"
      key-expr="id"
      :collapsible="false"
      :multiple="false"
      item-title-template="titleTemplate"
      item-template="itemTemplate"
      v-model:selected-items="selectedItems">
      <template #titleTemplate="{ data }">
        <div class="header">
          <div class="icon">
            <JobStatusIcon
              :showNumberOfJobs="false"
              :icon="getIcon(data)"
              :rotate="rotate(data)" />
          </div>
          <div class="title">
            {{ data.name }}
          </div>
        </div>
      </template>
      <template #itemTemplate="{ data }">
        <div class="description">
          <dx-button text="Markér som læst" @click="markJobsAsRead([data.id])" :disabled="disableMarkJobAsReadButton(data)" />
          <br><br>
          <div v-if="data.description">
            {{ data.description }}<br/><br/>
          </div>
          <table>
            <tr v-if="data.status === JobStatus.FAILED">
              <td colspan="2">
                Fejl: <dx-button text="Detaljer" @click="openPopup(data.description, data.errors)" />
              </td>
            </tr>
            <tr v-if="data.startDateTime !== null">
              <td>
                Start:
              </td>
              <td>
                {{ formatService.stringToDate(data.startDateTime, 'DD.MM.YYYY h:mm:ss') }}
              </td>
            </tr>
            <tr v-if="data.endDateTime !== null">
              <td>
                Slut:
              </td>
              <td>
                {{ formatService.stringToDate(data.endDateTime, 'DD.MM.YYYY h:mm:ss') }}
              </td>
            </tr>
            <tr>
              <td>
                Bruger:
              </td>
              <td>
                {{ data.userName }}
              </td>
            </tr>
            <tr v-if="data.status !== JobStatus.IDLE">
              <td>
                Status:
              </td>
              <td>
                <span v-if="data.status == JobStatus.RUNNING">Kører</span>
                <span v-else-if="data.status == JobStatus.WAITING">Afventer</span>
                <span v-else-if="data.status == JobStatus.FAILED">Fejl</span>
                <span v-else-if="data.status == JobStatus.COMPLETED">Færdig</span>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </dx-accordion>
    <Popup v-if="showPopup"
      :width="600"
      :height="400"
      :title="jobDescription"
      :visible="showPopup"
      :content="jobErrors"
      saveButtonText=""
      cancelButtonText="Annuller"
      :showSaveButton="false"
      @onCancel="closePopup" />
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import useJob from '@/modules/shared/composables/job.composable.js';
import JobStatusIcon from '@/components/common/job-status-icon.vue';
import JobStatus from '@/enums/job-status.js';
import JobStatusIconEnum from '@/enums/job-status-icon.js';
import Popup from '@/components/common/popup.vue';

export default {
  name: 'JobsPanel',
  components: {
    JobStatusIcon,
    Popup,
  },
  setup() {
    const selectedItems = ref([]);
    const formatService = inject('formatService');

    const {
      jobList,
      markJobsAsRead,
    } = useJob();

    const getIcon = (data) => {
      switch (data.status) {
        case JobStatus.IDLE:
        case JobStatus.WAITING:
        case JobStatus.RUNNING:
          return JobStatusIconEnum.REFRESH;
        case JobStatus.FAILED:
          return JobStatusIconEnum.WARNING;
        case JobStatus.COMPLETED:
          return JobStatusIconEnum.CHECK;
        default:
          return '';
      }
    };

    const rotate = (data) => (!!(data.status === JobStatus.IDLE || data.status === JobStatus.WAITING || data.status === JobStatus.RUNNING));

    const disableMarkJobAsReadButton = (data) => (!!(data.status === JobStatus.IDLE || data.status === JobStatus.WAITING || data.status === JobStatus.RUNNING));

    const showPopup = ref(false);
    const jobDescription = ref('');
    const jobErrors = ref('');

    const openPopup = (description, error) => {
      jobDescription.value = description;
      jobErrors.value = `<div style="white-space: break-spaces;">${error}</div>`;
      showPopup.value = true;
    };

    const closePopup = () => {
      showPopup.value = false;
    };

    return {
      jobList,
      selectedItems,
      getIcon,
      rotate,
      markJobsAsRead,
      JobStatus,
      formatService,
      disableMarkJobAsReadButton,
      showPopup,
      closePopup,
      jobErrors,
      jobDescription,
      openPopup,
    };
  },
};
</script>

<style lang="scss" scoped>
.jobs-panel-root {
  height: 100%;
  width: 250px !important;
  background-color: #FFF;
  overflow-y: scroll;

  .icon {
    float: left;
    margin-right: 10px
  }

  .header {
    min-height: 40px;
  }

  .title {
    font-size: 13px;
    font-weight: 700;
  }

  .description {
    word-break: break-word;
  }
}
</style>
