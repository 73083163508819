<template>
  <div class="product-price-group-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Services</h2></div>
        <div class="subheadline-item">{{ overviewInfo.loadingCompleted ? overviewInfo.data.headline : '' }}</div>
      </div>
      <div><u @click="onBackClick" class="link">Prisperioder</u> > Services</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onBackClick"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="productPriceGroupDataGrid"
        :data-source="datasource"
        height="80vh"
        :focused-row-key="focusedRowKey"
        @editor-preparing="onEditorPreparing"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Services" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="productPriceGroup-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="pricePeriod && !pricePeriod.active"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="number">
              <dx-label text="Servicenummer" />
            </dx-simple-item>
            <dx-simple-item data-field="internal" :editor-options="internalEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="definesVolumeBasedPrices" :editor-options="volumebasedEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="volumeBasedPricesIndexed" :editor-options="priceIndeksEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="volumeBasedPricesTier" :editor-options="tierEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Navn"
          :hiding-priority="8">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="number"
          caption="Servicenummer"
          :hiding-priority="7"
          :width="130">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="definesVolumeBasedPrices"
          caption="Har trappe"
          data-type="boolean"
          :hiding-priority="6"
          :width="130"
          :set-cell-value="setCellValue" />

        <dx-column
          data-field="internal"
          caption="Intern"
          data-type="boolean"
          :hiding-priority="5"
          :width="130" />

        <dx-column
          data-field="volumeBasedPricesIndexed"
          caption="Indeksreg."
          data-type="boolean"
          :hiding-priority="4"
          :width="130" />

        <dx-column
          data-field="volumeBasedPricesTier"
          caption="Tier-trappe"
          data-type="boolean"
          :hiding-priority="3"
          :width="130" />

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <VolumebasedPriceForm v-if="showVolumebasedPriceForm" :pricePeriodActive="pricePeriod.active" :pricePeriodId="parseInt(id)" :productPriceGroupId="row.data.id" @cancel="showVolumebasedPriceForm = false" />
            <DetailView
              v-else
              :row="row"
              :pricePeriod="pricePeriod"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick"
              @onVolumebasedPriceClick="onVolumebasedPriceClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import DetailView from '@/modules/productPriceGroups/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/productPriceGroups/composables/datagrid.composable.js';
import useMasterdata from '@/modules/productPriceGroups/composables/masterdata.composable.js';
import useVolumebasedPriceDatagrid from '@/modules/productPriceGroups/composables/volumebasedPrice.datagrid.composable.js';
import VolumebasedPriceForm from '@/modules/productPriceGroups/components/volumebased-price-form.vue';

const internalEditorOptions = {
  text: 'Intern service',
};

const volumebasedEditorOptions = {
  text: 'Har pristrappe',
};

const priceIndeksEditorOptions = {
  text: 'Trappen prisindeksreguleres',
};

const tierEditorOptions = {
  text: 'Trappen er en tier-trappe',
};

export default {
  name: 'ProductPriceGroupList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    VolumebasedPriceForm,
  },
  props: {
    id: String,
  },
  setup(props) {
    const {
      datasource,
      onEditorPreparing,
      setCellValue,
    } = useDatagrid(props.id);

    const {
      overviewInfo,
      pricePeriod,
    } = useMasterdata(props.id);

    const {
      onVolumebasedPriceClick,
      showVolumebasedPriceForm,
    } = useVolumebasedPriceDatagrid();

    const router = useRouter();

    function onBackClick() {
      router.push({ name: 'PricePeriods' });
    }

    return {
      datasource,
      onEditorPreparing,
      onBackClick,
      overviewInfo,
      internalEditorOptions,
      tierEditorOptions,
      volumebasedEditorOptions,
      priceIndeksEditorOptions,
      setCellValue,
      onVolumebasedPriceClick,
      showVolumebasedPriceForm,
      pricePeriod,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne service?<br/><b>${e.data.name}</b>`, 'Slet service');
    },
  },
};
</script>

<style lang="scss">
.product-price-group-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 200;
    padding-left: 20px;
  }
}
</style>
