<template>
  <div class="bi-graph-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Navn:
          <dx-textbox
            :value="row.data.name"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Graph angivelse:
          <dx-number-box
            :value="row.data.graphType"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Dato for visning af data:
          <dx-textbox
            :value="formatService.stringToDate(row.data.dateForPublishedData)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Start år:
          <dx-number-box
            :value="row.data.defaultYear"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="4" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Besked omkring data:
          <dx-text-area
            :value="row.data.noDataMessage"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button-large" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="event" :text="row.data.dateForPublishedData == null ? 'Publicer' : 'Dato for visning af data'" @click="$emit('onPublishDateClick', row)" class="action-button-large" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  setup() {
    const formatService = inject('formatService');

    return {
      formatService,
    };
  },
};
</script>

<style lang="scss">
.bi-graph-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button-large {
      margin-bottom: 5px;
      width: 220px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }
}
</style>
