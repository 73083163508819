<template>
  <div class="cms-info-area-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>CMS</h2></div>
        <div class="subheadline-item">&nbsp; - Info-område</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="cmsInfoAreaDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="InfoOmråde" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="cmsInfoArea-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="title">
              <dx-label text="Titel" />
            </dx-simple-item>
            <dx-simple-item
              data-field="text"
              editor-type="dxTextArea"
              :editor-options="textAreaOptions">
              <dx-label text="Tekst" />
            </dx-simple-item>
            <dx-simple-item
              data-field="supplierId"
              editor-type="dxSelectBox"
              :editor-options="supplierOptions">
              <dx-label text="Leverandør" />
            </dx-simple-item>
            <dx-simple-item data-field="pageLocationId">
              <dx-label text="Område" />
            </dx-simple-item>
            <dx-simple-item data-field="contactPersonIdList">
              <dx-label text="Kontaktpersoner" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="title"
          caption="Titel"
          data-type="string"
          :hiding-priority="7"
          width="300">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="text"
          caption="Tekst"
          :hiding-priority="6"
          :visible="false">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="contactPersonIdList"
          caption="Kontakpersoner"
          :hiding-priority="5"
          edit-cell-template="contactPersonSelector"
          :visible="false">
          <dx-grid-lookup
            :data-source="contactPersonList.data"
            value-expr="id"
            display-expr="name" />
        </dx-column>

        <dx-column
          data-field="supplierId"
          caption="Leverandør"
          :hiding-priority="6"
          data-type="number">
          <dx-grid-lookup
            :data-source="supplierList.data"
            value-expr="id"
            display-expr="name"
            :show-clear-button="true" />
        </dx-column>

        <dx-column
          data-field="pageLocationId"
          caption="Område"
          :hiding-priority="6"
          data-type="number">
          <dx-grid-lookup
            :data-source="pageLocationTypeList.data"
            value-expr="id"
            display-expr="name" />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              :contactPersonList="contactPersonList.data"
              :supplierList="supplierList.data"
              :pageLocationTypeList="pageLocationTypeList.data"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>

        <template #contactPersonSelector="{ data }">
          <div>
            <dx-tag-box
              :items="contactPersonList.data"
              value-expr="id"
              display-expr="name"
              :show-selection-controls="true"
              :search-enabled="true"
              :value="data.value"
              @value-changed="data.setValue($event.value)" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import DetailView from '@/modules/masterData/cmsInfoArea/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/cmsInfoArea/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/cmsInfoArea/composables/masterdata.composable.js';

export default {
  name: 'CMSInfoAreaModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  setup() {
    const {
      datasource,
      getDatasource,
    } = useDatagrid();

    getDatasource();

    const {
      loadContactPersonList,
      contactPersonList,
      loadSupplierList,
      supplierList,
      loadPageLocationTypeList,
      pageLocationTypeList,
    } = useMasterdata();

    loadContactPersonList();
    loadSupplierList();
    loadPageLocationTypeList();

    const textAreaOptions = {
      height: 200,
    };

    const supplierOptions = {
      showClearButton: true,
    };

    return {
      datasource,
      contactPersonList,
      textAreaOptions,
      supplierList,
      pageLocationTypeList,
      supplierOptions,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette dette område?<br/><b>${e.data.title}</b>`, 'Slet info-område?');
    },
  },
};
</script>

<style lang="scss" scoped>
.cms-info-area-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 0;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }
}
</style>
