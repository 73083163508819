import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useFusionRepository() {
  const appsettings = inject('appsettings');

  const getFusion = (clientId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clients/${clientId}/fusion`);

  const getClients = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clients`);

  const updateFusion = (clientId, payload) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clients/${clientId}/fusion`, payload);

  return {
    getFusion,
    updateFusion,
    getClients,
  };
}
