<template>
  <MainInvoiceFrequencyModule />
</template>

<script>
import MainInvoiceFrequencyModule from '@/modules/masterData/invoiceFrequency/components/main.vue';

export default {
  name: 'MasterDataMainProducts',
  components: {
    MainInvoiceFrequencyModule,
  },
};
</script>
