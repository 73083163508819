<template>
  <div v-if="notification.type === NotificationTypes.ERROR">
    <DxPopup
      v-model:visible="notification.visible"
      :drag-enabled="true"
      :close-on-outside-click="true"
      :show-title="true"
      :width="500"
      :height="250"
      titleTemplate="header-title">
      <dx-toolbar-item
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="closeButtonOptions"
      />

      <ul class="popup-list">
        <li v-for="item in notification.messageList" :key="item.index">
          {{ item.message }}
        </li>
      </ul>
      <template #header-title>
        <div class="popup-title">
          <i class="dx-icon-info popup-title-icon" />Information
        </div>
      </template>
    </DxPopup>
  </div>
  <div v-else>
    <DxToast
      v-model:visible="notification.visible"
      :type="getToastType(notification.type)"
      :width="500"
      :closeOnOutsideClick="true"
      :display-time="getToastDisplayTime(notification.type)">
      <ul class="toast-list">
        <li v-for="item in notification.messageList" :key="item.index">
          {{ item.message }}
        </li>
      </ul>
    </DxToast>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { DxToast } from 'devextreme-vue/toast';
import { DxPopup } from 'devextreme-vue/popup';
import NotificationTypes from '@/enums/notification-types.js';

export default {
  name: 'Notification',
  components: {
    DxToast,
    DxPopup,
  },
  setup() {
    const notification = reactive({});

    const closeButtonOptions = {
      text: 'Luk',
      elementAttr: {
        class: 'notification-close-button',
      },
      onClick: () => {
        notification.visible = false;
      },
    };

    return {
      notification,
      NotificationTypes,
      closeButtonOptions,
    };
  },
  methods: {
    getNotificationType(messageList) {
      const anyErrors = messageList.some((message) => message.type === NotificationTypes.ERROR);
      const anyWarnings = messageList.some((message) => message.type === NotificationTypes.WARNING);
      const anyInfos = messageList.some((message) => message.type === NotificationTypes.INFO);

      if (anyErrors) return NotificationTypes.ERROR;
      if (anyWarnings) return NotificationTypes.WARNING;
      if (anyInfos) return NotificationTypes.INFO;

      return NotificationTypes.SUCCESS;
    },
    getToastType(type) {
      switch (type) {
        case NotificationTypes.WARNING:
          return 'warning';
        case NotificationTypes.INFO:
          return 'info';
        default:
          return 'success';
      }
    },
    getToastDisplayTime(type) {
      switch (type) {
        case NotificationTypes.WARNING:
          return 60000;
        case NotificationTypes.INFO:
          return 10000;
        default:
          return 3000;
      }
    },
  },
  computed: {
    notificationState() {
      return this.$store.state.notification;
    },
  },
  watch: {
    notificationState(value) {
      if (value !== null) {
        const type = this.getNotificationType(value.messageList);

        this.notification.visible = true;
        this.notification.type = type;

        // If list of messages contains a mix of types, then we only want the most important
        this.notification.messageList = value.messageList.filter((message) => message.type === type);
      }
    },
  },
};
</script>

<style lang="scss">
.notification-close-button {
  margin-right: 10px;
}

ul.toast-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.popup-list {
  padding-left: 15px;
  margin-top: 0px;
}

.popup-title {
  display: flex;
  align-items: center;
  font-size: 22px;
  padding: 20px;
}

.popup-title-icon {
  margin-right: 8px;
  font-size: 25px;
  color: red;
}
</style>
