<template>
  <DistributionKeyModule />
</template>

<script>
import DistributionKeyModule from '@/modules/masterData/distributionKeys/components/main.vue';

export default {
  name: 'MasterDataDistributionKeys',
  components: {
    DistributionKeyModule,
  },
};
</script>
