<template>
  <div class="edit-form-container">
    <form @submit="handleSubmit($event)">
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <dx-location screen="xs sm" :row="0" :col="0" />
          <div class="detail-element-container padding-right">
            Navn:
            <dx-textbox
              :value="row.data.name"
              id="name"
              name="name">
              <dx-validator>
                <dx-required-rule message="Navn skal udfyldes" />
              </dx-validator>
            </dx-textbox>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container">
            Sorteringsnummer:
            <dx-number-box
              id="order"
              :value="row.data.order"
              name="order">
              <dx-validator>
                <dx-required-rule message="Sorteringsnummer skal udfyldes" />
              </dx-validator>
            </dx-number-box>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" :colspan="2" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container">
            Beskrivelse:
            <dx-text-area
              id="description"
              name="description"
              :value="row.data.description" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <dx-location screen="xs sm" :row="3" :col="0" />
          <div class="detail-element-container">
            <a href="#" @click.prevent="$emit('exportFileClick', row.data.id)">Klik her for at åbne vedhæftede PDF fil</a>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="3" :col="0" :colspan="2" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container">
            Fil:
            <DxFileUploader
              id="fileContent"
              name="fileContent"
              select-button-text="Vælg fil"
              label-text="eller drop fil her"
              :multiple="false"
              :allowed-file-extensions="['.pdf']"
              accept="application/pdf"
              upload-mode="useForm"
              invalid-file-extension-message="Forkert filtype"
              invalid-max-file-size-message="Filen er for stor. Den må maks. fylde 52MB."
              ready-to-upload-message="Klar til upload." />
          </div>
        </dx-item>
      </dx-responsive-box>

      <div class="validation-row row">
        <dx-validation-summary />
      </div>
      <div class="button-row row">
        <dx-button text="Gem" :use-submit-behavior="true" class="button margin-right" />
        <dx-button text="Annuller" @click="handleCancel" class="button" />
      </div>
    </form>
  </div>
</template>

<script>
import { DxFileUploader } from 'devextreme-vue/file-uploader';

export default {
  name: 'EditForm',
  props: {
    row: Object,
  },
  components: {
    DxFileUploader,
  },
  setup(props, context) {
    const handleSubmit = (e) => {
      context.emit('save', e);
      e.preventDefault();
    };

    const handleCancel = () => {
      context.emit('cancel');
    };

    return {
      handleSubmit,
      handleCancel,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-form-container {
  display: flex;
  flex-direction: column;

  .row {
    padding-bottom: 15px;
  }

  .padding-right {
    padding-right: 15px;
  }

  .button {
    width: 100px;
  }

  .margin-right {
    margin-right: 15px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }
}
</style>
