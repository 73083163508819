import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getProductListForPriceList = (supplierId, billingSetId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingsets/${billingSetId}/products/${supplierId}`);

  const getOverviewData = (billingSetId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/basedata`);

  const getMainProductList = (supplierId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/mainproducts/${supplierId}`);

  const getBillingPeriodList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingperiods`);

  const getBrandList = (supplierId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/brands/list/${supplierId}`);

  return {
    getProductListForPriceList,
    getOverviewData,
    getMainProductList,
    getBillingPeriodList,
    getBrandList,
  };
}
