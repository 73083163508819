<template>
  <div class="externaldataset-list-root datagrid-root">
    <div class="content-block">
      <Headline title="Leverandør datasæt" :contextData="billingSetEntry" />
      <div><u @click="router.push({ name: 'BillingSet' })" class="link">Kørsler</u> > Leverandør datasæt</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="router.push({ name: 'BillingSet' })"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button icon="plus" @click="onNewClick" hint="Indlæs nyt datasæt" class="create-button"></dx-button>
          <dx-button @click="onNewLoadFromApiClick" class="create-button" v-if="billingSetEntry.data && billingSetEntry.data.supplierId == 2">Indlæs datasæt fra Api</dx-button>
          <dx-button icon="refresh" @click="reCalculate" text="Genberegn datasæt" hint="Genberegn priser i alle indlæste datasæt i kørslen" class="recalculate-button" />
          <dx-dropdown-button
            icon="download"
            key-expr="key"
            display-expr="text"
            :items="downloadReportActionList"
            text="Hent rapporter"
            @item-click="onDownloadReportClick"
            class="report-button"
            :drop-down-options="dropDownOptions"
            item-template="itemTemplate">
            <template #itemTemplate="{ data }" >
              <div>
                <div><b>{{ data.text }}</b></div>
                <div>{{ data.description }}</div>
              </div>
            </template>
          </dx-dropdown-button>
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="externalDataSetDataGrid"
        :data-source="datasource"
        height="80vh"
        :focused-row-key="focusedRowKey"
        @exporting="onExporting"
        @exported="onExported"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Leverandør datasæt" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="externalDataSet-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing :confirm-delete="false" />

        <dx-column
          caption="Filer"
          :hiding-priority="8"
          :width="400"
          cell-template="filesTemplate" />

        <dx-column
          caption="Indlæst"
          :hiding-priority="7"
          :width="150"
          cell-template="processedTemplate" />

        <dx-column
          data-field="createdDate"
          caption="Indlæst"
          data-type="date"
          format="dd.MM.yyyy"
          :visible="false"
          :hiding-priority="7"
          sort-order="desc"
          :width="150">
        </dx-column>

        <dx-column
          data-field="totalPrice"
          caption="Nets total"
          :format="NumberFormatting.kronerWithTwoDecimals"
          :hiding-priority="6"
          :width="150">
        </dx-column>

        <dx-column
          data-field="totalCalculatedPrice"
          caption="Beregnet total"
          :format="NumberFormatting.kronerWithTwoDecimals"
          :hiding-priority="5"
          :width="150">
        </dx-column>

        <dx-column
          caption="Difference"
          :format="NumberFormatting.kronerWithTwoDecimals"
          :hiding-priority="4"
          :width="150"
          cell-template="differenceTemplate">
        </dx-column>

        <dx-column
          data-field="expectedTotal"
          caption="Forventet total"
          :format="NumberFormatting.kronerWithTwoDecimals"
          :hiding-priority="3"
          :width="150">
        </dx-column>

        <dx-column
          caption="Manuelle håndteringer"
          :hiding-priority="2"
          :width="200"
          cell-template="manualHandlingTemplate" />

        <dx-column
          caption="Status"
          :hiding-priority="1"
          cell-template="statusTemplate" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <DetailView
            :row="row"
            @onAnnulClick="onAnnulClick"
            @onDeleteClick="onDeleteClick"
            @onManualHandlingClick="onManualHandlingClick"
            :billingSetId="id" />
        </template>

        <template #filesTemplate="{ data }">
          <div>
            <div v-for="file in data.data.fileList" :key="file.id">
              {{ file.name }}
            </div>
          </div>
        </template>

        <template #manualHandlingTemplate="{ data }">
          <div>
            <div v-if="(data.data.numberOfProcessedManualEntries + data.data.numberOfUnprocessedManualEntries) === 0">
              Ingen
            </div>
            <div v-else>
              <a @click="onManualHandlingClick(data.data.id)">{{ data.data.numberOfProcessedManualEntries }}/{{ data.data.numberOfProcessedManualEntries + data.data.numberOfUnprocessedManualEntries }} er håndteret.</a>
            </div>
          </div>
        </template>

        <template #differenceTemplate="{ data }">
          <div>
            {{ formatService.formatNumber(data.data.expectedTotal - data.data.totalCalculatedPrice, 2, 2) + ' kr.' }}
          </div>
        </template>

        <template #processedTemplate="{ data }">
          <div>
            <div v-if="!data.data.processed">
              Under indlæsning
            </div>
            <div v-else>
              {{ formatService.stringToDate(data.data.createdDate, 'DD.MM.YYYY') }}
            </div>
          </div>
        </template>

        <template #statusTemplate="{ data }">
          <div>
            <div v-if="data.data.processed">
              <i v-if="data.data.status == ExternalDataSetStatus.INFO" class="dx-icon-info" title="Totalbeløbet afviger fra tidligere perioder" />
              <i v-else-if="data.data.status == ExternalDataSetStatus.WARNING" class="dx-icon-warning" title="Totalbeløbet afviger betragteligt fra tidligere perioder" />

              <i v-if="data.data.totalDifference != 0" class="dx-icon-info nets-diff-color" :title="`Nets total svarer ikke til den forventede total (difference ${$filters.formatNumber(data.data.totalDifference)} kr)`" />
              <i v-else class="dx-icon-info nets-no-diff-color" title="Nets total svarer til den forventede total." />
            </div>
            <div v-else>
              <i class="dx-icon-runner" title="Datasæt er igang med at blive behandlet" />
            </div>
          </div>
        </template>
      </dx-data-grid>
      <Popup v-if="showConfirmPopup"
        :title="annulModel.title"
        :visible="showConfirmPopup"
        :content="annulModel.content"
        :saveButtonText="annulModel.saveButtonText"
        :cancelButtonText="annulModel.cancelButtonText"
        @onSave="onAnnulSave"
        @onCancel="onAnnulCancel"
        :show-close-button="false" />

      <FileUploadPopup v-if="showUploadFilePopup"
        title="Indlæs leverandør datasæt"
        :visible="showUploadFilePopup"
        saveButtonText="Upload"
        :height="400"
        @save="onUploadSaveClick"
        @cancel="onUploadExternalDataSetCancel"
        :allowMultipleFiles="true"
        selectButtonText="Vælg filer"
        labelText="eller drop filer her"
        :allowedFileExtensions="['.csv']"
        invalidFileExtensionMessage="Filtypen er forkert. Kun csv er tilladt.">
        <div>
          <div class="content-row">
            Forventet total:
            <dx-number-box name="expectedTotal" id="expectedTotal" :value="null" :format="NumberFormatting.kronerWithTwoDecimals" :showClearButton="true" :width="300" />
          </div>
          <div class="content-row">
            <dx-checkbox text="Tillad indlæsning selv om der er advarsler" name="ignoreWarnings" id="ignoreWarnings" />
          </div>
        </div>
      </FileUploadPopup>

      <LoadFromApiPopup v-if="showLoadFromApiPopup"
        title="Indlæs datasæt fra Api"
        :visible="showLoadFromApiPopup"
        saveButtonText="Indlæs"
        :height="300"
        :contractList="contractList.data"
        @save="onLoadFromApiSave"
        @cancel="onLoadFromApiCancel"
        :showWarningCheckbox="true"
        selectButtonText="Vælg filer"
        labelText="eller drop filer her" />
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import DetailView from '@/modules/externalDataSet/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/externalDataSet/composables/datagrid.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';
import ExternalDataSetStatus from '@/enums/externaldataset-status.js';
import useAnnul from '@/modules/externalDataSet/composables/annul.composable.js';
import Popup from '@/components/common/popup.vue';
import FileUploadPopup from '@/components/common/file-upload-popup.vue';
import LoadFromApiPopup from '@/components/common/load-from-api-popup.vue';
import useUploadExternalDataSet from '@/modules/shared/composables/upload.externaldataset.composable.js';
import useExternalDataSet from '@/modules/externalDataSet/composables/externaldataset.composable.js';
import useJob from '@/modules/shared/composables/job.composable.js';
import useSharedBillingSet from '@/modules/shared/composables/billingset.composable.js';
import Headline from '@/components/common/headline.vue';

export default {
  name: 'ExternalDataSetList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    Popup,
    FileUploadPopup,
    Headline,
    LoadFromApiPopup,
  },
  props: {
    // BillingSetID
    id: String,
  },
  setup(props) {
    const formatService = inject('formatService');

    const {
      datasource,
      onExporting,
      onExported,
    } = useDatagrid(props.id);

    const dataGrid = ref();

    const router = useRouter();

    const onManualHandlingClick = (externalDataSetId) => {
      router.push({ name: 'ManualHandlingList', params: { billingSetId: props.id, externalDataSetId } });
    };

    const {
      loadBillingSet,
      billingSetEntry,
    } = useSharedBillingSet();
    loadBillingSet(props.id);

    const {
      onUploadExternalDataSetClick,
      showUploadFilePopup,
      onUploadExternalDataSetCancel,
      onUploadExternalDataSetSave,
      onLoadFromApiClick,
      onLoadFromApiSave,
      onLoadFromApiCancel,
      showLoadFromApiPopup,
      contractList,
    } = useUploadExternalDataSet();

    const onNewClick = () => {
      onUploadExternalDataSetClick(props.id);
    };

    const onNewLoadFromApiClick = () => {
      onLoadFromApiClick(props.id, billingSetEntry.data.supplierId);
    };

    const { startFastJobPolling } = useJob();

    const onUploadSaveClick = (form) => {
      onUploadExternalDataSetSave(form)
        .then((response) => {
          if (response.success) {
            // Start polling JobList at a fast pace
            startFastJobPolling();

            // Reload datagrid when new file has been uploaded.
            dataGrid.value.instance.refresh();
          }
        });
    };

    const onLoadFromApiSaveClick = (form) => {
      onLoadFromApiSave(form)
        .then((response) => {
          if (response.success) {
            // Start polling JobList at a fast pace
            startFastJobPolling();

            // Reload datagrid when new file has been uploaded.
            dataGrid.value.instance.refresh();
          }
        });
    };

    const {
      reCalculate,
      onDownloadReportClick,
      downloadReportActionList,
    } = useExternalDataSet(props.id);

    const {
      onAnnulClick,
      showConfirmPopup,
      annulModel,
      onAnnulSave,
      onAnnulCancel,
    } = useAnnul(props.id, dataGrid);

    const dropDownOptions = { width: 450 };

    return {
      datasource,
      router,
      onNewClick,
      NumberFormatting,
      ExternalDataSetStatus,
      onAnnulClick,
      showConfirmPopup,
      annulModel,
      onAnnulSave,
      onAnnulCancel,
      dataGrid,
      showUploadFilePopup,
      onUploadExternalDataSetCancel,
      onUploadSaveClick,
      reCalculate,
      onDownloadReportClick,
      downloadReportActionList,
      dropDownOptions,
      onManualHandlingClick,
      billingSetEntry,
      formatService,
      onLoadFromApiClick,
      showLoadFromApiPopup,
      onLoadFromApiCancel,
      onNewLoadFromApiClick,
      onLoadFromApiSaveClick,
      contractList,
      onLoadFromApiSave,
      onExporting,
      onExported,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, 'Vil du slette dette leverandør datasæt?', 'Slet datasæt');
    },
  },
};
</script>

<style lang="scss">
.externaldataset-list-root {
  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }

  .grid-button-container .report-button {
    margin-right: 46px;
  }

  .recalculate-button, .create-button {
    margin-right: 5px
  }

  .nets-diff-color {
    color: #DDBB00;
  }

  .nets-no-diff-color {
    color: #00BB00;
  }
}
</style>
