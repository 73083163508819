<template>
  <BrandModule />
</template>

<script>
import BrandModule from '@/modules/masterData/brands/components/main.vue';

export default {
  name: 'MasterDataBrand',
  components: {
    BrandModule,
  },
};
</script>
