<template>
  <div class="accountmapping-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>PII</h2></div>
        <div class="subheadline-item">&nbsp; - Kontomapping</div>
      </div>
    </div>

    <div class="content-block">
      <div class="client-container">
        Pengeinstitut:
        <dx-select-box
          :data-source="clientList.data"
          display-expr="name"
          value-expr="identifier"
          @value-changed="clientChanged"
          item-template="item"
          field-template="field">
          <template #item="{ data }">
            <div>
              {{ getClientText(data) }}
            </div>
          </template>
          <template #field="{ data }">
            <div>
              <dx-textbox
                :value="getClientText(data)"
                :read-only="true"
              />
            </div>
          </template>
        </dx-select-box>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button
            @click="showPopup = !showPopup"
            icon="edit"
            hint="Sæt konto for valgte produkter"
            text="Opdater kontomapning"
            class="update-account-mapping"
            :disabled="selectedRowKeyList.length === 0"></dx-button>
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="accountMappingDataGrid"
        :data-source="datasource"
        height="75vh"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :selected-row-keys="selectedRowKeyList"
        @selection-changed="onDataGridSelectionChanged"
        @row-click="null"
        :focused-row-key="focusedRowKey">

        <dx-selection select-all-mode="allPages" show-check-boxes-mode="always" mode="multiple"/>
        <dx-export :enabled="true" fileName="AccountMapping" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-column
          data-field="productIdentifier"
          caption="Varekode"
          data-type="string"
          :hiding-priority="8"
          width="100" />

        <dx-column
          data-field="productName"
          caption="Vare"
          data-type="string"
          :hiding-priority="7"
          :allow-resizing="true" />

        <dx-column
          data-field="brandName"
          caption="Brand"
          :hiding-priority="6"
          data-type="string" />

        <dx-column
          data-field="serviceName"
          caption="Service"
          :hiding-priority="5"
          data-type="string" />

        <dx-column
          data-field="registrationAndAccountNumber"
          caption="Konto"
          :hiding-priority="4"
          data-type="string"
          width="150" />

        <dx-column
          data-field="dateOfChangeAndUser"
          caption="Ændret"
          :hiding-priority="3"
          data-type="string"
          width="300" />
      </dx-data-grid>
    </div>

    <FormPopup
      :visible="showPopup"
      title="Vælg konto"
      :height="300"
      @cancel="showPopup = !showPopup"
      @save="onSaveClick">
      <div>
        <div v-if="selectedRowKeyList.length > 1">
          Vælg den konto som de valgte {{ selectedRowKeyList.length }} produkter skal mappes til.<br /><br />
        </div>
        <div v-else>
          Vælg den konto som det valgte produkt skal mappes til.<br /><br />
        </div>
        <div>
          Konto:
        </div>
        <dx-select-box
          id="account"
          name="account"
          :data-source="accountList.data"
          display-expr="registrationNumberText"
          value-expr="id">
          <dx-validator validation-group="mainForm">
            <dx-required-rule message="Du skal vælge en konto" />
          </dx-validator>
        </dx-select-box>
      </div>
    </FormPopup>

    <Popup v-if="confirmPopupModel.show"
      :title="confirmPopupModel.title"
      :visible="confirmPopupModel.show"
      :content="confirmPopupModel.content"
      :saveButtonText="confirmPopupModel.saveButtonText"
      :cancelButtonText="confirmPopupModel.cancelButtonText"
      @onSave="confirmPopupModel.saveClickHandler"
      @onCancel="confirmPopupModel.show = !confirmPopupModel.show"
      :height="300" />
  </div>
</template>

<script>
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/accountMapping/composables/datagrid.composable.js';
import useMasterdata from '@/modules/accountMapping/composables/masterdata.composable.js';
import FormPopup from '@/components/common/form-popup.vue';
import Popup from '@/components/common/popup.vue';

export default {
  name: 'MainAccountMappingModule',
  extends: BaseDataGrid,
  components: {
    FormPopup,
    Popup,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      showAccountMappingConfirmPopup,
      showPopup,
      confirmPopupModel,
      selectedRowKeyList,
      selectedClientId,
      dataGrid,
      onDataGridSelectionChanged,
    } = useDatagrid();

    const {
      clientList,
      getAccountList,
      accountList,
    } = useMasterdata();

    const clientChanged = (e) => {
      const clientId = e.value;
      selectedClientId.value = clientId;

      // Reset selected products
      selectedRowKeyList.value = [];

      getDatasource(clientId);
      getAccountList(clientId);
    };

    const onSaveClick = (form) => {
      showAccountMappingConfirmPopup(form, accountList, selectedRowKeyList.value.length);
    };

    const getClientText = (data) => {
      if (data == null) return null;

      return `${data.registrationNumber} - ${data.name}`;
    };

    return {
      datasource,
      clientList,
      clientChanged,
      selectedRowKeyList,
      showPopup,
      confirmPopupModel,
      accountList,
      onSaveClick,
      dataGrid,
      onDataGridSelectionChanged,
      getClientText,
    };
  },
  mounted() {
    // Remove rowClick event (which is set in BaseDataGrid)
    this.dataGrid.instance.off('rowClick');
  },
};
</script>

<style lang="scss">
.accountmapping-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 auto;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .client-container {
    padding: 20px;
    background-color: #FFF;
  }

  .grid-button-container .update-account-mapping {
    margin-right: 46px;
  }
}
</style>
