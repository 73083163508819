import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { getCurrentInstance } from 'vue';
import useUserManager from '@/services/userManager.service.js';

const axiosInstance = axios.create({ withCredentials: true });

axiosRetry(axiosInstance, {
  retries: 8,
  // Only retry when GET, HEAD, OPTIONS, PUT or DELETE (IdempotentRequestError) or when network error
  retryCondition: (e) => isNetworkOrIdempotentRequestError(e),
  retryDelay: axiosRetry.exponentialDelay,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    };

    const { getUser } = useUserManager();
    const user = await getUser();
    const accesstoken = user.access_token;

    if (accesstoken != null) {
      headers.Authorization = `Bearer ${accesstoken}`;
    }

    // eslint-disable-next-line no-param-reassign
    config.headers = headers;

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  (error) => {
    if (error.response) {
      const vm = getCurrentInstance();

      switch (error.response.status) {
        case 401: { // Unauthorized
          vm.proxy.$log.error('User is not authenticated (401)', error.response);
          return Promise.reject(new Error('Du har ikke adgang til serveren (401)'));
        }
        case 403: { // Forbidden
          vm.proxy.$log.error('User is not authorized (403)', error.response);
          return Promise.reject(new Error('Din rolle har ikke rettighed til at kalde serveren (403)'));
        }
        default: {
          vm.proxy.$log.error('An error happend when calling api', error.response);
          return Promise.reject(new Error('Serveren er midlertidigt nede'));
        }
      }
    }
  },
);

export default axiosInstance;
