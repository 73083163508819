import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useJobRepository() {
  const appsettings = store.state.appSettings;

  const getJobList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/jobs/joblist`);

  const markJobsAsRead = (jobIdList) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/jobs/joblist/markasread`, { list: jobIdList });

  return {
    getJobList,
    markJobsAsRead,
  };
}
