import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useManualBillingEntryRepository(billingSetId) {
  const appsettings = store.state.appSettings;

  const loadManualBilingEntries = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/overview`);

  return {
    loadManualBilingEntries,
  };
}
