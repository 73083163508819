const DistributionType = {
  DISTRIBUTIONBYKEY: 1,
  DISTRIBUTIONBYPRODUCTIDENTIFIERCOUNT: 5,
  DISTRIBUTIONBYPRODUCTIDENTIFIERPRICE: 6,
  DISTRIBUTIONBYPRODUCTIDENTIFIERPRICEINCLMARKUP: 7,
};

export const DistributionTypeDescription = new Map([
  [DistributionType.DISTRIBUTIONBYKEY, 'Pr. fordelingsnøgle'],
  [DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERCOUNT, 'Pr. varenummer antal'],
  [DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERPRICE, 'Pr. varenummer beløb'],
  [DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERPRICEINCLMARKUP, 'Pr. varenummer beløb inkl. markup'],
]);

export default DistributionType;
