import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/manualBilling/repositories/masterdata.repository.js';
import store from '@/store/index';

export default function useMasterdata(billingSetId) {
  const masterdataRepository = useMasterdataRepository();

  const productList = reactive({ loadingCompleted: false, data: null });
  const overviewData = reactive({ loadingCompleted: false, data: null });

  const getProductList = (supplierId) => masterdataRepository.getProductListForPriceList(supplierId, billingSetId)
    .then((response) => {
      // Map response
      const list = response.data.map((product) => ({
        id: product.id,
        name: `${product.productIdentifier} - ${product.name} - ${product.brandName}`,
      }));

      productList.loadingCompleted = true;
      productList.data = list;
    });

  masterdataRepository.getOverviewData(billingSetId)
    .then((response) => {
      overviewData.loadingCompleted = true;

      if (response.data.success) {
        overviewData.data = response.data.value;
        const { supplierId } = response.data.value;
        getProductList(supplierId);
      } else if (response.data.messages.length > 0) {
        store.dispatch('addNotifications', { messageList: response.data.messages });
      }
    });

  return {
    productList,
    overviewData,
  };
}
