<template>
  <div class="mccclientdataset-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <div class="detail-element-container padding-right">
            Pengeinstitut:
            <dx-textbox
              :value="row.data.cliName"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container">
            Reg. nr.
            <dx-number-box
              :value="row.data.cliRegistrationNumber"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container padding-right">
            Beløb:
            <dx-number-box
              :value="row.data.cliTotalPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container">
            Status:
            <div>
              {{ row.data.statusText }}
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="1" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container">
            Fusion:
            <div v-if="row.data.partOfFusion">
              <i class="dx-icon-link" style="color: #2b2b2b; font-size: 18px;" title="Pengeinstitut indgår i fusion" />
            </div>
          </div>
        </dx-item>

      </dx-responsive-box>
    </div>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  setup() {
    return {
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss" scoped>
.mccclientdataset-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button-large {
      margin-bottom: 5px;
      width: 170px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 20px;
  }
}
</style>
