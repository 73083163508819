<template>
  <div class="datagrid-root">
    <div class="content-block">
      <h2>X-Pays kørsler</h2>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid ref="dataGrid"
                    id="billingSetDataGrid"
                    :data-source="datasource"
                    height="80vh"
                    :focused-row-key="focusedRowKey"
                    @editor-preparing="onEditorPreparing"
                    @editing-start="initFormFields"
                    @init-new-row="onInitNewRow"
                    @rowPrepared="onRowPrepared"
                    @editCanceled="editCanceled"
                    @row-removing="rowRemoving">

        <dx-selection mode="single" />
        <dx-export :enabled="true" fileName="Kørsler" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="xpbillingset-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />

        <dx-editing :allow-updating="true"
                    :allow-deleting="true"
                    :allow-adding="true"
                    :confirm-delete="false"
                    mode="form">
          <dx-form :col-count="1"
                   label-location="top"
                   :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="supplierId">
              <dx-label text="Leverandør" />
            </dx-simple-item>
            <dx-simple-item data-field="billingSetTypeId">
              <dx-label text="Kørselstype" />
            </dx-simple-item>
            <dx-simple-item data-field="billingPeriodId">
              <dx-label text="Faktureringsperiode" />
            </dx-simple-item>
            <dx-simple-item data-field="publishingDate">
              <dx-label text="Publiceringsdato" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column data-field="name"
                   caption="Navn"
                   :hiding-priority="8"
                   :width="400"
                   :form-item="nameFormItem">
          <dx-required-rule />
        </dx-column>

        <dx-column data-field="publishingDate"
                   caption="Publiceringsdato"
                   data-type="date"
                   :hiding-priority="7"
                   :form-item="publisingDateFormItem"
                   format="dd.MM.yyyy">
        </dx-column>

        <dx-column data-field="billingSetTypeName"
                   caption="Kørselstype"
                   :hiding-priority="3">
        </dx-column>

        <dx-column data-field="billingPeriodId"
                   caption="Faktureringsperiode"
                   :hiding-priority="6"
                   :width="250"
                   :form-item="billingPeriodFormItem">
          <dx-grid-lookup :data-source="billingPeriodList.data"
                          value-expr="id"
                          display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column data-field="supplierId"
                   caption="Leverandør"
                   :hiding-priority="5"
                   :group-index="0"
                   :set-cell-value="setCellValue"
                   :form-item="supplierFormItem">
          <dx-grid-lookup :data-source="supplierList.data"
                          value-expr="id"
                          display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column data-field="billingSetTypeId"
                   caption="Kørselstype"
                   :hiding-priority="5"
                   :visible="false"
                   :form-item="billingSetTypeFormItem">
          <dx-grid-lookup value-expr="id"
                          display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column data-field="createdDate"
                   caption="Oprettet dato"
                   data-type="date"
                   :allow-editing="false"
                   format="dd.MM.yyyy"
                   :hiding-priority="4"
                   sort-order="desc">
        </dx-column>

        <dx-column type="buttons"
                   :visible="false" />

        <dx-master-detail :enabled="false"
                          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDataCompleted" />
          <div v-else>
            <DetailView :row="row"
                        @onEditClick="editClick"
                        @onDeleteClick="onDeleteClick"
                        @onPublishDateClick="publishDateClick"
                        @onPublishDateNowClick="publishDateNowClick"
                        @onUploadExternalDataSetClick="onUploadExternalDataSetClick($event.data.id)"
                        @onExternalDataSetDetailClick="router.push({ name: 'XPExternalDataSetList', params: { id: $event.key } })"
                        @onClientDataSetClick="router.push({ name: 'XPClientDataSetList', params: { billingSetId: $event.key } })" />
          </div>
        </template>
      </dx-data-grid>

      <FileUploadPopup v-if="showUploadFilePopup"
        title="Indlæs leverandør datasæt"
        :visible="showUploadFilePopup"
        saveButtonText="Upload"
        :height="350"
        @save="onUploadXPDataSetSave"
        @cancel="onUploadExternalDataSetCancel"
        :isLoading="isLoading"
        :allowMultipleFiles="true"
        selectButtonText="Vælg filer"
        labelText="eller drop filer her"
        :allowedFileExtensions="['.csv']"
        invalidFileExtensionMessage="Filtypen er forkert. Kun csv er tilladt.">
        <div>
          <div class="content-row">
            <dx-checkbox text="Tillad indlæsning selv om der er advarsler" name="ignoreWarnings" id="ignoreWarnings" />
          </div>
        </div>
      </FileUploadPopup>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import DetailView from '@/modules/xpBillingSet/components/detail-view.vue';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/xpBillingSet/composables/datagrid.composable.js';
import FileUploadPopup from '@/components/common/file-upload-popup.vue';
import useMasterdata from '@/modules/xpBillingSet/composables/masterdata.composable.js';
import useUploadExternalDataSet from '@/modules/shared/composables/upload.externaldataset.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';

const nameFormItem = {
  visible: true,
  disabled: false,
};

const publisingDateFormItem = {
  visible: true,
};

const billingPeriodFormItem = {
  visible: false,
};

const supplierFormItem = {
  visible: false,
};

const currencyRateEURFormItem = {
  visible: false,
};

const currencyRateUSDFormItem = {
  visible: false,
};

const markupPercentFormItem = {
  visible: false,
};

const currencyRateDescriptionFormItem = {
  visible: false,
};

const billingSetTypeFormItem = {
  visisble: false,
};

export default {
  name: 'BillingSetList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    ContentLoaderList,
    FileUploadPopup,
  },
  setup() {
    const dataGrid = ref();
    const {
      datasource,
      initFormFields,
      onInitNewRow,
      isPublishDateMode,
      onRowPrepared,
      initPublishFields,
      editCanceled,
      editorPreparing,
      setCellValue,
      publishNow,
    } = useDatagrid(dataGrid);

    const {
      billingPeriodList,
      supplierList,
      billingSetTypeList,
    } = useMasterdata();

    const {
      onUploadExternalDataSetClick,
      showUploadFilePopup,
      onUploadExternalDataSetCancel,
      onUploadXPDataSetSave,
      isLoading,
    } = useUploadExternalDataSet();

    const loadingDataCompleted = computed(() => billingPeriodList.loadingCompleted);
    const router = useRouter();

    const onEditorPreparing = (e) => {
      editorPreparing(e, billingSetTypeList);
    };

    return {
      loadingDataCompleted,
      supplierList,
      datasource,
      nameFormItem,
      publisingDateFormItem,
      onInitNewRow,
      initFormFields,
      isPublishDateMode,
      dataGrid,
      billingPeriodList,
      billingPeriodFormItem,
      onRowPrepared,
      supplierFormItem,
      showUploadFilePopup,
      onUploadExternalDataSetClick,
      onUploadExternalDataSetCancel,
      onUploadXPDataSetSave,
      router,
      initPublishFields,
      editCanceled,
      isLoading,
      onEditorPreparing,
      setCellValue,
      publishNow,
      currencyRateEURFormItem,
      currencyRateUSDFormItem,
      currencyRateDescriptionFormItem,
      markupPercentFormItem,
      NumberFormatting,
      billingSetTypeFormItem,
    };
  },
  methods: {
    editClick(e) {
      this.isPublishDateMode = false;

      // Call base class
      this.onEditClick(e);
    },
    publishDateClick(e) {
      this.initPublishFields(e);

      // Call base class
      this.onEditClick(e);
    },
    publishDateNowClick(e) {
      this.publishNow(e);
    },
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne kørsel incl. evt. leverandør- og PI-datasæt?<br/><b>${e.data.name}</b>`, 'Slet kørsel');
    },
  },
};
</script>

<style lang="scss">
.dx-form-group-caption {
  font-size: 14px;
}

.dx-form-group-with-caption > .dx-form-group-content {
  border-top: none;
}
</style>
