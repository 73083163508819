const ClientDataSetStatus = {
  NOTDEFINED: 0,
  DATASETGENERATED: 1,
  INVOICECREATED: 2,
  INVOICEEXPORTED: 3,
  CREDITINVOICECREATED: 4,
  CREDITINVOICEEXPORTED: 5,
  DATASETCANCELLED: 6,
  NOTCREATED: 10,
  INVOICESGENERATEDPARTLY: 12,
  INVOICESGENERATEDFULLY: 13,
};

export const ClientDataSetStatusDescription = new Map([
  [ClientDataSetStatus.NOTDEFINED, 'Status ikke sat'],
  [ClientDataSetStatus.DATASETGENERATED, 'Oprettet'],
  [ClientDataSetStatus.INVOICECREATED, 'Faktura oprettet'],
  [ClientDataSetStatus.INVOICEEXPORTED, 'Faktura eksporteret'],
  [ClientDataSetStatus.CREDITINVOICECREATED, 'Kreditnota oprettet'],
  [ClientDataSetStatus.CREDITINVOICEEXPORTED, 'Kreditnota eksporteret'],
  [ClientDataSetStatus.DATASETCANCELLED, 'Annulleret'],
  [ClientDataSetStatus.NOTCREATED, 'Mangler'],
  [ClientDataSetStatus.INVOICESGENERATEDPARTLY, 'Delvist faktureret'],
  [ClientDataSetStatus.INVOICESGENERATEDFULLY, 'Faktureret'],
]);

export default ClientDataSetStatus;
