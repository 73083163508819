import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/accountMapping/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();
  const clientList = reactive({ loadingCompleted: false, data: null });
  const accountList = reactive({ loadingCompleted: false, data: null });

  masterdataRepository.getClientList()
    .then((response) => {
      // Sort list by RegistrationNumber
      const sortedClientList = response.data.sort((a, b) => a.registrationNumber - b.registrationNumber);

      clientList.loadingCompleted = true;
      clientList.data = sortedClientList;
    });

  const getAccountList = (clientId) => {
    accountList.loadingCompleted = false;

    masterdataRepository.getAccountList(clientId)
      .then((response) => {
        accountList.loadingCompleted = true;
        accountList.data = response.data;
      });
  };

  return {
    clientList,
    getAccountList,
    accountList,
  };
}
