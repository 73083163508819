<template>
  <GraphTooltipModule />
</template>

<script>
import GraphTooltipModule from '@/modules/masterData/cmsGraphTooltips/components/main.vue';

export default {
  name: 'MasterDataGraphTooltips',
  components: {
    GraphTooltipModule,
  },
};
</script>
