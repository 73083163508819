import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/brandGroups/repositories/masterdata.repository.js';
import BrandGroupType, { BrandGroupTypeDescription } from '@/enums/brand-group-type';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const biGraphList = reactive({ loadingCompleted: false, data: null });
  const brandGroupList = reactive({ loadingCompleted: false, data: null });

  masterdataRepository
    .getBiGraphList()
    .then((response) => {
      biGraphList.loadingCompleted = true;
      biGraphList.data = response.data;
    });

  brandGroupList.data = [
    { id: BrandGroupType.INTERNATIONALE, name: BrandGroupTypeDescription.get(BrandGroupType.INTERNATIONALE) },
    { id: BrandGroupType.DKVISA, name: BrandGroupTypeDescription.get(BrandGroupType.DKVISA) },
    { id: BrandGroupType.DANKORT, name: BrandGroupTypeDescription.get(BrandGroupType.DANKORT) },
    { id: BrandGroupType.NEUTRAL, name: BrandGroupTypeDescription.get(BrandGroupType.NEUTRAL) },
  ];
  brandGroupList.loadingCompleted = true;

  return {
    biGraphList,
    brandGroupList,
  };
}
