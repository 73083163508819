import { reactive } from 'vue';
import store from '@/store/index';
import useShareBillingSetRepository from '@/modules/shared/repositories/billingset.repository.js';

export default function useSharedBillingSet() {
  const billingSetRepository = useShareBillingSetRepository();
  const billingSetEntry = reactive({ loadingCompleted: false, data: null });

  const loadBillingSet = (billingSetId) => {
    billingSetRepository.getBillingSet(billingSetId)
      .then((response) => {
        billingSetEntry.loadingCompleted = true;

        if (response.data.success) {
          billingSetEntry.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const loadMCCBillingSet = (billingSetId) => {
    billingSetRepository.getMCCBillingSet(billingSetId)
      .then((response) => {
        billingSetEntry.loadingCompleted = true;

        if (response.data.success) {
          billingSetEntry.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const loadDKVBillingSet = (billingSetId) => {
    billingSetRepository.getDKVBillingSet(billingSetId)
      .then((response) => {
        billingSetEntry.loadingCompleted = true;

        if (response.data.success) {
          billingSetEntry.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const loadXPBillingSet = (billingSetId) => {
    billingSetRepository.getXPBillingSet(billingSetId)
      .then((response) => {
        billingSetEntry.loadingCompleted = true;

        if (response.data.success) {
          billingSetEntry.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    loadBillingSet,
    loadMCCBillingSet,
    loadDKVBillingSet,
    loadXPBillingSet,
    billingSetEntry,
  };
}
