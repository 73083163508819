import { ref } from 'vue';
import store from '@/store/index';
import useManualBillingEntryRepository from '@/modules/externalDataSet/repositories/manualbillingentry.repository.js';

export default function useDeleteManualHandling(billingSetId, dataGrid) {
  const deleteModel = ref();
  const showConfirmDeletePopup = ref(false);
  const manualBillingEntryRepository = useManualBillingEntryRepository(billingSetId);

  function showConfirmPopup(manualBillingEntryId) {
    deleteModel.value = {
      id: manualBillingEntryId,
      title: 'Slet',
      content: 'Vil du slette den manuelle håndtering?',
      saveButtonText: 'Ja',
      cancelButtonText: 'Nej',
    };

    showConfirmDeletePopup.value = true;
  }

  const onDeleteCancel = () => {
    showConfirmDeletePopup.value = false;
  };

  const onDeleteSave = () => {
    showConfirmDeletePopup.value = false;

    manualBillingEntryRepository.deleteManualBillingEntry(deleteModel.value.id)
      .then((response) => {
        if (response.data.success) {
          // Update datagrid with new data
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'Manuel datapost er nu slettet');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    showConfirmPopup,
    showConfirmDeletePopup,
    deleteModel,
    onDeleteSave,
    onDeleteCancel,
  };
}
