const LogLevels = {
  VERBOSE: 0,
  DEBUG: 1,
  INFORMATION: 2,
  WARNING: 3,
  ERROR: 4,
  FATAL: 5,
};

export default LogLevels;
