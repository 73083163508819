import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/cmsContactPersons/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const supplierList = reactive({ data: null });

  const loadSupplierList = () => {
    masterdataRepository.getSupplierList()
      .then((response) => {
        supplierList.data = response.data;
      });
  };

  return {
    supplierList,
    loadSupplierList,
  };
}
