<template>
  <div class="headline-container">
    <div class="headline-item">
      <h2>{{ title }}</h2>
    </div>
    <div v-if="contextData.loadingCompleted" class="context-headline">
      <div>
        {{ contextData.data.supplierName }} - {{ contextData.data.name }}
      </div>
      <div>
        {{ contextData.data.billingPeriodName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Headline',
  props: {
    title: String,
    contextData: Object,
  },
};
</script>

<style lang="scss" scoped>
.headline-container {
    display: flex;

    .headline-item {
      margin-right: 15px;
      h2 {
        margin-top: 0px;
      }
    }

    .context-headline {
      font-size: 12px;
      margin-top: 2px;
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }
</style>
