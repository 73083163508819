<template>
  <CardProductMappingModule />
</template>

<script>
import CardProductMappingModule from '@/modules/masterData/cardProductMapping/components/main.vue';

export default {
  name: 'MasterDataCardProductMapping',
  components: {
    CardProductMappingModule,
  },
};
</script>
