<template>
  <div class="price-form-root">
    <div class="row">
      <div>
        <div>
          Antal:
        </div>
        <div>
          <dx-number-box v-model:value="quantity" :format="NumberFormatting.number">
            <dx-validator>
              <dx-required-rule message="Antal skal udfyldes" />
            </dx-validator>
          </dx-number-box>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="padding-right">
        <div>
          Leverand&oslash;r stykpris:
        </div>
        <div>
          <dx-number-box v-model:value="netsPrice" :format="NumberFormatting.kronerWithFourDecimals" :read-only="true" >
            <dx-validator>
              <dx-required-rule message="Stykpris skal udfyldes" />
            </dx-validator>
          </dx-number-box>
        </div>
      </div>
      <div>
        <div>
          BOKIS stykpris:
        </div>
        <div>
          <dx-number-box v-model:value="price" :format="NumberFormatting.kronerWithFourDecimals" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="padding-right">
        <div>
          BOKIS markup stykpris:
        </div>
        <div>
          <dx-number-box v-model:value="markup" :format="NumberFormatting.kronerWithFourDecimals">
            <dx-validator>
              <dx-required-rule message="Markup skal udfyldes" />
            </dx-validator>
          </dx-number-box>
        </div>
      </div>
      <div>
        <div>
          BOKIS markup total:
        </div>
        <div>
          <dx-number-box v-model:value="totalMarkup" :format="NumberFormatting.kronerWithFourDecimals" :read-only="true" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="padding-right">
        <div>
          BOKIS totalpris:
        </div>
        <div>
          <dx-number-box v-model:value="totalPrice" :format="NumberFormatting.kronerWithFourDecimals" :read-only="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'PriceForm',
  props: {
    modelValue: Object,
    overviewData: Object,
  },
  emits: ['update:modelValue'],
  setup() {
    const calculateTotal = (quantity, price) => quantity * price;

    return {
      NumberFormatting,
      calculateTotal,
    };
  },
  computed: {
    netsPrice: {
      get() {
        return this.modelValue.netsPrice;
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, netsPrice: value });
      },
    },
    price: {
      get() {
        return this.modelValue.price;
      },
      set(value) {
        const totalPrice = this.calculateTotal(this.quantity, value);
        const markupPrice = value * this.overviewData.markupPercent;
        const totalMarkupPrice = this.calculateTotal(this.quantity, markupPrice);
        this.$emit('update:modelValue', {
          ...this.modelValue, price: value, totalPrice, markupPrice, totalMarkupPrice,
        });
      },
    },
    markup: {
      get() {
        return this.modelValue.markupPrice;
      },
      set(value) {
        const totalMarkupPrice = this.calculateTotal(this.quantity, value);
        this.$emit('update:modelValue', { ...this.modelValue, markupPrice: value, totalMarkupPrice });
      },
    },
    quantity: {
      get() {
        return this.modelValue.quantity;
      },
      set(value) {
        const totalPrice = this.calculateTotal(value, this.price);
        const totalMarkupPrice = this.calculateTotal(value, this.markup);
        this.$emit('update:modelValue', {
          ...this.modelValue, quantity: value, totalPrice, totalMarkupPrice,
        });
      },
    },
    totalPrice() {
      return this.calculateTotal(this.quantity, this.price) + this.totalMarkup;
    },
    totalMarkup() {
      return this.calculateTotal(this.quantity, this.markup);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-form-root {
  padding: 20px;
  background-color: #FFF;

  .row {
    display: flex;
    padding-bottom: 15px;
  }

  .padding-right {
    padding-right: 25px;
  }
}
</style>
