<template>
  <ClientAccountModule />
</template>

<script>
import ClientAccountModule from '@/modules/masterData/clientAccounts/components/main.vue';

export default {
  name: 'MasterDataClientsAccounts',
  components: {
    ClientAccountModule,
  },
};
</script>
