<template>
  <div class="bi-publish-data billing-period-root datagrid-root">
    <div class="content-block">
      <h2>BC - import af invoice metadata</h2>
    </div>

    <div class="content-block background-color">
      <div class="button-row">
        <dx-checkbox text="Overskriv eksisterende       " v-model:value="overrideExisting" />
        <dx-button text="Start importering" icon="download" @click="onImportBCInvoiceMetadata(overrideExisting)" hint="Importér invoice-metadata via BC-API" />
      </div>

      <dx-data-grid
        ref="dataGrid"
        id="billingPeriodDataGrid"
        :data-source="datasource"
        height="75vh">

        <dx-selection mode="multiple" show-check-boxes-mode="always" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="bc-billing-period-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="7"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="startDate"
          caption="Startdato"
          data-type="date"
          :hiding-priority="6"
          format="dd.MM.yyyy"
          width="25%"
          sort-order="desc">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="endDate"
          caption="Slutdato"
          data-type="date"
          format="dd.MM.yyyy"
          :hiding-priority="5"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import useBCImportData from '@/modules/dataTransfer/composables/bc.composable.js';
import useDatagrid from '@/modules/masterData/billingPeriods/composables/datagrid.composable.js';

export default {
  name: 'ImportBCData',
  components: {
  },
  setup() {
    const {
      datasource,
      getDatasource,
    } = useDatagrid();

    getDatasource();
    const dataGrid = ref();
    const overrideExisting = ref(false);

    const {
      onImportBCInvoiceMetadata,
    } = useBCImportData(dataGrid);

    return {
      datasource,
      dataGrid,
      overrideExisting,
      onImportBCInvoiceMetadata,
    };
  },
};
</script>

<style lang="scss" scoped>
.bi-publish-data {
  .background-color {
    background-color: #FFF;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
  }
}
</style>
