<template>
  <SupplierModule />
</template>

<script>
import SupplierModule from '@/modules/masterData/suppliers/components/main.vue';

export default {
  name: 'MasterDataSuppliers',
  components: {
    SupplierModule,
  },
};
</script>
