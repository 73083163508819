import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useManualBillingRepository(billingSetId) {
  const appsettings = store.state.appSettings;
  const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

  const load = (loadOptions) => {
    let params = '?';

    [
      'filter',
      'group',
      'groupSummary',
      'parentIds',
      'requireGroupCount',
      'requireTotalCount',
      'searchExpr',
      'searchOperation',
      'searchValue',
      'select',
      'sort',
      'skip',
      'take',
      'totalSummary',
      'userData',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    return ApiService
      .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/overview${params}`);
  };

  const getManualBilling = (manualBillingEntryId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingEntryId}`);

  const insertManualBilling = (formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries`, formData);

  const updateManualBilling = (manualBillingEntryId, payload) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingEntryId}`, JSON.stringify(payload));

  const deleteManualBilling = (manualBillingEntryId) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingEntryId}`);

  return {
    load,
    getManualBilling,
    insertManualBilling,
    updateManualBilling,
    deleteManualBilling,
  };
}
