<template>
  <div class="billingset-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col :ratio="4" />
        <dx-col :ratio="1" screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <div class="header-container">
            <div class="header">
              Leverandør datasæt
            </div>
            <div v-if="billingSetEntry.loadingCompleted" class="link-container">
              <a @click="$emit('onUploadExternalDataSetClick', row)">Indlæs datasæt</a>
            </div>
            <div class="link-container">
              <a @click="$emit('onExternalDataSetDetailClick', row)">Detaljer</a>
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" />
          <div class="detail-element-container padding-right">
            <div>
              <ExternalDataSetList
                :dataSource="externalDataSetDatasource"
                :isLoaded="isLoaded"
                @startDataPolling="startDataPolling"
                @stopDataPolling="stopDataPolling"
              />
            </div>
            <div v-if="billingSetEntry.loadingCompleted" class="padding-top">
              Antal poster: {{ $filters.formatNumber(billingSetEntry.data.numberOfRecords) }}
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <div class="header-container padding-top">
            <div class="header">
              PI datasæt
            </div>
            <div class="link-container">
              <a @click="createClientDataSets()">Opret PI-datasæt</a>
            </div>
            <div class="link-container">
              <a @click="$emit('onClientDataSetClick', row)">Detaljer</a>
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="3" :col="0" />
          <div class="detail-element-container padding-right">
            <ClientDataSetStatusList
              v-if="statusSummaryList.loadingCompleted"
              @stopClientDataPolling="stopClientDataPolling"
              :data="statusSummaryList" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" :rowspan="5" />
          <dx-location screen="xs sm" :row="6" :col="0" />
          <div>
            <div class="action-button-wrapper">
              <dx-button icon="edit" text="Rediger kørsel" @click="$emit('onEditClick', row)" class="action-button-large"/>
            </div>
            <div class="action-button-wrapper">
              <dx-button icon="event" :text="row.data.publishingDate == null ? 'Publicér' : 'Publiceringsdato'" @click="$emit('onPublishDateClick', row)" class="action-button-large" />
            </div>
            <div class="action-button-wrapper" v-if="row.data.publishingDate == null">
              <dx-button icon="event" text="Publicér nu" @click="$emit('onPublishDateNowClick', row)" class="action-button-large" />
            </div>
            <div class="action-button-wrapper">
              <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button-large" />
            </div>
          </div>
        </dx-item>
      </dx-responsive-box>
    </div>
  </div>
</template>

<script>
import { ref, inject, watch } from 'vue';
import useExternalDataSet from '@/modules/xpBillingSet/composables/externaldataset.composable.js';
import ExternalDataSetList from '@/modules/xpBillingSet/components/externaldataset-list.vue';
import useClientDataSet from '@/modules/xpBillingSet/composables/clientdataset.composable.js';
import ClientDataSetStatusList from '@/modules/xpBillingSet/components/clientdataset-status-list.vue';
import useBillingSet from '@/modules/xpBillingSet/composables/billingset.composable.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  setup(props) {
    const billingSetId = ref(props.row.data.id);

    const externalDataSetUpdateDateTime = inject('externalDataSetUpdateDateTime');

    watch(() => externalDataSetUpdateDateTime.value, () => {
      // Reload billingset when new file has been uploaded.
      loadBillingSet(billingSetId.value);
    });

    // ExternalDataSets (Leverandør datasæt)
    const {
      externalDataSetDatasource,
      startDataPolling,
      stopDataPolling,
      isLoaded,
    } = useExternalDataSet(billingSetId.value);

    const {
      loadBillingSet,
      billingSetEntry,
    } = useBillingSet();

    loadBillingSet(billingSetId.value);

    // DataSets (PI datasæt)
    const {
      loadStatusSummary,
      statusSummaryList,
      createClientDataSets,
      stopClientDataPolling,
    } = useClientDataSet(billingSetId.value);

    loadStatusSummary();

    return {
      externalDataSetDatasource,
      ExternalDataSetList,
      ClientDataSetStatusList,
      createClientDataSets,
      statusSummaryList,
      billingSetEntry,
      startDataPolling,
      stopDataPolling,
      isLoaded,
      stopClientDataPolling,
    };
  },
};
</script>

<style lang="scss">
.billingset-detail-view {
  .header-container {
     display: flex;
     flex-direction: row !important;
     padding-bottom: 7px;

    .header {
      font-size: 18px;
      font-weight: 500;
    }

    .link-container {
      padding: 4px 0 0 15px;

      a {
        text-decoration: underline;
      }

      a:hover {
        cursor: pointer;
      }
    }
  }

  .d-inline {
    display: inline !important;
  }

  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }

    .action-button-large {
      margin-bottom: 5px;
      width: 170px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 20px;
  }

  .publish-date-field-root {
    display: flex;

    .padding-right {
      padding-right: 20px;
    }
  }
}
</style>
