<template>
  <ClientBillingEntryModule />
</template>

<script>
import ClientBillingEntryModule from '@/modules/clientDataSet/components/clientBillingEntry/list.vue';

export default {
  name: 'ClientBillingEntryList',
  components: {
    ClientBillingEntryModule,
  },
};
</script>
