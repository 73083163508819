<template>
  <div class="fusion-form-container">
    <form @submit="handleSubmit($event)">
      <div class="row">
        Ophører:
        <dx-textbox :value="row.data.registrationNumber + ' - ' + row.data.name" valueChangeEvent="input" :disabled="true">
        </dx-textbox>
      </div>

      <div class="row">
        Overtaget af:
        <dx-select-box
          :data-source="clientsList"
          :display-expr="displayExpr"
          value-expr="identifier"
          :disabled="modelValue.continuingClientId !== null"
          :value="modelValue.continuingClientId" @value-changed="(e) => update('continuingClientId', e.value)" />
      </div>

      <div class="date-row">
        <div class="date-item">
          Overtagelsesdato:
          <dx-date-box
            :value="modelValue.endingDate"
            type="date"
            display-format="dd-MM-yyyy"
            :disabled="modelValue.endingDate !== null"
            valueChangeEvent="input"
            @value-changed="(e) => update('endingDate', e.value)">
            <dx-validator>
              <dx-required-rule message="Overtagelsesdato skal udfyldes" />
            </dx-validator>
          </dx-date-box>
        </div>
      </div>

      <div class="validation-row row">
        <dx-validation-summary />
      </div>
      <div class="button-row row">
        <dx-button text="Gem" :use-submit-behavior="true" class="button margin-right" />
        <dx-button text="Annuller" @click="handleCancel" class="button" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'FusionForm',
  props: {
    modelValue: Object,
    row: Object,
    clients: Object,
  },
  computed: {
    clientsList: {
      get() {
        if (this.clients && this.clients.data && this.clients.data.length > 0) {
          // Return list of clients excliuding the current client sorted by id
          return this.clients.data.filter((client) => client.identifier !== this.row.data.id).sort((a, b) => a.registrationNumber - b.registrationNumber);
        }
        return [];
      },
    },
  },
  setup(props, context) {
    const update = (key, value) => {
      context.emit('update:modelValue', { ...props.modelValue, [key]: value });
    };

    const handleSubmit = (e) => {
      context.emit('save', props.row.data.id);
      e.preventDefault();
    };

    const handleCancel = () => {
      context.emit('cancel');
    };

    function displayExpr(item) {
      return item && `${item.registrationNumber} - ${item.name}`;
    }

    return {
      update,
      handleSubmit,
      handleCancel,
      displayExpr,
    };
  },
};
</script>

<style lang="scss" scoped>
.fusion-form-container {
  display: flex;
  flex-direction: column;

  .row {
    padding-bottom: 15px;
  }

  .date-item {
    flex: 1 0 auto;
  }

  .padding-right {
    padding-right: 15px;
  }

  .date-row {
    display: flex;
    flex-direction: row;
  }

  .button {
    width: 100px;
  }

  .margin-right {
    margin-right: 15px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;

  }
}
</style>
