import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useShareBillingSetRepository() {
  const appsettings = inject('appsettings');

  const getBillingSet = (key) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${key}`);

  const getMCCBillingSet = (key) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${key}`);

  const getDKVBillingSet = (key) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/dkvinvoicing/dkvbillingsets/${key}`);

  const getXPBillingSet = (key) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/xpinvoicing/xpbillingsets/${key}`);

  return {
    getBillingSet,
    getMCCBillingSet,
    getDKVBillingSet,
    getXPBillingSet,
  };
}
