import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/billingPeriods/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const invoiceFrequencyList = reactive({ data: null });

  const loadInvoiceFrequencyList = () => {
    masterdataRepository.getInvoiceFrequencyList()
      .then((response) => {
        invoiceFrequencyList.data = response.data;
      });
  };

  return {
    invoiceFrequencyList,
    loadInvoiceFrequencyList,
  };
}
