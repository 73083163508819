<template>
  <MCCExternalDataSetList />
</template>

<script>
import MCCExternalDataSetList from '@/modules/mccExternalDataSet/components/list.vue';

export default {
  name: 'ExternalDataSets',
  components: {
    MCCExternalDataSetList,
  },
};
</script>
