<template>
  <div class="copy-form-container">
    <form @submit="handleSubmit($event)">
      <div class="row">
        Foretag prisindeksregulering for hele prislisten.
      </div>
      <div class="row">
        Prisindeksregulering procent:
        <dx-number-box
          :value="modelValue.priceindexPercent"
          valueChangeEvent="input"
          @value-changed="(e) => update('priceindexPercent', e.value)"
          :min="0"
          :max="100"
          :format="NumberFormatting.percentageWithFourteenDecimals">
          <dx-validator>
            <dx-required-rule message="Prisindeks skal udfyldes" />
          </dx-validator>
        </dx-number-box>
      </div>
      <div class="validation-row row">
        <dx-validation-summary />
      </div>
      <div class="button-row row">
        <dx-button text="Opdatér" :use-submit-behavior="true" class="button margin-right" />
        <dx-button text="Annuller" @click="handleCancel" class="button" />
      </div>
    </form>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'PriceindexForm',
  props: {
    modelValue: Object,
  },
  setup(props, context) {
    const update = (key, value) => {
      context.emit('update:modelValue', { ...props.modelValue, [key]: value });
    };

    const handleSubmit = (e) => {
      context.emit('save');
      e.preventDefault();
    };

    const handleCancel = () => {
      context.emit('cancel');
    };

    return {
      update,
      handleSubmit,
      handleCancel,
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss" scoped>
.copy-form-container {
  display: flex;
  flex-direction: column;

  .row {
    padding-bottom: 15px;
  }

  .button {
    width: 100px;
  }

  .margin-right {
    margin-right: 15px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;

  }
}
</style>
