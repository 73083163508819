import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useExternalDataFileEntryRepository from '@/modules/externalDataSet/repositories/externaldatafileentry.repository.js';
import store from '@/store/index';

export default function useManualHandlingDatagrid(billingSetId, externalDataSetId) {
  const loadingDatasourceCompleted = ref(false);
  const externalDataFileEntryRepository = useExternalDataFileEntryRepository(billingSetId, externalDataSetId);

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => {
      loadingDatasourceCompleted.value = false;

      return externalDataFileEntryRepository.load(loadOptions)
        .then((response) => {
          if (response.data.success) {
            loadingDatasourceCompleted.value = true;
            return {
              data: response.data.value.data,
              totalCount: response.data.value.totalCount,
              summary: response.data.value.summary,
              groupCount: response.data.value.groupCount,
            };
          }
          if (response.data.messages.length > 0) {
            store.dispatch('addNotifications', { messageList: response.data.messages });
          }

          return null;
        });
    },
  });

  return {
    loadingDatasourceCompleted,
    datasource,
  };
}
