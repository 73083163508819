<template>
  <div title="Job status" @click="onClick" class="job-status-root">
    <div v-if="jobStatusSummary.numberOfRunningJobs > 0">
      <JobStatusIcon
        :numberOfJobs="jobStatusSummary.numberOfRunningJobs"
        :showNumberOfJobs="true"
        :icon="JobStatusIconEnum.REFRESH"
        :rotate="true" />
    </div>
    <div v-else-if="jobStatusSummary.numberOfFailedJobs > 0">
       <JobStatusIcon
        :numberOfJobs="jobStatusSummary.numberOfFailedJobs"
        :showNumberOfJobs="true"
        :icon="JobStatusIconEnum.WARNING"
        :rotate="false" />
    </div>
    <div v-else>
      <JobStatusIcon
        :showNumberOfJobs="false"
        :icon="JobStatusIconEnum.CHECK"
        :rotate="false" />
    </div>
  </div>
</template>

<script>
import useJob from '@/modules/shared/composables/job.composable.js';
import store from '@/store/index';
import JobStatusIcon from '@/components/common/job-status-icon.vue';
import JobStatusIconEnum from '@/enums/job-status-icon.js';

export default {
  name: 'JobsSummary',
  components: {
    JobStatusIcon,
  },
  setup() {
    const {
      jobStatusSummary,
    } = useJob();

    const onClick = () => {
      store.dispatch('showJobsPanel', !store.state.showJobsPanel);
    };

    return {
      jobStatusSummary,
      onClick,
      JobStatusIconEnum,
    };
  },
};
</script>

<style lang="scss" scoped>
.job-status-root:hover {
  cursor: pointer;
}
</style>
