import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useClientGroupsRepository() {
  const appsettings = inject('appsettings');

  const getClientGroupList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clientgroups`);

  return {
    getClientGroupList,
  };
}
