import useClientDataSetRepository from '@/modules/mccClientDataSet/repositories/clientdataset.repository.js';
import store from '@/store/index';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useClientDataSet(billingSetId) {
  const clientDataSetRepository = useClientDataSetRepository(billingSetId);
  const { startFastJobPolling } = useJob();

  const createClientDataSets = () => {
    clientDataSetRepository.create()
      .then(() => {
        // Start polling JobList at a fast pace
        startFastJobPolling();

        store.dispatch('addSuccessNotification', 'Manglende PI-datasæt bliver nu oprettet.');
      });
  };

  return {
    createClientDataSets,
  };
}
