import { ref, reactive } from 'vue';
import useMasterdataRepository from '@/modules/prices/repositories/masterdata.repository.js';

export default function useMasterdata(id) {
  const loadingInvoicingFrequencyListCompleted = ref(false);
  const loadingInvoiceCategoryListCompleted = ref(false);
  const loadingPriceTypeListCompleted = ref(false);

  const masterdataRepository = useMasterdataRepository();

  const invoicingFrequencyList = ref();
  const invoiceCategoryList = ref();
  const priceTypeList = ref();
  const overviewInfo = reactive({ loadingCompleted: false, data: null });
  const pricePeriod = ref();

  masterdataRepository.getOverviewInfo(id)
    .then((response) => {
      overviewInfo.loadingCompleted = true;

      if (response.data.success) {
        overviewInfo.data = response.data.value;
      } else {
        overviewInfo.data = {};
      }
    });

  masterdataRepository.getInvoiceFrequencyList()
    .then((response) => {
      loadingInvoicingFrequencyListCompleted.value = true;
      invoicingFrequencyList.value = response.data;
    });

  masterdataRepository.getInvoiceCategoryList(id)
    .then((response) => {
      loadingInvoiceCategoryListCompleted.value = true;
      invoiceCategoryList.value = response.data;
    });

  masterdataRepository.getPriceTypeList(id)
    .then((response) => {
      loadingPriceTypeListCompleted.value = true;
      priceTypeList.value = response.data;
    });

  masterdataRepository.getPricingPeriod(id)
    .then((response) => {
      pricePeriod.value = response.data.value;
    });

  return {
    overviewInfo,
    invoicingFrequencyList,
    invoiceCategoryList,
    priceTypeList,
    loadingPriceTypeListCompleted,
    loadingInvoiceCategoryListCompleted,
    loadingInvoicingFrequencyListCompleted,
    pricePeriod,
  };
}
