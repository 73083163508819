<template>
  <div class="billingset-type-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <dx-location screen="xs sm" :row="0" :col="0" />
        <div class="detail-element-container">
          Navn:
          <dx-textbox
            :value="row.data.name"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>
      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="0" :col="0" />
        <div class="detail-element-container">
          Apple Pay Kørsel:
          <dx-checkbox
            :value="row.data.applePay"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>
      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
export default {
  name: 'DetailView',
  props: {
    row: Object,
    supplierList: Array,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.billingset-type-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .detail-element-container {
    padding-bottom: 10px;
  }
}
</style>
