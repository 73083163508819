<template>
  <MBList />
</template>

<script>
import MBList from '@/modules/manualBilling/components/list.vue';

export default {
  name: 'ManualBillings',
  components: {
    MBList,
  },
};
</script>
