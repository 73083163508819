import CustomStore from 'devextreme/data/custom_store';
import useBIRepository from '@/modules/dataTransfer/repositories/bi.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const biRepository = useBIRepository();

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => biRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
  });

  const startPublishing = () => {
    biRepository.startPublishing()
      .then((response) => {
        if (response.data.success) {
          store.dispatch('addSuccessNotification', 'Publicering af data til BOKIS BI er nu startet. Det kan tage nogle minutter før data er overført.');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    datasource,
    startPublishing,
  };
}
