import {
  reactive, watch, ref, inject,
} from 'vue';
import useBillingSetRepository from '@/modules/clientDataSet/repositories/billingset.repository.js';
import store from '@/store/index';

export default function usePublishDate(billingSetId) {
  const billingSetRepository = useBillingSetRepository(billingSetId);
  const publishDate = reactive({ loadingCompleted: false, data: null });
  const publishDateButtonText = ref('Publiceringsdato: ikke publiceret');
  const formatService = inject('formatService');
  const showDatePopup = ref(false);

  const updatePublishDate = (newDate) => billingSetRepository.updatePublishDate(newDate)
    .then((response) => {
      if (response.data.success) {
        showDatePopup.value = false;
        publishDate.data = newDate;
        store.dispatch('addSuccessNotification', 'Publiceringsdato er nu opdateret');
      } else if (response.data.messages.length > 0) {
        store.dispatch('addNotifications', { messageList: response.data.messages });
      }
    });

  const getPublishDate = () => {
    publishDate.loadingCompleted = false;

    billingSetRepository.getPublishDate()
      .then((response) => {
        publishDate.loadingCompleted = true;

        if (response.data.success) {
          publishDate.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const PublishDateAction = {
    PUBLISHNOW: 1,
    PICKADATE: 2,
  };

  const publishDateActionList = [
    { key: PublishDateAction.PUBLISHNOW, text: 'Sæt publiceringsdato til dags dato' },
    { key: PublishDateAction.PICKADATE, text: 'Vælg en publiceringsdato' },
  ];

  const onPublishDateClick = (e) => {
    switch (e.itemData.key) {
      case PublishDateAction.PUBLISHNOW: {
        updatePublishDate(new Date().toISOString());
        break;
      }
      case PublishDateAction.PICKADATE: {
        showDatePopup.value = true;
        break;
      }
      default:
        break;
    }
  };

  const onSaveClick = (form) => {
    const formData = new FormData(form.target);
    let newDate;
    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formData.entries()) {
      if (pair[0] === 'publishDateField') {
        // eslint-disable-next-line prefer-destructuring
        newDate = pair[1];
        break;
      }
    }

    // Setting PublishDate to null means "not published"
    newDate = newDate === '' ? null : new Date(newDate).toISOString();

    updatePublishDate(newDate);
  };

  watch(
    () => publishDate,
    () => {
      publishDateButtonText.value = `Publiceringsdato: ${publishDate.data == null ? 'ikke publiceret' : formatService.stringToDate(publishDate.data)}`;
    },
    { deep: true },
  );

  return {
    getPublishDate,
    publishDateButtonText,
    onPublishDateClick,
    publishDateActionList,
    showDatePopup,
    onSaveClick,
    publishDate,
  };
}
