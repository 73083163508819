<template>
  <div class="bi-graph-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - BI Graftyper</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="biGraphDataGrid"
        :data-source="datasource"
        height="75vh"
        @editing-start="initFormFields"
        :focused-row-key="focusedRowKey">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="BiGraftyper" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="bi-graph-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="false"
          :allow-adding="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="graphType">
              <dx-label text="Graph angivelse" />
            </dx-simple-item>
            <dx-simple-item data-field="dateForPublishedData">
              <dx-label text="Dato for visning af data" />
            </dx-simple-item>
            <dx-simple-item data-field="defaultYear">
              <dx-label text="Start år" />
            </dx-simple-item>
            <dx-simple-item data-field="noDataMessage">
              <dx-label text="Besked omkring data" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :form-item="nameFormItem"
          :hiding-priority="8">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="graphType"
          caption="Graph angivelse"
          data-type="string"
          :hiding-priority="7"
          :form-item="graphTypeFormItem">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="dateForPublishedData"
          caption="Dato for visning af data"
          data-type="date"
          format="dd.MM.yyyy"
          :hiding-priority="6"
          :form-item="dateForPublishedDataFormItem">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="defaultYear"
          caption="Start år"
          data-type="number"
          :hiding-priority="5"
          :form-item="defaultYearFormItem">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="noDataMessage"
          caption="Besked omkring data"
          data-type="string"
          :visible="false"
          :hiding-priority="4"
          :form-item="noDataMessageFormItem">
          <dx-required-rule />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              @onEditClick="editClick"
              @onPublishDateClick="publishDateClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import DetailView from '@/modules/masterData/biGraphs/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/biGraphs/composables/datagrid.composable.js';

const nameFormItem = {
  visible: true,
};

const graphTypeFormItem = {
  visible: false,
};

const dateForPublishedDataFormItem = {
  visible: true,
};

const defaultYearFormItem = {
  visible: false,
};

const noDataMessageFormItem = {
  visible: false,
};

export default {
  name: 'MainBIGraphModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      isPublishDateMode,
      initPublishFields,
      initFormFields,
    } = useDatagrid();

    getDatasource();

    return {
      datasource,
      isPublishDateMode,
      initPublishFields,
      nameFormItem,
      graphTypeFormItem,
      dateForPublishedDataFormItem,
      defaultYearFormItem,
      noDataMessageFormItem,
      initFormFields,
    };
  },
  methods: {
    editClick(e) {
      this.isPublishDateMode = false;

      // Call base class
      this.onEditClick(e);
    },
    publishDateClick(e) {
      this.initPublishFields(e);

      // Call base class
      this.onEditClick(e);
    },
  },
};
</script>

<style lang="scss">
.bi-graph-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }
}
</style>
