import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useInvoiceRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const getTotalClientBillingReport = () => ApiService
    // .get(`${appsettings.ADMINISTRATION_API}/api/v1/dkvinvoicing/dkvbillingsets/${billingSetId}/totalclientbillingreport`);
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/xpinvoicing/xpbillingsets/${billingSetId}/totalclientbillingreportcontent`);

  const getSummaryClientBillingReport = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/xpinvoicing/xpbillingsets/${billingSetId}/summaryclientbillingreport`);

  const getXPaySTAT2Report = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/xpinvoicing/xpbillingsets/${billingSetId}/stat2report`, { responseType: 'arraybuffer' });

  const getXPaySTAT3Report = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/xpinvoicing/xpbillingsets/${billingSetId}/stat3report`, { responseType: 'arraybuffer' });

  return {
    getTotalClientBillingReport,
    getSummaryClientBillingReport,
    getXPaySTAT2Report,
    getXPaySTAT3Report,
  };
}
