<template>
  <div class="manual-billing-form-root">
    <div class="content-block">

      <div class="headline-container">
        <Headline :title="isNewEntity ? 'Opret ny BOKIS post' : 'Rediger BOKIS post'" :contextData="billingSetEntry" />
        <div><u @click="onBackClick" class="link">Kørsler</u> > BOKIS poster</div>
      </div>

      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onCancelClick"></dx-button>
      </div>

      <form @submit="handleSubmit($event)" enctype="application/x-www-form-urlencoded">
        <dx-form validation-group="mainForm" />
        <div class="row">
          <div>
            Vare:
          </div>
          <div>
            <dx-lookup
              id="productId"
              name="productId"
              :data-source="productList.data"
              display-expr="name"
              value-expr="id"
              :disabled="!isNewEntity"
              v-model:value="identifierOfProduct"
              :drop-down-options="productListOptions"
              placeholder="Vælg vare">
              <dx-validator>
                <dx-required-rule message="Vare skal udfyldes" />
              </dx-validator>
            </dx-lookup>
          </div>
        </div>
        <div v-if="manualBillingEntity.loadingCompleted">
          <div class="row" v-if="!isNewEntity">
            <div>
              Supplerende tekst:
            </div>
            <div>
              <dx-textbox v-model:value="manualBillingEntity.data.text">
                <dx-validator validation-group="mainForm">
                  <dx-required-rule message="Tekst skal udfyldes" />
                </dx-validator>
              </dx-textbox>
            </div>
          </div>
          <div class="row"  v-if="!isNewEntity">
            <div>
              Kommentar til fordeling:
            </div>
            <div>
              <dx-text-area v-model:value="manualBillingEntity.data.comment" :height="90" />
            </div>
          </div>

          <div class="row tab-panel-container" v-if="!isNewEntity">
            <dx-tab-panel
              :items="tabPanelItems"
              item-title-template="tabTitle"
              v-model:selected-index="selectedTabIndex">
              <template #tabTitle="{ data }">
                <p>{{ data.title }}</p>
              </template>
              <template #prices>
                <PriceForm v-model="manualBillingEntity.data" :overviewData="overviewData.data" />
              </template>
              <template #selection>
                <SelectionForm v-if="overviewData.loadingCompleted"
                  :billingSetId="_billingSetId"
                  :manualBillingId="manualBillingId"
                  :overviewData="overviewData.data"
                  @generateDistributionKeys="onGenerateDistributionKeysClick" />
              </template>
              <template #distribution>
                <div>
                  <DistributionForm v-model="manualBillingEntity.data" @importFile="onImportDistributionFile" />
                </div>
              </template>
            </dx-tab-panel>
          </div>

          <div class="validation-row row">
            <dx-validation-summary validation-group="mainForm" />
          </div>
        </div>

        <div class="sticky-button-container">
          <div class="button-row row">
            <div class="action-button-wrapper">
              <dx-button text="Gem" :use-submit-behavior="true" validation-group="mainForm" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <Popup v-if="showConfirmPopup"
      title="Advarsel"
      :visible="showConfirmPopup"
      content="Fordelingen af priser matcher ikke totalbeløb. Vil du fortsætte med at gemme?"
      saveButtonText="Ja"
      cancelButtonText="Nej"
      @onSave="saveExistingManualBilling()"
      @onCancel="closeConfirmPopup"
      :show-close-button="false"
      :height="220" />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useRouter } from 'vue-router';
import { ref, inject } from 'vue';
import { get } from 'lodash';
import Headline from '@/components/common/headline.vue';
import PriceForm from '@/modules/manualBilling/components/form/price-form.vue';
import SelectionForm from '@/modules/manualBilling/components/form/selection/form-container.vue';
import DistributionForm from '@/modules/manualBilling/components/form/distribution-form.vue';
import useMasterdata from '@/modules/manualBilling/composables/masterdata.composable.js';
import useManualBilling from '@/modules/manualBilling/composables/manualBilling.composable.js';
import useSelection from '@/modules/manualBilling/composables/selection.composable.js';
import useSharedBillingSet from '@/modules/shared/composables/billingset.composable.js';
import Popup from '@/components/common/popup.vue';

const productListOptions = {
  closeOnOutsideClick: true,
  showTitle: false,
};

export default {
  name: 'FormContainer',
  components: {
    PriceForm,
    DistributionForm,
    SelectionForm,
    Popup,
    Headline,
  },
  props: {
    // BillingSet Id
    _billingSetId: String,
    // Manual Billing ID
    _manualBillingId: String,
  },
  computed: {
    identifierOfProduct: {
      get() {
        return get(this.manualBillingEntity, ['data', 'identifierOfProduct'], 0);
      },
      set(value) {
        if (this.manualBillingEntity.data == null) return;

        this.manualBillingEntity.data.identifierOfProduct = value;
      },
    },
  },
  setup(props) {
    const tabPanelItems = [{
      title: 'Priser',
      template: 'prices',
    }, {
      title: 'Selektering',
      template: 'selection',
    },
    {
      title: 'Fordeling',
      template: 'distribution',
    }];

    const selectedTabIndex = ref();
    const manualBillingId = ref(props._manualBillingId);
    const isNewEntity = ref(manualBillingId.value == null);

    const {
      manualBillingEntity,
      insertManualBilling,
      loadManualBilling,
      updateManualBilling,
    } = useManualBilling(props._billingSetId, manualBillingId.value);
    const { productList, overviewData } = useMasterdata(props._billingSetId);

    if (!isNewEntity.value) {
      loadManualBilling(manualBillingId.value);
    }

    const {
      loadBillingSet,
      billingSetEntry,
    } = useSharedBillingSet();
    loadBillingSet(props._billingSetId);

    const router = useRouter();

    const formatService = inject('formatService');
    const showConfirmPopup = ref(false);
    const showConfirmDialog = (data) => {
      // First calculate prices
      const calculatedTotalPrice = data.manualBillingEntryDistributionList.reduce((accumulator, curr) => accumulator + curr.totalBasePrice, 0);
      const calculatedTotalMarkupPrice = data.manualBillingEntryDistributionList.reduce((accumulator, curr) => accumulator + curr.totalMarkupPrice, 0);

      // Then match calculated prices with total prices
      const totalPriceDoNotMatch = formatService.formatNumber(calculatedTotalPrice, 2, 4) !== formatService.formatNumber(data.totalPrice, 2, 4);
      const totalMarkupPriceDoNotMatch = formatService.formatNumber(calculatedTotalMarkupPrice, 2, 4) !== formatService.formatNumber(data.totalMarkupPrice, 2, 4);

      // Show confirm dialog if prices don't match
      return totalPriceDoNotMatch || totalMarkupPriceDoNotMatch;
    };

    const closeConfirmPopup = () => {
      showConfirmPopup.value = false;
      // Select tab called "Fordeling" tab
      selectedTabIndex.value = 2;
    };

    // Get url querystring params
    const routeQuery = router.currentRoute.value.query;

    const routeToPreviousPage = () => {
      if (routeQuery.referer === 'manualHandling') {
        router.push({
          name: 'ManualHandlingList',
          params: {
            billingSetId: props._billingSetId,
            externalDataSetId: routeQuery.externalDataSetId,
          },
        });
      } else {
        router.push({
          name: 'ManualBillingList',
          params: { id: props._billingSetId },
        });
      }
    };

    const saveExistingManualBilling = () => {
      // Save existing manualBilling
      updateManualBilling(manualBillingId.value, manualBillingEntity.data)
        .then((response) => {
          if (response.data.success) {
            routeToPreviousPage();
          }
        });
    };

    const handleSubmit = (e) => {
      if (isNewEntity.value) {
        // Create new manualBilling with selected product
        insertManualBilling(e)
          .then((newManualBillingId) => {
            if (newManualBillingId == null) return;

            loadManualBilling(newManualBillingId);
            isNewEntity.value = false;
            manualBillingId.value = String(newManualBillingId);
          });
      } else if (showConfirmDialog(manualBillingEntity.data)) {
        showConfirmPopup.value = true;
      } else {
        saveExistingManualBilling();
      }

      e.preventDefault();
    };

    const onCancelClick = () => {
      routeToPreviousPage();
    };

    function onBackClick() {
      router.push({ name: 'BillingSet' });
    }

    const {
      generateDistributionKeys,
      importDistributionFile,
    } = useSelection(props._billingSetId);

    const onGenerateDistributionKeysClick = (selection) => {
      generateDistributionKeys(manualBillingId.value, selection)
        .then((response) => {
          if (response == null) return;

          const newDistributionKeyList = response;
          manualBillingEntity.data.manualBillingEntryDistributionList.forEach((distItem) => {
            // Find new distribution item for client
            const newDistItem = newDistributionKeyList.find((x) => x.cliNo === distItem.cliNo);
            const distKey = newDistItem.value;
            const { selected } = newDistItem;

            // Update existing distribution item with new values
            distItem.disValue = selected ? distKey : null;
            distItem.selected = selected;
          });

          // Select tab called "Fordeling" tab
          selectedTabIndex.value = 2;
        });
    };

    const onImportDistributionFile = (form) => {
      importDistributionFile(form)
        .then((importedDistributionList) => {
          manualBillingEntity.data.manualBillingEntryDistributionList.forEach((distItem) => {
            const newDistributionItem = importedDistributionList.find((x) => x.regNumber === distItem.cliRegistrationNumber);

            if (newDistributionItem == null) {
              // Reset distribution item
              distItem.selected = false;
              distItem.disValue = null;
              distItem.totalBasePrice = null;
              distItem.totalMarkupPrice = null;
            } else {
              // Select and Update distribution item
              distItem.selected = true;
              distItem.disValue = newDistributionItem.distributionValue;
              distItem.totalBasePrice = newDistributionItem.price;
              distItem.totalMarkupPrice = newDistributionItem.markupPrice;
            }
          });
        });
    };

    return {
      tabPanelItems,
      productList,
      productListOptions,
      handleSubmit,
      manualBillingEntity,
      isNewEntity,
      onCancelClick,
      overviewData,
      manualBillingId,
      onGenerateDistributionKeysClick,
      selectedTabIndex,
      onImportDistributionFile,
      showConfirmPopup,
      saveExistingManualBilling,
      billingSetEntry,
      closeConfirmPopup,
      onBackClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.manual-billing-form-root {
  padding-bottom: 50px;

  .row {
    padding-bottom: 15px;
  }

  .tab-panel-container {
    padding-top: 15px;
  }

  .validation-row,
  .button-row {
    display: flex;
    justify-content: flex-end;

    .action-button-wrapper {
      margin-left: 15px;
    }
  }

  .sticky-button-container {
    background: #f2f2f2;
    position: sticky;
    z-index: 1500;
    bottom: 0;
    padding: 20px 20px 0;
  }

  .back-button-container {
    margin: 10px 0;
  }

  .button-container {
    padding: 20px;
    background-color: #FFF;
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
