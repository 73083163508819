import useClientDataSetRepository from '@/modules/clientDataSet/repositories/clientdataset.repository.js';
import useCreditRepository from '@/modules/clientDataSet/repositories/credit.repository.js';
import useInvoiceRepository from '@/modules/clientDataSet/repositories/invoice.repository.js';
import store from '@/store/index';

export default function useDelete(billingSetId, dataGrid) {
  const clientDataSetRepository = useClientDataSetRepository(billingSetId);
  const creditRepository = useCreditRepository(billingSetId);
  const invoiceRepository = useInvoiceRepository(billingSetId);

  const deleteClientDataSets = (clientDataSetIdList) => {
    store.dispatch('showLoadingPanel', true);

    clientDataSetRepository.deleteClientDataSets(clientDataSetIdList)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          // Reload datagrid
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'PI-datasæt er nu slettet');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const deleteCreditInvoices = (clientDataSetIdList) => {
    store.dispatch('showLoadingPanel', true);

    creditRepository.deleteCreditInvoices(clientDataSetIdList)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          // Reload datagrid
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'Kreditnotaer er nu slettet');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const deleteInvoices = (clientDataSetIdList) => {
    store.dispatch('showLoadingPanel', true);

    invoiceRepository.deleteInvoices(clientDataSetIdList)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          // Reload datagrid
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'Fakturaer er nu slettet');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const DeleteAction = {
    DELETECREDITINVOICES: 1,
    DELETEINVOICES: 2,
    DELETECLIENTDATASETS: 3,
  };

  const deleteActionList = [
    { key: DeleteAction.DELETECLIENTDATASETS, text: 'Slet PI-datasæt', description: 'Slet alle markerede PI-datasæt' },
    { key: DeleteAction.DELETEINVOICES, text: 'Slet fakturaer', description: 'Slet faktureringsdatasæt for alle markerede PI-datasæt' },
    { key: DeleteAction.DELETECREDITINVOICES, text: 'Slet kreditnotaer', description: 'Slet krediteringsdatasæt for alle markerede PI-datasæt' },
  ];

  const onDeleteClick = (e) => {
    const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();

    // Remove pi's with no client data set
    const clientDataSetIdList = selectedRowsData
      .filter((x) => x.id != null)
      .map((x) => x.id);

    if (clientDataSetIdList.length === 0) {
      store.dispatch('addInfoNotification', 'Du skal vælge minimum ét datasæt.');
      return;
    }

    switch (e.itemData.key) {
      case DeleteAction.DELETECLIENTDATASETS: {
        // Delete ClientDataSets
        deleteClientDataSets(clientDataSetIdList);
        break;
      }
      case DeleteAction.DELETEINVOICES: {
        // Delete invoices
        deleteInvoices(clientDataSetIdList);
        break;
      }
      case DeleteAction.DELETECREDITINVOICES: {
        // Delete invoices
        deleteCreditInvoices(clientDataSetIdList);
        break;
      }
      default:
        break;
    }
  };

  return {
    onDeleteClick,
    deleteActionList,
  };
}
