<template>
  <div class="xpexternaldataset-summary-list">
    <dx-data-grid
      ref="dataGrid"
      id="xpExternalDataSetSummaryDataGrid"
      :data-source="dataSource"
      :column-auto-width="true"
    >
      <dx-export :enabled="false" fileName="Kørsler" />
      <dx-filter-row :visible="false" />
      <dx-load-panel :enabled="!isLoaded" />
      <dx-header-filter :visible="false" />

      <dx-column
        caption="Fil"
        data-type="string"
        :hiding-priority="4"
        cell-template="fileTemplate" />

      <dx-column
        caption="Filtype"
        :hiding-priority="4"
        cell-template="fileTypeTemplate" />

      <dx-column
        caption="Advarsler"
        :hiding-priority="1"
        cell-template="warningsTemplate" />

        <template #fileTemplate="{ data }">
        <div>
          <a href="#" @click.prevent="onDownloadFileClick(data.data.id, data.data.fileName)">{{ data.data.fileName }} </a>
        </div>
      </template>

      <template #fileTypeTemplate="{ data }">

        <div v-if="!data.data.statType">
            (under indlæsning)
        </div>
        <div v-else>
          STAT {{data.data.statType}}
        </div>
      </template>

      <template #warningsTemplate="{ data }">
        <div>
          <div v-if="!data.data.parsed">
            (under indlæsning)
          </div>
          <div v-else-if="getNumberOfWarnings(data.data.warningList) > 0">
            <a @click="showWarnings(data.data.warningList)">Der er {{ getNumberOfWarnings(data.data.warningList) }}  {{ getNumberOfWarnings(data.data.warningList) === 1 ? 'advarsel' : 'advarsler' }}</a>
          </div>
          <div v-else>
            Ingen
          </div>
        </div>
      </template>
    </dx-data-grid>
    <Popup
      :visible="showWarningsPopup"
      title="Advarsler"
      :content="warningsPopupContent"
      cancelButtonText="Luk"
      :showSaveButton="false"
      :showCancelButton="true"
      :showCloseButton="false"
      :dragEnabled="true"
      :width="600"
      :height="400"
      @onCancel="showWarningsPopup = false" />
  </div>
</template>

<script>
import {
  inject, watch, ref, onBeforeUnmount,
} from 'vue';
import ExternalDataSetStatus from '@/enums/externaldataset-status.js';
import useWarnings from '@/modules/shared/composables/warning.composable.js';
import Popup from '@/components/common/popup.vue';
import useFileExport from '@/modules/xpBillingSet/composables/fileexport.composable.js';

export default {
  name: 'ExternalDataSetList',
  props: {
    dataSource: {
      type: Object,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popup,
  },
  emits: ['stopDataPolling', 'startDataPolling'],
  setup(props, context) {
    const dataGrid = ref();
    const externalDataSetUpdateDateTime = inject('externalDataSetUpdateDateTime');

    watch(() => externalDataSetUpdateDateTime.value, () => {
      // Reload datagrid when new file has been uploaded.
      dataGrid.value.instance.refresh();
      context.emit('startDataPolling');
    });

    onBeforeUnmount(() => {
      context.emit('stopDataPolling');
    });

    const {
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      getNumberOfWarnings,
    } = useWarnings();

    const {
      onDownloadFileClick,
    } = useFileExport();

    return {
      ExternalDataSetStatus,
      dataGrid,
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      getNumberOfWarnings,
      onDownloadFileClick,
    };
  },
};
</script>

<style lang="scss">
.xpexternaldataset-summary-list {
  .dx-datagrid {
    background-color: transparent;
    padding: 0px;
  }

  .dx-row:hover {
    cursor: auto;
  }

  .nets-diff-color {
    color: #DDBB00;
  }

  .nets-no-diff-color {
    color: #00BB00;
  }

  .dx-icon-clear {
    color: #FF0000;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
  }
}
</style>
