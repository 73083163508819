const BrandGroupType = {
  INTERNATIONALE: 1,
  DKVISA: 2,
  DANKORT: 3,
  NEUTRAL: 4,
};

export const BrandGroupTypeDescription = new Map([
  [BrandGroupType.INTERNATIONALE, 'Int. kort'],
  [BrandGroupType.DKVISA, 'Visa/Dankort'],
  [BrandGroupType.DANKORT, 'Dankort'],
  [BrandGroupType.NEUTRAL, 'Neutral'],
]);

export default BrandGroupType;
