<template>
  <div class="dkvclientdataset-list-root datagrid-root">
    <div class="content-block flex-container">
      <div class="flex-item">
        <Headline title="PI datasæt" :contextData="billingSetEntry" />
        <div><u @click="router.push({ name: 'XPBillingSet' })" class="link">X-Pays kørsler</u> > PI-datasæt</div>
      </div>
      <div class="flex-item">
        <div class="publish-date-container">
          <div>
            <dx-dropdown-button
              icon="upload"
              key-expr="key"
              display-expr="text"
              :items="publishDateActionList"
              :text="publishDateButtonText"
              @item-click="onPublishDateClick"
              item-template="itemTemplate">
              <template #itemTemplate="{ data }" >
                <div>
                  <div>{{ data.text }}</div>
                </div>
              </template>
            </dx-dropdown-button>
          </div>
        </div>
      </div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="router.push({ name: 'XPBillingSet' })"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button icon="plus" text="Opret PI-datasæt" @click="createClientDataSets()" hint="Opret alle PI-datasæt for kørsel" class="create-button"></dx-button>
          <dx-dropdown-button
            icon="money"
            key-expr="key"
            display-expr="text"
            :items="xpaysReportActionList"
            text="STAT-rapporter"
            class="invoice-button"
            @item-click="xpaysReportClick(billingSetEntry.data.name, $event)"
            :drop-down-options="{ width: 520 }"
            item-template="itemTemplate">
            <template #itemTemplate="{ data }" >
              <div>
                <div><b>{{ data.text }}</b></div>
                <div>{{ data.description }}</div>
              </div>
            </template>
          </dx-dropdown-button>
          <dx-button icon="money" text="Hent totaloversigt" @click="getTotalClientBillingReport(billingSetEntry.data.name)" hint="Hent oversigt over alle poster i alle PI-datasæt i CSV-format" class="invoice-button"></dx-button>
          <dx-button icon="money" text="Slet PI-datasæt" @click="onDeleteClick()" hint="Slet alle markerede PI-datasæt" class="delete-button"></dx-button>
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="dkvClientDataSetDataGrid"
        :data-source="datasource"
        height="80vh"
        :focused-row-key="focusedRowKey"
        @cell-prepared="onCellPrepared">

        <dx-selection mode="multiple" show-check-boxes-mode="always" />
        <dx-export :enabled="true" fileName="PI-Datasæt" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="dkvclientDataSet-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-column
          data-field="cliRegistrationNumber"
          caption="Reg. nr."
          :hiding-priority="4"
          sort-order="asc"
          :width="100" />

        <dx-column
          data-field="cliName"
          caption="Pengeinstitut"
          :hiding-priority="5" />

        <dx-column
          data-field="cliTotalPrice"
          caption="Beløb STAT1"
          :hiding-priority="3"
          :width="150"
          cell-template="cliTotalTemplate" />

        <dx-column
          data-field="statusText"
          caption="Status"
          :hiding-priority="2"
          :width="150" />

        <dx-column
          caption="Fusion"
          :hiding-priority="1"
          :width="150"
          cell-template="fusionTemplate" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <DetailView :row="row" />
        </template>

        <template #fusionTemplate="{ data }">
          <div v-if="data.data.partOfFusion">
            <i class='dx-icon-link' style='color: #2b2b2b; font-size: 18px;' title='Pengeinstitut indgår i fusion' />
          </div>
        </template>

        <template #cliTotalTemplate="{ data }">
          <div>
            <span v-if="data.data.cliTotalPrice">{{ formatService.formatNumber(data.data.cliTotalPrice, 2, 2) + ' kr.' }}</span>
          </div>
        </template>
      </dx-data-grid>
    </div>

    <FormPopup
      :visible="showDatePopup"
      title="Vælg publiceringsdato"
      :height="250"
      @cancel="showDatePopup = !showDatePopup"
      @save="onSaveClick">
      <dx-date-box
        :value="publishDate.data"
        id="publishDateField"
        name="publishDateField" >
        <dx-validator validation-group="mainForm" />
      </dx-date-box>
    </FormPopup>

    <Popup v-if="confirmPopupModel.show"
        :title="confirmPopupModel.title"
        :visible="confirmPopupModel.show"
        :content="confirmPopupModel.content"
        :saveButtonText="confirmPopupModel.saveButtonText"
        :cancelButtonText="confirmPopupModel.cancelButtonText"
        @onSave="confirmPopupModel.saveClickHandler"
        @onCancel="confirmPopupModel.show = !confirmPopupModel.show"
        :height="250" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, inject } from 'vue';
import DetailView from '@/modules/xpClientDataSet/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/xpClientDataSet/composables/datagrid.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';
import useClientDataSet from '@/modules/xpClientDataSet/composables/clientdataset.composable.js';
import useInvoice from '@/modules/xpClientDataSet/composables/invoice.composable.js';
import useDelete from '@/modules/xpClientDataSet/composables/delete.composable.js';
import useSharedBillingSet from '@/modules/shared/composables/billingset.composable.js';
import Headline from '@/components/common/headline.vue';
import usePublishDate from '@/modules/xpClientDataSet/composables/publishdate.composable.js';
import FormPopup from '@/components/common/form-popup.vue';
import Popup from '@/components/common/popup.vue';

export default {
  name: 'DKVClientDataSetList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    Headline,
    FormPopup,
    Popup,
  },
  props: {
    billingSetId: String,
  },
  setup(props) {
    const formatService = inject('formatService');
    const dataGrid = ref();

    const {
      datasource,
      onCellPrepared,
    } = useDatagrid(props.billingSetId);

    const router = useRouter();

    const {
      createClientDataSets,
    } = useClientDataSet(props.billingSetId);

    const {
      collectionDatePopup,
      confirmPopupModel,
      getTotalClientBillingReport,
      getSummaryClientBillingReport,
      getXPaySTAT2Report,
      getXPaySTAT3Report,
      xpaysReportActionList,
      xpaysReportClick,
    } = useInvoice(props.billingSetId);

    const {
      onDeleteClick,
    } = useDelete(props.billingSetId, dataGrid);

    const {
      loadXPBillingSet,
      billingSetEntry,
    } = useSharedBillingSet();
    loadXPBillingSet(props.billingSetId);

    const {
      getPublishDate,
      publishDateButtonText,
      onPublishDateClick,
      publishDateActionList,
      showDatePopup,
      onSaveClick,
      publishDate,
    } = usePublishDate(props.billingSetId);
    getPublishDate();

    return {
      datasource,
      router,
      NumberFormatting,
      createClientDataSets,
      getTotalClientBillingReport,
      getSummaryClientBillingReport,
      getXPaySTAT2Report,
      getXPaySTAT3Report,
      onCellPrepared,
      dataGrid,
      onDeleteClick,
      billingSetEntry,
      publishDateButtonText,
      onPublishDateClick,
      publishDateActionList,
      showDatePopup,
      onSaveClick,
      collectionDatePopup,
      confirmPopupModel,
      publishDate,
      formatService,
      xpaysReportActionList,
      xpaysReportClick,
    };
  },
};
</script>

<style lang="scss">
.dkvclientdataset-list-root {
  .link:hover {
    cursor: pointer;
  }

  .flex-container {
    display: flex;

    .flex-item {
      flex: 1;

      .publish-date-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }

  .grid-button-container .delete-button {
    margin-right: 46px;
  }

  .invoice-button, .recalculate-button, .create-button {
    margin-right: 5px
  }

  .display-none {
    display: none;
  }
}
</style>
