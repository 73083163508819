<template>
  <ManualHandlingList :billingSetId="billingSetId" :externalDataSetId="externalDataSetId" />
</template>

<script>
import ManualHandlingList from '@/modules/externalDataSet/components/manualHandling/list.vue';

export default {
  name: 'ManualHandlings',
  props: {
    billingSetId: String,
    externalDataSetId: String,
  },
  components: {
    ManualHandlingList,
  },
};
</script>
