<template>
  <div class="crm-form-root">
    <div class="row">
      <h4>Filtrering af pengeinstitut</h4>
    </div>
    <div class="row">
      <div v-for="crmParameter in crmParameterList" :key="crmParameter.index" class="row crm-parameter-list-container">
        <div class="switch row">
          <dx-switch :value="getSwitchValue(crmParameter.crpNo)" @value-changed="toggleSwitch($event, crmParameter.crpNo)" />
        </div>
        <div class="name row">
          {{ crmParameter.crpName }}:
        </div>
        <div v-if="crmParameter.crpDataType === CRMDataType.CHOICELIST" class="filter row">
          <dx-tag-box
            :items="crmParameter.choiceList.sort((a, b) => a.clpOrder - b.clpOrder)"
            value-expr="clpNo"
            display-expr="clpName"
            :value="crmParameter.choiceListValue"
            @value-changed="crmParameter.choiceListValue = $event.value"
            :disabled="!crmParameter.selected" />
        </div>
        <div v-if="crmParameter.crpDataType === CRMDataType.BOOLEAN" class="filter row boolean-type">
          <dx-checkbox
          :value="crmParameter.boolValue"
            @value-changed="crmParameter.boolValue = $event.value"
            :disabled="!crmParameter.selected" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { cloneDeep } from 'lodash';
import CRMDataType from '@/enums/crm-datatype.js';

export default {
  name: 'SelectionCRMForm',
  props: {
    modelValue: Object,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const crmParameterList = ref([]);
    crmParameterList.value = cloneDeep(props.modelValue.crmParameters);

    // Add selected property
    crmParameterList.value.forEach((crm) => {
      // eslint-disable-next-line no-param-reassign
      crm.selected = crm.choiceListValue.length > 0 || crm.boolValue;
    });

    watch(() => cloneDeep(crmParameterList.value), () => {
      // Emit updates to parent
      emit('update:modelValue', { ...props.modelValue, crmParameters: crmParameterList.value });
    });

    const getSwitchValue = (crpNo) => {
      // Get CRMParameter
      const crmParameter = crmParameterList.value.find((x) => x.crpNo === crpNo);

      switch (crmParameter.crpDataType) {
        case CRMDataType.CHOICELIST:
          return crmParameter.choiceListValue.length > 0;
        case CRMDataType.BOOLEAN:
          return crmParameter.boolValue != null;
        default:
          return false;
      }
    };

    const toggleSwitch = (event, crpNo) => {
      const crmParameter = crmParameterList.value.find((x) => x.crpNo === crpNo);
      crmParameter.selected = event.value;

      if (event.value) {
        if (crmParameter.crpDataType === CRMDataType.BOOLEAN) {
          // When selecting a boolean
          crmParameter.boolValue = false;
        }
      } else {
        // When deselecting then reset all selected values
        crmParameter.choiceListValue = [];
        crmParameter.boolValue = null;
      }
    };

    return {
      CRMDataType,
      crmParameterList,
      getSwitchValue,
      toggleSwitch,
    };
  },
};
</script>

<style lang="scss" scoped>
.crm-form-root {
  padding: 20px;
  background-color: #FFF;

  .row {
    padding-bottom: 15px;
  }

  .crm-parameter-list-container {
    display: flex;

    .switch {
      width: 60px;
      padding-top: 5px;
    }

    .name {
      width: 150px;
      padding-top: 7px;
    }

    .boolean-type {
      padding-top: 7px;
    }

    .filter {
      width: 300px;
    }
  }

  .margin-right {
    margin-right: 15px;
  }
}
</style>
