import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getOverviewInfo = (pricePeriodId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${pricePeriodId}/overview`);

  const getInvoiceFrequencyList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/invoicefrequencies`);

  const getMainProductList = (pricePeriodId, currentMainProductId) => {
    let param = '';
    if (currentMainProductId !== null) {
      param = `?mainproductidtoinclude=${currentMainProductId}`;
    }

    return ApiService
      .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${pricePeriodId}/mainproducts${param}`);
  };

  const getProductPriceGroupList = (id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${id}/productpricegroups`);

  const getInvoiceCategoryList = (id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${id}/productprices/invoicecategorytypes`);

  const getPriceTypeList = (id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${id}/productprices/productpricetypes`);

  const getVolumeBasedList = (pricePeriodId, id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${pricePeriodId}/productpricegroups/${id}/volumebasedprices`);

  const getPricingPeriod = (id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/prices/pricingperiods/${id}`);

  return {
    getOverviewInfo,
    getInvoiceFrequencyList,
    getMainProductList,
    getProductPriceGroupList,
    getInvoiceCategoryList,
    getPriceTypeList,
    getVolumeBasedList,
    getPricingPeriod,
  };
}
