<template>
  <ProductPriceGroupList />
</template>

<script>
import ProductPriceGroupList from '@/modules/productPriceGroups/components/product-price-group-list.vue';

export default {
  name: 'ProductPriceGroups',
  components: {
    ProductPriceGroupList,
  },
};
</script>
