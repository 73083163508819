import useClientDataSetRepository from '@/modules/xpClientDataSet/repositories/clientdataset.repository.js';
import store from '@/store/index';

export default function useDelete(billingSetId, dataGrid) {
  const clientDataSetRepository = useClientDataSetRepository(billingSetId);

  const deleteClientDataSets = (clientDataSetIdList) => {
    store.dispatch('showLoadingPanel', true);

    clientDataSetRepository.deleteClientDataSets(clientDataSetIdList)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          // Reload datagrid
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'PI-datasæt er nu slettet');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onDeleteClick = () => {
    const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();

    // Remove pi's with no client data set
    const clientDataSetIdList = selectedRowsData
      .filter((x) => x.id != null)
      .map((x) => x.id);

    if (clientDataSetIdList.length === 0) {
      store.dispatch('addInfoNotification', 'Du skal vælge minimum ét datasæt.');
      return;
    }

    deleteClientDataSets(clientDataSetIdList);
  };

  return {
    onDeleteClick,
  };
}
