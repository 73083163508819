import store from '@/store/index';
import useManualBillingEntryRepository from '@/modules/externalDataSet/repositories/manualbillingentry.repository.js';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useManualBillingEntry(billingSetId, dataGrid) {
  const manualBillingEntryRepository = useManualBillingEntryRepository(billingSetId);
  const { startFastJobPolling } = useJob();

  const createManualBillingEntryUsingClickOnce = (externalDataFileEntryId) => {
    manualBillingEntryRepository.createManualBillingEntryFromExternalEntry(externalDataFileEntryId)
      .then((response) => {
        if (response.data.success) {
          // Update datagrid with new data
          dataGrid.value.instance.refresh();

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Posten er nu håndteret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const createManualBillingEntryManually = (externalDataFileEntryId) => new Promise((resolve) => {
    manualBillingEntryRepository.createManualBillingEntryFromExternalEntry(externalDataFileEntryId)
      .then((response) => {
        // Start polling JobList at a fast pace
        startFastJobPolling();

        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }

        resolve(response);
      });
  });

  return {
    createManualBillingEntryUsingClickOnce,
    createManualBillingEntryManually,
  };
}
