<template>
  <div class="cms-contact-person-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>CMS</h2></div>
        <div class="subheadline-item">&nbsp; - Kontaktpersoner</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="cmsContactPersonDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Kontaktpersoner" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="cmsContactPerson-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="phone">
              <dx-label text="Telefon" />
            </dx-simple-item>
            <dx-simple-item data-field="email">
              <dx-label text="E-mail" />
            </dx-simple-item>
            <dx-simple-item data-field="position">
              <dx-label text="Stilling" />
            </dx-simple-item>
            <dx-simple-item data-field="supplierIdList">
              <dx-label text="Leverandør" />
            </dx-simple-item>
            <dx-simple-item data-field="order" help-text="Skriv et tal. 1 vil blive vist først, 2 derefter, osv.">
              <dx-label text="Sorteringsnummer" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="order"
          caption="Order"
          data-type="number"
          sort-order="asc"
          :hiding-priority="8"
          width="100">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="7"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="email"
          caption="E-mail"
          data-type="string"
          :hiding-priority="6"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="phone"
          caption="Telefon"
          data-type="string"
          :hiding-priority="5">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="position"
          caption="Stilling"
          data-type="string"
          :hiding-priority="4">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="supplierIdList"
          caption="Leverandør"
          :hiding-priority="3"
          edit-cell-template="supplierSelector"
          :visible="false"
          data-type="object">
          <dx-grid-lookup
            :data-source="supplierList.data"
            value-expr="id"
            display-expr="name" />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <PictureForm
              v-if="showPictureForm"
              :picture="loadedPicture"
              @onPictureSelected="onPictureSelected"
              @save="uploadPicture(row, storageKey)"
              @cancel="showPictureForm = false;" />
            <DetailView
              v-else
              :row="row"
              :supplierList="supplierList.data"
              @onEditClick="onEditClick"
              @onPictureEditClick="onPictureEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>

        <template #supplierSelector="{ data }">
          <div>
            <dx-tag-box
              :items="supplierList.data"
              value-expr="id"
              display-expr="name"
              :show-selection-controls="true"
              :search-enabled="true"
              :value="data.value"
              @value-changed="data.setValue($event.value)" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import DetailView from '@/modules/masterData/cmsContactPersons/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/cmsContactPersons/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/cmsContactPersons/composables/masterdata.composable.js';
import PictureForm from '@/modules/masterData/cmsContactPersons/components/picture-form.vue';

export default {
  name: 'CMSContactPersonModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    PictureForm,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      onPictureSelected,
      showPictureForm,
      onPictureEditClick,
      loadedPicture,
      uploadPicture,
    } = useDatagrid();

    getDatasource();

    const {
      supplierList,
      loadSupplierList,
    } = useMasterdata();

    loadSupplierList();

    return {
      datasource,
      supplierList,
      DxFileUploader,
      onPictureSelected,
      showPictureForm,
      onPictureEditClick,
      loadedPicture,
      uploadPicture,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne kontaktperson?<br/><b>${e.data.name}</b>`, 'Slet kontaktperson');
    },
  },
};
</script>

<style lang="scss">
.cms-contact-person-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 0;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }
}
</style>
