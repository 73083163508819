<template>
  <BillingPeriodsModule />
</template>

<script>
import BillingPeriodsModule from '@/modules/masterData/billingPeriods/components/main.vue';

export default {
  name: 'MasterDataBillingPeriods',
  components: {
    BillingPeriodsModule,
  },
};
</script>
