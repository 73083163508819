import { ref, reactive } from 'vue';
import useFusionRepository from '@/modules/masterData/clients/repositories/fusion.repository.js';
import store from '@/store/index';

export default function useFusion(dataGrid) {
  const fusionRepository = useFusionRepository();
  const showFusion = ref(false);
  const fusionModel = ref();

  const clientList = reactive({ data: null });

  const loadClients = (clientId) => {
    fusionRepository.getClients(clientId)
      .then((response) => {
        clientList.data = response.data;
      });
  };

  const loadFusion = (clientId) => {
    fusionRepository.getFusion(clientId)
      .then((response) => {
        showFusion.value = true;
        fusionModel.value = {
          endingClientId: response.data.value.endingClientId,
          continuingClientId: response.data.value.continuingClientId,
          endingDate: response.data.value.endingDate,
        };
      });
  };

  function onEditFusionClick(row) {
    fusionModel.value = {
      endingClientId: row.data.id,
      continuingClientId: null,
      endingDate: null,
    };

    loadFusion(row.data.id);
    loadClients();
  }

  function loadFusionClick(row) {
    fusionModel.value = {
      endingClientId: row.data.id,
      continuingClientId: null,
      endingDate: null,
    };
    fusionRepository.getFusion(row.data.id)
      .then((response) => {
        fusionModel.value = {
          endingClientId: response.data.value.endingClientId,
          continuingClientId: response.data.value.continuingClientId,
          endingDate: response.data.value.endingDate,
        };
      });
  }

  const onFusionSave = (clientId) => {
    const payload = fusionModel.value;

    fusionRepository.updateFusion(clientId, payload)
      .then((response) => {
        if (response.data.success) {
          dataGrid.value.instance.refresh();
          showFusion.value = false;
          store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    loadFusion,
    onEditFusionClick,
    showFusion,
    onFusionSave,
    fusionModel,
    loadClients,
    clientList,
    loadFusionClick,
  };
}
