<template>
  <div class="invoice-frequency-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Faktureringsfrekvenser</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="invoiceFrequencyDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Faktureringsfrekvenser" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="invoice-frequency-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="7"
          width="30%">
          <dx-required-rule />
          <dx-custom-validator
            :validation-callback="validateName"
            message="Navn findes."
          />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import DetailView from '@/modules/masterData/invoiceFrequency/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/invoiceFrequency/composables/datagrid.composable.js';

export default {
  name: 'MainInvoiceFrequencyModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      invoiceFrequencyList,
    } = useDatagrid();

    getDatasource();

    // eslint-disable-next-line consistent-return
    function validateName(e) {
      let nameExists = false;
      nameExists = invoiceFrequencyList.data.data.some((item) => (item.name.normalize().toLowerCase() === e.value.normalize().toLowerCase()));
      if (!nameExists) return true;
    }

    return {
      datasource,
      validateName,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne faktureringsfrekvens?<br/><b>${e.data.name}</b>`, 'Slet faktureringsfrekvens');
    },
  },
};
</script>

<style lang="scss">
.invoice-frequency-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
