<template>
  <div class="cms-attachment-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>CMS</h2></div>
        <div class="subheadline-item">&nbsp; - Bilag</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="showNewAttachmentPopup = true" icon="add" hint="Opret nyt bilag" class="margin-right"></dx-button>
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <div>
        <dx-data-grid
          ref="dataGrid"
          id="cmsAttachmentGrid"
          :data-source="datasource"
          height="75vh"
          :focused-row-key="focusedRowKey"
          @row-removing="rowRemoving">

          <dx-selection mode="single"/>
          <dx-filter-row :visible="true" />
          <dx-header-filter :visible="false" />
          <dx-grouping :auto-expand-all="false" />
          <dx-group-panel :visible="false" />
          <dx-state-storing :enabled="true" type="sessionStorage" storage-key="cmsAttachment-list" />
          <dx-scrolling mode="virtual" />
          <dx-paging :page-size="50" />

          <dx-editing
            :allow-updating="false"
            :allow-deleting="true"
            :allow-adding="false"
            :confirm-delete="false"
            mode="form" />

          <dx-column
            data-field="order"
            caption="Order"
            data-type="number"
            sort-order="asc"
            :hiding-priority="2"
            width="100" />

          <dx-column
            data-field="name"
            caption="Navn"
            data-type="string"
            :hiding-priority="1" />

          <dx-column
          type="buttons"
          :visible="false" />

          <dx-master-detail
            :enabled="false"
            template="detailTemplate" />

          <template #detailTemplate="{ data: row }">
            <div>
              <EditForm v-if="showEditForm"
                :row="row"
                @cancel="showEditForm = false"
                @save="onUpdateAttachment(row.data.id, $event)"
                @exportFileClick="onDownloadFileClick" />
              <DetailView
                v-else
                :row="row"
                @onEditClick="showEditForm = !showEditForm"
                @onDeleteClick="onDeleteClick"
                @exportFileClick="onDownloadFileClick" />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <template v-if="showNewAttachmentPopup">
        <FormPopup
          title="Opret nyt bilag"
          @save="onSaveNewAttachment"
          @cancel="showNewAttachmentPopup = !showNewAttachmentPopup"
          :visible="showNewAttachmentPopup"
          :height="620">
          <div class="padding-bottom">
            Navn:
            <dx-textbox
              id="name"
              name="name">
              <dx-validator validation-group="mainForm">
                <dx-required-rule message="Navn skal udfyldes" />
              </dx-validator>
            </dx-textbox>
          </div>
          <div class="padding-bottom">
            Beskrivelse:
            <dx-text-area
              id="description"
              name="description" />
          </div>
          <div class="padding-bottom">
            Sorteringsnummer:
            <dx-number-box
              id="order"
              :value="1"
              name="order">
              <dx-validator validation-group="mainForm">
                <dx-required-rule message="Sorteringsnummer skal udfyldes" />
              </dx-validator>
            </dx-number-box>
          </div>
          <div>
          Fil:
            <DxFileUploader
              id="fileContent"
              name="fileContent"
              select-button-text="Vælg fil"
              label-text="eller drop fil her"
              :multiple="false"
              :allowed-file-extensions="['.pdf']"
              accept="application/pdf"
              upload-mode="useForm"
              invalid-file-extension-message="Forkert filtype"
              invalid-max-file-size-message="Filen er for stor. Den må maks. fylde 52MB."
              ready-to-upload-message="Klar til upload." />
          </div>
        </FormPopup>
      </template>
    </div>
  </div>
</template>

<script>
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { ref } from 'vue';
import DetailView from '@/modules/masterData/cmsAttachments/components/detail-view.vue';
import EditForm from '@/modules/masterData/cmsAttachments/components/edit-form.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/cmsAttachments/composables/datagrid.composable.js';
import FormPopup from '@/components/common/form-popup.vue';
import useFileExport from '@/modules/masterData/cmsAttachments/composables/fileexport.composable.js';

export default {
  name: 'CMSAttachmentModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    FormPopup,
    DxFileUploader,
    EditForm,
  },
  setup() {
    const dataGrid = ref();

    const {
      datasource,
      getDatasource,
      onSaveNewAttachment,
      showNewAttachmentPopup,
      showEditForm,
      onUpdateAttachment,
    } = useDatagrid(dataGrid);

    getDatasource();

    const {
      onDownloadFileClick,
    } = useFileExport();

    return {
      datasource,
      onSaveNewAttachment,
      showNewAttachmentPopup,
      dataGrid,
      showEditForm,
      onUpdateAttachment,
      onDownloadFileClick,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette dette bilag?<br/><b>${e.data.name}</b>`, 'Slet bilag');
    },
  },
};
</script>

<style lang="scss" scoped>
.cms-attachment-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 0;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .grid-button-container {
    position: inherit;
    padding-right: 20px;
    height: 65px;
    background-color: #FFF;
    padding-top: 30px;

    .flex-align-right {
      display: flex;
      justify-content: flex-end;

      .dx-button {
        z-index: 100;
      }
    }
  }

  .margin-right {
    margin-right: 5px;
  }
}
</style>
