<template>
  <BiGraphModule />
</template>

<script>
import BiGraphModule from '@/modules/masterData/biGraphs/components/main.vue';

export default {
  name: 'MasterDataBiGraphs',
  components: {
    BiGraphModule,
  },
};
</script>
