import { reactive } from 'vue';
import useInvoiceRepository from '@/modules/xpClientDataSet/repositories/invoice.repository.js';
import store from '@/store/index';

export default function useInvoice(billingSetId) {
  const invoiceRepository = useInvoiceRepository(billingSetId);
  const collectionDatePopup = reactive({
    show: false, defaultDate: null, minimumDate: null, onSaveClickHandler: null,
  });
  const confirmPopupModel = reactive({
    show: false, title: null, content: null, saveButtonText: null, cancelButtonText: null, saveClickHandler: null, data: null,
  });

  const getTotalClientBillingReport = (billingSetName) => {
    store.dispatch('showLoadingPanel', true);
    invoiceRepository.getTotalClientBillingReport()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        if (response.data?.messages?.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        } else if (response.data) {
          const { data } = response;
          const blob = new Blob([data]);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          const date = new Date();
          // date format yyyyMMddhhmmss
          const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
          // The filename should be "Totaloversigt_{BillingSetName}_{DateTimestamp}.cvs"
          link.download = `Totaloversigt_${billingSetName.replace(/\s/g, '_')}_${dateString}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  };

  const getXPaySTAT2Report = (billingSetName) => {
    store.dispatch('showLoadingPanel', true);
    invoiceRepository.getXPaySTAT2Report()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        if (response.data?.messages?.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        } else if (response.data) {
          const { data } = response;
          const blob = new Blob([data]);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          const date = new Date();
          // date format yyyyMMddhhmmss
          const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
          // The filename should be "STAT3_{BillingSetName}_{DateTimestamp}.xlsx"
          link.download = `STAT2_${billingSetName.replace(/\s/g, '_')}_${dateString}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  };

  const getXPaySTAT3Report = (billingSetName) => {
    store.dispatch('showLoadingPanel', true);
    invoiceRepository.getXPaySTAT3Report()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        if (response.data?.messages?.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        } else if (response.data) {
          const { data } = response;
          const blob = new Blob([data]);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          const date = new Date();
          // date format yyyyMMddhhmmss
          const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
          // The filename should be "STAT3_{BillingSetName}_{DateTimestamp}.xlsx"
          link.download = `STAT3_${billingSetName.replace(/\s/g, '_')}_${dateString}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  };

  const getSummaryClientBillingReport = (billingSetName) => {
    store.dispatch('showLoadingPanel', true);
    invoiceRepository.getSummaryClientBillingReport()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        if (response.data?.messages?.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        } else if (response.data) {
          const { data } = response;
          const blob = new Blob([data]);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          const date = new Date();
          // date format yyyyMMddhhmmss
          const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
          // The filename should be "Totaloversigt_{BillingSetName}_{DateTimestamp}.cvs"
          link.download = `STAT1_${billingSetName.replace(/\s/g, '_')}_${dateString}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  };

  const xpaysReportActionList = [
    { key: 'STAT1', text: 'Hent STAT1-rapport', description: 'Hent oversigt over alle poster i alle PI-datasæt i CSV-format' },
    { key: 'STAT2', text: 'Hent STAT2-rapport', description: 'STAT2-beskrivelse' },
    { key: 'STAT3', text: 'Hent STAT3-rapport', description: 'STAT3-beskrivelse' },
  ];

  const xpaysReportClick = (billingSetName, e) => {
    switch (e.itemData.key) {
      case 'STAT1': {
        getSummaryClientBillingReport(billingSetName);
        break;
      }
      case 'STAT2': {
        getXPaySTAT2Report(billingSetName);
        break;
      }
      case 'STAT3': {
        getXPaySTAT3Report(billingSetName);
        break;
      }
      default:
        break;
    }
  };

  return {
    collectionDatePopup,
    confirmPopupModel,
    getTotalClientBillingReport,
    getSummaryClientBillingReport,
    getXPaySTAT2Report,
    getXPaySTAT3Report,
    xpaysReportActionList,
    xpaysReportClick,
  };
}
