<template>
  <div class="transaction-filter-form-root">
    <div class="row">
      <h4>Transaktionsfilter</h4>
    </div>
    <div class="row">
      Faktureringsperiode:
      <dx-tag-box
        :items="billingPeriodList"
        display-expr="name"
        value-expr="id"
        v-model="selectedBillingPeriodList"
        :show-selection-controls="true"
        apply-value-mode="useButtons"
        :search-enabled="true"
        :disabled="modelValue.distributionType === DistributionType.DISTRIBUTIONBYKEY" />
    </div>
    <div class="row">
      Vare:
      <dx-tag-box
        :items="mainProductDataSource"
        display-expr="name"
        value-expr="id"
        v-model="selectedMainProductList"
        :show-selection-controls="true"
        apply-value-mode="useButtons"
        :search-enabled="true"
        :disabled="modelValue.distributionType === DistributionType.DISTRIBUTIONBYKEY" />
    </div>
    <div>
      Kort type (brand):
      <dx-tag-box
        :items="brandList"
        display-expr="name"
        value-expr="id"
        v-model="selectedBrandList"
        :show-selection-controls="true"
        apply-value-mode="useButtons"
        :search-enabled="true"
        :disabled="modelValue.distributionType === DistributionType.DISTRIBUTIONBYKEY" />
    </div>
  </div>
</template>

<script>
import DistributionType from '@/enums/distribution-type.js';

export default {
  name: 'SelectionTransactionFilterForm',
  props: {
    modelValue: Object,
    mainProductList: Array,
    billingPeriodList: Array,
    brandList: Array,
  },
  emits: ['update:modelValue'],
  computed: {
    mainProductDataSource: {
      get() {
        // Return unique list of main products
        return this.mainProductList.filter((item, index, self) => index === self.findIndex((t) => (
          t.identifier === item.identifier
        ))).map((x) => ({
          id: x.id,
          name: `${x.identifier} - ${x.name}`,
        }));
      },
    },
    selectedBillingPeriodList: {
      get() {
        return this.modelValue.selectedBillingPeriods;
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, selectedBillingPeriods: value });
      },
    },
    selectedMainProductList: {
      get() {
        return this.modelValue.selectedProducts;
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, selectedProducts: value });
      },
    },
    selectedBrandList: {
      get() {
        return this.modelValue.selectedBrands;
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, selectedBrands: value });
      },
    },
  },
  setup() {
    return {
      DistributionType,
    };
  },
};
</script>

<style lang="scss" scoped>
.transaction-filter-form-root {
  padding: 20px;
  background-color: #FFF;

  .row {
    padding-bottom: 15px;
  }

  .flex {
    display: flex;
    justify-content: flex-end;
  }

  .margin-right {
    margin-right: 15px;
  }
}
</style>
