import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useInvoiceRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const getTotalClientBillingReport = () => ApiService
    // .get(`${appsettings.ADMINISTRATION_API}/api/v1/dkvinvoicing/dkvbillingsets/${billingSetId}/totalclientbillingreport`);
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/dkvinvoicing/dkvbillingsets/${billingSetId}/totalclientbillingreportcontent`);

  return {
    getTotalClientBillingReport,
  };
}
