import { reactive } from 'vue';
import useBillingSetRepository from '@/modules/dkvBillingSet/repositories/billingset.repository.js';
import store from '@/store/index';

export default function useBillingSet() {
  const billingSetRepository = useBillingSetRepository();
  const billingSetEntry = reactive({ loadingCompleted: false, data: null });

  const loadBillingSet = (billingSetId) => {
    billingSetRepository.getBillingSet(billingSetId)
      .then((response) => {
        billingSetEntry.loadingCompleted = true;

        if (response.data.success) {
          billingSetEntry.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    loadBillingSet,
    billingSetEntry,
  };
}
