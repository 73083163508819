<template>
  <InfoAreaModule />
</template>

<script>
import InfoAreaModule from '@/modules/masterData/cmsInfoArea/components/main.vue';

export default {
  name: 'MasterDataInfoArea',
  components: {
    InfoAreaModule,
  },
};
</script>
