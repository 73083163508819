<template>
  <ClientDistributionModule />
</template>

<script>
import ClientDistributionModule from '@/modules/masterData/clientDistributions/components/main.vue';

export default {
  name: 'MasterDataClientDistributions',
  components: {
    ClientDistributionModule,
  },
};
</script>
