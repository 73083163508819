<template>
  <div>
    <div v-if="data.data && data.data.length > 0">
      <div v-for="item in data.data" :key="item.index">
        Antal med status '{{ item.statusDescription }}': {{ item.count }}
      </div>
    </div>
    <div v-else-if="data.data.length === 0 && !data.jobIsRunning">
      Ingen PI datasæt
    </div>
    <div v-else-if="data.jobIsRunning">
      <i class="dx-icon-runner"></i> Job til oprettelse af PI-Datasæt er igang med at blive behandlet.
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount } from 'vue';

export default {
  name: 'ClientDataSetStatusList',
  props: {
    data: {
      type: Object,
    },
  },
  emits: ['stopClientDataPolling'],
  setup(props, context) {
    onBeforeUnmount(() => {
      context.emit('stopClientDataPolling');
    });
  },
};
</script>
