import { reactive } from 'vue';
import useClientDataSetRepository from '@/modules/mccBillingSet/repositories/clientdataset.repository.js';
import store from '@/store/index';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useClientDataSet(billingSetId) {
  const clientDataSetRepository = useClientDataSetRepository(billingSetId);
  const statusSummaryList = reactive({ loadingCompleted: false, data: null, jobIsRunning: false });
  const { startFastJobPolling } = useJob();

  const loadStatusSummary = () => {
    statusSummaryList.loadingCompleted = false;

    clientDataSetRepository.loadStatusSummary()
      .then((response) => {
        statusSummaryList.loadingCompleted = true;
        statusSummaryList.data = response.data;
        if (response.data.jobIsRunning) {
          startClientDataPolling();
        }
      });
  };

  const loadStatusSummaryWithoutAnimation = () => {
    clientDataSetRepository.loadStatusSummary()
      .then((response) => {
        statusSummaryList.data = response.data;
      });
  };

  const fastPollingIntervalInSeconds = 3000;
  let fastPollingId;

  // Poll every 3 second
  const startClientDataPolling = () => {
    stopClientDataPolling();
    fastPollingId = setInterval(() => {
      loadStatusSummaryWithoutAnimation();
    }, fastPollingIntervalInSeconds);
  };

  const stopClientDataPolling = () => {
    if (fastPollingId != null) {
      clearInterval(fastPollingId);
      fastPollingId = null;
    }
  };

  const createClientDataSets = () => {
    clientDataSetRepository.create()
      .then(() => {
        statusSummaryList.jobIsRunning = true;

        // Start polling JobList at a fast pace
        startFastJobPolling();
        startClientDataPolling();

        store.dispatch('addSuccessNotification', 'PI-datasæt bliver nu oprettet.');
      });
  };

  return {
    loadStatusSummary,
    statusSummaryList,
    createClientDataSets,
    startClientDataPolling,
    stopClientDataPolling,
  };
}
