<template>
  <BillingSetList />
</template>

<script>
import BillingSetList from '@/modules/billingSet/components/billingset-list.vue';

export default {
  name: 'BillingSet',
  components: {
    BillingSetList,
  },
};
</script>
