<template>
  <div class="cms-text-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>CMS</h2></div>
        <div class="subheadline-item">&nbsp; - BI tekster</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="cmsBITextDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="BI_Tekst" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="cmsBIText-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="false"
          :allow-adding="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item
              data-field="title"
              help-text="Du kan indsætte dynamisk indhold med disse tags: [year] = nuværende år, [clientname] = navn på det pengeinstitut som er logget ind, [clienthreg] = HReg for det pengeinstitut som er logget ind.">
              <dx-label text="Overskrift" />
            </dx-simple-item>
            <dx-simple-item
              data-field="text"
              editor-type="dxTextArea"
              :editor-options="textAreaOptions"
              help-text="Du kan indsætte dynamisk indhold med disse tags: [year] = nuværende år, [clientname] = navn på det pengeinstitut som er logget ind, [clienthreg] = HReg for det pengeinstitut som er logget ind.">
              <dx-label text="Tekst" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="title"
          caption="Navn"
          data-type="string"
          :hiding-priority="7"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="supplierId"
          caption="Leverandør"
          :hiding-priority="6"
          data-type="string">
          <dx-grid-lookup
            :data-source="supplierList.data"
            value-expr="id"
            display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="areaId"
          caption="Område"
          :hiding-priority="4">
          <dx-grid-lookup
            :data-source="areaTypeList.data"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="text"
          caption="Tekst"
          data-type="string"
          :visible="false"
          :hiding-priority="3"
          :allow-resizing="true">
          <dx-required-rule />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              :areaTypeList="areaTypeList.data"
              :supplierList="supplierList.data"
              @onEditClick="onEditClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import DetailView from '@/modules/masterData/cmsBIText/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/cmsBIText/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/cmsBIText/composables/masterdata.composable.js';

export default {
  name: 'CMSBITextModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  setup() {
    const {
      datasource,
      getDatasource,
    } = useDatagrid();

    getDatasource();

    const {
      supplierList,
      loadSupplierList,
      areaTypeList,
      loadAreaTypeList,
    } = useMasterdata();

    loadSupplierList();
    loadAreaTypeList();

    const textAreaOptions = {
      height: 200,
    };

    return {
      datasource,
      supplierList,
      areaTypeList,
      textAreaOptions,
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
.cms-text-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 0;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }
}
</style>
