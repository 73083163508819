<template>
  <DKVExternalDataSetList />
</template>

<script>
import DKVExternalDataSetList from '@/modules/dkvExternalDataSet/components/list.vue';

export default {
  name: 'ExternalDataSets',
  components: {
    DKVExternalDataSetList,
  },
};
</script>
