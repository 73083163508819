<template>
  <dx-popup
    :visible="isVisible"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :width="width"
    :height="height"
    :show-close-button="false"
    :title="title">
    <template #content>
      <form id="upload-form" @submit="handleSubmit($event)" enctype="multipart/form-data">
        <div class="popup-content-root">
          <div>
            <DxScrollView
              width="100%"
              :height="150"
            >
              <div class="content-row">
                <DxFileUploader
                  :name="allowMultipleFiles ? 'files[]' : 'file'"
                  :select-button-text="selectButtonText"
                  :input-attr="inputAttr"
                  :label-text="labelText"
                  :multiple="allowMultipleFiles"
                  :max-file-size="maxFileSize"
                  :allowed-file-extensions="allowedFileExtensions"
                  upload-mode="useForm"
                  :invalid-file-extension-message="invalidFileExtensionMessage"
                  invalid-max-file-size-message="Filen er for stor. Den må maks. fylde 110MB."
                  ready-to-upload-message="Klar til upload."
                  @value-changed="valueChanged"
                />
              </div>
            </DxScrollView>
          </div>
          <div>
            <slot />
          </div>
          <div class="button-row">
            <div class="action-button-wrapper">
              <ButtonWithLoadingState :buttonText="saveButtonText" :isLoading="isLoading"></ButtonWithLoadingState>
            </div>
            <div class="action-button-wrapper">
              <dx-button :text="cancelButtonText" @click="$emit('cancel')" />
            </div>
          </div>
        </div>
      </form>
    </template>
  </dx-popup>
</template>

<script>
import { computed, reactive } from 'vue';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import ButtonWithLoadingState from '@/components/common/button-with-loading-state.vue';
import store from '@/store/index';
import NotificationTypes from '@/enums/notification-types.js';

export default {
  name: 'FileUploadPopup',
  components: {
    ButtonWithLoadingState,
  },
  data() {
    return {
      inputAttr: { id: 'inputId' },
    };
  },
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: 'Gem',
    },
    cancelButtonText: {
      type: String,
      default: 'Annuller',
    },
    selectButtonText: {
      type: String,
      default: 'Vælg fil',
    },
    labelText: {
      type: String,
      default: 'eller drop fil her',
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 700,
    },
    allowMultipleFiles: {
      type: Boolean,
      default: false,
    },
    allowedFileExtensions: {
      type: Array,
    },
    invalidFileExtensionMessage: {
      type: String,
      default: 'Filtypen er forkert. Kun .xlsx er tilladt.',
    },
    maxFileSize: {
      type: Number,
      default: 109715200,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isVisible = computed(() => props.visible);
    const validationResult = reactive({ success: true, messages: [] });

    const handleSubmit = (e) => {
      // First validate
      if (validationResult.success) {
        context.emit('save', e);
      } else {
        store.dispatch('addNotifications', { messageList: validationResult.messages });
      }

      e.preventDefault();
    };

    const valueChanged = (e) => {
      validationResult.success = true;
      validationResult.messages = [];

      // Check that at least one file has been selected
      if (e.value.length === 0) {
        validationResult.success = false;
        validationResult.messages.push({ message: 'Du skal vælge mindst én fil', type: NotificationTypes.ERROR });
        return;
      }
      e.value.forEach((file) => {
        // Check file size
        if (file.size > props.maxFileSize) {
          validationResult.success = false;
          validationResult.messages.push({ message: `Filen ${file.name} er for stor. Den må maks. fylde ${props.maxFileSize} bytes.`, type: NotificationTypes.ERROR });
          return;
        }

        // Check file type
        switch (file.type) {
          // Excel
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            if (!props.allowedFileExtensions.some((x) => x === '.xlsx')) {
              validationResult.success = false;
              validationResult.messages.push({ message: 'Filetypen .xlsx er ikke tilladt', type: NotificationTypes.ERROR });
            }
            break;
          // CSV
          case 'text/csv':
          case 'application/vnd.ms-excel':
            if (!props.allowedFileExtensions.some((x) => x === '.csv')) {
              validationResult.success = false;
              validationResult.messages.push({ message: 'Filetypen .csv er ikke tilladt', type: NotificationTypes.ERROR });
            }
            break;
          default:
            validationResult.success = false;
            validationResult.messages.push({ message: `Filetypen ${file.type} er ikke tilladt`, type: NotificationTypes.ERROR });
            break;
        }

        // eslint-disable-next-line no-useless-return
        if (!validationResult.success) return;
      });
    };

    return {
      isVisible,
      handleSubmit,
      DxFileUploader,
      DxScrollView,
      valueChanged,
    };
  },
};
</script>

<style lang="scss">
.popup-content-root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-row {
    padding: 0 0 10px 0;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;

    .action-button-wrapper {
      margin-left: 15px;
    }
  }

  .button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .indicators {
    display: flex;
    align-items: center;
  }

  .dx-button {
    height: 36px;
  }

  .d-flex{
    display: flex;
  }
  .type-select{
    border-color: #ddd;
    padding: 7px 18px 8px;
    border-radius: 4px;
  }
}
</style>
