import Oidc from 'oidc-client';
import polly from 'polly-js';
import store from '@/store/index.js';

let mgr = null;

export default function useUserManager() {
  const appsettings = store.state.appSettings;

  const config = {
    authority: appsettings.AUTHENTICATION_API,
    client_id: 'bokis.adm',
    redirect_uri: `${appsettings.ADMINISTRATION_SPA}/callback`,
    response_type: 'code',
    scope: 'openid profile roles bokis.administration.api bokis.bi.api offline_access',
    post_logout_redirect_uri: appsettings.ADMINISTRATION_SPA,
    automaticSilentRenew: true,
  };

  if (mgr == null) {
    mgr = new Oidc.UserManager(config);
  }

  const pollyConfig = {
    // delay in ms between each retry
    delay: [1000, 2000, 4000, 20000, 40000],
  };

  function login(returnUrl) {
    polly()
      .waitAndRetry(pollyConfig.delay)
      .executeForPromise(() => mgr.signinRedirect({
        state: returnUrl,
      }));
  }

  function logout() {
    polly()
      .waitAndRetry(pollyConfig.delay)
      .executeForPromise(() => {
        mgr.signoutRedirect();
      });
  }

  function getUser() {
    return mgr.getUser();
  }

  function signinSilentCallback() {
    return mgr.signinSilentCallback();
  }

  return {
    login,
    logout,
    getUser,
    signinSilentCallback,
  };
}
