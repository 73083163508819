<template>
  <div class="client-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Pengeinstitutter</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button class="margin-right" @click="getClientsReport" icon="exportxlsx" hint="Exporter oversigt over alle konti til Excel"></dx-button>
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="clientDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        :column-auto-width="true"
        @selection-changed="onSelectionChanged"
        @editing-start="showClientFields=false"
        @edit-canceling="showClientFields=false"
        @init-new-row="onInitNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Pengeinstitutter" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="mainClient-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="registrationNumber">
              <dx-label text="Reg.nr." />
            </dx-simple-item>
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="clientGroupId">
              <dx-label text="Gruppe" />
            </dx-simple-item>
            <dx-simple-item data-field="active" :editor-options="activeEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="canBeInvoiced" :editor-options="canBeInvoicedEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="isBokis" :editor-options="isBokisEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-empty-item />
            <dx-group-item :caption="showClientFields ? 'Primær konto:' : ''">
              <dx-simple-item data-field="aCCRegistrationNumber" :visible="showClientFields">
                <dx-label text="Reg.nr." />
              </dx-simple-item>
              <dx-simple-item data-field="aCCAccountNumber" :visible="showClientFields">
                <dx-label text="Kontonummer" />
              </dx-simple-item>
              <dx-simple-item data-field="aCCDescription" :visible="showClientFields">
                <dx-label text="Kontobeskrivelse" />
              </dx-simple-item>
            </dx-group-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="registrationNumber"
          caption="Reg.nr."
          data-type="number"
          sort-order="asc"
          :selected-filter-operation="selectedOperation"
          :hiding-priority="8">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="7">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="clientGroupId"
          caption="Gruppe"
          :hiding-priority="6">
          <dx-grid-lookup
            :data-source="clientGroupList.data"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="aCCRegistrationNumber"
          caption="Reg.nr."
          data-type="number"
          sort-order="asc"
          :visible="false"
          :hiding-priority="5">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="aCCAccountNumber"
          caption="Kontonummer"
          data-type="number"
          sort-order="asc"
          :visible="false"
          :hiding-priority="4">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="aCCDescription"
          caption="Kontobeskrivelse"
          data-type="string"
          :visible="false"
          :hiding-priority="7"
          :allow-resizing="true">
        </dx-column>

        <dx-column
          data-field="active"
          :form-item="activeEditorOptions"
          caption="Aktiv"
          data-type="boolean"
          :hiding-priority="3">
        </dx-column>

        <dx-column
          data-field="canBeInvoiced"
          caption="Kunden kan faktureres"
          data-type="boolean"
          :visible="false"
          :hiding-priority="2">
        </dx-column>

        <dx-column
          data-field="isBokis"
          caption="BOKIS"
          data-type="boolean"
          :visible="false"
          :hiding-priority="1">
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <CRMParameter
              v-if="showCrmForm && row.data.crmValues"
              :row="row"
              @save="onCrmSave"
              @cancel="showCrmForm = false;" />
            <Fusion
              v-else-if="showFusion"
              v-model="fusionModel"
              :row="row"
              :clients="clientList"
              @save="onFusionSave"
              @cancel="showFusion = false;" />
            <DetailView
              v-else
              :row="row"
              :clientGroupList="clientGroupList.data"
              :fusionModel="fusionModel"
              @onEditClick="onEditClick"
              @onEditCrmClick="onEditCrmClick"
              @onEditFusionClick="onEditFusionClick"
              @loadFusion="loadFusionClick"
              @onAccountsClick="router.push({ name: 'MasterDataClientsAccounts', params: { id: $event.key } })"
              @onSubclientsClick="router.push({ name: 'MasterDataClientsSubclients', params: { id: $event.key } })"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import DetailView from '@/modules/masterData/clients/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/clients/composables/datagrid.composable.js';
import useCrm from '@/modules/masterData/clients/composables/crm.composable.js';
import useFusion from '@/modules/masterData/clients/composables/fusion.composable.js';
import CRMParameter from '@/modules/masterData/clients/components/crm-form.vue';
import Fusion from '@/modules/masterData/clients/components/fusion-form.vue';
import useClientGroup from '@/modules/masterData/clients/composables/clientGroup.composable.js';

const activeEditorOptions = {
  text: 'Kunden er aktiv',
};

const canBeInvoicedEditorOptions = {
  text: 'Kunden kan faktureres',
};

const isBokisEditorOptions = {
  text: 'BOKIS',
};

const selectedOperation = 'contains';

export default {
  name: 'MainClientModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    CRMParameter,
    Fusion,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      showClientFields,
      onInitNewRow,
      getClientsReport,
    } = useDatagrid();

    getDatasource();

    const dataGrid = ref();

    const {
      onEditCrmClick,
      showCrmForm,
      onCrmSave,
    } = useCrm(dataGrid);

    const {
      showFusion,
      onEditFusionClick,
      onFusionSave,
      fusionModel,
      clientList,
      loadFusionClick,
    } = useFusion(dataGrid);

    const {
      loadClientGroupList,
      clientGroupList,
    } = useClientGroup();

    loadClientGroupList();

    const router = useRouter();

    function onSelectionChanged() {
      showCrmForm.value = false;
      showFusion.value = false;
    }

    return {
      datasource,
      activeEditorOptions,
      canBeInvoicedEditorOptions,
      onEditCrmClick,
      showCrmForm,
      onCrmSave,
      showFusion,
      onEditFusionClick,
      onFusionSave,
      fusionModel,
      clientList,
      router,
      clientGroupList,
      dataGrid,
      isBokisEditorOptions,
      onSelectionChanged,
      showClientFields,
      onInitNewRow,
      selectedOperation,
      getClientsReport,
      loadFusionClick,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne pengeinstitut?<br/><b>${e.data.name}</b>`, 'Slet pengeinstitut');
    },
  },
};
</script>

<style lang="scss">
.client-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .dx-datagrid-header-panel {
    padding-right: 82px;
  }

  .margin-right {
    margin-right: 5px;
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
