<template>
  <div class="cms-contact-person-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <dx-location screen="xs sm" :row="0" :col="0" />
        <div class="detail-element-container padding-right">
          Navn:
          <dx-textbox
            :value="row.data.name"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container">
          Telefon:
          <dx-textbox
            :value="row.data.phone"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="2" :col="0" />
        <div class="detail-element-container padding-right">
          E-mail:
          <dx-textbox
            :value="row.data.email"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container">
          Stilling:
          <dx-textbox
            :value="row.data.position"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container padding-right">
          Leverandør:
          <dx-tag-box
            :items="supplierList"
            value-expr="id"
            display-expr="name"
            :value="row.data.supplierIdList"
            :read-only="true"
            placeholder=""
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container">
          Sorteringsnummer:
          <dx-number-box
            :value="row.data.order"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="card" text="Vælg billede" @click="$emit('onPictureEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
export default {
  name: 'DetailView',
  props: {
    row: Object,
    supplierList: Array,
  },
  setup() {
  },
};
</script>

<style lang="scss">
.cms-contact-person-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 180px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 25px;
  }
}
</style>
