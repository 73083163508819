import {
  ref, provide, readonly, reactive,
} from 'vue';
import store from '@/store/index';
import useExternalDataSetRepository from '@/modules/shared/repositories/externaldataset.repository.js';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useUploadExternalDataSet() {
  const showUploadFilePopup = ref(false);
  const showLoadFromApiPopup = ref(false);
  const selectedBillingSetId = ref();
  const externalDataSetUpdateDateTime = ref();
  const contractList = reactive({ loadingCompleted: false, data: null });
  const isLoading = ref(false);
  provide('externalDataSetUpdateDateTime', readonly(externalDataSetUpdateDateTime));

  function onUploadExternalDataSetClick(billingSetId) {
    selectedBillingSetId.value = billingSetId;
    showUploadFilePopup.value = true;
  }

  const loadContacts = (supplierId) => {
    const externalDataSetRepository = useExternalDataSetRepository();

    return externalDataSetRepository.getContractList(supplierId)
      .then((response) => {
        contractList.loadingCompleted = true;
        contractList.data = response.data;
      });
  };

  function onLoadFromApiClick(billingSetId, supplierId) {
    selectedBillingSetId.value = billingSetId;
    showLoadFromApiPopup.value = true;
    loadContacts(supplierId);
  }

  function onLoadFromApiDKVClick(billingSetId) {
    selectedBillingSetId.value = billingSetId;
    showLoadFromApiPopup.value = true;
  }

  const onUploadExternalDataSetCancel = () => {
    showUploadFilePopup.value = false;
  };

  const onLoadFromApiCancel = () => {
    showLoadFromApiPopup.value = false;
  };

  const onLoadFromApiDKVCancel = () => {
    showLoadFromApiPopup.value = false;
  };

  const { startFastJobPolling } = useJob();

  const onUploadExternalDataSetSave = (form) => {
    isLoading.value = true;
    const formData = new FormData(form.target);

    const externalDataSetRepository = useExternalDataSetRepository();

    return externalDataSetRepository.uploadExternalDatasetFiles(selectedBillingSetId.value, formData)
      .then((response) => {
        if (response.data.success) {
          showUploadFilePopup.value = false;
          isLoading.value = false;

          // This variable is used as an injected value in child component, to make sure
          // the list of ExternalDataSets is reloaded.
          externalDataSetUpdateDateTime.value = new Date();

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Leverandør datasæt er nu blevet uploadet');
          return Promise.resolve(response.data);
        }

        isLoading.value = false;
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  const onUploadMCCDataSetSave = (form) => {
    isLoading.value = true;
    const formData = new FormData(form.target);

    const externalDataSetRepository = useExternalDataSetRepository();

    return externalDataSetRepository.uploadMCCDatasetFile(selectedBillingSetId.value, formData)
      .then((response) => {
        if (response.data.success) {
          showUploadFilePopup.value = false;
          isLoading.value = false;

          // This variable is used as an injected value in child component, to make sure
          // the list of ExternalDataSets is reloaded.
          externalDataSetUpdateDateTime.value = new Date();

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Leverandør datasæt er nu blevet uploadet');
          return Promise.resolve(response.data);
        }

        isLoading.value = false;
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  const onUploadDKVDataSetSave = (form) => {
    isLoading.value = true;
    const formData = new FormData(form.target);

    const externalDataSetRepository = useExternalDataSetRepository();

    return externalDataSetRepository.uploadDKVDatasetFile(selectedBillingSetId.value, formData)
      .then((response) => {
        if (response.data.success) {
          showUploadFilePopup.value = false;
          isLoading.value = false;

          // This variable is used as an injected value in child component, to make sure
          // the list of ExternalDataSets is reloaded.
          externalDataSetUpdateDateTime.value = new Date();

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Leverandør datasæt er nu blevet uploadet');
          return Promise.resolve(response.data);
        }

        isLoading.value = false;
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  const onUploadXPDataSetSave = (form) => {
    isLoading.value = true;
    const formData = new FormData(form.target);

    const externalDataSetRepository = useExternalDataSetRepository();

    return externalDataSetRepository.uploadXPDatasetFile(selectedBillingSetId.value, formData)
      .then((response) => {
        if (response.data.success) {
          showUploadFilePopup.value = false;
          isLoading.value = false;

          // This variable is used as an injected value in child component, to make sure
          // the list of ExternalDataSets is reloaded.
          externalDataSetUpdateDateTime.value = new Date();

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Leverandør datasæt er nu blevet uploadet');
          return Promise.resolve(response.data);
        }

        isLoading.value = false;
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  const onLoadFromApiSave = (form) => {
    isLoading.value = true;
    const formData = new FormData(form.target);
    const data = {
      contractNumber: formData.get('contractNumber'),
      ignoreWarnings: Boolean(formData.get('ignoreWarnings')),
    };
    const externalDataSetRepository = useExternalDataSetRepository();

    return externalDataSetRepository.loadDatasetFromApi(selectedBillingSetId.value, data)
      .then((response) => {
        if (response.data.success) {
          showLoadFromApiPopup.value = false;
          isLoading.value = false;

          // This variable is used as an injected value in child component, to make sure
          // the list of ExternalDataSets is reloaded.
          externalDataSetUpdateDateTime.value = new Date();

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Leverandør datasæt er nu blevet uploadet');
          return Promise.resolve(response.data);
        }

        isLoading.value = false;
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  const onLoadFromApiDKVSave = (form) => {
    isLoading.value = true;
    const formData = new FormData(form.target);
    const data = {
      ignoreWarnings: formData.get('ignoreWarnings') === 'true',
    };
    const externalDataSetRepository = useExternalDataSetRepository();

    return externalDataSetRepository.loadDKVDatasetFromApi(selectedBillingSetId.value, data)
      .then((response) => {
        if (response.data.success) {
          showLoadFromApiPopup.value = false;
          isLoading.value = false;

          // This variable is used as an injected value in child component, to make sure
          // the list of ExternalDataSets is reloaded.
          externalDataSetUpdateDateTime.value = new Date();

          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Leverandør datasæt er nu blevet uploadet');
          return Promise.resolve(response.data);
        }
        isLoading.value = false;
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  return {
    onUploadExternalDataSetClick,
    showUploadFilePopup,
    onUploadExternalDataSetCancel,
    onUploadExternalDataSetSave,
    externalDataSetUpdateDateTime,
    onLoadFromApiClick,
    onLoadFromApiDKVClick,
    onLoadFromApiSave,
    onLoadFromApiDKVSave,
    showLoadFromApiPopup,
    onLoadFromApiCancel,
    onLoadFromApiDKVCancel,
    loadContacts,
    contractList,
    isLoading,
    onUploadMCCDataSetSave,
    onUploadDKVDataSetSave,
    onUploadXPDataSetSave,
  };
}
