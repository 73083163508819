<template>
  <div class="distribution-key-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Fordelingsnøgler</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="distributionKeyDataGrid"
        :data-source="datasource"
        height="75vh"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :focused-row-key="focusedRowKey"
        @init-new-row="onInitNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Fordelingsnøgler" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="distribution-key-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="description" editor-type="dxTextArea">
              <dx-label text="Beskrivelse" />
            </dx-simple-item>
            <dx-simple-item data-field="warningThresholdPercent">
              <dx-label text="Afvigelsesgrænse for advarsel" />
            </dx-simple-item>
            <dx-simple-item data-field="clientCanEdit">
              <dx-label label="Redigering tilladt af PI" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="7">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="description"
          caption="Beskrivelse"
          data-type="string"
          :allow-resizing="true"
         width="30%"
          :hiding-priority="7">
        </dx-column>

        <dx-column
          data-field="periodeCount"
          caption="Antal perioder"
          data-type="number"
          :hiding-priority="6">
        </dx-column>

        <dx-column
          data-field="clientCanEdit"
          caption="Redigering tilladt af PI"
          data-type="boolean"
          :hiding-priority="5" />

        <dx-column
          data-field="warningThresholdPercent"
          caption="Redigering tilladt af PI"
          data-type="number"
          :visible="false"
          :hiding-priority="6">
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row"
              @onEditClick="onEditClick"
              @onPeriodsClick="router.push({ name: 'MasterDataDistributionKeyPeriods', params: { id: $event.key } })"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import DetailView from '@/modules/masterData/distributionKeys/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/distributionKeys/composables/datagrid.composable.js';

export default {
  name: 'MainDistributionKeyModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      onInitNewRow,
    } = useDatagrid();

    getDatasource();

    const router = useRouter();

    return {
      datasource,
      router,
      onInitNewRow,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne fordelingsnøgle?<br/><b>${e.data.name}</b>`, 'Slet fordelingsnøgle');
    },
  },
};
</script>

<style lang="scss">
.distribution-key-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
