<template>
  <div class="client-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Reg.nr.:
          <dx-number-box
            :value="row.data.registrationNumber"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item v-if="isCRMMissing || isFusioned">
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container red-text padding-top">
          <span v-if="isCRMMissing">CRM parameter mangler.</span>
          <span v-if="isFusioned">Pengeinstituttet indgår i fusion.</span>
          <span v-if="isFusioned && fusionDataLoaded" class="dark-text">Blev overtaget af {{ row.data.overtakenByClient.registrationNumber }} den {{ formatService.stringToDate(fusionModel.endingDate, 'DD/MM-YYYY') }}</span>
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Navn:
          <dx-textbox
            :value="row.data.name"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container padding-right">
          Gruppe:
          <dx-textbox
            :value="getClientGroupById(row.data.clientGroupId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="0" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container">
          <dx-checkbox
            :value="row.data.active"
            :read-only="true"
            text="Kunden er aktiv"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="4" :col="0" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container">
          <dx-checkbox
            :value="row.data.canBeInvoiced"
            :read-only="true"
            text="Kunden kan faktureres"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="5" :col="0" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container">
          <dx-checkbox
            :value="row.data.isBokis"
            :read-only="true"
            text="BOKIS"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button text="CRM" @click="$emit('onEditCrmClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button text="Fusion" @click="$emit('onEditFusionClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button text="Konti" @click="$emit('onAccountsClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button text="Filialer" @click="$emit('onSubclientsClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { get } from 'lodash';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    clientGroupList: Array,
    fusionModel: Object,
  },
  setup(props, context) {
    function getClientGroupById(id) {
      const importFormat = props.clientGroupList.find((x) => x.id === id);

      return get(importFormat, ['name']);
    }

    const isCRMMissing = computed(() => props.row.data.active && (props.row.data.crmValues.some((item) => ((item.crpDataType === 5) && item.cpvValueNumber === -1)
          || ((item.crpDataType === 6) && !item.choiceListSelectedId))));

    const isFusioned = computed(() => props.row.data.isOvertakenByClient || props.row.data.isOvertakenClient);

    const fusionDataLoaded = computed(() => props.fusionModel.endingDate && props.row.data.overtakenByClient.registrationNumber);

    const formatService = inject('formatService');

    if (isFusioned.value) {
      context.emit('loadFusion', props.row);
    }

    return {
      getClientGroupById,
      isCRMMissing,
      isFusioned,
      formatService,
      fusionDataLoaded,
    };
  },
};
</script>

<style lang="scss">
.client-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 25px;
  }

  .red-text {
    color: red;
  }

  .dark-text {
    color: #333;
  }
}
</style>
