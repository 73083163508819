import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useClientDistributionHistoryRepository(periodId) {
  const appsettings = store.state.appSettings;
  const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

  const load = (loadOptions) => {
    let params = '?';

    [
      'filter',
      'group',
      'groupSummary',
      'parentIds',
      'requireGroupCount',
      'requireTotalCount',
      'searchExpr',
      'searchOperation',
      'searchValue',
      'select',
      'sort',
      'skip',
      'take',
      'totalSummary',
      'userData',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    return ApiService
      .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clientdistributions/${periodId}/changelogs/overview${params}`);
  };

  return {
    load,
  };
}
