<template>
  <DistributionKeyPeriodModule />
</template>

<script>
import DistributionKeyPeriodModule from '@/modules/masterData/distributionKeyPeriods/components/main.vue';

export default {
  name: 'MasterDataDistributionKeyPeriods',
  components: {
    DistributionKeyPeriodModule,
  },
};
</script>
