import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getContactPersonList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/contactpersons`);

  const getSupplierList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/suppliers`);

  const getPageLocationTypeList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/infoareas/pagelocationtypes`);

  return {
    getContactPersonList,
    getSupplierList,
    getPageLocationTypeList,
  };
}
