<template>
  <div class="selection-form-container">
    <form @submit="handleSubmit($event)" enctype="application/x-www-form-urlencoded">
      <dx-form validation-group="selectionForm" />
      <div>
        <Preset v-if="selectionEntity.loadingCompleted && masterDataEntity.loadingCompleted"
          v-model="selectionEntity.data"
          :presetList="masterDataEntity.data.presetList"
          @presetChanged="getPreset(manualBillingId, $event)"
          @savePreset="onSavePresetClick"
          @savePresetAs="showSaveNamePopup = true"
          @deletePreset="showConfirmPopup = true"
          :selectedPresetId="selectedPresetId" />
      </div>
      <div>
        <DistributionType v-if="selectionEntity.loadingCompleted && masterDataEntity.loadingCompleted"
          v-model="selectionEntity.data"
          :distributionList="masterDataEntity.data.distributionList" />
      </div>
      <div>
        <TransactionFilter v-if="selectionEntity.loadingCompleted && mainProductList.loadingCompleted && billingPeriodList.loadingCompleted && brandList.loadingCompleted"
          v-model="selectionEntity.data"
          :mainProductList="mainProductList.data"
          :billingPeriodList="billingPeriodList.data"
          :brandList="brandList.data" />
      </div>
      <div>
        <CRMParameter v-if="selectionEntity.loadingCompleted"
          v-model="selectionEntity.data" />
      </div>
      <div class="validation-row row">
        <dx-validation-summary validation-group="selectionForm" />
      </div>
      <div>
        <dx-button text="Generér fordelingsnøgler" icon="chart" :use-submit-behavior="true" validation-group="selectionForm" />
      </div>
    </form>
    <Popup v-if="showConfirmPopup"
      title="Advarsel"
      :visible="showConfirmPopup"
      content="Vil du slette denne preset?"
      saveButtonText="Ja"
      cancelButtonText="Nej"
      @onSave="onDeletePresetClick"
      @onCancel="closeConfirmPopup"
      :show-close-button="false"
      :height="220" />

    <SaveNamePopup v-if="showSaveNamePopup"
      v-model="selectionEntity.data"
      title="Gem preset som"
      :visible="showSaveNamePopup"
      content="Vil du slette denne preset?"
      saveButtonText="Gem"
      cancelButtonText="Annuller"
      @onSave="onSavePresetAsClick"
      @onCancel="closeSaveNamePopup"
      :show-close-button="false"
      :height="220" />
  </div>
</template>

<script>
import { ref } from 'vue';
import useSelection from '@/modules/manualBilling/composables/selection.composable.js';
import Preset from '@/modules/manualBilling/components/form/selection/preset-form.vue';
import DistributionType from '@/modules/manualBilling/components/form/selection/distribution-type-form.vue';
import CRMParameter from '@/modules/manualBilling/components/form/selection/crm-form.vue';
import TransactionFilter from '@/modules/manualBilling/components/form/selection/transaction-filter-form.vue';
import Popup from '@/components/common/popup.vue';
import SaveNamePopup from '@/modules/manualBilling/components/form/selection/save-name-popup';

export default {
  name: 'SelectionFormContainer',
  props: {
    billingSetId: String,
    manualBillingId: String,
    overviewData: Object,
  },
  components: {
    Preset,
    DistributionType,
    CRMParameter,
    TransactionFilter,
    Popup,
    SaveNamePopup,
  },
  emits: ['generateDistributionKeys'],
  setup(props, { emit }) {
    const {
      getSelectionByManualBillingId,
      selectionEntity,
      getMasterData,
      masterDataEntity,
      getPreset,
      selectedPresetId,
      mainProductList,
      billingPeriodList,
      brandList,
      updatePreset,
      createPreset,
      deletePreset,
    } = useSelection(props.billingSetId);

    getMasterData(props.manualBillingId, props.overviewData.supplierId);
    getSelectionByManualBillingId(props.manualBillingId);

    const handleSubmit = (e) => {
      emit('generateDistributionKeys', selectionEntity.data);

      e.preventDefault();
    };

    const showSaveNamePopup = ref(false);

    const closeSaveNamePopup = () => {
      showSaveNamePopup.value = false;
    };

    const onSavePresetAsClick = () => {
      createPreset(props.manualBillingId, selectionEntity.data)
        .then((response) => {
          if (response == null) return;
          closeSaveNamePopup();

          // Load masterdata again, to get the new preset in the preset dropdown list
          getMasterData(props.manualBillingId, props.overviewData.supplierId);
        });
    };

    const onSavePresetClick = () => {
      updatePreset(props.manualBillingId, selectedPresetId.value, selectionEntity.data)
        .then((response) => {
          if (response == null) return;

          // Load masterdata again, to get the updated preset in the preset dropdown list
          getMasterData(props.manualBillingId, props.overviewData.supplierId);
        });
    };

    const showConfirmPopup = ref(false);

    const closeConfirmPopup = () => {
      showConfirmPopup.value = false;
    };

    const onDeletePresetClick = () => {
      selectionEntity.data.selectionName = null;
      selectionEntity.data.isPreset = false;
      deletePreset(props.manualBillingId, selectedPresetId.value)
        .then((response) => {
          if (response == null) return;
          closeConfirmPopup();

          // Load masterdata again, to get preset dropdown list without the deleted preset.
          getMasterData(props.manualBillingId, props.overviewData.supplierId);
        });
    };

    return {
      selectionEntity,
      masterDataEntity,
      getPreset,
      selectedPresetId,
      mainProductList,
      billingPeriodList,
      brandList,
      handleSubmit,
      onSavePresetClick,
      onSavePresetAsClick,
      onDeletePresetClick,
      showConfirmPopup,
      closeConfirmPopup,
      showSaveNamePopup,
      closeSaveNamePopup,
    };
  },
};
</script>

<style lang="scss" scoped>
.selection-form-container {
  padding: 20px;
  background-color: #FFF;

  .margin-right {
    margin-right: 15px;
  }

  .row {
    padding-bottom: 15px;
  }
}
</style>
