import { reactive } from 'vue';
import useClientGroupsRepository from '@/modules/masterData/clients/repositories/clientGroup.repository.js';

export default function useClientGroups() {
  const clientGroupsRepository = useClientGroupsRepository();

  const clientGroupList = reactive({ data: null });

  const loadClientGroupList = () => {
    clientGroupsRepository.getClientGroupList()
      .then((response) => {
        clientGroupList.data = response.data;
      });
  };

  return {
    clientGroupList,
    loadClientGroupList,
  };
}
