<template>
  <XPBillingSetList />
</template>

<script>
import XPBillingSetList from '@/modules/xpBillingSet/components/billingset-list.vue';

export default {
  name: 'XPBillingSet',
  components: {
    XPBillingSetList,
  },
};
</script>
