<template>
  <div class="price-detail-view">
    <dx-responsive-box>
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-row :ratio="0" />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <div class="detail-element-container padding-right">
          Vare:
          <dx-textbox
            :value="row.data.productName"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container">
          Varenummer:
          <dx-textbox
            :value="row.data.productNumber"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="2" :col="0" />
        <div class="detail-element-container padding-right">
          Service:
          <dx-textbox
            :value="row.data.serviceName"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container">
          Servicenr:
          <dx-textbox
            :value="row.data.serviceNumber"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container padding-right">
          Faktureringsfrekvens:
          <dx-tag-box
              :items="invoicingFrequencyList"
              value-expr="id"
              display-expr="name"
              :value="row.data.invoicingFrequencyIdArray"
              :read-only="true"
              class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container">
          Varekategori:
          <dx-textbox
            :value="getInvoiceCategoryById(row.data.invoiceCategoryId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="0" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <template>
          <div class="detail-element-container padding-right">
            Pristype:
            <dx-textbox
              :value="getPricetypeById(row.data.priceTypeId)"
              :read-only="true"
              class="textbox-readonly" />
          </div>
          <template v-if="row.data.priceTypeId === PriceTypes.FASTPRIS">
            <div class="detail-element-container padding-right">
              Basispris:
              <dx-number-box
                :value="row.data.basePrice"
                :read-only="true"
                class="textbox-readonly"
                :format="NumberFormatting.kronerWithFourDecimals">
              </dx-number-box>
            </div>
            <div class="detail-element-container">
            <dx-checkbox
              text="Denne vare har markup"
              :value="row.data.hasMarkup"
              :read-only="true"
              class="textbox-readonly" />
            </div>
            <div class="detail-element-container padding-right">
              Markup:
              <dx-number-box
                :value="row.data.markup"
                :read-only="true"
                class="textbox-readonly"
                :format="NumberFormatting.kronerWithEightDecimals">
              </dx-number-box>
            </div>
          </template>
          <template v-if="row.data.priceTypeId === PriceTypes.TRAPPE">
            <volume-based-price-list-container :pricePeriodId="pricePeriodId" :serviceId="row.data.serviceId" />
          </template>
        </template>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="1" />
        <dx-location screen="xs sm" :row="7" :col="0" />
        <div class="detail-element-container">
          <dx-checkbox
            text="Varen er prisindeksreguleret"
            :value="row.data.isPriceIndexed"
            :read-only="true"
            class="textbox-readonly" />
          <br/>
          <dx-checkbox
            text="Varen opgøres pr. volumen"
            :value="row.data.isVolumeBased"
            :read-only="true"
            class="textbox-readonly" />
            <div class="detail-element-container pt-10">
            Gyldige brands:
            <dx-text-area
              :read-only="true"
              :value="row.data.brandList.join(', ')"
              class="textbox-readonly"
            />
            </div>
        </div>
      </dx-item>
      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="5" />
        <dx-location screen="xs sm" :row="8" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" :disabled="pricePeriod.active" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" :disabled="pricePeriod.active" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { get } from 'lodash';
import PriceTypes from '@/enums/price-types.js';
import VolumeBasedPriceListContainer from '@/modules/prices/components/volume-based-price-list-container.vue';
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'DetailView',
  components: {
    VolumeBasedPriceListContainer,
  },
  props: {
    row: Object,
    pricePeriod: Object,
    pricePeriodId: Number,
    pricetypeList: Array,
    invoicingFrequencyList: Array,
    invoiceCategoryList: Array,
  },
  setup(props) {
    function getInvoiceCategoryById(id) {
      const invoiceCategory = props.invoiceCategoryList.find((x) => x.id === id);

      return get(invoiceCategory, ['name']);
    }

    function getPricetypeById(id) {
      const pricetype = props.pricetypeList.find((x) => x.id === id);

      return get(pricetype, ['name']);
    }

    return {
      getPricetypeById,
      getInvoiceCategoryById,
      PriceTypes,
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss">
.price-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 120px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }
}

.pt-10{
  padding-top: 10px;
}

</style>
