<template>
  <div id="root">
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
      >
        <div class="content">
          <router-view></router-view>
        </div>
      </component>
    </div>
    <Notification />
    <LoadingPanel />
  </div>
</template>

<script>
// Import dependencies
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
} from 'vue';
import Notification from '@/components/common/notification.vue';
import LoadingPanel from '@/components/common/loading-panel.vue';
import { sizes, subscribe, unsubscribe } from './utils/media-query';
import useJob from '@/modules/shared/composables/job.composable.js';

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes['screen-x-small'],
    isLarge: screenSizes['screen-large'],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

export default {
  components: {
    Notification,
    LoadingPanel,
  },
  setup() {
    const vm = getCurrentInstance();

    const { title } = vm.proxy.$appInfo;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();

    function screenSizeChanged() {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => ['app'].concat(screen.getScreenSizeInfo.cssClasses));

    const {
      startFastJobPolling,
    } = useJob();
    startFastJobPolling();

    return {
      title,
      screen,
      cssClasses,
    };
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "@/themes/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
