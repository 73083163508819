<template>
  <DKVBillingSetList />
</template>

<script>
import DKVBillingSetList from '@/modules/dkvBillingSet/components/billingset-list.vue';

export default {
  name: 'DKVBillingSet',
  components: {
    DKVBillingSetList,
  },
};
</script>
