import CustomStore from 'devextreme/data/custom_store';
import useManualBillingRepository from '@/modules/manualBilling/repositories/manualBilling.repository.js';
import store from '@/store/index';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useDatagrid(billingSetId) {
  const manualBillingRepository = useManualBillingRepository(billingSetId);
  const { startFastJobPolling } = useJob();

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => manualBillingRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
    remove: (key) => new Promise((resolve, reject) => {
      manualBillingRepository.deleteManualBilling(key)
        .then((response) => {
          if (response.data.success) {
            // Start polling JobList at a fast pace
            startFastJobPolling();

            store.dispatch('addSuccessNotification', 'BOKIS post er nu slettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
  });

  return {
    datasource,
  };
}
