import { ref } from 'vue';
import store from '@/store/index';
import usePricePeriodRepository from '@/modules/pricePeriods/repositories/priceperiod.repository.js';

export default function usePriceindex() {
  const showPriceindexForm = ref(false);
  const priceindexModel = ref();

  function onPriceindexClick(row) {
    // Model for new priceindex value
    priceindexModel.value = {
      id: row.data.id,
      priceindexPercent: 0,
    };

    showPriceindexForm.value = true;
  }

  const onPriceindexSave = () => {
    const pricePeriodRepository = usePricePeriodRepository();

    const payload = {
      value: priceindexModel.value.priceindexPercent,
    };

    pricePeriodRepository.priceindex(priceindexModel.value.id, payload)
      .then((response) => {
        if (response.data.success) {
          // Hide Priceindex form
          showPriceindexForm.value = false;

          store.dispatch('addSuccessNotification', 'Prisindeks er nu opdateret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    onPriceindexClick,
    showPriceindexForm,
    priceindexModel,
    onPriceindexSave,
  };
}
