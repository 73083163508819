import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useAttachmentRepository from '@/modules/masterData/cmsAttachments/repositories/attachment.repository.js';
import store from '@/store/index';

export default function useDatagrid(dataGrid) {
  const attachmentRepository = useAttachmentRepository();
  const datasource = ref();
  const showNewAttachmentPopup = ref(false);
  const showEditForm = ref(false);

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => attachmentRepository.load(loadOptions)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      remove: (key) => new Promise((resolve, reject) => {
        attachmentRepository.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Bilag er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const onSaveNewAttachment = (form) => {
    const formData = new FormData(form.target);

    attachmentRepository.insert(formData)
      .then((response) => {
        if (response.data.success) {
          store.dispatch('addSuccessNotification', 'Bilag er nu oprettet');
          showNewAttachmentPopup.value = false;

          dataGrid.value.instance.refresh();
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onUpdateAttachment = (key, form) => {
    const formData = new FormData(form.target);

    attachmentRepository.update(key, formData)
      .then((response) => {
        if (response.data.success) {
          store.dispatch('addSuccessNotification', 'Bilag er nu opdateret');
          showEditForm.value = false;

          dataGrid.value.instance.refresh();
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    datasource,
    getDatasource,
    onSaveNewAttachment,
    showNewAttachmentPopup,
    showEditForm,
    onUpdateAttachment,
  };
}
