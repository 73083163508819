<template>
  <div class="user-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Brugere</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="userDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @init-new-row="initNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Brugere" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="mainUser-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="email">
              <dx-label text="E-mail" />
            </dx-simple-item>
            <dx-simple-item data-field="fullName">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="initials">
              <dx-label text="Initialer" />
            </dx-simple-item>
            <dx-simple-item data-field="active" :editor-options="activeEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="isAdministrator" :editor-options="administratorEditorOptions">
              <dx-label :visible="false" />
            </dx-simple-item>
            <dx-simple-item data-field="password" :editor-options="passwordOptions">
              <dx-label text="Ny adgangskode" />
            </dx-simple-item>
            <dx-simple-item data-field="repeatPassword" :editor-options="passwordOptions">
              <dx-label text="Gentag adgangskode" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="email"
          caption="E-mail"
          data-type="string"
          sort-order="asc"
          :hiding-priority="8"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="initials"
          caption="Initialer"
          data-type="string"
          :hiding-priority="7"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="fullName"
          caption="Navn"
          data-type="string"
          :hiding-priority="7"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="isAdministrator"
          caption="Administrator"
          data-type="boolean"
          :hiding-priority="7"
          :width="130">
        </dx-column>

        <dx-column
          data-field="password"
          caption="Ny adgangskode"
          data-type="string"
          :form-item="passwordFormItem"
          :visible="false">
        </dx-column>

        <dx-column
          data-field="repeatPassword"
          caption="Gentag adgangskode"
          data-type="string"
          :form-item="passwordRepeatFormItem"
          :visible="false">
        </dx-column>

        <dx-column
          data-field="active"
          caption="Aktiv"
          data-type="boolean"
          :hiding-priority="5"
          :width="130" />

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <PasswordForm v-if="showPasswordForm" v-model="passwordModel" @save="onPasswordSave(row, storageKey)" @cancel="showPasswordForm = false;" />
            <DetailView
              v-else
              :row="row"
              @onEditClick="onEditClick"
              @onChangePasswordClick="onChangePasswordClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import DetailView from '@/modules/masterData/users/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/users/composables/datagrid.composable.js';
import PasswordForm from '@/modules/masterData/users/components/password-form.vue';
import usePassword from '@/modules//masterData/users/composables/password.composable.js';

const activeEditorOptions = {
  text: 'Aktiv',
};

const administratorEditorOptions = {
  text: 'Administrator',
};

const passwordOptions = {
  mode: 'password',
};

const passwordFormItem = {
  visible: false,
};

const passwordRepeatFormItem = {
  visible: false,
};

export default {
  name: 'MainUserModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    PasswordForm,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      onInitNewRow,
    } = useDatagrid();

    getDatasource();

    const {
      onChangePasswordClick,
      showPasswordForm,
      passwordModel,
      onPasswordSave,
    } = usePassword();

    return {
      datasource,
      activeEditorOptions,
      administratorEditorOptions,
      passwordOptions,
      passwordFormItem,
      passwordRepeatFormItem,
      onInitNewRow,
      onChangePasswordClick,
      showPasswordForm,
      passwordModel,
      onPasswordSave,
    };
  },
  methods: {
    initNewRow(e) {
      this.onInitNewRow(e);
    },
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne bruge?<br/><b>${e.data.fullName}</b>`, 'Slet bruge');
    },
  },
};
</script>

<style lang="scss">
.user-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
