import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useInvoiceRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const createInvoices = (clientDataSetIdList) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/invoices`, { list: clientDataSetIdList });

  const getDifferenceReport = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/externaldiffreport`);

  const getTotalClientBillingReport = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/totalclientbillingreportcontent`, { responseType: 'arraybuffer' });

  const getFAKReport = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/fakreportcontent`, { responseType: 'arraybuffer' });

  const getInvoices = (clientDataSetIdList) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/invoices/export`, { list: clientDataSetIdList });

  const deleteInvoices = (clientDataSetIdList) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/clientdatasets/invoices`, { data: { list: clientDataSetIdList } });

  const updateCollectionDate = (clientDataSetIdList, collectionDate) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/setchargingdate`, { clientDataSetIdList, chargingDate: collectionDate });

  return {
    createInvoices,
    getInvoices,
    getDifferenceReport,
    getTotalClientBillingReport,
    getFAKReport,
    deleteInvoices,
    updateCollectionDate,
  };
}
