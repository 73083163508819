export default {
  namespaced: true,
  state: {
    mainProducts: {
      selectedSupplier: null,
    },
    brands: {
      selectedSupplier: null,
    },
    cardProductMapping: {
      selectedSupplier: null,
    },
    cmsGraphTooltips: {
      selectedSupplier: null,
    },
    billingSetTypes: {
      selectedSupplier: null,
    },
  },
  mutations: {
    updateMainProductSelectedSupplier(state, supplierId) {
      state.mainProducts.selectedSupplier = supplierId;
    },
    updateBrandSelectedSupplier(state, supplierId) {
      state.brands.selectedSupplier = supplierId;
    },
    updateCardProductMappingSelectedSupplier(state, supplierId) {
      state.cardProductMapping.selectedSupplier = supplierId;
    },
    updateCmsGraphTooltipsSelectedSupplier(state, supplierId) {
      state.cmsGraphTooltips.selectedSupplier = supplierId;
    },
    updateBillingSetTypeSelectedSupplier(state, supplierId) {
      state.billingSetTypes.selectedSupplier = supplierId;
    },
  },
};
