import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useContactPersonRepository from '@/modules/masterData/cmsContactPersons/repositories/contactPerson.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const contacPersonRepostory = useContactPersonRepository();
  const datasource = ref();
  const picture = ref();
  const loadedPicture = ref();
  const showPictureForm = ref(false);

  const uploadPicture = (contactPersonId) => contacPersonRepostory.uploadPicture(contactPersonId.key, picture.value)
    .then((response) => {
      if (response.data.success) {
        store.dispatch('addSuccessNotification', 'Billedet er nu uploadet');
        picture.value = null;
        showPictureForm.value = false;
        return Promise.resolve(response.data);
      }
      if (response.data.messages.length > 0) {
        store.dispatch('addNotifications', { messageList: response.data.messages });
      }
      return Promise.resolve();
    });

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => contacPersonRepostory.load(loadOptions)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      update: (key, values) => new Promise((resolve, reject) => {
        contacPersonRepostory.update(key, values)
          .then((response) => {
            if (response.data.success) {
              if (picture.value) {
                uploadPicture(key);
              }
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      insert: (values) => new Promise((resolve, reject) => {
        contacPersonRepostory.insert(values)
          .then((response) => {
            if (response.data.success) {
              if (picture.value) {
                uploadPicture(response.data.value);
              }
              store.dispatch('addSuccessNotification', 'Kontaktperson er nu oprettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      remove: (key) => new Promise((resolve, reject) => {
        contacPersonRepostory.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Kontaktperson er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const onPictureSelected = (form) => {
    const file = form[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      picture.value = Buffer.from(reader.result).toString('base64');
    };
  };

  const loadContactPerson = (clientId) => {
    contacPersonRepostory.getContactPerson(clientId)
      .then((response) => {
        if (response.data.value.picture) {
          // eslint-disable-next-line no-buffer-constructor
          loadedPicture.value = Buffer(response.data.value.picture, 'base64').toString('ascii');
        }
      });
  };

  const onPictureEditClick = (row) => {
    showPictureForm.value = true;
    loadedPicture.value = null;
    loadContactPerson(row.data.id);
  };

  return {
    datasource,
    getDatasource,
    uploadPicture,
    onPictureSelected,
    showPictureForm,
    onPictureEditClick,
    loadedPicture,
  };
}
