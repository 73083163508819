<template>
  <MainClientGroupModule />
</template>

<script>
import MainClientGroupModule from '@/modules/masterData/clientGroups/components/main.vue';

export default {
  name: 'MasterDataMainProducts',
  components: {
    MainClientGroupModule,
  },
};
</script>
