<template>
  <ContactPersonModule />
</template>

<script>
import ContactPersonModule from '@/modules/masterData/cmsContactPersons/components/main.vue';

export default {
  name: 'MasterDataContactPersons',
  components: {
    ContactPersonModule,
  },
};
</script>
