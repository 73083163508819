import { ref } from 'vue';
import useCRMRepository from '@/modules/masterData/clients/repositories/crm.repository.js';
import store from '@/store/index';

export default function useCrm(dataGrid) {
  const crmRepository = useCRMRepository();
  const showCrmForm = ref(false);

  function onEditCrmClick() {
    showCrmForm.value = true;
  }

  const onCrmSave = (clientId, payload) => {
    crmRepository.updateCrmParametersList(clientId, payload)
      .then((response) => {
        if (response.data.success) {
          dataGrid.value.instance.refresh();
          showCrmForm.value = false;
          store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    onEditCrmClick,
    showCrmForm,
    onCrmSave,
  };
}
