import { inject } from 'vue';
import ApiService from '@/services/api.service.js';
import SupplierInvoiceType from '@/enums/supplier-invoice-type.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getBillingPeriodList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingperiods`);

  const getSupplierList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/suppliers?invoicetype=${SupplierInvoiceType.DKVEDERLAG}`);

  const getBillingSetTypeList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/billingsettypes/list`);

  return {
    getBillingPeriodList,
    getSupplierList,
    getBillingSetTypeList,
  };
}
