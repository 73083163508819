<template>
  <div class="clientdataset-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <div class="detail-element-container padding-right">
            Pengeinstitut:
            <dx-textbox
              :value="row.data.cliName"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container">
            Reg. nr.
            <dx-number-box
              :value="row.data.cliRegistrationNumber"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container padding-right">
            Beløb:
            <dx-number-box
              :value="row.data.cliTotalPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithTwoDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="1" />
          <dx-location screen="xs sm" :row="3" :col="0" />
          <div class="detail-element-container">
            Opkrævningsdato:
            <dx-date-box
              :value="row.data.chargingDate"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container">
            Status:
            <p>
              {{ ClientDataSetStatusDescription.get(row.data.status) }}

              <dx-data-grid v-if="row.data.invoices.length > 0"
                ref="invoiceGrid"
                id="bcInvoiceGrid"
                :data-source="row.data.invoices"
              >
                <dx-column
                  data-field="invoiceNumber"
                  caption="Fakturanr."
                  :hiding-priority="5"
                  width="auto"/>
                <dx-column
                  data-field="invoiceDate"
                  caption="Fakturadato"
                  data-type="date"
                  format="dd.MM.yyyy"
                  :hiding-priority="5"
                  width="auto"/>
                <dx-column
                  caption="Hent faktura"
                  :hiding-priority="5"
                  width="120px"
                  cell-template="downloadTemplate" />
                <template #downloadTemplate="{ data }">
                    <i class="dx-icon-download" @click="getBCInvoiceFile(data.data.invoiceFileId)"></i>
                </template>
              </dx-data-grid>
            </p>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="1" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container">
            Afvigelse:
            <div v-html="getDeviationContent(row.data, true)" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="2" :rowspan="6" />
          <dx-location screen="xs sm" :row="11" :col="0" />
          <div>
            <div class="action-button-wrapper">
              <dx-button
                v-if="row.data.status !== ClientDataSetStatus.NOTCREATED"
                icon="detailslayout"
                text="Vis poster"
                @click="$emit('onShowClientBillingEntriesClick', row)"
                class="action-button-large" />
            </div>
          </div>
        </dx-item>
      </dx-responsive-box>
    </div>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';
import ClientDataSetStatus, { ClientDataSetStatusDescription } from '@/enums/clientdataset-status.js';
import useDeviationContent from '@/modules/clientDataSet/composables/deviationcontent.composable.js';
import useBCInvoice from '@/modules/shared/composables/bcinvoice.composable.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  setup() {
    const {
      getDeviationContent,
    } = useDeviationContent();

    const {
      getBCInvoiceFile,
    } = useBCInvoice();

    return {
      NumberFormatting,
      ClientDataSetStatusDescription,
      ClientDataSetStatus,
      getDeviationContent,
      getBCInvoiceFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.clientdataset-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button-large {
      margin-bottom: 5px;
      width: 170px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 20px;
  }
}
</style>

<style>
  #bcInvoiceGrid .dx-datagrid {
    background-color: transparent;
  }

  #bcInvoiceGrid td {
    padding: 1px;
  }
</style>
