<template>
  <div class="product-price-group-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <dx-location screen="xs sm" :row="0" :col="0" />
        <div class="detail-element-container padding-right">
          Varenummer:
          <dx-textbox
            :value="row.data.identifier"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container">
          Navn:
          <dx-textbox
            :value="row.data.name"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container padding-right">
          Brands:
          <dx-tag-box
            :items="brandList"
            value-expr="id"
            display-expr="name"
            :value="row.data.brands"
            :read-only="true"
            placeholder="(Ingen)"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="1" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container padding-top">
          <dx-checkbox
            :value="row.data.active"
            :read-only="true"
            text="Aktiv"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="3" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    supplierList: Array,
    brandList: Array,
  },
  setup(props) {
    function getSupplierById(id) {
      const supplier = props.supplierList.find((x) => x.id === id);

      return get(supplier, ['name']);
    }

    return {
      getSupplierById,
    };
  },
};
</script>

<style lang="scss">
.product-price-group-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 25px;
  }
}
</style>
