import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/cmsGraphTooltips/repositories/masterdata.repository.js';
import store from '@/store/index';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const supplierList = reactive({ loadingCompleted: false, data: null, selectedSupplier: null });
  const brandList = reactive({ loadingCompleted: false, data: null });
  const graphTypeList = reactive({ loadingCompleted: false, data: null });
  const monthTypeList = reactive({ loadingCompleted: false, data: null });
  const graphLevelList = reactive({ loadingCompleted: false, data: null });
  const serviceList = reactive({ loadingCompleted: false, data: null });

  const saveSelectedSupplierInStore = (supplierId) => {
    store.commit('masterData/updateCmsGraphTooltipsSelectedSupplier', supplierId);
  };

  masterdataRepository.getSupplierList()
    .then((response) => {
      const list = response.data;

      supplierList.loadingCompleted = true;
      supplierList.data = list;

      let { selectedSupplier } = store.state.masterData.cmsGraphTooltips;

      if (selectedSupplier == null) {
        // Set 'Nets efter 2020' as the default selected supplier
        const netsAfter2020 = list.find((x) => x.name.startsWith('Nets efter 2020'));

        if (netsAfter2020 != null) {
          selectedSupplier = netsAfter2020.id;
          saveSelectedSupplierInStore(selectedSupplier);
        }
      }

      supplierList.selectedSupplier = selectedSupplier;
    });

  const getMasterData = (supplierId) => {
    masterdataRepository.getBrandList(supplierId)
      .then((response) => {
        brandList.loadingCompleted = true;
        brandList.data = response.data;
      });

    masterdataRepository.getGraphTypeList()
      .then((response) => {
        graphTypeList.loadingCompleted = true;
        graphTypeList.data = response.data;
      });

    masterdataRepository.getMonthTypeList()
      .then((response) => {
        monthTypeList.loadingCompleted = true;
        monthTypeList.data = response.data;
      });

    masterdataRepository.getServiceList(supplierId)
      .then((response) => {
        serviceList.loadingCompleted = true;
        serviceList.data = response.data;
      });

    graphLevelList.data = [
      { id: 1, name: 'Graf drill down level 1' },
      { id: 2, name: 'Graf drill down level 2' },
      { id: 3, name: 'Graf drill down level 3' },
    ];
  };

  return {
    supplierList,
    saveSelectedSupplierInStore,
    getMasterData,
    brandList,
    graphTypeList,
    monthTypeList,
    graphLevelList,
    serviceList,
  };
}
