<template>
  <BITextModule />
</template>

<script>
import BITextModule from '@/modules/masterData/cmsBIText/components/main.vue';

export default {
  name: 'MasterDataBIText',
  components: {
    BITextModule,
  },
};
</script>
