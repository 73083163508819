<template>
  <div class="content-loader-list-container">
    <content-loader
      :width="200"
      :height="100"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="25" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="50" rx="3" ry="3" width="200" height="15" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'ContentLoaderList',
  components: {
    ContentLoader,
  },
};
</script>

<style lang="scss" scoped>
.content-loader-list-container {
  width: 250px;
}
</style>
