<template>
  <PricePeriodList />
</template>

<script>
import PricePeriodList from '@/modules/pricePeriods/components/price-period-list.vue';

export default {
  name: 'PricePeriods',
  components: {
    PricePeriodList,
  },
};
</script>
