<template>
  <dx-popup
    :visible="visible"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :width="width"
    :height="height"
    :show-close-button="false"
    :title="title">
    <template #content>
      <form @submit="handleSubmit($event)">
        <div class="popup-content-root">
          <div class="content-row">
            <dx-select-box v-if="contractList != null && contractList.length > 0"
              :data-source="contractList"
              display-expr="name"
              value-expr="contractNumber"
              name="contractNumber"
              id="contractNumber" />
          </div>
          <div class="content-row" v-if="showWarningCheckbox">
            <dx-checkbox text="Tillad indlæsning selv om der er advarsler" name="ignoreWarnings" id="ignoreWarnings" />
          </div>
          <div class="button-row">
            <div class="action-button-wrapper">
              <ButtonWithLoadingState :buttonText="saveButtonText" :isLoading="isLoading"></ButtonWithLoadingState>
            </div>
            <div class="action-button-wrapper">
              <dx-button :text="cancelButtonText" @click="$emit('cancel')" />
            </div>
          </div>
        </div>
      </form>
    </template>
  </dx-popup>
</template>

<script>
import ButtonWithLoadingState from '@/components/common/button-with-loading-state.vue';

export default {
  name: 'LoadFromApiPopup',
  components: {
    ButtonWithLoadingState,
  },
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: 'Gem',
    },
    cancelButtonText: {
      type: String,
      default: 'Annuller',
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 700,
    },
    showWarningCheckbox: {
      type: Boolean,
      default: false,
    },
    contractList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const handleSubmit = (e) => {
      context.emit('save', e);
      e.preventDefault();
    };

    const scrollViewHeight = () => {
      let viewHeight = props.height;

      if (props.showWarningCheckbox) {
        viewHeight -= 170;
      }

      return viewHeight;
    };

    return {
      handleSubmit,
      scrollViewHeight,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup-content-root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-row {
    padding: 0 0 10px 0;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;

    .action-button-wrapper {
      margin-left: 15px;
    }
  }
}
</style>
