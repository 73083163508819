function persistToStorage(storageKey, array) {
  sessionStorage.setItem(storageKey, JSON.stringify(array));
}

function getFromStorage(storageKey) {
  const value = sessionStorage.getItem(storageKey);

  if (value == null) return [];

  return JSON.parse(value);
}

const rowStateService = {
  getRowKeysFromStorage: (storageKey) => getFromStorage(storageKey),
  addKeyToStorage: (storageKey, key) => {
    // First get array from localstorage
    const array = getFromStorage(storageKey);

    // Remove all existing master keys, but keep all group keys
    // Master keys are numbers, Group keys are Arrays
    const cleanArray = array.filter((x) => Array.isArray(x));

    // If row already exist in array then return
    if (cleanArray.some((x) => JSON.stringify(x) === JSON.stringify(key))) return;

    // Add new key to array
    cleanArray.push(key);

    // Persist array to localstorage
    persistToStorage(storageKey, cleanArray);
  },
  removeKeyFromStorage: (storageKey, key) => {
    // First get array from sessionStorage
    let array = getFromStorage(storageKey);

    // Then remove key from array
    array = array.filter((x) => JSON.stringify(x) !== JSON.stringify(key));

    // Persist array to sessionStorage
    persistToStorage(storageKey, array);
  },
  removeAllKeysFromStorage: (storageKey) => {
    // First get array from sessionStorage
    const array = getFromStorage(storageKey);

    // Then remove all keys
    array.length = 0;

    // Persist array to sessionStorage
    persistToStorage(storageKey, array);
  },
};

export default rowStateService;
