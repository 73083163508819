<template>
  <div class="price-period-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <div class="detail-element-container padding-right">
          Navn:
          <dx-textbox
            :value="row.data.name"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container padding-right">
          Startdato:
          <dx-textbox
            :value="formatService.stringToDate(row.data.startDate)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="2" :col="0" />
        <div class="detail-element-container">
          Supplier:
          <dx-textbox
            :value="getSupplierById(row.data.supplierId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container">
          Slutdato:
          <dx-textbox
            :value="formatService.stringToDate(row.data.endDate)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container padding-right">
          Markup:
          <dx-number-box
            :value="row.data.markup"
            :read-only="true"
            class="textbox-readonly"
            :format="NumberFormatting.percentage">
          </dx-number-box>
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container padding-top">
          <dx-checkbox
            :value="row.data.active"
            :read-only="true"
            text="Aktiv"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="5" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" :visible="!row.data.active" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="money" text="Prisliste" @click="$emit('onPrislisteClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="preferences" text="Services" @click="$emit('onServicesClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="upload" text="Aktiver" @click="$emit('onActivateClick', row)" :visible="!row.data.active" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="download" text="Inaktiver" @click="$emit('onInactivateClick', row)" :visible="row.data.active" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="copy" text="Kopier" @click="$emit('onCopyClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" :visible="!row.data.active" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="chart" text="Markup" @click="$emit('onMarkupClick', row)" :visible="!row.data.active" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="chart" text="Prisindeks" @click="$emit('onPriceindexClick', row)" :visible="!row.data.active" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { get } from 'lodash';
import { inject } from 'vue';
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    supplierList: Array,
  },
  setup(props) {
    const formatService = inject('formatService');

    function getSupplierById(id) {
      const supplier = props.supplierList.find((x) => x.id === id);

      return get(supplier, ['name']);
    }

    return {
      getSupplierById,
      NumberFormatting,
      formatService,
    };
  },
};
</script>

<style lang="scss">
.price-period-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 20px;
  }
}
</style>
