import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useClientDistributionHistoryRepository from '@/modules/masterData/clientDistributionHistory/repositories/clientDistribution.repository.js';

export default function useDatagrid(periodId) {
  const mainClientDistributionHistory = useClientDistributionHistoryRepository(periodId);
  const datasource = ref();

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainClientDistributionHistory.load(loadOptions)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
    });
  };

  return {
    datasource,
    getDatasource,
  };
}
