<template>
  <DKVClientDataSetList />
</template>

<script>
import DKVClientDataSetList from '@/modules/dkvClientDataSet/components/list.vue';

export default {
  name: 'ClientDataSets',
  components: {
    DKVClientDataSetList,
  },
};
</script>
