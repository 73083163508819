<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>

      <dx-item
        v-if="title"
        location="before"
        css-class="header-title dx-toolbar-label"
      >
        <div>{{ title }}</div>
      </dx-item>

      <dx-item location="after">
        <div>
          <JobsSummary />
        </div>
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
      <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              height="100%"
              styling-mode="text"
            >
              <user-panel :name="user.name" :email="user.email" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>

      <template #menuUserItem>
        <user-panel
          :name="user.name"
          :email="user.email"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { computed } from 'vue';
import useUserManager from '@/services/userManager.service.js';
import UserPanel from './user-panel';
import store from '@/store/index';
import JobsSummary from '@/components/jobs-summary.vue';

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function,
  },
  setup() {
    const user = computed(() => store.state.user);
    const { logout } = useUserManager();

    function onLogoutClick() {
      logout();
    }

    const userMenuItems = [
      {
        text: 'Log ud',
        icon: 'runner',
        onClick: onLogoutClick,
      }];

    return {
      user,
      userMenuItems,
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
    JobsSummary,
  },
};
</script>

<style lang="scss">
@import "@/themes/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: #fff;
  }
}

.header-toolbar {
  background-color: $base-color-1;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
  color: #FFF;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
</style>
