<template>
  <PriceList />
</template>

<script>
import PriceList from '@/modules/prices/components/price-list.vue';

export default {
  name: 'Prices',
  components: {
    PriceList,
  },
};
</script>
