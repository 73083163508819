/* eslint-disable no-param-reassign */
import { get } from 'lodash';
import { ref } from 'vue';
import CustomStore from 'devextreme/data/custom_store';
import PriceTypes from '@/enums/price-types.js';
import usePriceRepository from '@/modules/prices/repositories/price.repository.js';
import useMasterdataRepository from '@/modules/prices/repositories/masterdata.repository.js';
import store from '@/store/index';

export default function useDatagrid(pricePeriodId) {
  const priceRepository = usePriceRepository(pricePeriodId);
  const masterdataRepository = useMasterdataRepository();
  const markupPercent = ref();

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => priceRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
    update: (key, values) => new Promise((resolve, reject) => {
      priceRepository.update(key, values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    insert: (values) => new Promise((resolve, reject) => {
      priceRepository.insert(values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Pris er nu oprettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    remove: (key) => new Promise((resolve, reject) => {
      priceRepository.remove(key)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Pris er nu slettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
  });

  const onEditorPreparing = (e) => {
    if (e.dataField === 'markup' && e.parentType === 'dataRow') {
      e.editorOptions.disabled = !get(e.row.data, ['hasMarkup'], false);
    }
  };

  const currentEditingKey = ref();
  const mainProductList = ref();
  const productPriceGroupList = ref();

  const onInitNewRow = (value) => {
    markupPercent.value = value;
    currentEditingKey.value = 0;

    // Load masterdata for "Vare"-SelectBox
    masterdataRepository
      .getMainProductList(pricePeriodId, null)
      .then((response) => {
        const list = response.data.map((product) => ({
          id: product.id,
          name: `${product.number}: ${product.name}`,
        }));

        mainProductList.value = list;
      });

    // Load masterdata for "Service"-SelectBox
    masterdataRepository
      .getProductPriceGroupList(pricePeriodId)
      .then((response) => {
        const list = response.data.map((service) => ({
          id: service.id,
          name: `${service.number}: ${service.name}`,
        }));

        productPriceGroupList.value = list;
      });
  };

  const onEditingStart = (e) => {
    currentEditingKey.value = e.key;

    const currentMainProductId = e.data.productId;

    // Load masterdata for "Vare"-SelectBox
    masterdataRepository
      .getMainProductList(pricePeriodId, currentMainProductId)
      .then((response) => {
        const list = response.data.map((product) => ({
          id: product.id,
          name: `${product.number}: ${product.name}`,
        }));

        mainProductList.value = list;
      });

    // Load masterdata for "Service"-SelectBox
    masterdataRepository
      .getProductPriceGroupList(pricePeriodId)
      .then((response) => {
        const list = response.data.map((service) => ({
          id: service.id,
          name: `${service.number}: ${service.name}`,
        }));

        productPriceGroupList.value = list;
      });
  };

  function setCellValue(newData, value) {
    const column = this;
    column.defaultSetCellValue(newData, value);
  }

  const basePriceValue = ref();

  function onBasePriceChanged(newData, value) {
    basePriceValue.value = value;
    const column = this;
    column.defaultSetCellValue(newData, value);
  }

  function onHasMarkupChanged(newData, value) {
    const column = this;
    column.defaultSetCellValue(newData, value);
    if ((basePriceValue.value !== null) && (markupPercent.value !== null)) {
      newData.markup = (basePriceValue.value * markupPercent.value) / 100;
    }
  }

  const dataGrid = ref();
  const selectedServiceId = ref();

  const customizeItem = (item) => {
    if (item.itemType !== 'group') return;

    // Get grid instance
    const { instance } = dataGrid.value;

    // Get index of current row
    const index = currentEditingKey.value === 0 ? 0 : instance.getRowIndexByKey(currentEditingKey.value);

    // Get selected PriceType
    const priceTypeId = instance.cellValue(index, 'priceTypeId');

    if (item.name === 'fixedPrice') {
      item.visible = priceTypeId === PriceTypes.FASTPRIS;
    } else if (item.name === 'volumeBasedPriceList') {
      if (priceTypeId === PriceTypes.TRAPPE) {
        selectedServiceId.value = instance.cellValue(index, 'serviceId');
        item.visible = true;
      } else {
        item.visible = false;
      }
    }
  };

  return {
    datasource,
    onEditorPreparing,
    dataGrid,
    selectedServiceId,
    customizeItem,
    setCellValue,
    onEditingStart,
    onInitNewRow,
    mainProductList,
    productPriceGroupList,
    currentEditingKey,
    basePriceValue,
    onBasePriceChanged,
    onHasMarkupChanged,
  };
}
