<template>
  <div>
    <div v-if="showNumberOfJobs" class="small-circle-container">
      <div class="flex-center-container circle-small circle-small-font">
        {{ numberOfJobs }}
      </div>
    </div>
    <div class="flex-center-container circle">
      <i :class="{
        'rotate': rotate,
        'dx-icon-refresh': icon === JobStatusIcon.REFRESH,
        'icon-refresh': icon === JobStatusIcon.REFRESH,
        'dx-icon-warning': icon === JobStatusIcon.WARNING,
        'icon-warning': icon === JobStatusIcon.WARNING,
        'dx-icon-check': icon === JobStatusIcon.CHECK,
        'icon-check': icon === JobStatusIcon.CHECK,
      }" />
    </div>
  </div>
</template>

<script>
import JobStatusIcon from '@/enums/job-status-icon.js';

export default {
  name: 'JobStatusIcon',
  props: {
    numberOfJobs: Number,
    showNumberOfJobs: Boolean,
    icon: Number,
    rotate: Boolean,
  },
  setup() {
    return {
      JobStatusIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.rotate {
  display: inline-block;
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to{ transform: rotate(360deg); }
}

.icon-refresh {
  font-size: 20px;
  color: green;
}

.icon-warning {
  font-size: 20px;
  color: red;
}

.icon-check {
  font-size: 20px;
  color: green;
}

.circle {
  height: 29px;
  width: 29px;
  background-color: #FFF;
  border-radius: 50%;
  display: inline-block;
}

.small-circle-container {
  position: relative;
  height: 0px;
  padding-left: 23px;
}

.circle-small {
  height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.circle-small-font {
  font-size: 10px;
  color: #FFF;
  font-weight: 700;
}

.flex-center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
