import CustomStore from 'devextreme/data/custom_store';
import { get } from 'lodash';
import useProductPriceGroupRepository from '@/modules/productPriceGroups/repositories/productPriceGroup.repository.js';
import store from '@/store/index';

export default function useDatagrid(pricePeriodId) {
  const productPriceGroupRepository = useProductPriceGroupRepository(pricePeriodId);

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => productPriceGroupRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
    update: (key, values) => new Promise((resolve, reject) => {
      productPriceGroupRepository.update(key, values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    insert: (values) => new Promise((resolve, reject) => {
      productPriceGroupRepository.insert(values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Service er nu oprettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    remove: (key) => new Promise((resolve, reject) => {
      productPriceGroupRepository.remove(key)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Service er nu slettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
  });

  const onEditorPreparing = (e) => {
    if ((e.dataField === 'volumeBasedPricesIndexed' || e.dataField === 'volumeBasedPricesTier') && e.parentType === 'dataRow') {
      e.editorOptions.disabled = !get(e.row.data, ['definesVolumeBasedPrices'], false);
    }
  };

  function setCellValue(newData, value) {
    const column = this;
    column.defaultSetCellValue(newData, value);
  }

  return {
    datasource,
    onEditorPreparing,
    setCellValue,
  };
}
