import { createStore } from 'vuex';
import NotificationTypes from '@/enums/notification-types.js';
import JobStatus from '@/enums/job-status.js';
import masterDataModule from '@/store/masterData.module.js';

const store = createStore({
  state() {
    return {
      user: {
        isAuthenticated: false,
        roles: [],
        name: null,
        email: null,
      },
      notification: {
        messageList: [],
      },
      showLoadingPanel: false,
      appSettings: null,
      jobs: [],
      showJobsPanel: false,
    };
  },
  modules: {
    masterData: masterDataModule,
  },
  actions: {
    addSuccessNotification({ commit }, message) {
      commit('addNotification', { messageList: [{ message, type: NotificationTypes.SUCCESS }] });
    },
    addInfoNotification({ commit }, message) {
      commit('addNotification', { messageList: [{ message, type: NotificationTypes.INFO }] });
    },
    addNotifications({ commit }, payload) {
      commit('addNotification', payload);
    },
    showLoadingPanel({ commit }, value) {
      commit('showLoadingPanel', value);
    },
    addJobs({ commit }, payload) {
      commit('addJobs', payload);
    },
    showJobsPanel({ commit }, value) {
      commit('showJobsPanel', value);
    },
  },
  mutations: {
    updateUser(state, payload) {
      state.user.isAuthenticated = true;
      state.user.roles = payload.roles;
      state.user.name = payload.name;
      state.user.email = payload.email;
    },
    addNotification(state, payload) {
      // Delete previous notification
      state.notification = null;

      // Add new notification
      state.notification = payload;
    },
    showLoadingPanel(state, value) {
      state.showLoadingPanel = value;
    },
    updateAppsettings(state, payload) {
      state.appSettings = payload;
    },
    addJobs(state, payload) {
      // Delete previous jobs
      state.jobs = null;

      // Add latest jobs
      state.jobs = payload;
    },
    showJobsPanel(state, value) {
      state.showJobsPanel = value;
    },
  },
  getters: {
    jobStatusSummary: (state) => {
      const numberOfRunningJobs = state.jobs.filter((x) => x.status === JobStatus.WAITING || x.status === JobStatus.RUNNING || x.status === JobStatus.IDLE).length;
      const numberOfFailedJobs = state.jobs.filter((x) => x.status === JobStatus.FAILED).length;
      return {
        numberOfRunningJobs,
        numberOfFailedJobs,
      };
    },
  },
});

export default store;
