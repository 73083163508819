import { reactive, ref } from 'vue';
import ExcelJS from 'exceljs';
import useSelectionRepository from '@/modules/manualBilling/repositories/selection.repository.js';
import useMasterdataRepository from '@/modules/manualBilling/repositories/masterdata.repository.js';
import store from '@/store/index';

export default function useSelection(billingSetId) {
  const selectionRepository = useSelectionRepository(billingSetId);
  const masterdataRepository = useMasterdataRepository();
  const selectionEntity = reactive({ loadingCompleted: false, data: null });
  const masterDataEntity = reactive({ loadingCompleted: false, data: null });
  const selectedPresetId = ref();
  const mainProductList = reactive({ loadingCompleted: false, data: null });
  const billingPeriodList = reactive({ loadingCompleted: false, data: null });
  const brandList = reactive({ loadingCompleted: false, data: null });

  const getSelectionByManualBillingId = (manualBillingId) => {
    selectionEntity.loadingCompleted = false;

    selectionRepository.getSelectionByManualBillingId(manualBillingId)
      .then((response) => {
        selectionEntity.loadingCompleted = true;

        if (response.data.success) {
          selectionEntity.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const getMasterData = (manualBillingId, supplierId) => {
    // Load MasterData
    masterDataEntity.loadingCompleted = false;
    selectionRepository.getMasterData(manualBillingId)
      .then((response) => {
        masterDataEntity.loadingCompleted = true;

        if (response.data.success) {
          masterDataEntity.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });

    // Load MainProducts
    mainProductList.loadingCompleted = false;
    masterdataRepository.getMainProductList(supplierId)
      .then((response) => {
        mainProductList.loadingCompleted = true;
        mainProductList.data = response.data;
      });

    // Load BillingPeriods
    billingPeriodList.loadingCompleted = false;
    masterdataRepository.getBillingPeriodList()
      .then((response) => {
        billingPeriodList.loadingCompleted = true;
        billingPeriodList.data = response.data;
      });

    // Load Brands
    brandList.loadingCompleted = false;
    masterdataRepository.getBrandList(supplierId)
      .then((response) => {
        brandList.loadingCompleted = true;
        brandList.data = response.data;
      });
  };

  const getPreset = (manualBillingId, event) => {
    selectionEntity.loadingCompleted = false;
    const presetId = event.value;
    selectedPresetId.value = presetId;

    selectionRepository.getPreset(manualBillingId, presetId)
      .then((response) => {
        selectionEntity.loadingCompleted = true;

        if (response.data.success) {
          selectionEntity.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const generateDistributionKeys = (manualBillingId, selection) => selectionRepository.generateDistributionKeys(manualBillingId, selection)
    .then((response) => {
      if (response.data.success) {
        store.dispatch('addSuccessNotification', 'Fordelingsnøgler er nu lavet og indsat');
        return Promise.resolve(response.data.value);
      }
      if (response.data.messages.length > 0) {
        store.dispatch('addNotifications', { messageList: response.data.messages });
      }
      return Promise.resolve();
    });

  const updatePreset = (manualBillingId, presetId, selection) => selectionRepository.updatePreset(manualBillingId, presetId, selection)
    .then((response) => {
      if (response.data.success) {
        store.dispatch('addSuccessNotification', 'Preset er nu opdateret');
        return Promise.resolve(response.data);
      }
      if (response.data.messages.length > 0) {
        store.dispatch('addNotifications', { messageList: response.data.messages });
      }
      return Promise.resolve();
    });

  const createPreset = (manualBillingId, selection) => selectionRepository.createPreset(manualBillingId, selection)
    .then((response) => {
      if (response.data.success) {
        const presetId = response.data.value;
        selectedPresetId.value = presetId;
        store.dispatch('addSuccessNotification', 'Preset er nu oprettet');
        return Promise.resolve(response.data.value);
      }
      if (response.data.messages.length > 0) {
        store.dispatch('addNotifications', { messageList: response.data.messages });
      }
      return Promise.resolve();
    });

  const deletePreset = (manualBillingId, presetId) => selectionRepository.deletePreset(manualBillingId, presetId)
    .then((response) => {
      if (response.data.success) {
        store.dispatch('addSuccessNotification', 'Preset er nu slettet');
        return Promise.resolve(response.data);
      }
      if (response.data.messages.length > 0) {
        store.dispatch('addNotifications', { messageList: response.data.messages });
      }
      return Promise.resolve();
    });

  // Extract data from Excel file and return data as array
  const importDistributionFile = async (form) => new Promise((resolve) => {
    const formData = new FormData(form.target);

    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    const selectionList = [];
    let index = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formData.entries()) {
      if (pair[1] instanceof File) {
        const file = pair[1];
        reader.readAsArrayBuffer(file);
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
          const buffer = reader.result;
          wb.xlsx.load(buffer)
            .then((workbook) => {
              workbook.eachSheet((sheet) => {
                sheet.eachRow((row) => {
                  // Exclude header row
                  if (index > 0) {
                    selectionList.push({
                      regNumber: row.values[1],
                      distributionValue: typeof row.values[3] !== 'undefined' ? row.values[3] : null,
                      price: typeof row.values[4] !== 'undefined' ? row.values[4] : null,
                      markupPrice: typeof row.values[5] !== 'undefined' ? row.values[5] : null,
                    });
                  }

                  // eslint-disable-next-line no-plusplus
                  index++;
                });
              });

              // Return result
              resolve(selectionList);
            });
        };
      }
    }
  });

  return {
    getSelectionByManualBillingId,
    selectionEntity,
    getMasterData,
    masterDataEntity,
    getPreset,
    selectedPresetId,
    mainProductList,
    billingPeriodList,
    brandList,
    generateDistributionKeys,
    updatePreset,
    createPreset,
    deletePreset,
    importDistributionFile,
  };
}
