<template>
  <ExternalDataSetList />
</template>

<script>
import ExternalDataSetList from '@/modules/externalDataSet/components/list.vue';

export default {
  name: 'ExternalDataSets',
  components: {
    ExternalDataSetList,
  },
};
</script>
