<template>
  <AccountMappingModule />
</template>

<script>
import AccountMappingModule from '@/modules/accountMapping/components/main.vue';

export default {
  name: 'AccountMapping',
  components: {
    AccountMappingModule,
  },
};
</script>
