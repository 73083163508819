<template>
  <div>
    <DxLoadPanel
      :position="{ of: '#root' }"
      v-model:visible="showLoadingPanel"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="true"
      shading-color="rgba(0,0,0,0.4)"
      message="Henter data..."
    />
  </div>
</template>

<script>
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  name: 'LoadingPanel',
  components: {
    DxLoadPanel,
  },
  computed: {
    showLoadingPanel: {
      get() {
        return this.$store.state.showLoadingPanel;
      },
      set(value) {
        this.$store.dispatch('showLoadingPanel', value);
      },

    },
  },
};
</script>
