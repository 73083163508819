import useBCInvoiceRepository from '@/modules/shared/repositories/bcinvoice.repository.js';
import store from '@/store/index';

export default function useBCInvoice() {
  const invoiceRepository = useBCInvoiceRepository();

  const openPDFFile = (fileContentResult) => {
    // Base64 decode content
    const decodedFileContent = Buffer.from(fileContentResult.fileContents, 'base64');

    const fileURL = window.URL.createObjectURL(new Blob([decodedFileContent], { type: 'application/pdf' }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileContentResult.fileDownloadName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  const getBCInvoiceFile = async (id) => {
    store.dispatch('showLoadingPanel', true);
    const response = await invoiceRepository.getBCInvoiceFile(id);
    store.dispatch('showLoadingPanel', false);

    if (response.data.success) {
      const fileContentResult = response.data.value;
      openPDFFile(fileContentResult);
    } else if (response.data.messages.length > 0) {
      store.dispatch('addNotifications', { messageList: response.data.messages });
    }
  };

  return {
    getBCInvoiceFile,
  };
}
