import moment from 'moment';

export default {
  install: (app) => {
    const stringToDate = (value, format = 'DD.MM.YYYY') => {
      if (value) {
        return moment(String(value)).format(format);
      }
      return '';
    };

    const formatNumber = (value, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
      if (value) {
        return new Intl
          .NumberFormat('da-DK', { minimumFractionDigits, maximumFractionDigits })
          .format(value);
      }
      return '';
    };

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$filters = {
      stringToDate,
      formatNumber,
    };
  },
};
