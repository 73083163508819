import { reactive } from 'vue';
import moment from 'moment';
import useInvoiceRepository from '@/modules/mccClientDataSet/repositories/invoice.repository.js';
import store from '@/store/index';
import useJob from '@/modules/shared/composables/job.composable.js';
import getFormTextByValue from '@/services/form.service.js';

export default function useInvoice(billingSetId, dataGrid) {
  const invoiceRepository = useInvoiceRepository(billingSetId);
  const { startFastJobPolling } = useJob();
  const collectionDatePopup = reactive({
    show: false, defaultDate: null, minimumDate: null, onSaveClickHandler: null,
  });
  const confirmPopupModel = reactive({
    show: false, title: null, content: null, saveButtonText: null, cancelButtonText: null, saveClickHandler: null, data: null,
  });

  const createInvoices = (clientDataSetIdList) => {
    invoiceRepository.createInvoices(clientDataSetIdList)
      .then(() => {
        // Start polling JobList at a fast pace
        startFastJobPolling();

        store.dispatch('addSuccessNotification', 'Job til oprettelse af fakturaer er sat i kø.');
      });
  };

  const InvoicingAction = {
    CREATEINVOICES: 1,
    EXPORTINVOICES: 2,
    EXPORTTOTALCLIENTBILLINGREPORT: 4,
    UPDATECOLLECTIONDATE: 5,
  };

  const invoicingActionList = [
    { key: InvoicingAction.CREATEINVOICES, text: 'Opret fakturaer', description: 'Opret faktureringsdatasæt for alle markerede PI-datasæt' },
    { key: InvoicingAction.EXPORTINVOICES, text: 'Eksportér fakturaer', description: 'Eksportér/hent faktureringsdatasæt for alle markerede PI-datasæt i CSV-format.' },
    { key: InvoicingAction.EXPORTTOTALCLIENTBILLINGREPORT, text: 'Hent totaloversigt', description: 'Hent oversigt over alle poster i alle PI-datasæt i CSV-format.' },
  ];

  const saveCollectionDate = async (form) => {
    let date = getFormTextByValue(form, 'collectionDate');
    date = new Date(date).toISOString();
    const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();

    // remove pi's with no client data set
    const clientDataSetIdList = selectedRowsData
      .filter((x) => x.id != null)
      .map((x) => x.id);

    const response = await invoiceRepository.updateCollectionDate(clientDataSetIdList, date);
    return response;
  };

  const saveCollectionDateFromConfirmPopup = () => {
    const form = confirmPopupModel.data;

    saveCollectionDate(form)
      .then((response) => {
        if (response.data.success) {
          confirmPopupModel.show = false;
          collectionDatePopup.show = false;

          // Reload datagrid
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'Opkrævningsdato er blevet gemt.');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const showCollectionDateConfirmPopup = (form) => {
    confirmPopupModel.show = true;
    confirmPopupModel.title = 'Er du sikker?';
    confirmPopupModel.content = 'Den valgte dato er mere end 7 dage fra den oprindelige dato. <br/ >Vil du fortsætte med at gemme?';
    confirmPopupModel.saveButtonText = 'OK';
    confirmPopupModel.cancelButtonText = 'Annuller';
    confirmPopupModel.saveClickHandler = saveCollectionDateFromConfirmPopup;
    confirmPopupModel.data = form;
  };

  const openFile = (fileContentResult) => {
    // Base64 decode content
    const decodedFileContent = Buffer.from(fileContentResult.fileContents, 'base64');

    const fileURL = window.URL.createObjectURL(new Blob([decodedFileContent], { type: 'text/csv' }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileContentResult.fileDownloadName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  const getTotalClientBillingReport = (billingSetName) => {
    store.dispatch('showLoadingPanel', true);
    invoiceRepository.getTotalClientBillingReport()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        if (response.data.success) {
          const { fileContents } = response.data.value;

          // Base64 decode content
          const decodedFileContent = Buffer.from(fileContents, 'base64');

          const blob = new Blob([decodedFileContent], { type: 'text/csv' });
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          const date = new Date();
          // date format yyyyMMddhhmmss
          const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
          // The filename should be "Totaloversigt_{BillingSetName}_{DateTimestamp}.cvs"
          link.download = `Totaloversigt_${billingSetName.replace(/\s/g, '_')}_${dateString}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const getInvoices = async (clientDataSetIdList) => {
    store.dispatch('showLoadingPanel', true);
    const response = await invoiceRepository.getInvoices(clientDataSetIdList);
    store.dispatch('showLoadingPanel', false);

    if (response.data.success) {
      const fileContentResult = response.data.value;
      openFile(fileContentResult);
    } else if (response.data.messages.length > 0) {
      store.dispatch('addNotifications', { messageList: response.data.messages });
    }
  };

  const onSaveCollectionDateAndExportInvoices = (form) => {
    saveCollectionDate(form)
      .then((response) => {
        if (response.data.success) {
          const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();
          const clientDataSetIdList = selectedRowsData
            .filter((x) => x.id != null) // remove pi's with no client data set
            .map((x) => x.id);

          getInvoices(clientDataSetIdList)
            .then(() => {
              collectionDatePopup.show = false;

              // Reload datagrid
              dataGrid.value.instance.refresh();

              store.dispatch('addSuccessNotification', 'Opkrævningsdato er blevet gemt.');
            });
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onSaveCollectionDate = (form) => {
    const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();
    const collectionDateList = selectedRowsData
      .filter((x) => x.id != null) // remove pi's with no client data set
      .map((x) => x.chargingDate);
    let earliestDate = collectionDateList.reduce((previous, current) => (Date.parse(previous) > Date.parse(current) ? current : previous));

    let newDate = getFormTextByValue(form, 'collectionDate');

    earliestDate = moment(earliestDate, 'YYYY-MM-DD');
    newDate = moment(newDate, 'YYYY-MM-DD');
    const difference = newDate.diff(earliestDate, 'days');

    if (difference > 7 || difference < -7) {
      showCollectionDateConfirmPopup(form);
      return;
    }

    saveCollectionDate(form)
      .then((response) => {
        if (response.data.success) {
          collectionDatePopup.show = false;

          // Reload datagrid
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'Opkrævningsdato er blevet gemt.');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onInvoicingClick = (billingSetName, e) => {
    switch (e.itemData.key) {
      case InvoicingAction.CREATEINVOICES: {
        // Create invoices
        const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();

        // If id (clientDataSetId) is null, it means that a ClientDataSet has not been created yet for the PI
        // Therefore it is not be possible to select it.
        const clientDataSetIdList = selectedRowsData
          .filter((x) => x.id != null)
          .map((x) => x.id);

        if (clientDataSetIdList.length === 0) {
          store.dispatch('addInfoNotification', 'Du skal vælge minimum ét datasæt.');
          return;
        }

        createInvoices(clientDataSetIdList);
        break;
      }
      case InvoicingAction.EXPORTINVOICES: {
        // Export invoices
        let selectedRowsData = dataGrid.value.instance.getSelectedRowsData();

        // If id (clientDataSetId) is null, it means that a ClientDataSet has not been created yet for the PI
        // Therefore it is not be possible to select it.
        selectedRowsData = selectedRowsData
          .filter((x) => x.id != null);

        if (selectedRowsData.length === 0) {
          store.dispatch('addInfoNotification', 'Du skal vælge minimum ét datasæt.');
          return;
        }

        collectionDatePopup.defaultDate = new Date();
        collectionDatePopup.show = true;
        collectionDatePopup.onSaveClickHandler = onSaveCollectionDateAndExportInvoices;
        break;
      }
      case InvoicingAction.EXPORTTOTALCLIENTBILLINGREPORT: {
        // Export totaloversigt
        getTotalClientBillingReport(billingSetName);
        break;
      }
      case InvoicingAction.UPDATECOLLECTIONDATE: {
        let selectedRowsData = dataGrid.value.instance.getSelectedRowsData();

        // Remove pi's with no client data set
        selectedRowsData = selectedRowsData
          .filter((x) => x.id != null);

        if (selectedRowsData.length === 0) {
          store.dispatch('addInfoNotification', 'Du skal vælge minimum ét datasæt.');
          return;
        }

        const collectionDateList = selectedRowsData.map((x) => x.chargingDate);
        const earliestDate = collectionDateList.reduce((previous, current) => (Date.parse(previous) > Date.parse(current) ? current : previous));

        collectionDatePopup.defaultDate = earliestDate == null ? new Date() : new Date(earliestDate);
        collectionDatePopup.show = true;
        collectionDatePopup.onSaveClickHandler = onSaveCollectionDate;
        break;
      }
      default:
        break;
    }
  };

  return {
    onInvoicingClick,
    invoicingActionList,
    collectionDatePopup,
    confirmPopupModel,
  };
}
