<template>
  <BillingSetTypesModule />
</template>

<script>
import BillingSetTypesModule from '@/modules/masterData/billingSetTypes/components/main.vue';

export default {
  name: 'MasterDataBillingSetTypes',
  components: {
    BillingSetTypesModule,
  },
};
</script>
