<template>
  <div class="volumebased-price-form-container">
    <div class="row">
      <h3>Pristrappe</h3>
      <dx-data-grid
        :data-source="datasource"
        @rowPrepared="onRowPrepared">
        <dx-editing
          :allow-updating="!pricePeriodActive"
          :allow-deleting="!pricePeriodActive"
          :allow-adding="!pricePeriodActive"
          mode="row" />

        <dx-column
          data-field="minQuantity"
          caption="Min. antal"
          :editor-options="editorMinQuantity"
          :hiding-priority="4"
          sort-order="asc"
          :format="NumberFormatting.number">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="maxQuantity"
          caption="Maks. antal"
          :editor-options="editorMaxQuantity"
          :hiding-priority="3"
          :format="NumberFormatting.number">
        </dx-column>

        <dx-column
          data-field="basePrice"
          caption="Basis stykpris"
          :editor-options="editorBaseprice"
          :hiding-priority="2"
          :format="NumberFormatting.kronerWithFourDecimals">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="markup"
          caption="Markup"
          :editor-options="editorMarkup"
          :hiding-priority="1"
          :format="NumberFormatting.kronerWithFourDecimals">
          <dx-required-rule />
        </dx-column>
      </dx-data-grid>
    </div>

    <div class="button-row row">
      <dx-button text="Luk" @click="handleClose" class="button" />
    </div>
  </div>
</template>

<script>
import useVolumebasedPriceDatagrid from '@/modules/productPriceGroups/composables/volumebasedPrice.datagrid.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'VolumeBasedPriceForm',
  props: {
    pricePeriodId: Number,
    pricePeriodActive: Boolean,
    productPriceGroupId: Number,
  },
  setup(props, context) {
    const {
      datasource,
    } = useVolumebasedPriceDatagrid(props.pricePeriodId, props.productPriceGroupId);

    const handleClose = () => {
      context.emit('cancel');
    };

    const onRowPrepared = (e) => {
      if (e.rowType === 'data' && e.data.warningLight) {
        e.rowElement.style.backgroundColor = '#FAE0DE';
      }
    };

    const editorMinQuantity = {
      format: NumberFormatting.number,
    };

    const editorMaxQuantity = {
      format: NumberFormatting.number,
    };

    const editorBaseprice = {
      format: NumberFormatting.kronerWithFourDecimals,
    };

    const editorMarkup = {
      format: NumberFormatting.kronerWithFourDecimals,
    };

    return {
      datasource,
      handleClose,
      editorBaseprice,
      editorMarkup,
      editorMinQuantity,
      editorMaxQuantity,
      onRowPrepared,
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss">
.volumebased-price-form-container {
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0px;
  }

  .dx-gridbase-container {
    padding: 0px;
  }

  .dx-datagrid,
  .dx-toolbar {
    background-color: transparent;
  }

  .row {
    padding-bottom: 15px;
  }

  .button {
    width: 100px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;

  }
}
</style>
