<template>
  <UserModule />
</template>

<script>
import UserModule from '@/modules/masterData/users/components/main.vue';

export default {
  name: 'MasterDataUsers',
  components: {
    UserModule,
  },
};
</script>
