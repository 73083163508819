<template>
  <ManualBillingForm />
</template>

<script>
import ManualBillingForm from '@/modules/manualBilling/components/form/form-container.vue';

export default {
  name: 'ManualBilling',
  components: {
    ManualBillingForm,
  },
};
</script>
