<template>
  <MCCBillingSetList />
</template>

<script>
import MCCBillingSetList from '@/modules/mccBillingSet/components/billingset-list.vue';

export default {
  name: 'MCCBillingSet',
  components: {
    MCCBillingSetList,
  },
};
</script>
