import axios from 'axios';
import { inject } from 'vue';
import LogLevels from '@/enums/log-levels.js';
import useUserManager from '@/services/userManager.service.js';

export default {
  install: (app) => {
    const axiosInstance = axios.create({
      timeout: 1000,
    });

    axiosInstance.interceptors.request.use(
      async (config) => {
        const headers = {
          'Content-Type': 'application/json',
        };

        const { getUser } = useUserManager();
        const user = await getUser();
        const accesstoken = user.access_token;

        if (accesstoken != null) {
          headers.Authorization = `Bearer ${accesstoken}`;
        }

        // eslint-disable-next-line no-param-reassign
        config.headers = headers;

        return config;
      },
      (error) => Promise.reject(error),
    );

    function log(logEvent) {
      const payload = {
        logEvent: {
          logLevel: logEvent.logLevel,
          application: 'bokis.administration.web',
          message: logEvent.message,
          exception: JSON.stringify(logEvent.exception),
        },
      };

      const appsettings = inject('appsettings');

      axiosInstance.post(`${appsettings.LOGGING_API}/api/v1/logging/logevent`, payload);
    }

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$log = {
      error(message, exception) {
        log({ logLevel: LogLevels.ERROR, message, exception });
      },
      warning(message) {
        log({ logLevel: LogLevels.WARNING, message });
      },
      info(message) {
        log({ logLevel: LogLevels.INFORMATION, message });
      },
      debug(message) {
        log({ logLevel: LogLevels.DEBUG, message });
      },
    };
  },
};
