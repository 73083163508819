import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useSelectionRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const getSelectionByManualBillingId = (manualBillingId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingId}/selection`);

  const getMasterData = (manualBillingId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingId}/selection/masterdata`);

  const getPreset = (manualBillingId, presetId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingId}/selection/preset/${presetId}`);

  const generateDistributionKeys = (manualBillingId, selection) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingId}/selection/generatedistributionkeys`, JSON.stringify(selection));

  const createPreset = (manualBillingId, selection) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingId}/selection/preset`, JSON.stringify(selection));

  const updatePreset = (manualBillingId, presetId, selection) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingId}/selection/preset/${presetId}`, JSON.stringify(selection));

  const deletePreset = (manualBillingId, presetId) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingId}/selection/preset/${presetId}`);

  return {
    getSelectionByManualBillingId,
    getMasterData,
    getPreset,
    generateDistributionKeys,
    createPreset,
    updatePreset,
    deletePreset,
  };
}
