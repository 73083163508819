<template>
  <div class="externaldataset-detail-view">
    <div>
      <dx-responsive-box>
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-row />
        <dx-col />
        <dx-col />
        <dx-col screen="md lg" />

        <dx-item>
          <dx-location :row="0" :col="0" />
          <div class="detail-element-container padding-right padding-bottom">
            Filer:
            <div v-for="file in row.data.fileList" :key="file.id">
              <a href="#" @click.prevent="onDownloadFileClick(file)">{{ file.name }} </a>
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="1" />
          <dx-location screen="xs sm" :row="1" :col="0" />
          <div class="detail-element-container">
            Dato for indlæsning:
            <dx-date-box
              :value="row.data.createdDate"
              :read-only="true"
              class="textbox-readonly" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="0" />
          <dx-location screen="xs sm" :row="2" :col="0" />
          <div class="detail-element-container padding-right">
            Nets total:
            <dx-number-box
              :value="row.data.totalPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithEightDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="1" :col="1" />
          <dx-location screen="xs sm" :row="3" :col="0" />
          <div class="detail-element-container">
            Beregnet total:
            <dx-number-box
              :value="row.data.totalCalculatedPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithEightDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="0" />
          <dx-location screen="xs sm" :row="4" :col="0" />
          <div class="detail-element-container padding-right">
            Difference:
            <dx-number-box
              :value="row.data.expectedTotal - row.data.totalCalculatedPrice"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithEightDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="2" :col="1" />
          <dx-location screen="xs sm" :row="5" :col="0" />
          <div class="detail-element-container">
            Forventet total:
            <dx-number-box
              :value="row.data.expectedTotal"
              :read-only="true"
              class="textbox-readonly"
              :format="NumberFormatting.kronerWithEightDecimals" />
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="3" :col="0" />
          <dx-location screen="xs sm" :row="6" :col="0" />
          <div class="detail-element-container">
            Manuelle håndteringer:
            <div v-if="(row.data.numberOfProcessedManualEntries + row.data.numberOfUnprocessedManualEntries) === 0">
              Ingen
            </div>
            <div v-else>
              <a @click="$emit('onManualHandlingClick', row.data.id)">{{ row.data.numberOfProcessedManualEntries }}/{{ row.data.numberOfProcessedManualEntries + row.data.numberOfUnprocessedManualEntries }} er håndteret.</a>
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="3" :col="1" />
          <dx-location screen="xs sm" :row="7" :col="0" />
          <div class="detail-element-container">
            Status:
            <div v-if="row.data.processed">
              <div v-if="row.data.status == ExternalDataSetStatus.INFO">
                <i class="dx-icon-info margin-right" />Totalbeløbet afviger fra tidligere perioder.
              </div>
              <div v-else-if="row.data.status == ExternalDataSetStatus.WARNING">
                <i class="dx-icon-warning margin-right" />Totalbeløbet afviger betragteligt fra tidligere perioder.
              </div>

              <div v-if="row.data.totalDifference != 0">
                <i class="dx-icon-info nets-diff-color margin-right" />Nets total svarer ikke til den forventede total (difference {{formatService.formatNumber(row.data.totalDifference, 2, 2)}} kr).
              </div>
              <div v-else>
                <i class="dx-icon-info nets-no-diff-color margin-right" />Nets total svarer til den forventede total.
              </div>
            </div>
            <div v-else>
              <i class="dx-icon-runner margin-right" />Datasæt er igang med at blive behandlet.
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="4" :col="0" />
          <dx-location screen="xs sm" :row="8" :col="0" />
          <div class="detail-element-container padding-bottom">
            Advarsler:
            <div v-if="numberOfWarnings > 0">
              <a @click="showWarnings(row.data.warningList)">Der er {{ numberOfWarnings }}  {{ numberOfWarnings === 1 ? 'advarsel' : 'advarsler' }}</a>
            </div>
            <div v-else>
              Ingen
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="5" :col="0" />
          <dx-location screen="xs sm" :row="9" :col="0" />
          <div v-if="row.data.cancelled"
            class="detail-element-container padding-right red-text">
            Datasættet er annulleret.
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="6" :col="0" :colspan="2" />
          <dx-location screen="xs sm" :row="10" :col="0" />
          <div>
            <div class="header">
              Afvigelser
            </div>
            <div class="padding-bottom">
              Afvigelser mellem pris fra leverandør og pris fra prisliste. Prisafvigelsen er summeret pr. varenummer.
            </div>
            <div>
              <DeviationList :dataSource="deviationDataSource" />
            </div>
          </div>
        </dx-item>

        <dx-item>
          <dx-location :row="0" :col="2" :rowspan="6" />
          <dx-location screen="xs sm" :row="11" :col="0" />
          <div>
            <div class="action-button-wrapper">
              <dx-button icon="close" text="Annuller" @click="$emit('onAnnulClick', row)" :visible="!row.data.cancelled" class="action-button-large" />
            </div>
            <div class="action-button-wrapper">
              <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button-large" />
            </div>
          </div>
        </dx-item>
      </dx-responsive-box>
    </div>
    <Popup
      :visible="showWarningsPopup"
      title="Advarsler"
      :content="warningsPopupContent"
      cancelButtonText="Luk"
      :showSaveButton="false"
      :showCancelButton="true"
      :showCloseButton="false"
      :dragEnabled="true"
      :width="600"
      :height="400"
      @onCancel="showWarningsPopup = false"
    />
  </div>
</template>

<script>
import { inject } from 'vue';
import NumberFormatting from '@/constants/number.formatting.js';
import ExternalDataSetStatus from '@/enums/externaldataset-status.js';
import Popup from '@/components/common/popup.vue';
import useWarnings from '@/modules/shared/composables/warning.composable.js';
import DeviationList from '@/modules/externalDataSet/components/deviation-list.vue';
import useDeviation from '@/modules/externalDataSet/composables/deviation.composable.js';
import useFileExport from '@/modules/externalDataSet/composables/fileexport.composable.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    billingSetId: String,
  },
  components: {
    Popup,
    DeviationList,
  },
  emits: ['onManualHandlingClick', 'onAnnulClick', 'onDeleteClick'],
  setup(props) {
    const formatService = inject('formatService');

    const {
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      getNumberOfWarnings,
    } = useWarnings();

    const numberOfWarnings = getNumberOfWarnings(props.row.data.warningList);

    const {
      deviationDataSource,
    } = useDeviation(props.billingSetId, props.row.data.id);

    const {
      onDownloadFileClick,
    } = useFileExport();

    return {
      NumberFormatting,
      ExternalDataSetStatus,
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      numberOfWarnings,
      deviationDataSource,
      formatService,
      onDownloadFileClick,
    };
  },
};
</script>

<style lang="scss">
.externaldataset-detail-view {
  .header {
    font-size: 18px;
    font-weight: 500;
  }

  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }

    .action-button-large {
      margin-bottom: 5px;
      width: 170px;
    }
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-style: dashed;
    border-color: #ddd;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon,
  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 20px;
  }

  .padding-bottom {
    padding-bottom: 26px;
  }

  .publish-date-field-root {
    display: flex;

    .padding-right {
      padding-right: 20px;
    }
  }

  .red-text {
    font-weight:700;
    color: red;
    font-size: 18px;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
  }

  .nets-diff-color {
    color: #DDBB00;
  }

  .nets-no-diff-color {
    color: #00BB00;
  }

  .margin-right {
    margin-right: 5px;
  }
}
</style>
