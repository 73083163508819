import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useClientDataSetRepository(billingSetId) {
  const appsettings = store.state.appSettings;

  const loadStatusSummary = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/clientdatasets/statussummary`);

  const create = () => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/createclientdataset`);

  return {
    loadStatusSummary,
    create,
  };
}
