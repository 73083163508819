<template>
  <div class="crm-form-container">
    <form @submit="handleSubmit($event)">
      <div class="row">
        <h4>CRM parametre</h4>
      </div>
      <div class="row">
        <div v-for="crmParameter in crmParameterList" :key="crmParameter.index" class="row crm-parameter-list-container">
          <div class="name row">
            {{ crmParameter.name }}:
          </div>
          <div v-if="crmParameter.crpDataType === CRMDataType.CHOICELIST" class="filter row">
          <dx-select-box
            :data-source="crmParameter.choiceListDropDown"
            display-expr="name"
            value-expr="id"
            :hint="crmParameter.crpDescription"
            :value="crmParameter.choiceListSelectedId"
            @value-changed="crmParameter.choiceListSelectedId = $event.value" />
          </div>
          <div v-if="crmParameter.crpDataType === CRMDataType.BOOLEAN" class="filter row boolean-type">
          <dx-checkbox
            :value="convertToBooleanValue(crmParameter.cpvValueNumber)"
            @value-changed="crmParameter.cpvValueNumber = reverseBooleanValue($event.value)"
            :hint="crmParameter.crpDescription" />
          </div>
        </div>
      </div>
      <div class="button-row row">
        <dx-button text="Gem" :use-submit-behavior="true" class="button margin-right" />
        <dx-button text="Annuller" @click="handleCancel" class="button" />
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import CRMDataType from '@/enums/crm-datatype.js';

export default {
  name: 'CRMForm',
  props: {
    row: Object,
  },
  setup(props, context) {
    const crmParameterList = ref([]);
    crmParameterList.value = cloneDeep(props.row.data.crmValues);

    const handleSubmit = (e) => {
      context.emit('save', props.row.data.id, crmParameterList.value);
      e.preventDefault();
    };

    const handleCancel = () => {
      context.emit('cancel');
    };

    const convertToBooleanValue = (cpvValueNumber) => {
      // Convert -1, 0, 1 to Boolean
      if (cpvValueNumber < 0) {
        return null;
      }
      return Boolean(cpvValueNumber);
    };

    const reverseBooleanValue = (cpvValueNumber) => {
      // Convert Boolean to -1, 0, 1
      if (cpvValueNumber === null) {
        return -1;
      } if (cpvValueNumber === true) {
        return 1;
      }
      return 0;
    };

    return {
      CRMDataType,
      crmParameterList,
      handleSubmit,
      handleCancel,
      convertToBooleanValue,
      reverseBooleanValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.crm-form-container {
  display: flex;
  flex-direction: column;

  .row {
    padding-bottom: 15px;
  }

  .date-item {
    flex: 1 0 auto;
  }

  .padding-right {
    padding-right: 15px;
  }

  .date-row {
    display: flex;
    flex-direction: row;
  }

  .button {
    width: 100px;
  }

  .margin-right {
    margin-right: 15px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;
  }

  h4 {
    margin: 0;
  }
}
</style>
