export default [
  {
    text: 'Fakturering',
    icon: 'refresh',
    items: [
      {
        text: 'Fakturering',
        path: '/koersler',
      },
      {
        text: 'MC Connect',
        path: '/mcckoersler',
      },
    ],
  },
  {
    text: 'Statistik',
    icon: 'refresh',
    items: [
      {
        text: 'DK Vederlag',
        path: '/dkvkoersler',
      },
      {
        text: 'X-Pays',
        path: '/xpkoersler',
      },
    ],
  },
  {
    text: 'Priser',
    path: '/prisperioder',
    icon: 'money',
  },
  {
    text: 'PII kontomapning',
    path: '/piikontomapping',
    icon: 'fieldchooser',
  },
  {
    text: 'Stamdata',
    icon: 'preferences',
    items: [
      {
        text: 'Brands',
        path: '/stamdata/brands',
      },
      {
        text: 'Brugere',
        path: '/stamdata/brugere',
      },
      {
        text: 'Card product mapning',
        path: '/stamdata/card-product-mapping',
      },
      {
        text: 'Faktureringsfrekvenser',
        path: '/stamdata/faktureringsfrekvenser',
      },
      {
        text: 'Faktureringsperioder',
        path: '/stamdata/faktureringsperioder',
      },
      {
        text: 'Fordelingsnøgler',
        path: '/stamdata/fordelingsnoegler',
      },
      {
        text: 'Kørselstyper',
        path: '/stamdata/koerselstyper',
      },
      {
        text: 'Pengeinstitutter',
        path: '/stamdata/pengeinstitutter',
      },
      {
        text: 'Suppliers',
        path: '/stamdata/suppliers',
      },
      {
        text: 'Varer',
        path: '/stamdata/varer',
      },
    ],
  },
  {
    text: 'BOKISbi/pii',
    icon: 'fields',
    items: [
      {
        text: 'Graftyper',
        path: '/stamdata/bi-graftyper',
      },
      {
        text: 'Brandgrupper',
        path: '/stamdata/brandgrupper',
      },
      {
        text: 'Pengeinstitutgrupper',
        path: '/stamdata/pengeinstitutgrupper',
      },
      {
        text: 'Kontaktpersoner',
        path: '/stamdata/kontaktpersoner',
      },
      {
        text: 'Infoområder',
        path: '/stamdata/info-omraader',
      },
      {
        text: 'PII tekster',
        path: '/stamdata/piitekst',
      },
      {
        text: 'BI tekster',
        path: '/stamdata/bitekst',
      },
      {
        text: 'Bilag',
        path: '/stamdata/bilag',
      },
      {
        text: 'Graf Tooltips',
        path: '/stamdata/graf-tooltips',
      },
    ],
  },
  {
    text: 'Overførsler',
    icon: 'refresh',
    items: [
      {
        text: 'BI Publicering',
        path: '/bipubliserdata',
      },
      {
        text: 'BC Importer data',
        path: '/bcimporterdata',
      },
    ],
  },

];
