<template>
  <div class="password-form-container">
    <form @submit="handleSubmit($event)">
      <div class="row">
        Skift adgangskode.
      </div>

      <div class="row">
        Nuværende adgangskode:
        <dx-textbox :value="modelValue.password" mode="password" valueChangeEvent="input" @value-changed="(e) => update('password', e.value)">
          <dx-validator>
            <dx-required-rule message="Nuværende adgangskode skal udfyldes" />
          </dx-validator>
        </dx-textbox>
      </div>

      <div class="row">
        Ny adgangskode:
        <dx-textbox :value="modelValue.newPassword" mode="password" valueChangeEvent="input" @value-changed="(e) => update('newPassword', e.value)">
          <dx-validator>
            <dx-required-rule message="Ny adgangskode skal udfyldes" />
          </dx-validator>
        </dx-textbox>
      </div>

      <div class="row">
        Gentag adgangskode:
        <dx-textbox :value="modelValue.repeatPassword" mode="password" valueChangeEvent="input" @value-changed="(e) => update('repeatPassword', e.value)">
          <dx-validator>
            <dx-required-rule message="Ny adgangskode skal udfyldes" />
          </dx-validator>
        </dx-textbox>
      </div>

      <div class="validation-row row">
        <dx-validation-summary />
      </div>
      <div class="button-row row">
        <dx-button text="Gem" :use-submit-behavior="true" class="button margin-right" />
        <dx-button text="Annuller" @click="handleCancel" class="button" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'PasswordForm',
  props: {
    modelValue: Object,
  },
  setup(props, context) {
    const update = (key, value) => {
      context.emit('update:modelValue', { ...props.modelValue, [key]: value });
    };

    const handleSubmit = (e) => {
      context.emit('save');
      e.preventDefault();
    };

    const handleCancel = () => {
      context.emit('cancel');
    };

    return {
      update,
      handleSubmit,
      handleCancel,
    };
  },
};
</script>

<style lang="scss" scoped>
.password-form-container {
  display: flex;
  flex-direction: column;

  .row {
    padding-bottom: 15px;
  }

  .date-item {
    flex: 1 0 auto;
  }

  .padding-right {
    padding-right: 15px;
  }

  .date-row {
    display: flex;
    flex-direction: row;
  }

  .button {
    width: 100px;
  }

  .margin-right {
    margin-right: 15px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;

  }
}
</style>
