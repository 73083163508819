<template>
  <div class="distribution-form-root">
    <div class="flex-container">
      <div class="flex-item">
        <dx-button icon="chart" text="Fordel beløb jf. fordelingsnøgle" @click="onDistributePriceClick(modelValue.totalPrice, modelValue.totalMarkupPrice)" />
        <span class="import-link-container">
          <a @click="showUploadFilePopup = !showUploadFilePopup">Importér fordeling</a>
        </span>
      </div>
      <div class="flex-item flex-container flex-align-end align-items-center">
        <div class="total-price-element" :class="{ 'warning-color': $filters.formatNumber(totalPrice, 2, 4) !== $filters.formatNumber(modelValue.totalPrice, 2, 4)  }">
          Total beløb: {{ $filters.formatNumber(totalPrice, 2, 4) }} kr.
        </div>
        <div class="total-price-element" :class="{ 'warning-color': $filters.formatNumber(totalMarkupPrice, 2, 4) !== $filters.formatNumber(modelValue.totalMarkupPrice, 2, 4)  }">
          Total markup: {{ $filters.formatNumber(totalMarkupPrice, 2, 4) }} kr.
        </div>
      </div>
    </div>
    <dx-data-grid
      :data-source="dataSource"
      key-expr="cliNo"
      :selected-row-keys="selectedItemKeys"
      @selection-changed="selectionChanged">

      <dx-paging :enabled="false" />
      <dx-selection
        mode="multiple"
        show-check-boxes-mode="always" />
      <dx-export :enabled="true" :allow-export-selected-data="true" fileName="Fordeling" :texts="{ exportTo: 'Eksportér fordeling' }" />

      <dx-editing
        :allow-updating="true"
        mode="cell"
        :select-text-on-edit-start="true"
        start-edit-action="click" />

      <dx-column
        data-field="cliRegistrationNumber"
        caption="Regnr."
        :allow-editing="false"
        :width="100"
        :hiding-priority="8" />

      <dx-column
        data-field="nameOfClient"
        caption="Pengeinstitut"
        :allow-editing="false"
        :hiding-priority="7" />

      <dx-column
        data-field="disValue"
        caption="Fordelingsnøgle"
        data-type="number"
        :allow-editing="false"
        :format="NumberFormatting.decimal"
        :hiding-priority="6"
        :width="150"/>

      <dx-column
        data-field="totalBasePrice"
        caption="Beløb"
        data-type="number"
        :format="NumberFormatting.kronerWithFourDecimals"
        :editor-options="{ format: NumberFormatting.kronerWithFourDecimals }"
        :hiding-priority="5"
        :width="150" />

      <dx-column
        data-field="totalMarkupPrice"
        caption="Markup"
        data-type="number"
        :format="NumberFormatting.kronerWithFourDecimals"
        :editor-options="{ format: NumberFormatting.kronerWithFourDecimals }"
        :hiding-priority="4"
        :width="150" />

    </dx-data-grid>
    <FileUploadPopup v-if="showUploadFilePopup"
      title="Importer selektering"
      :visible="showUploadFilePopup"
      saveButtonText="Importér"
      :height="400"
      @save="onImportFile"
      @cancel="showUploadFilePopup = false"
      :allowedFileExtensions="['.xlsx']" />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { computed, ref } from 'vue';
import NumberFormatting from '@/constants/number.formatting.js';
import FileUploadPopup from '@/components/common/file-upload-popup.vue';

export default {
  name: 'DistributionForm',
  props: {
    modelValue: Object,
  },
  components: {
    FileUploadPopup,
  },
  emits: ['update:modelValue', 'importFile'],
  setup(props, context) {
    const dataSource = computed(() => props.modelValue.manualBillingEntryDistributionList);
    const selectedItemKeys = computed(() => dataSource.value.filter((x) => x.selected).map((y) => y.cliNo));
    const totalPrice = computed(() => dataSource.value.reduce((accumulator, curr) => accumulator + curr.totalBasePrice, 0));
    const totalMarkupPrice = computed(() => dataSource.value.reduce((accumulator, curr) => accumulator + curr.totalMarkupPrice, 0));
    const showUploadFilePopup = ref(false);

    const selectionChanged = (data) => {
      // Deselect clients
      data.currentDeselectedRowKeys.forEach((cliNo) => {
        dataSource.value.find((x) => x.cliNo === cliNo).selected = false;
      });

      // Select clients
      data.currentSelectedRowKeys.forEach((cliNo) => {
        dataSource.value.find((x) => x.cliNo === cliNo).selected = true;
      });

      // Push changes to parent component
      context.emit('update:modelValue', { ...props.modelValue, manualBillingEntryDistributionList: dataSource.value });
    };

    // eslint-disable-next-line no-shadow
    const onDistributePriceClick = (totalPrice, totalMarkupPrice) => {
      const distributionKeyList = [];

      // Has Client list any distribution keys
      const disValueExist = dataSource.value.some((x) => x.disValue !== null);

      let totalDistributionValue;

      // First build list of distribution keys
      if (disValueExist) {
        dataSource.value.forEach((item) => {
          distributionKeyList.push({ cliNo: item.cliNo, distributionValue: item.disValue });
        });

        totalDistributionValue = dataSource.value.filter((x) => x.selected).reduce((total, current) => total + current.disValue, 0);
      } else {
        dataSource.value.forEach((item) => {
          distributionKeyList.push({ cliNo: item.cliNo, distributionValue: 1 });
        });

        totalDistributionValue = dataSource.value.filter((x) => x.selected).length;
      }

      // eslint-disable-next-line no-shadow
      const calculateAndFormatDistributionPrice = (price, distributionValue, totalDistributionValue) => {
        const distributionPrice = (price * distributionValue) / totalDistributionValue;
        return parseFloat(distributionPrice.toFixed(4));
      };

      const fixRoundingDifference = () => {
        const calculatedTotalBasePrice = dataSource.value.reduce((accumulator, current) => accumulator + current.totalBasePrice, 0);
        const calculatedTotalMarkupPrice = dataSource.value.reduce((accumulator, current) => accumulator + current.totalMarkupPrice, 0);

        // Check TotalBasePrice difference
        if (calculatedTotalBasePrice !== props.modelValue.totalPrice) {
          // Get difference between totalPrice and the calculated totalPrice
          const difference = parseFloat((props.modelValue.totalPrice - calculatedTotalBasePrice).toFixed(4));

          // Find the first client
          const firstClient = dataSource.value.find((client) => client.selected);

          // Add rounding difference to the first client.
          firstClient.totalBasePrice += difference;
        }

        // Check TotalMarkupPrice difference
        if (calculatedTotalMarkupPrice !== props.modelValue.totalMarkupPrice) {
          // Get difference between totalMarkupPrice and the calculated totalMarkupPrice
          const difference = parseFloat((props.modelValue.totalMarkupPrice - calculatedTotalMarkupPrice).toFixed(4));

          // Find the first client
          const firstClient = dataSource.value.find((client) => client.selected);

          // Add rounding difference to the first client.
          firstClient.totalMarkupPrice += difference;
        }
      };

      // Then calculate prices based on distribution key
      dataSource.value.forEach((client) => {
        // Get distribution key for client
        const { distributionValue } = distributionKeyList.find((x) => x.cliNo === client.cliNo);

        client.totalBasePrice = client.selected ? calculateAndFormatDistributionPrice(totalPrice, distributionValue, totalDistributionValue) : null;
        client.totalMarkupPrice = client.selected ? calculateAndFormatDistributionPrice(totalMarkupPrice, distributionValue, totalDistributionValue) : null;
      });

      fixRoundingDifference();

      context.emit('update:modelValue', { ...props.modelValue, manualBillingEntryDistributionList: dataSource.value });
    };

    const onImportFile = (form) => {
      showUploadFilePopup.value = false;
      context.emit('importFile', form);
    };

    return {
      dataSource,
      selectedItemKeys,
      selectionChanged,
      NumberFormatting,
      onDistributePriceClick,
      totalPrice,
      totalMarkupPrice,
      showUploadFilePopup,
      onImportFile,
    };
  },
};
</script>

<style lang="scss">
.distribution-form-root {
  padding: 20px;
  background-color: #FFF;

  .flex-container {
    display: flex;

    .flex-item {
      flex-grow: 1;
    }

    .flex-align-end {
      justify-content: flex-end;
    }

    .align-items-center {
      align-items: center;
    }

    .total-price-element {
      text-align: right;
      flex-basis: 200px;
    }

    .margin-left-10 {
      margin-left: 10px;
    }

    .import-link-container {
      padding: 4px 0 0 15px;

      a {
        text-decoration: underline;
      }

      a:hover {
        cursor: pointer;
      }
    }
  }

  .warning-color {
    color: red;
    font-weight: 600;
  }
}

.dx-datagrid-export-menu {
  ul.dx-menu-items-container >
  li.dx-menu-item-wrapper:first-child {
    display: none;
  }
}
</style>
