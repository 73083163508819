<template>
  <div class="brand-group-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Brandgrupper</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="brandGroupDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Brandgrupper" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="brand-group-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="shortName">
              <dx-label text="Kort navn" />
            </dx-simple-item>
            <dx-simple-item data-field="graphTypeId">
              <dx-label text="Graf typen" />
            </dx-simple-item>
            <dx-simple-item data-field="group">
              <dx-label text="Gruppe" />
            </dx-simple-item>
            <dx-simple-item data-field="helpText">
              <dx-label text="Hjælpetekst" />
            </dx-simple-item>
            <dx-simple-item data-field="infoText">
              <dx-label text="Info tekst" />
            </dx-simple-item>
            <dx-simple-item data-field="order">
              <dx-label text="Rækkefølge" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="8">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="shortName"
          caption="Kort navn"
          data-type="string"
          :hiding-priority="7">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="graphTypeId"
          caption="Graf typen"
          :hiding-priority="6">
          <dx-grid-lookup
            :data-source="biGraphList.data"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="group"
          caption="Gruppe"
          :hiding-priority="5">
          <dx-grid-lookup
            :data-source="brandGroupList.data"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="helpText"
          caption="Hjælpetekst"
          data-type="string"
          :visible="false"
          :hiding-priority="4">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="infoText"
          caption="Info tekst"
          data-type="string"
          :visible="false"
          :hiding-priority="3">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="order"
          caption="Rækkefølge"
          data-type="number"
          :hiding-priority="2">
          <dx-required-rule />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDataCompleted" />
          <div v-else>
            <DetailView
              :row="row"
              :biGraphList="biGraphList.data"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import DetailView from '@/modules/masterData/brandGroups/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/brandGroups/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/brandGroups/composables/masterdata.composable.js';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';

export default {
  name: 'MainBrandGroupModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
    ContentLoaderList,
  },
  setup() {
    const {
      datasource,
      getDatasource,
    } = useDatagrid();

    getDatasource();

    const {
      biGraphList,
      brandGroupList,
    } = useMasterdata();

    const loadingDataCompleted = computed(() => biGraphList.loadingCompleted && brandGroupList.loadingCompleted);

    return {
      datasource,
      biGraphList,
      brandGroupList,
      loadingDataCompleted,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne brandgruppe?<br/><b>${e.data.name}</b>`, 'Slet brandgruppe');
    },
  },
};
</script>

<style lang="scss">
.brand-group-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
