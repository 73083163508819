<template>
  <div class="billing-period-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Stamdata</h2></div>
        <div class="subheadline-item">&nbsp; - Faktureringsperioder</div>
      </div>
    </div>

    <div class="content-block">
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="billingPeriodDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @init-new-row="onInitNewRow"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Faktureringsperioder" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="billing-period-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          :confirm-delete="false"
          mode="form">
          <dx-form
            :col-count="1"
            label-location="top"
            :showValidationSummary="true">
            <dx-simple-item data-field="name">
              <dx-label text="Navn" />
            </dx-simple-item>
            <dx-simple-item data-field="startDate">
              <dx-label text="Startdato" />
            </dx-simple-item>
            <dx-simple-item data-field="endDate">
              <dx-label text="Slutdato" />
            </dx-simple-item>
            <dx-simple-item data-field="selectedInvoiceFrequencyList">
              <dx-label text="Faktureringsfrekvens" />
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="7"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="startDate"
          caption="Startdato"
          data-type="date"
          :hiding-priority="6"
          format="dd.MM.yyyy"
          width="25%"
          sort-order="desc">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="endDate"
          caption="Slutdato"
          data-type="date"
          format="dd.MM.yyyy"
          :hiding-priority="5"
          width="25%">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="selectedInvoiceFrequencyList"
          caption="Faktureringsfrekvens"
          :hiding-priority="4"
          edit-cell-template="invoiceFrequencySelector"
          :visible="false"
          data-type="object">
          <dx-grid-lookup
            :data-source="invoiceFrequencyList.data"
            value-expr="id"
            display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :invoiceFrequencyList="invoiceFrequencyList.data"
              :row="row"
              @onEditClick="onEditClick"
              @onDeleteClick="onDeleteClick" />
          </div>
        </template>

        <template #invoiceFrequencySelector="{ data }">
          <div>
            <dx-tag-box
              :items="invoiceFrequencyList.data"
              value-expr="id"
              display-expr="name"
              :show-selection-controls="true"
              :search-enabled="true"
              :value="data.value"
              @value-changed="data.setValue($event.value)" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import DetailView from '@/modules/masterData/billingPeriods/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/billingPeriods/composables/datagrid.composable.js';
import useMasterdata from '@/modules/masterData/billingPeriods/composables/masterdata.composable.js';

export default {
  name: 'MainBillingPeriodModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  setup() {
    const {
      datasource,
      getDatasource,
      onInitNewRow,
    } = useDatagrid();

    const {
      invoiceFrequencyList,
      loadInvoiceFrequencyList,
    } = useMasterdata();

    getDatasource();
    loadInvoiceFrequencyList();

    return {
      datasource,
      invoiceFrequencyList,
      onInitNewRow,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne faktureringsperiode?<br/><b>${e.data.name}</b>`, 'Slet faktureringsperiode');
    },
  },
};
</script>

<style lang="scss">
.billing-period-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
