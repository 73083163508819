<template>
  <div class="externaldataset-summary-list">
    <dx-data-grid
      ref="dataGrid"
      id="externalDataSetSummaryDataGrid"
      :data-source="dataSource"
      :column-auto-width="true"
    >
      <dx-export :enabled="false" fileName="Kørsler" />
      <dx-filter-row :visible="false" />
      <dx-load-panel :enabled="!isLoaded" />
      <dx-header-filter :visible="false" />

      <dx-column
        caption="Filer"
        :hiding-priority="4"
        cell-template="filesTemplate" />

      <dx-column
        caption="Manuelle håndteringer"
        :hiding-priority="3"
        cell-template="manualHandlingTemplate" />

      <dx-column
        caption="Status"
        :hiding-priority="2"
        cell-template="statusTemplate" />

      <dx-column
        caption="Advarsler"
        :hiding-priority="1"
        cell-template="warningsTemplate" />

      <template #filesTemplate="{ data }">
        <div>
          <div v-for="file in data.data.fileList" :key="file.id">
            {{ file.name }}
          </div>
        </div>
      </template>

      <template #manualHandlingTemplate="{ data }">
        <div>
          <div v-if="data.data.processed">
            <div v-if="data.data.numberOfProcessedManualEntries == null || data.data.numberOfUnprocessedManualEntries == null"></div>
            <div v-else-if="(data.data.numberOfProcessedManualEntries + data.data.numberOfUnprocessedManualEntries) === 0">
              Ingen
            </div>
            <div v-else>
              <a @click="$emit('onManualHandlingClick', data.data.id, data.data.billingSetId)">
                {{ data.data.numberOfProcessedManualEntries }}/{{ data.data.numberOfProcessedManualEntries + data.data.numberOfUnprocessedManualEntries }} er håndteret.
              </a>
            </div>
          </div>
        </div>
      </template>

      <template #statusTemplate="{ data }">
        <div>
          <div v-if="data.data.cancelled">
            <i class="dx-icon-clear" title="Datasæt er blevet annulleret" />
          </div>
          <div v-else-if="data.data.processed">
            <i v-if="data.data.status == ExternalDataSetStatus.INFO" class="dx-icon-info" title="Totalbeløbet afviger fra tidligere perioder" />
            <i v-else-if="data.data.status == ExternalDataSetStatus.WARNING" class="dx-icon-warning" title="Totalbeløbet afviger betragteligt fra tidligere perioder" />

            <i v-if="data.data.totalDifference != 0" class="dx-icon-info nets-diff-color" :title="`Nets total svarer ikke til den forventede total (difference ${formatService.formatNumber(data.data.totalDifference, 2, 2)} kr)`" />
            <i v-else class="dx-icon-info nets-no-diff-color" title="Nets total svarer til den forventede total." />
          </div>
          <div v-else>
            <i class="dx-icon-runner" title="Datasæt er igang med at blive behandlet" />
          </div>
        </div>
      </template>

      <template #warningsTemplate="{ data }">
        <div>
          <div v-if="data.data.processed">
            <div v-if="getNumberOfWarnings(data.data.warningList) > 0">
              <a @click="showWarnings(data.data.warningList)">Der er {{ getNumberOfWarnings(data.data.warningList) }}  {{ getNumberOfWarnings(data.data.warningList) === 1 ? 'advarsel' : 'advarsler' }}</a>
            </div>
            <div v-else>
              Ingen
            </div>
          </div>
        </div>
      </template>
    </dx-data-grid>
    <Popup
      :visible="showWarningsPopup"
      title="Advarsler"
      :content="warningsPopupContent"
      cancelButtonText="Luk"
      :showSaveButton="false"
      :showCancelButton="true"
      :showCloseButton="false"
      :dragEnabled="true"
      :width="600"
      :height="400"
      @onCancel="showWarningsPopup = false" />
  </div>
</template>

<script>
import {
  inject, watch, ref, onBeforeUnmount,
} from 'vue';
import ExternalDataSetStatus from '@/enums/externaldataset-status.js';
import useWarnings from '@/modules/shared/composables/warning.composable.js';
import Popup from '@/components/common/popup.vue';

export default {
  name: 'ExternalDataSetList',
  props: {
    dataSource: {
      type: Object,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popup,
  },
  emits: ['onManualHandlingClick', 'stopDataPolling', 'startDataPolling'],
  setup(props, context) {
    const dataGrid = ref();
    const externalDataSetUpdateDateTime = inject('externalDataSetUpdateDateTime');
    const formatService = inject('formatService');

    watch(() => externalDataSetUpdateDateTime.value, () => {
      // Reload datagrid when new file has been uploaded.
      dataGrid.value.instance.refresh();
      context.emit('startDataPolling');
    });

    onBeforeUnmount(() => {
      context.emit('stopDataPolling');
    });

    const {
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      getNumberOfWarnings,
    } = useWarnings();

    return {
      ExternalDataSetStatus,
      dataGrid,
      formatService,
      showWarnings,
      showWarningsPopup,
      warningsPopupContent,
      getNumberOfWarnings,
    };
  },
};
</script>

<style lang="scss">
.externaldataset-summary-list {
  .dx-datagrid {
    background-color: transparent;
    padding: 0px;
  }

  .dx-row:hover {
    cursor: auto;
  }

  .nets-diff-color {
    color: #DDBB00;
  }

  .nets-no-diff-color {
    color: #00BB00;
  }

  .dx-icon-clear {
    color: #FF0000;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
  }
}
</style>
