<template>
  <BCImportDataModule />
</template>

<script>
import BCImportDataModule from '@/modules/dataTransfer/components/bc-import-data.vue';

export default {
  name: 'BCImportData',
  components: {
    BCImportDataModule,
  },
};
</script>
