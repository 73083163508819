import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useClientBillingEntryRepository from '@/modules/clientDataSet/repositories/clientbillingentry.repository.js';

export default function useDatagrid(billingSetId, clientDataSetId) {
  const loadingDatasourceCompleted = ref(false);
  const clientBillingEntryRepository = useClientBillingEntryRepository(billingSetId, clientDataSetId);

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => {
      loadingDatasourceCompleted.value = false;

      return clientBillingEntryRepository.load(loadOptions)
        .then((response) => {
          loadingDatasourceCompleted.value = true;
          return {
            data: response.data.data,
            totalCount: response.data.totalCount,
            summary: response.data.summary,
            groupCount: response.data.groupCount,
          };
        });
    },
  });

  return {
    loadingDatasourceCompleted,
    datasource,
  };
}
