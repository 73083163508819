import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/billingSet/repositories/masterdata.repository.js';

export default function useMasterdata() {
  const billingPeriodList = reactive({ loadingCompleted: false, data: null });
  const supplierList = reactive({ loadingCompleted: false, data: null });
  const billingSetTypeList = reactive({ loadingCompleted: false, data: null });
  const masterdataRepository = useMasterdataRepository();

  masterdataRepository
    .getBillingPeriodList()
    .then((result) => {
      billingPeriodList.loadingCompleted = true;

      // Sort list by EndDate, so newest date is first
      const sortedData = result.data.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
      billingPeriodList.data = sortedData;
    });

  masterdataRepository
    .getSupplierList()
    .then((result) => {
      supplierList.loadingCompleted = true;
      supplierList.data = result.data;
    });

  masterdataRepository
    .getBillingSetTypeList()
    .then((result) => {
      billingSetTypeList.loadingCompleted = true;
      billingSetTypeList.data = result.data;
    });

  return {
    billingPeriodList,
    supplierList,
    billingSetTypeList,
  };
}
