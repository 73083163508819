import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useManualBillingEntryRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const createManualBillingEntryFromExternalEntry = (externalDataFileEntryId) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/createmanualbillingentryfromexternalentry`, { value: externalDataFileEntryId });

  const deleteManualBillingEntry = (manualBillingEntryId) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/manualbillingentries/${manualBillingEntryId}`);

  return {
    deleteManualBillingEntry,
    createManualBillingEntryFromExternalEntry,
  };
}
