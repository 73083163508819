import store from '@/store/index';
import useExternalDataSetRepository from '@/modules/externalDataSet/repositories/externaldataset.repository.js';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useExternalDataSet(billingSetId) {
  const externalDataSetRepository = useExternalDataSetRepository(billingSetId);
  const { startFastJobPolling } = useJob();

  const reCalculate = () => {
    externalDataSetRepository.reCalculate()
      .then((response) => {
        if (response.data.success) {
          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'Job til genberegning er sat igang');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const getBuyReport = () => {
    store.dispatch('showLoadingPanel', true);

    externalDataSetRepository.getBuyReport()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          const fileContentResult = response.data.value;
          // Base64 decode content
          const decodedFileContent = Buffer.from(fileContentResult.fileContents, 'base64');

          const fileURL = window.URL.createObjectURL(new Blob([decodedFileContent], { type: 'text/csv' }));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileContentResult.fileDownloadName);
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const getDifferenceReport = () => {
    store.dispatch('showLoadingPanel', true);

    externalDataSetRepository.getDifferenceReport()
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        const fileContentResult = response.data;
        const fileURL = window.URL.createObjectURL(new Blob([fileContentResult], { type: 'text/csv' }));
        const fileLink = document.createElement('a');

        // TODO: Get filename from api
        const fileName = `Forskelsrapport_${new Date().toLocaleDateString('da').replaceAll('.', '')}.csv`;
        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      });
  };

  const downloadReportActionList = [
    { key: 1, text: 'Hent købsrapport', description: 'Hent oversigt over købte services i CSV-format' },
    { key: 2, text: 'Hent forskelsrapport', description: 'Hent datasæt i CSV-format inkl. beregnede priser og evt. differencer' },
  ];

  const onDownloadReportClick = (e) => {
    if (e.itemData.key === 1) {
      // Hent købsrapport
      getBuyReport();
    } else if (e.itemData.key === 2) {
      // Hent forskelsrapport
      getDifferenceReport();
    }
  };

  return {
    reCalculate,
    onDownloadReportClick,
    downloadReportActionList,
  };
}
