import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useCreditRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const createCreditInvoices = (clientDataSetIdList) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/createcreditinvoices`, { list: clientDataSetIdList });

  const exportCreditInvoices = (clientDataSetIdList) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/creditinvoices/export`, { list: clientDataSetIdList });

  const deleteCreditInvoices = (clientDataSetIdList) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/creditinvoices`, { data: { list: clientDataSetIdList } });

  return {
    createCreditInvoices,
    exportCreditInvoices,
    deleteCreditInvoices,
  };
}
