import { ref } from 'vue';

export default function useWarning() {
  const showWarningsPopup = ref(false);
  const warningsPopupContent = ref();

  const getNumberOfWarnings = (warningList) => {
    if (warningList.some((x) => x.parsingMessages !== '' && x.parsingMessages !== null)) {
      let count = 0;
      warningList.forEach((x) => {
        if (x.parsingMessages != null) {
          // Get number of '-' in parsingMessage string. Each message is separated by '-'
          const numberOfHyphens = (x.parsingMessages.match(/-/g) || []).length;
          count += numberOfHyphens === 0 ? 1 : numberOfHyphens;
        }
      });

      return count;
    }
    return 0;
  };

  const showWarnings = (warningList) => {
    const html = [];

    const numberOfWarnings = getNumberOfWarnings(warningList);

    warningList.forEach((x) => {
      html.push(x.fileName, '<br /><ul>');

      if (numberOfWarnings > 1 && x.parsingMessages !== null) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < x.parsingMessages.length; i++) {
          let currentChar = x.parsingMessages.charAt(i);
          if (currentChar === '-') { currentChar = '<li>'; }
          html.push(currentChar);
        }
      } else {
        html.push('<li>', x.parsingMessages);
      }

      html.push('</ul>');
    });

    showWarningsPopup.value = true;
    warningsPopupContent.value = html.join('');
  };

  return {
    showWarnings,
    showWarningsPopup,
    warningsPopupContent,
    getNumberOfWarnings,
  };
}
