import CustomStore from 'devextreme/data/custom_store';
import useExternalDataSetRepository from '@/modules/xpExternalDataSet/repositories/externaldataset.repository.js';
import store from '@/store/index';

export default function useDatagrid(billingSetId) {
  const externalDataSetRepository = useExternalDataSetRepository(billingSetId);

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => externalDataSetRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
    remove: (key) => new Promise((resolve, reject) => {
      externalDataSetRepository.remove(key)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Leverandør datasæt er nu slettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
  });

  const onExporting = (e) => {
    e.component.beginUpdate();
    e.component.columnOption('Filer', 'visible', false);
    e.component.columnOption('Indlæst', 'visible', false);
    e.component.columnOption('Manuelle håndteringer', 'visible', false);
    e.component.columnOption('Status', 'visible', false);
    e.component.columnOption('Difference', 'visible', false);
  };

  const onExported = (e) => {
    e.component.columnOption('Filer', 'visible', true);
    e.component.columnOption('Indlæst', 'visible', true);
    e.component.columnOption('Manuelle håndteringer', 'visible', true);
    e.component.columnOption('Status', 'visible', true);
    e.component.columnOption('Difference', 'visible', true);
    e.component.endUpdate();
  };

  return {
    datasource,
    onExporting,
    onExported,
  };
}
