import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useFileExportRepository() {
  const appsettings = inject('appsettings');

  const createFileExport = (fileId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/fileexport/export/importedfile/dkvfiles/${fileId}`);

  return {
    createFileExport,
  };
}
