import { ref } from 'vue';
import store from '@/store/index';
import usePricePeriodRepository from '@/modules/pricePeriods/repositories/priceperiod.repository.js';

export default function useMarkup() {
  const showMarkupForm = ref(false);
  const markupModel = ref();

  function onMarkupClick(row) {
    // Model for new markup value
    markupModel.value = {
      id: row.data.id,
      markupPercent: row.data.markup,
    };

    showMarkupForm.value = true;
  }

  const onMarkupSave = (row) => {
    const pricePeriodRepository = usePricePeriodRepository();

    const payload = {
      value: markupModel.value.markupPercent,
    };

    pricePeriodRepository.markup(markupModel.value.id, payload)
      .then((response) => {
        if (response.data.success) {
          const datagrid = row.component;

          // Update datagrid with new data
          datagrid.refresh();

          // Hide Markup form
          showMarkupForm.value = false;

          store.dispatch('addSuccessNotification', 'Markup er nu opdateret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    onMarkupClick,
    showMarkupForm,
    markupModel,
    onMarkupSave,
  };
}
