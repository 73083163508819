<template>
  <div class="bi-publish-data datagrid-root">
    <div class="content-block">
      <h2>BI - Publicering af data</h2>
    </div>

    <div class="content-block background-color">
      <div class="button-row">
        <dx-button text="Start publicering" icon="upload" @click="startPublishing" hint="Eksportér data fra BOKISadm til BOKIS BI" />
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="biPublishDataDataGrid"
        :data-source="datasource"
        height="80vh">

        <dx-filter-row :visible="false" />
        <dx-header-filter :visible="false" />

        <dx-column
          data-field="name"
          caption="Navn"
          data-type="string"
          :hiding-priority="8"
          :width="300" />

        <dx-column
          data-field="startDateTime"
          caption="Start"
          data-type="date"
          :hiding-priority="7"
          :width="200"
          sort-order="desc"
          format="dd/MM/yyyy HH:mm:ss" />

        <dx-column
          data-field="endDateTime"
          caption="Slut"
          data-type="date"
          :hiding-priority="6"
          :width="200"
          format="dd/MM/yyyy HH:mm:ss" />

        <dx-column
          data-field="status"
          caption="Status"
          data-type="Number"
          :width="100"
          :hiding-priority="5"
          cell-template="statusTemplate" />

         <dx-column
          data-field="errorsLastRun"
          caption="Fejl"
          data-type="string"
          :hiding-priority="4" />

        <template #statusTemplate="{ data }">
          <div>
            {{ BIRunStatusDescription.get(data.data.status) }}
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import useDatagrid from '@/modules/dataTransfer/composables/bi.composable.js';
import { BIRunStatusDescription } from '@/enums/bi-run-status.js';

export default {
  name: 'PublishData',
  setup() {
    const dataGrid = ref();

    const {
      datasource,
      startPublishing,
    } = useDatagrid();

    // Get fresh data for grid every 5 sec.
    setInterval(() => {
      if (dataGrid.value !== null) {
        dataGrid.value.instance.refresh();
      }
    }, 5000);

    return {
      datasource,
      BIRunStatusDescription,
      startPublishing,
      dataGrid,
    };
  },
};
</script>

<style lang="scss" scoped>
.bi-publish-data {
  .background-color {
    background-color: #FFF;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
}
</style>
