import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useFileExportRepository() {
  const appsettings = inject('appsettings');

  const fileExport = (id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/attachments/exportfile/${id}`);

  return {
    fileExport,
  };
}
