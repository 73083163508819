<template>
  <ClientDataSetList />
</template>

<script>
import ClientDataSetList from '@/modules/clientDataSet/components/list.vue';

export default {
  name: 'ClientDataSets',
  components: {
    ClientDataSetList,
  },
};
</script>
