import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useBillingSetRepository(billingSetId) {
  const appsettings = inject('appsettings');

  const updatePublishDate = (publishDate) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/updatepublishdate`, { value: publishDate });

  const getPublishDate = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/publishdate`);

  return {
    updatePublishDate,
    getPublishDate,
  };
}
