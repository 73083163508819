import useCreditRepository from '@/modules/mccClientDataSet/repositories/credit.repository.js';
import store from '@/store/index';

export default function useCredit(billingSetId, dataGrid) {
  const creditRepository = useCreditRepository(billingSetId);

  const createCreditInvoices = (clientDataSetIdList) => {
    store.dispatch('showLoadingPanel', true);

    creditRepository.createCreditInvoices(clientDataSetIdList)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          // Reload datagrid
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'Kreditnota er nu oprettet');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const openFile = (fileContentResult) => {
    // Base64 decode content
    const decodedFileContent = Buffer.from(fileContentResult.fileContents, 'base64');

    const fileURL = window.URL.createObjectURL(new Blob([decodedFileContent], { type: 'text/csv' }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileContentResult.fileDownloadName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  const exportCreditInvoices = (clientDataSetIdList) => {
    store.dispatch('showLoadingPanel', true);

    creditRepository.exportCreditInvoices(clientDataSetIdList)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          const fileContentResult = response.data.value;
          openFile(fileContentResult);

          // Reload datagrid
          dataGrid.value.instance.refresh();
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const CreditAction = {
    CREATECREDITINVOICES: 1,
    EXPORTCREDITINVOICES: 2,
  };

  const creditActionList = [
    { key: CreditAction.CREATECREDITINVOICES, text: 'Opret kreditnotaer', description: 'Opret krediteringsdatasæt for alle markerede PI-datasæt' },
    { key: CreditAction.EXPORTCREDITINVOICES, text: 'Eksportér kreditnotaer', description: 'Eksportér/hent krediteringsdatasæt for alle markerede PI-datasæt i CSV-format.' },
  ];

  const onCreditClick = (e) => {
    const selectedRowsData = dataGrid.value.instance.getSelectedRowsData();

    // Remove pi's with no client data set
    const clientDataSetIdList = selectedRowsData
      .filter((x) => x.id != null)
      .map((x) => x.id);

    if (clientDataSetIdList.length === 0) {
      store.dispatch('addInfoNotification', 'Du skal vælge minimum ét datasæt.');
      return;
    }

    switch (e.itemData.key) {
      case CreditAction.CREATECREDITINVOICES: {
        // Create credit invoices
        createCreditInvoices(clientDataSetIdList);
        break;
      }
      case CreditAction.EXPORTCREDITINVOICES: {
        // Export credit invoices
        exportCreditInvoices(clientDataSetIdList);
        break;
      }
      default:
        break;
    }
  };

  return {
    onCreditClick,
    creditActionList,
  };
}
