import { ref, inject } from 'vue';
import store from '@/store/index';
import useDistributionKeyPeriodRepository from '@/modules/masterData/distributionKeyPeriods/repositories/distributionKeyPeriod.repository.js';
import rowStateService from '@/services/rowState.service.js';

export default function useCopy(clientId) {
  const showCopyForm = ref(false);
  const copyModel = ref();

  const formatService = inject('formatService');

  function onCopyClick(row) {
    copyModel.value = {
      id: row.data.id,
      name: `kopi_${row.data.name}`,
      startDate: null,
      endDate: null,
    };

    showCopyForm.value = true;
  }

  const onCopySave = (row, storageKey) => {
    const mainDistributionKeyPeriodRepository = useDistributionKeyPeriodRepository(clientId);

    const payload = {
      name: copyModel.value.name,
      startDate: formatService.stringToDate(copyModel.value.startDate.toISOString(), 'YYYY-MM-DD'),
      endDate: formatService.stringToDate(copyModel.value.endDate.toISOString(), 'YYYY-MM-DD'),
    };

    mainDistributionKeyPeriodRepository.copy(copyModel.value.id, payload)
      .then((response) => {
        if (response.data.success) {
          const datagrid = row.component;

          // Update datagrid with new data
          datagrid.refresh();

          // Hide Copy form
          showCopyForm.value = false;

          // Collapse all rows and remove current open row from storage
          datagrid.collapseAll(-1);
          rowStateService.removeKeyFromStorage(storageKey, row.key);

          // Expand new row and add newId to storage
          const newId = response.data.value;
          row.component.expandRow(newId);
          rowStateService.addKeyToStorage(storageKey, newId);

          store.dispatch('addSuccessNotification', 'Prisperioden er blevet kopieret og gemt');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    onCopyClick,
    showCopyForm,
    copyModel,
    onCopySave,
  };
}
