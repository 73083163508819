import { reactive } from 'vue';
import useManualBillingRepository from '@/modules/manualBilling/repositories/manualBilling.repository.js';
import store from '@/store/index';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useManualBilling(billingSetId) {
  const manualBillingRepository = useManualBillingRepository(billingSetId);
  const manualBillingEntity = reactive({ loadingCompleted: false, data: null });
  const { startFastJobPolling } = useJob();

  const insertManualBilling = (form) => {
    const formData = new FormData(form.target);
    return manualBillingRepository.insertManualBilling(formData)
      .then((response) => {
        if (response.data.success) {
          // Start polling JobList at a fast pace
          startFastJobPolling();

          return Promise.resolve(response.data.value);
        }
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
        return Promise.resolve();
      });
  };

  const loadManualBilling = (manualBillingId) => {
    manualBillingRepository.getManualBilling(manualBillingId)
      .then((response) => {
        manualBillingEntity.loadingCompleted = true;

        if (response.data.success) {
          manualBillingEntity.data = response.data.value;
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const updateManualBilling = (manualBillingId, values) => {
    // Map payload
    const payload = {
      text: values.text,
      comment: values.comment,
      quantity: values.quantity,
      price: values.price,
      totalPrice: values.totalPrice,
      markupPrice: values.markupPrice,
      totalMarkupPrice: values.totalMarkupPrice,
      manualBillingEntryDistributionList: [...values.manualBillingEntryDistributionList],
    };

    return manualBillingRepository.updateManualBilling(manualBillingId, payload)
      .then((response) => {
        if (response.data.success) {
          // Start polling JobList at a fast pace
          startFastJobPolling();

          store.dispatch('addSuccessNotification', 'BOKIS post er nu gemt');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }

        return Promise.resolve(response);
      });
  };

  return {
    manualBillingEntity,
    insertManualBilling,
    loadManualBilling,
    updateManualBilling,
  };
}
