import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useProductPriceGroupRepository from '@/modules/productPriceGroups/repositories/productPriceGroup.repository.js';
import store from '@/store/index';

export default function useDatagrid(pricePeriodId, productPriceGroupId) {
  const showVolumebasedPriceForm = ref(false);
  const productPriceGroupRepository = useProductPriceGroupRepository(pricePeriodId);

  const onVolumebasedPriceClick = () => {
    showVolumebasedPriceForm.value = true;
  };

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => productPriceGroupRepository.loadVolumebasedPrices(loadOptions, productPriceGroupId)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
    update: (key, values) => new Promise((resolve, reject) => {
      productPriceGroupRepository.updateVolumebasedPrice(key, productPriceGroupId, values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    insert: (values) => new Promise((resolve, reject) => {
      productPriceGroupRepository.insertVolumebasedPrice(productPriceGroupId, values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Pris er nu tilføjet til pristrappe');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    remove: (key) => new Promise((resolve, reject) => {
      productPriceGroupRepository.removeVolumebasedPrice(key, productPriceGroupId)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Pris er nu slettet fra pristrappe');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
  });

  return {
    onVolumebasedPriceClick,
    showVolumebasedPriceForm,
    datasource,
  };
}
