<template>
  <div class="clientbillingentry-list-root datagrid-root">
    <div class="content-block">
      <Headline title="PI datasæt poster" :contextData="billingSetEntry" />
      <div>
        <u @click="router.push({ name: 'BillingSet' })" class="link">Kørsler</u> >
        <u @click="router.push({ name: 'ClientDataSetList', props: { billingSetId, clientDataSetId } })" class="link">PI-datasæt</u> > PI-datasæt poster
      </div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="router.push({ name: 'ClientDataSetList', props: { billingSetId, clientDataSetId } })"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="clientBillingEntryDataGrid"
        :data-source="datasource"
        height="80vh">

        <dx-export :enabled="true" fileName="PIDatasætPoster" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="false" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-column
          data-field="text"
          caption="Tekst"
          :hiding-priority="4" />

        <dx-column
          data-field="productIdentifier"
          caption="Varenummer"
          :hiding-priority="3"
          :width="150" />

        <dx-column
          data-field="basePrice"
          caption="Pris"
          :hiding-priority="2"
          :width="150"
          :format="NumberFormatting.kronerWithTwoDecimals" />

        <dx-column
          data-field="markup"
          caption="Markup"
          :hiding-priority="1"
          :width="150"
          :format="NumberFormatting.kronerWithTwoDecimals" />

        <dx-column
          data-field="quantity"
          caption="Antal"
          :hiding-priority="1"
          :width="150"
          :format="NumberFormatting.number" />

        <dx-column
          data-field="totalPrice"
          caption="Total BOKIS-pris"
          :hiding-priority="1"
          :width="150"
          sort-order="desc"
          :format="NumberFormatting.kronerWithTwoDecimals" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDatasourceCompleted" />
          <div v-else>
            <DetailView :row="row" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/clientDataSet/composables/clientbillingentry.datagrid.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';
import DetailView from '@/modules/clientDataSet/components/clientBillingEntry/detail-view.vue';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';
import useSharedBillingSet from '@/modules/shared/composables/billingset.composable.js';
import Headline from '@/components/common/headline.vue';

export default {
  name: 'ClientBillingEntryList',
  extends: BaseDataGrid,
  props: {
    billingSetId: String,
    clientDataSetId: String,
  },
  components: {
    DetailView,
    ContentLoaderList,
    Headline,
  },
  setup(props) {
    const {
      loadingDatasourceCompleted,
      datasource,
    } = useDatagrid(props.billingSetId, props.clientDataSetId);

    const router = useRouter();

    const {
      loadBillingSet,
      billingSetEntry,
    } = useSharedBillingSet();
    loadBillingSet(props.billingSetId);

    return {
      loadingDatasourceCompleted,
      datasource,
      router,
      NumberFormatting,
      billingSetEntry,
    };
  },
};
</script>

<style lang="scss" scoped>
.clientbillingentry-list-root {
  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }
}
</style>
