import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useBCRepository() {
  const appsettings = store.state.appSettings;

  const importBCInvoiceMetadata = (formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/invoices/importbcdata`, formData);

  return {
    importBCInvoiceMetadata,
  };
}
