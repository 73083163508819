<template>
  <div class="mccexternaldataset-list-root datagrid-root">
    <div class="content-block">
      <Headline title="Leverandør datasæt" :contextData="billingSetEntry" />
      <div><u @click="router.push({ name: 'MCCBillingSet' })" class="link">MC Connect kørsler</u> > Leverandør datasæt</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="router.push({ name: 'MCCBillingSet' })"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button icon="plus" @click="onNewClick" :disabled="rowCount > 0" hint="Indlæs nyt datasæt" class="create-button"></dx-button>
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="mccExternalDataSetDataGrid"
        :data-source="datasource"
        height="80vh"
        :focused-row-key="focusedRowKey"
        @exporting="onExporting"
        @exported="onExported"
        @row-removing="rowRemoving"
        @content-ready="onContentReady">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Leverandør datasæt" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="mccexternalDataSet-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing :confirm-delete="false" />

        <dx-column
          data-field="fileName"
          caption="Fil"
          :hiding-priority="8"
          data-type="string" />

        <dx-column
          data-field="created"
          caption="Indlæst"
          data-type="date"
          format="dd.MM.yyyy"
          :visible="true"
          :hiding-priority="7"
          sort-order="desc"
          :width="150">
        </dx-column>

        <dx-column
          data-field="totalAmountInCurrencyDKK"
          caption="Totalbeløb i DKK"
          :format="NumberFormatting.kronerWithTwoDecimals"
          :hiding-priority="6"
          :width="150">
        </dx-column>

        <dx-column
          data-field="totalAmountForCurrencyUSD"
          caption="Totalbeløb i USD"
          format="#,##0.############## $"
          :hiding-priority="5"
          :width="150">
        </dx-column>

        <dx-column
          data-field="totalAmountForCurrencyEUR"
          caption="Totalbeløb i EUR"
          format="#,##0.############## €"
          :hiding-priority="5"
          :width="150">
        </dx-column>

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <DetailView
            :row="row"
            @onDeleteClick="onDeleteClick" />
        </template>
      </dx-data-grid>

      <FileUploadPopup v-if="showUploadFilePopup"
        title="Indlæs leverandør datasæt"
        :visible="showUploadFilePopup"
        saveButtonText="Upload"
        :height="450"
        @save="onUploadSaveClick"
        @cancel="onUploadExternalDataSetCancel"
        :allowMultipleFiles="false"
        selectButtonText="Vælg fil"
        labelText="eller drop fil her"
        :allowedFileExtensions="['.csv']"
        invalidFileExtensionMessage="Filtypen er forkert. Kun csv er tilladt.">
        <div>
          <div class="content-row">
            Forventet total USD:
            <dx-number-box name="expectedTotalAmountForCurrencyUSD" id="expectedTotalAmountForCurrencyUSD" :value="null" format="#,##0.## $" :showClearButton="true" />
          </div>
          <div class="content-row">
            Forventet total EUR:
            <dx-number-box name="expectedTotalAmountForCurrencyEUR" id="expectedTotalAmountForCurrencyEUR" :value="null" format="#,##0.## €" :showClearButton="true" />
          </div>
          <div class="content-row">
            <dx-checkbox text="Tillad indlæsning selv om der er advarsler" name="ignoreWarnings" id="ignoreWarnings" />
          </div>
        </div>
      </FileUploadPopup>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import DetailView from '@/modules/mccExternalDataSet/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/mccExternalDataSet/composables/datagrid.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';
import FileUploadPopup from '@/components/common/file-upload-popup.vue';
import useUploadExternalDataSet from '@/modules/shared/composables/upload.externaldataset.composable.js';
import useJob from '@/modules/shared/composables/job.composable.js';
import useSharedBillingSet from '@/modules/shared/composables/billingset.composable.js';
import Headline from '@/components/common/headline.vue';

export default {
  name: 'ExternalDataSetList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    FileUploadPopup,
    Headline,
  },
  props: {
    // BillingSetID
    id: String,
  },
  setup(props) {
    const {
      datasource,
      onExporting,
      onExported,
    } = useDatagrid(props.id);

    const dataGrid = ref();

    const router = useRouter();

    const {
      loadMCCBillingSet,
      billingSetEntry,
    } = useSharedBillingSet();
    loadMCCBillingSet(props.id);

    const {
      onUploadExternalDataSetClick,
      showUploadFilePopup,
      onUploadExternalDataSetCancel,
      onUploadMCCDataSetSave,
    } = useUploadExternalDataSet();

    const onNewClick = () => {
      onUploadExternalDataSetClick(props.id);
    };

    const { startFastJobPolling } = useJob();

    const onUploadSaveClick = (form) => {
      onUploadMCCDataSetSave(form)
        .then((response) => {
          if (response.success) {
            // Start polling JobList at a fast pace
            startFastJobPolling();

            // Reload datagrid when new file has been uploaded.
            dataGrid.value.instance.refresh();
          }
        });
    };

    const rowCount = ref();
    const onContentReady = (e) => {
      rowCount.value = e.component.totalCount();
    };

    return {
      datasource,
      router,
      onNewClick,
      NumberFormatting,
      dataGrid,
      showUploadFilePopup,
      onUploadExternalDataSetCancel,
      onUploadSaveClick,
      billingSetEntry,
      onExporting,
      onExported,
      onContentReady,
      rowCount,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, 'Vil du slette dette leverandør datasæt?', 'Slet datasæt');
    },
  },
};
</script>

<style lang="scss">
.mccexternaldataset-list-root {
  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }

  .recalculate-button, .create-button {
    margin-right: 47px
  }
}
</style>
