import StatusLevelType from '@/enums/statuslevel-types.js';

export default function useDeviationContent() {
  const getDeviationContent = (data, showText) => {
    let icon = '';
    let color = '';
    let text = '';

    // First set icon and color
    if (data.partOfFusion) {
      icon = 'dx-icon-link';
      color = '#2b2b2b';
    } else if (data.differenceSinceLastMonth === StatusLevelType.WARNING || data.differenceSinceSameMonthLastYear === StatusLevelType.WARNING) {
      icon = 'dx-icon-warning';
      color = '#CC0000';
    } else if (data.differenceSinceLastMonth === StatusLevelType.INFO || data.differenceSinceSameMonthLastYear === StatusLevelType.INFO) {
      icon = 'dx-icon-info';
      color = '#DDBB00';
    }

    // Then set text
    if (data.partOfFusion) {
      text += 'Pengeinstitut indgår i fusion';
    }

    if (data.differenceSinceLastMonth === StatusLevelType.WARNING) {
      if (text.length > 0) text += '\n';
      text += 'Totalbeløbet afviger betragteligt fra tidligere perioder';
    } else if (data.differenceSinceLastMonth === StatusLevelType.INFO) {
      if (text.length > 0) text += '\n';
      text += 'Totalbeløbet afviger fra tidligere perioder';
    }

    if (data.differenceSinceSameMonthLastYear === StatusLevelType.WARNING) {
      if (text.length > 0) text += '\n';
      text += 'Totalbeløbet afviger betragteligt fra sidste år samme periode';
    } else if (data.differenceSinceSameMonthLastYear === StatusLevelType.INFO) {
      if (text.length > 0) text += '\n';
      text += 'Totalbeløbet afviger fra sidste år samme periode';
    }

    if (showText) {
      return `<p><i class='${icon}' style='color: ${color}; font-size: 18px; vertical-align: bottom;' title='${text}'></i> ${text}</p>`;
    }
    return `<i class='${icon}' style='color: ${color}; font-size: 18px;' title='${text}' />`;
  };

  return {
    getDeviationContent,
  };
}
