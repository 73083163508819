<template>
  <div class="volume-based-price-list">
    <content-loader-list v-if="!loadingDataCompleted" />
    <volume-based-price-list v-else-if="data.length > 0" :data="data" />
    <div v-else class="highlight">
      Der er ikke konfigureret nogen pristrappe for den valgte service.
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';
import VolumeBasedPriceList from '@/modules/prices/components/volume-based-price-list.vue';
import useMasterdataRepository from '@/modules/prices/repositories/masterdata.repository.js';

export default {
  name: 'VolumeBasedPriceListContainer',
  props: {
    pricePeriodId: Number,
    serviceId: Number,
  },
  components: {
    ContentLoaderList,
    VolumeBasedPriceList,
  },
  setup(props) {
    const loadingDataCompleted = ref(false);
    const data = ref();
    const masterdataRepository = useMasterdataRepository();

    function loadData(id) {
      if (id == null) return;

      loadingDataCompleted.value = false;

      masterdataRepository
        .getVolumeBasedList(props.pricePeriodId, props.serviceId)
        .then((response) => {
          loadingDataCompleted.value = true;
          data.value = response.data;
        });
    }

    loadData(props.serviceId);

    return {
      data,
      loadingDataCompleted,
    };
  },
};
</script>

<style lang="scss" scoped>
.volume-based-price-list {
  .highlight {
    font-weight: bold;
    color: red;
  }
}
</style>
