import CustomStore from 'devextreme/data/custom_store';
import useClientDataSetRepository from '@/modules/clientDataSet/repositories/clientdataset.repository.js';

export default function useDatagrid(billingSetId) {
  const clientDataSetRepository = useClientDataSetRepository(billingSetId);

  const datasource = new CustomStore({
    key: 'cliNo',
    load: (loadOptions) => clientDataSetRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
  });

  const onCellPrepared = (e) => {
    if (e.rowType === 'data' && e.column.command === 'select' && e.data.id == null) {
      // Hide checkbox when clientdataset hasn't been created.
      e.cellElement.getElementsByClassName('dx-select-checkbox')[0].classList.add('display-none');
    }
  };

  return {
    datasource,
    onCellPrepared,
  };
}
