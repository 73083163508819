<template>
  <ClientSubclientModule />
</template>

<script>
import ClientSubclientModule from '@/modules/masterData/clientSubclients/components/main.vue';

export default {
  name: 'MasterDataClientsSubclients',
  components: {
    ClientSubclientModule,
  },
};
</script>
