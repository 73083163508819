import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getPageLocationTypeList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/piitext/pagelocationtypes`);

  return {
    getPageLocationTypeList,
  };
}
