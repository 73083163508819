import CustomStore from 'devextreme/data/custom_store';
import { ref, reactive } from 'vue';
import useAccountRepository from '@/modules/accountMapping/repositories/account.repository.js';
import store from '@/store/index';
import getFormTextByValue from '@/services/form.service.js';

export default function useDatagrid() {
  const mainAccountRepository = useAccountRepository();
  const datasource = ref();
  const showPopup = ref(false);
  const confirmPopupModel = reactive({
    show: false,
    title: null,
    content: null,
    saveButtonText: null,
    cancelButtonText: null,
    saveClickHandler: null,
    data: null,
  });
  const selectedClientId = ref();
  const selectedRowKeyList = ref([]);
  const dataGrid = ref();

  const getDatasource = (clientId) => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainAccountRepository.load(loadOptions, clientId)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
    });
  };

  const saveAccountMapping = (clientId, accountId, productIdList) => {
    mainAccountRepository
      .saveAccountMapping(clientId, accountId, productIdList)
      .then((response) => {
        if (response.data.success) {
          // Reload data
          dataGrid.value.instance.refresh();

          // Close popup dialogs
          showPopup.value = false;
          confirmPopupModel.show = false;

          // Show success notification
          store.dispatch('addSuccessNotification', 'Kontomapning er nu gemt');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const saveAccountMappingFromConfirmPopup = () => {
    const form = confirmPopupModel.data;
    const selectedAccount = getFormTextByValue(form, 'account');

    saveAccountMapping(selectedClientId.value, selectedAccount, selectedRowKeyList.value);
  };

  const showAccountMappingConfirmPopup = (form, accountList, productCount) => {
    const selectedAccountId = getFormTextByValue(form, 'account');

    // Get selected account
    const selectedAccount = accountList.data.find((x) => x.id === parseInt(selectedAccountId, 10));

    confirmPopupModel.show = true;
    confirmPopupModel.title = 'Er du sikker?';
    confirmPopupModel.content = `Er du sikker på du vil sætte konto til ${selectedAccount.registrationNumberText} for de valgte produkter?<br/ ><br/ >
      I alt ${productCount} ${productCount > 1 ? 'produkter' : 'produkt'} vil blive opdateret.<br/ ><br/ >
      Vil du fortsætte med at gemme?`;
    confirmPopupModel.saveButtonText = 'OK';
    confirmPopupModel.cancelButtonText = 'Annuller';
    confirmPopupModel.saveClickHandler = saveAccountMappingFromConfirmPopup;
    confirmPopupModel.data = form;
  };

  const onDataGridSelectionChanged = ({ selectedRowKeys }) => {
    selectedRowKeyList.value = selectedRowKeys;
  };

  return {
    datasource,
    getDatasource,
    showAccountMappingConfirmPopup,
    showPopup,
    confirmPopupModel,
    selectedClientId,
    selectedRowKeyList,
    dataGrid,
    onDataGridSelectionChanged,
  };
}
