<template>
  <div class="manual-billing-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" />
        <div class="detail-element-container padding-right">
          Varenr:
          <dx-textbox
            :value="row.data.productIdentifier"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="1" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container">
          Varetekst:
          <dx-textbox
            :value="row.data.description"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" />
        <dx-location screen="xs sm" :row="2" :col="0" />
        <div class="detail-element-container padding-right">
          Stykpris:
          <dx-number-box
            :value="row.data.price"
            :read-only="true"
            class="textbox-readonly"
            :format="NumberFormatting.kronerWithFourDecimals" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container">
          Antal:
          <dx-number-box
            :value="row.data.quantity"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container padding-right">
          Totalbeløb:
          <dx-number-box
            :value="row.data.totalPrice"
            :read-only="true"
            class="textbox-readonly"
            :format="NumberFormatting.kronerWithFourDecimals" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container">
          Total markup:
          <dx-number-box
            :value="row.data.totalMarkup"
            :read-only="true"
            class="textbox-readonly"
            :format="NumberFormatting.kronerWithFourDecimals" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="0" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div class="detail-element-container padding-right">
          Antal PI:
          <dx-number-box
            :value="row.data.clientCount"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="1" />
        <dx-location screen="xs sm" :row="7" :col="0" />
        <div class="detail-element-container">
          Brand:
          <dx-textbox
            :value="row.data.brandName"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="8" />
        <dx-location screen="xs sm" :row="7" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'DetailView',
  props: {
    row: Object,
  },
  setup() {
    return {
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss">
.manual-billing-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-top {
    padding-top: 15px;
  }
}
</style>
