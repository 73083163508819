import store from '@/store/index';
import useFileExportRepository from '@/modules/externalDataSet/repositories/fileexport.repository.js';

export default function useFileExport() {
  const fileExportRepository = useFileExportRepository();

  const openFile = (fileContentResult, fileName) => {
    // Base64 decode content
    const decodedFileContent = Buffer.from(fileContentResult.fileContents, 'base64');

    const fileURL = window.URL.createObjectURL(new Blob([decodedFileContent], { type: 'text/csv' }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  const getExternalFile = (file) => {
    store.dispatch('showLoadingPanel', true);

    fileExportRepository.createFileExport(file.id)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);

        if (response.data.success) {
          const fileContentResult = response.data.value;
          openFile(fileContentResult, file.name);
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onDownloadFileClick = (file) => {
    getExternalFile(file);
  };

  return {
    onDownloadFileClick,
  };
}
