import CustomStore from 'devextreme/data/custom_store';
import { ref, reactive } from 'vue';
import useBillingPeriodRepository from '@/modules/masterData/billingPeriods/repositories/billingPeriod.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const mainBillingPeriodRepository = useBillingPeriodRepository();
  const datasource = ref();
  const billingPeriodList = reactive({ data: null });

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainBillingPeriodRepository.load(loadOptions)
        .then((response) => {
          billingPeriodList.data = response.data.data;
          return {
            data: response.data.data,
            totalCount: response.data.totalCount,
            summary: response.data.summary,
            groupCount: response.data.groupCount,
          };
        }),
      update: (key, values) => new Promise((resolve, reject) => {
        mainBillingPeriodRepository.update(key, values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      insert: (values) => new Promise((resolve, reject) => {
        mainBillingPeriodRepository.insert(values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Faktureringsperiode er nu oprettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
      remove: (key) => new Promise((resolve, reject) => {
        mainBillingPeriodRepository.remove(key)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Faktureringsperiode er nu slettet');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const initFormFields = (e) => {
    // Preset name, startDate and endDate fields
    if (billingPeriodList.data[0] && billingPeriodList.data[0].endDate) {
      const endDate = new Date(billingPeriodList.data[0].endDate);
      const nextMonth = endDate.setDate(endDate.getDate() + 1);
      const nextMonthName = new Intl.DateTimeFormat('da-DK', { month: 'long' }).format(nextMonth);
      const nextYear = new Date(nextMonth).getFullYear();
      const firstDay = new Date(new Date(nextMonth).getFullYear(), new Date(nextMonth).getMonth(), 1).toDateString();
      const lastDay = new Date(new Date(nextMonth).getFullYear(), new Date(nextMonth).getMonth() + 1, 0).toDateString();
      e.data.name = `${nextMonthName.charAt(0).toUpperCase() + nextMonthName.slice(1)} ${nextYear}`;
      e.data.startDate = firstDay;
      e.data.endDate = lastDay;
    }
  };

  const onInitNewRow = (e) => {
    initFormFields(e);
  };

  return {
    datasource,
    getDatasource,
    onInitNewRow,
  };
}
