import { inject } from 'vue';
import ApiService from '@/services/api.service.js';
import SupplierInvoiceType from '@/enums/supplier-invoice-type.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getSupplierList = (supInvoiceType) => {
    if (supInvoiceType) {
      return ApiService.get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/suppliers?invoicetype=${supInvoiceType}`);
    }
    return ApiService.get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/suppliers?invoicetype=${SupplierInvoiceType.STANDARDINVOICING}`);
  };

  return {
    getSupplierList,
  };
}
