import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import useBiGraphRepository from '@/modules/masterData/biGraphs/repositories/biGraph.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const mainBiGraphRepository = useBiGraphRepository();
  const datasource = ref();

  const getDatasource = () => {
    datasource.value = new CustomStore({
      key: 'id',
      load: (loadOptions) => mainBiGraphRepository.load(loadOptions)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.totalCount,
          summary: response.data.summary,
          groupCount: response.data.groupCount,
        })),
      update: (key, values) => new Promise((resolve, reject) => {
        mainBiGraphRepository.update(key, values)
          .then((response) => {
            if (response.data.success) {
              store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
              resolve(response);
            } else {
              if (response.data.messages.length > 0) {
                store.dispatch('addNotifications', { messageList: response.data.messages });
              }
              reject();
            }
          });
      }),
    });
  };

  const isPublishDateMode = ref(false);

  const initFormFields = (e) => {
    // Hide/Show fields
    e.component.columnOption('dateForPublishedData').formItem.visible = isPublishDateMode.value;
    e.component.columnOption('name').formItem.disabled = isPublishDateMode.value;
    e.component.columnOption('graphType').formItem.visible = !isPublishDateMode.value;
    e.component.columnOption('defaultYear').formItem.visible = !isPublishDateMode.value;
    e.component.columnOption('noDataMessage').formItem.visible = !isPublishDateMode.value;
  };

  const initPublishFields = (e) => {
    isPublishDateMode.value = true;
    e.component.columnOption('dateForPublishedData').value = new Date();
  };

  return {
    datasource,
    getDatasource,
    initPublishFields,
    initFormFields,
    isPublishDateMode,
  };
}
