<template>
  <ClientModule />
</template>

<script>
import ClientModule from '@/modules/masterData/clients/components/main.vue';

export default {
  name: 'MasterDataClients',
  components: {
    ClientModule,
  },
};
</script>
