<template>
  <div class="login-root">
    <h1>Login</h1>
    <DxLoadIndicator :height="20" :width="20" />
    Du bliver nu logget ind. Det kan i nogle tilfælde tage op til ét minut...
  </div>
</template>

<script>
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import useUserManager from '@/services/userManager.service.js';

export default {
  name: 'Login',
  components: {
    DxLoadIndicator,
  },
  created() {
    const { login } = useUserManager();

    // User is now redirected to IdentityServer login page
    login(this.$route.redirectedFrom.path);
  },
};
</script>

<style lang="scss" scoped>
.login-root {
  padding: 20px;
}
</style>
