import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useClientDataSetRepository(billingSetId) {
  const appsettings = store.state.appSettings;

  const loadStatusSummary = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/datasets/statussummary`);

  const create = () => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/mcinvoicing/mcbillingsets/${billingSetId}/createdataset`);

  return {
    loadStatusSummary,
    create,
  };
}
