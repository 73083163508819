<template>
  <div class="copy-form-container">
    <form @submit="handleSubmit($event)">
      <div class="row">
       Kopier fordelingsperiode.
      </div>
      <div class="row">
        Navn:
        <dx-textbox :value="modelValue.name" valueChangeEvent="input" @value-changed="(e) => update('name', e.value)">
          <dx-validator>
            <dx-required-rule message="Navn skal udfyldes" />
          </dx-validator>
        </dx-textbox>
      </div>
      <div class="date-row">
        <div class="date-item padding-right">
          Startdato:
          <dx-date-box :value="modelValue.startDate" type="date" display-format="dd-MM-yyyy" valueChangeEvent="input" @value-changed="(e) => update('startDate', e.value)">
            <dx-validator>
              <dx-required-rule message="Startdato skal udfyldes" />
            </dx-validator>
          </dx-date-box>
        </div>
        <div class="date-item">
          Slutdato:
          <dx-date-box :value="modelValue.endDate" type="date" display-format="dd-MM-yyyy" valueChangeEvent="input" @value-changed="(e) => update('endDate', e.value)">
            <dx-validator>
              <dx-required-rule message="Slutdato skal udfyldes" />
            </dx-validator>
          </dx-date-box>
        </div>
      </div>

      <div class="validation-row row">
        <dx-validation-summary />
      </div>
      <div class="button-row row">
        <dx-button text="Gem" :use-submit-behavior="true" class="button margin-right" />
        <dx-button text="Annuller" @click="handleCancel" class="button" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'CopyForm',
  props: {
    modelValue: Object,
  },
  setup(props, context) {
    const update = (key, value) => {
      context.emit('update:modelValue', { ...props.modelValue, [key]: value });
    };

    const handleSubmit = (e) => {
      context.emit('save');
      e.preventDefault();
    };

    const handleCancel = () => {
      context.emit('cancel');
    };

    return {
      update,
      handleSubmit,
      handleCancel,
    };
  },
};
</script>

<style lang="scss" scoped>
.copy-form-container {
  display: flex;
  flex-direction: column;

  .row {
    padding-bottom: 15px;
  }

  .date-item {
    flex: 1 0 auto;
  }

  .padding-right {
    padding-right: 15px;
  }

  .date-row {
    display: flex;
    flex-direction: row;
  }

  .button {
    width: 100px;
  }

  .margin-right {
    margin-right: 15px;
  }

  .button-row,
  .validation-row {
    display: flex;
    justify-content: flex-end;

  }
}
</style>
