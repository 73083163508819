import ApiService from '@/services/api.service.js';
import store from '@/store/index.js';

export default function useExternalDataSetRepository(billingSetId) {
  const appsettings = store.state.appSettings;
  const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

  const load = (loadOptions) => {
    let params = '?';

    [
      'filter',
      'group',
      'groupSummary',
      'parentIds',
      'requireGroupCount',
      'requireTotalCount',
      'searchExpr',
      'searchOperation',
      'searchValue',
      'select',
      'sort',
      'skip',
      'take',
      'totalSummary',
      'userData',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    return ApiService
      .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/externaldatasets/overview${params}`);
  };

  const remove = (externalDataSetId) => ApiService
    .delete(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/externaldatasets/${externalDataSetId}`);

  const uploadExternalDatasetFiles = (billingsetId, formData) => ApiService
    .post(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingsetId}/externaldatasets/uploadfiles`, formData);

  const annul = (externalDataSetId) => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/externaldatasets/${externalDataSetId}/cancel`);

  const reCalculate = () => ApiService
    .put(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/externaldatasets/recalculate`);

  const getBuyReport = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/buyreport`);

  const getDifferenceReport = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/invoicing/billingsets/${billingSetId}/externaldiffreport`);

  return {
    load,
    uploadExternalDatasetFiles,
    annul,
    remove,
    reCalculate,
    getBuyReport,
    getDifferenceReport,
  };
}
