import { ref } from 'vue';
import store from '@/store/index';
import useBillingSetRepository from '@/modules/billingSet/repositories/billingset.repository.js';

export default function useAnnul(dataGrid) {
  const annulModel = ref();
  const showConfirmPopup = ref(false);

  function onAnnulClick(row) {
    annulModel.value = {
      id: row.data.id,
      title: 'Annullering',
      content: `Vil du annullere ${row.data.name}?`,
      saveButtonText: 'Ja',
      cancelButtonText: 'Nej',
    };

    showConfirmPopup.value = true;
  }

  const onAnnulCancel = () => {
    showConfirmPopup.value = false;
  };

  const onAnnulSave = () => {
    showConfirmPopup.value = false;

    const billingSetRepository = useBillingSetRepository();

    billingSetRepository.annul(annulModel.value.id)
      .then((response) => {
        if (response.data.success) {
          // Update datagrid with new data
          dataGrid.value.instance.refresh();

          store.dispatch('addSuccessNotification', 'Kørslen er blevet annulleret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    onAnnulClick,
    showConfirmPopup,
    annulModel,
    onAnnulSave,
    onAnnulCancel,
  };
}
