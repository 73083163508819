import { computed } from 'vue';
import store from '@/store/index';
import useJobRepository from '@/modules/shared/repositories/job.repository.js';
import JobStatus from '@/enums/job-status.js';

const slowPollingIntervalInSeconds = 60000;
const fastPollingIntervalInSeconds = 3000;
let slowPollingId;
let fastPollingId;

export default function useJob() {
  const jobRepository = useJobRepository();

  const getJobList = () => jobRepository.getJobList()
    .then((response) => {
      store.dispatch('addJobs', response.data);
      return response;
    });

  const killExistingJobPolling = () => {
    if (fastPollingId != null) {
      clearInterval(fastPollingId);
      fastPollingId = null;
    }

    if (slowPollingId != null) {
      clearInterval(slowPollingId);
      slowPollingId = null;
    }
  };

  // Poll every 60 second
  const startSlowJobPolling = () => {
    killExistingJobPolling();

    slowPollingId = setInterval(() => {
      getJobList();
    }, slowPollingIntervalInSeconds);
  };

  // Poll every 3 second
  const startFastJobPolling = (callback) => {
    killExistingJobPolling();

    fastPollingId = setInterval(() => {
      getJobList()
        .then((response) => {
          if (callback) {
            callback(response.data);
          }
          const numberOfRunningJobs = response.data.filter((x) => x.status === JobStatus.WAITING || x.status === JobStatus.RUNNING || x.status === JobStatus.IDLE).length;

          // Slow down polling if running jobs have finished.
          if (numberOfRunningJobs === 0) {
            startSlowJobPolling();
          }
        });
    }, fastPollingIntervalInSeconds);
  };

  const markJobsAsRead = (jobIdList) => {
    jobRepository.markJobsAsRead(jobIdList)
      .then((response) => {
        if (response.data.success) {
          startFastJobPolling();
        }
      });
  };

  const jobStatusSummary = computed(() => store.getters.jobStatusSummary);
  const jobList = computed(() => store.state.jobs);

  return {
    startSlowJobPolling,
    startFastJobPolling,
    jobStatusSummary,
    jobList,
    markJobsAsRead,
  };
}
