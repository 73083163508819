<template>
  <div class="bokis-poster-root datagrid-root">
    <div class="content-block">
      <Headline title="BOKIS poster" :contextData="billingSetEntry" />
      <div><u @click="onBackClick" class="link">Kørsler</u> > BOKIS poster</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onBackClick"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button icon="plus" @click="onNewClick" class="create-button"></dx-button>
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="manualBillingDataGrid"
        :data-source="datasource"
        height="80vh"
        :focused-row-key="focusedRowKey"
        @row-removing="rowRemoving">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="BOKISposter" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="manualBilling-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-editing :confirm-delete="false" />

        <dx-column
          data-field="productIdentifier"
          caption="Varenr."
          :width="100"
          :hiding-priority="8">
        </dx-column>

        <dx-column
          data-field="description"
          caption="Varetekst"
          :hiding-priority="7">
        </dx-column>

        <dx-column
          caption="Type"
          :hiding-priority="6"
          cell-template="typeTemplate">
        </dx-column>

        <dx-column
          data-field="price"
          caption="Stykpris"
          :format="NumberFormatting.kronerWithFourDecimals"
          :width="150"
          :hiding-priority="5">
        </dx-column>

        <dx-column
          data-field="quantity"
          caption="Antal"
          :width="100"
          :hiding-priority="4">
        </dx-column>

        <dx-column
          data-field="totalPrice"
          caption="Totalbeløb"
          :width="150"
          :format="NumberFormatting.kronerWithFourDecimals"
          :hiding-priority="3">
        </dx-column>

        <dx-column
          data-field="totalMarkup"
          caption="Total markup"
          :width="150"
          :format="NumberFormatting.kronerWithFourDecimals"
          :hiding-priority="2">
        </dx-column>

        <dx-column
          data-field="clientCount"
          caption="Antal PI"
          :width="150"
          :hiding-priority="1">
        </dx-column>

        <dx-column
          data-field="brandName"
          caption="Brand"
          :width="150"
          :hiding-priority="0">
        </dx-column>

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <DetailView
            :row="row"
            @onEditClick="onEditClick"
            @onDeleteClick="onDeleteClick" />
        </template>

        <template #typeTemplate="{ data }">
          <div>
            <div v-if="data.data.externalDataFileEntryId === null">
              BOKIS post
            </div>
            <div v-else>
              Manuel håndtering
            </div>
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import DetailView from '@/modules/manualBilling/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/manualBilling/composables/datagrid.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';
import useSharedBillingSet from '@/modules/shared/composables/billingset.composable.js';
import Headline from '@/components/common/headline.vue';

export default {
  name: 'MBList',
  extends: BaseDataGrid,
  components: {
    DetailView,
    Headline,
  },
  props: {
    _billingSetId: String,
  },
  setup(props) {
    const {
      datasource,
    // eslint-disable-next-line no-underscore-dangle
    } = useDatagrid(props._billingSetId);

    const router = useRouter();

    function onBackClick() {
      router.push({ name: 'BillingSet' });
    }

    const onEditClick = (row) => {
      // BillingSetId
      // eslint-disable-next-line no-underscore-dangle
      const id = props._billingSetId;

      // ManualBillingId
      const manualBillingId = row.data.id;

      router.push({ name: 'ManualBilling', params: { _billingSetid: id, _manualBillingId: manualBillingId } });
    };

    const onNewClick = () => {
      // BillingSetId
      // eslint-disable-next-line no-underscore-dangle
      const id = props._billingSetId;

      router.push({ name: 'ManualBilling', params: { _billingSetId: id } });
    };

    const {
      loadBillingSet,
      billingSetEntry,
    } = useSharedBillingSet();
    // eslint-disable-next-line no-underscore-dangle
    loadBillingSet(props._billingSetId);

    return {
      datasource,
      onBackClick,
      onEditClick,
      onNewClick,
      NumberFormatting,
      billingSetEntry,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne BOKIS post?<br/><b>${e.data.description}</b>`, 'Slet BOKIS post');
    },
  },
};
</script>

<style lang="scss">
.bokis-poster-root {
  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 200;
    padding-left: 20px;
  }

  .grid-button-container .create-button {
    margin-right: 46px;
  }

  .link:hover {
    cursor: pointer;
  }
}
</style>
