import CustomStore from 'devextreme/data/custom_store';
import useDeviationRepository from '@/modules/externalDataSet/repositories/deviation.repository.js';
import store from '@/store/index';

export default function useDeviation(billingSetId, externalDataSetId) {
  const deviationRepository = useDeviationRepository(billingSetId, externalDataSetId);

  const deviationDataSource = new CustomStore({
    key: 'productIdentifier',
    load: (loadOptions) => deviationRepository.load(loadOptions)
      .then((response) => {
        if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }

        return {
          data: response.data.value.data,
          totalCount: response.data.value.totalCount,
          summary: response.data.value.summary,
          groupCount: response.data.value.groupCount,
        };
      }),
  });

  return {
    deviationDataSource,
  };
}
