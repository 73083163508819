<template>
  <div class="price-list-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Prisliste</h2></div>
        <div class="subheadline-item">{{ overviewInfo.loadingCompleted ? overviewInfo.data.headline : '' }}</div>
      </div>
      <div><u @click="onBackClick" class="prisperiode-link">Prisperioder</u> > Prisliste</div>
    </div>
    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onBackClick"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="prisListeDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        @editor-preparing="onEditorPreparing"
        @editing-start="onEditingStart"
        @init-new-row="onInitNewRow(overviewInfo.data.markupPercent)"
        @row-removing="rowRemoving">

        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />
        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Prisliste" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="price-list" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />

        <dx-editing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="pricePeriod && !pricePeriod.active"
          :confirm-delete="false"
          mode="form">
          <dx-form
            label-location="top"
            :customize-item="customizeItem"
            :showValidationSummary="true">
            <dx-simple-item data-field="productId">
              <dx-label text="Vare" />
            </dx-simple-item>
            <dx-simple-item data-field="serviceId">
              <dx-label text="Service" />
            </dx-simple-item>
            <dx-simple-item data-field="invoicingFrequencyIdArray">
              <dx-label text="Faktureringsfrekvens" />
            </dx-simple-item>
            <dx-simple-item data-field="invoiceCategoryId">
              <dx-label text="Varekategori" />
            </dx-simple-item>
            <dx-simple-item item-type="group">
              <dx-simple-item data-field="priceTypeId">
                <dx-label text="Pristype" />
              </dx-simple-item>
              <dx-simple-item item-type="group" name="fixedPrice">
                <dx-simple-item
                  data-field="basePrice"
                  editor-type="dxNumberBox"
                  :editor-options="editorBasePrice">
                  <dx-label text="Basispris" />
                </dx-simple-item>
                <dx-simple-item data-field="hasMarkup">
                  <dx-label :visible="false" />
                </dx-simple-item>
                <dx-simple-item
                  data-field="markup"
                  editor-type="dxNumberBox"
                  :editor-options="editorMarkupPrice">
                  <dx-label text="Markup" />
                </dx-simple-item>
              </dx-simple-item>
              <dx-simple-item item-type="group" name="volumeBasedPriceList">
                <dx-simple-item>
                  <template #default>
                    <volume-based-price-list-container :pricePeriodId="parseInt(id)" :serviceId="selectedServiceId" />
                  </template>
                </dx-simple-item>
              </dx-simple-item>
            </dx-simple-item>
            <dx-simple-item item-type="group" cssClass="checkbox-group">
              <dx-simple-item
                data-field="isPriceIndexed"
                :editor-options="isPriceIndexedOptions">
                <dx-label :visible="false" />
              </dx-simple-item>
              <dx-simple-item data-field="isVolumeBased">
                <dx-label :visible="false" />
              </dx-simple-item>
            </dx-simple-item>
          </dx-form>
        </dx-editing>

        <dx-column
          data-field="productId"
          :visible="false"
          caption="Varenummer"
          edit-cell-template="productLookup">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="productName"
          caption="Vare"
          :hiding-priority="9" />

        <dx-column
          data-field="productNumber"
          caption="Varenummer"
          :hiding-priority="8"
          :width="150" />

        <dx-column
          data-field="serviceId"
          :visible="false"
          caption="Service"
          edit-cell-template="serviceLookup"
          :set-cell-value="setCellValue">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="serviceName"
          caption="Service"
          :hiding-priority="7" />

        <dx-column
          data-field="serviceNumber"
          caption="Servicenr."
          :hiding-priority="6"
          :width="150" />

        <dx-column
          data-field="brands"
          caption="Gyldige brands"
          :hiding-priority="7"
          :width="200" />

        <dx-column
          data-field="priceTypeId"
          caption="Pristype"
          :hiding-priority="4"
          :width="150"
          :set-cell-value="setCellValue">
          <dx-grid-lookup
            :data-source="priceTypeList"
            value-expr="id"
            display-expr="name"
          />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="invoicingFrequencyIdArray"
          :visible="false"
          caption="Faktureringsfrekvens"
          edit-cell-template="invoicingFrequencySelector">
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="invoiceCategoryId"
          caption="Varekategori"
          :visible="false">
            <dx-grid-lookup
              :data-source="invoiceCategoryList"
              value-expr="id"
              display-expr="name" />
          <dx-required-rule />
        </dx-column>

        <dx-column
          data-field="basePrice"
          caption="Basispris"
          data-type="number"
          :hiding-priority="3"
          :width="90"
          :set-cell-value="onBasePriceChanged"
          :format="NumberFormatting.kronerWithFourDecimals">
        </dx-column>

        <dx-column
          data-field="hasMarkup"
          :visible="false"
          data-type="boolean"
          :set-cell-value="onHasMarkupChanged"
          :editor-options="hasMarkupOptions" />

        <dx-column
          data-field="markup"
          caption="Markup"
          data-type="number"
          :hiding-priority="2"
          :width="90"
          :format="NumberFormatting.kronerWithEightDecimals">
        </dx-column>

        <dx-column
          data-field="isPriceIndexed"
          caption="Indexreg."
          data-type="boolean"
          :hiding-priority="1"
          :width="90" />

        <dx-column
          data-field="isVolumeBased"
          :visible="false"
          data-type="boolean"
          :editor-options="isVolumeBasedOptions" />

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <ContentLoaderList v-if="!loadingDataCompleted" />
          <DetailView
            v-else
            :row="row"
            :pricePeriod="pricePeriod"
            :pricePeriodId="parseInt(id)"
            :pricetypeList="priceTypeList"
            :invoicingFrequencyList="invoicingFrequencyList"
            :invoiceCategoryList="invoiceCategoryList"
            @onEditClick="onEditClick"
            @onDeleteClick="onDeleteClick" />
        </template>

        <template #productLookup="{ data }">
          <dx-lookup
            :data-source="mainProductList"
            display-expr="name"
            value-expr="id"
            :value="data.value"
            :dropDownOptions="{ showTitle: false }"
            @value-changed="data.setValue($event.value)">
          </dx-lookup>
        </template>

        <template #serviceLookup="{ data }">
          <dx-lookup
            :data-source="productPriceGroupList"
            display-expr="name"
            value-expr="id"
            :value="data.value"
            :dropDownOptions="{ showTitle: false }"
            @value-changed="data.setValue($event.value)">
          </dx-lookup>
        </template>

        <template #invoicingFrequencySelector="{ data }">
          <div>
            <dx-tag-box
              :items="invoicingFrequencyList"
              value-expr="id"
              display-expr="name"
              :show-selection-controls="true"
              :value="data.value"
              @value-changed="data.setValue($event.value)" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import DetailView from '@/modules/prices/components/detail-view.vue';
import ContentLoaderList from '@/components/skeletonLoaders/content-loader-list.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import VolumeBasedPriceListContainer from '@/modules/prices/components/volume-based-price-list-container.vue';
import useDatagrid from '@/modules/prices/composables/datagrid.composable.js';
import useMasterdata from '@/modules/prices/composables/masterdata.composable.js';
import NumberFormatting from '@/constants/number.formatting.js';

const hasMarkupOptions = {
  text: 'Varen har markup',
};

const isPriceIndexedOptions = {
  text: 'Varen er prisindeksreguleres',
};

const isPaymentCardOptions = {
  text: 'Varen er betalingskort',
};

const isVolumeBasedOptions = {
  text: 'Varen opgøres pr. volumen',
};

export default {
  name: 'PriceList',
  extends: BaseDataGrid,
  props: {
    id: String,
  },
  components: {
    DetailView,
    ContentLoaderList,
    VolumeBasedPriceListContainer,
  },
  setup(props) {
    const {
      datasource,
      onEditorPreparing,
      dataGrid,
      selectedServiceId,
      customizeItem,
      setCellValue,
      onEditingStart,
      onInitNewRow,
      mainProductList,
      productPriceGroupList,
      currentEditingKey,
      onBasePriceChanged,
      onHasMarkupChanged,
    } = useDatagrid(props.id);

    const {
      overviewInfo,
      invoicingFrequencyList,
      invoiceCategoryList,
      priceTypeList,
      loadingPriceTypeListCompleted,
      loadingInvoiceCategoryListCompleted,
      loadingInvoicingFrequencyListCompleted,
      pricePeriod,
    } = useMasterdata(props.id);

    const loadingDataCompleted = computed(() => loadingPriceTypeListCompleted.value
        && loadingInvoiceCategoryListCompleted.value
        && loadingInvoicingFrequencyListCompleted.value);

    const calculateMarkup = () => {
      const currentRowKey = currentEditingKey.value;
      const datagridInstance = dataGrid.value.instance;

      // Get current row index
      const index = datagridInstance.getRowIndexByKey(currentRowKey) || 0;

      // Get current base price
      const basePrice = datagridInstance.cellValue(index, 'basePrice');
      const { markupPercent } = overviewInfo.data;

      // Calculate new markup price
      const markupPrice = (basePrice * markupPercent) / 100;
      dataGrid.value.instance.cellValue(index, 'markup', markupPrice);
    };

    const editorMarkupPrice = {
      format: NumberFormatting.kronerWithEightDecimals,
      buttons: [{
        name: 'calculateMarkup',
        location: 'after',
        options: {
          stylingMode: 'text',
          hint: 'Beregn markup',
          icon: 'chart',
          onClick: () => {
            calculateMarkup();
          },
        },
      }],
    };

    const editorBasePrice = {
      format: NumberFormatting.kronerWithFourDecimals,
    };

    const router = useRouter();

    function onBackClick() {
      router.push({ name: 'PricePeriods' });
    }

    return {
      datasource,
      mainProductList,
      productPriceGroupList,
      priceTypeList,
      overviewInfo,
      invoicingFrequencyList,
      invoiceCategoryList,
      loadingDataCompleted,
      onBackClick,
      onEditorPreparing,
      setCellValue,
      onEditingStart,
      customizeItem,
      dataGrid,
      onInitNewRow,
      selectedServiceId,
      hasMarkupOptions,
      isPriceIndexedOptions,
      isPaymentCardOptions,
      isVolumeBasedOptions,
      editorBasePrice,
      editorMarkupPrice,
      calculateMarkup,
      NumberFormatting,
      pricePeriod,
      onBasePriceChanged,
      onHasMarkupChanged,
    };
  },
  methods: {
    rowRemoving(e) {
      this.onRowRemoving(e, `Vil du slette denne prisliste?<br/><b>${e.data.productName}</b>`, 'Slet prisliste');
    },
  },
};
</script>

<style lang="scss">
.price-list-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .prisperiode-link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 200;
    padding-left: 20px;
  }

  .checkbox-group {
    margin-top: 20px;
  }

  .dx-row-inserted {
    .dx-icon-chart {
      display: none;
    }
  }
}
</style>
