import { ref } from 'vue';
import CustomStore from 'devextreme/data/custom_store';
import useBillingSetRepository from '@/modules/billingSet/repositories/billingset.repository.js';
import useMasterdata from '@/modules/billingSet/composables/masterdata.composable.js';
import store from '@/store/index';

export default function useDatagrid(dataGrid) {
  const billingSetRepository = useBillingSetRepository();

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => billingSetRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
    update: (key, values) => new Promise((resolve, reject) => {
      billingSetRepository.update(key, values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    insert: (values) => new Promise((resolve, reject) => {
      billingSetRepository.insert(values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Kørslen er nu oprettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    remove: (key) => new Promise((resolve, reject) => {
      billingSetRepository.remove(key)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Kørslen er nu slettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
  });

  const isPublishDateMode = ref(false);

  const {
    billingPeriodList,
  } = useMasterdata();

  const initFormFields = (e) => {
    // Hide/Show fields
    e.component.columnOption('name').formItem.disabled = isPublishDateMode.value;
    e.component.columnOption('publishingDate').formItem.visible = isPublishDateMode.value;
    e.component.columnOption('billingPeriodId').formItem.visible = false;
    e.component.columnOption('supplierId').formItem.visible = false;
    // assign default values
    const currentYear = new Date().getFullYear();
    const currentMonthName = new Intl.DateTimeFormat('da-DK', { month: 'long' }).format(new Date());
    const currentMonthNameAndYear = `${currentMonthName} ${currentYear}`;
    const index = billingPeriodList.data.findIndex((x) => x.name.toLowerCase() === currentMonthNameAndYear);
    if (index >= 0) {
      e.data.billingPeriodId = billingPeriodList.data[index + 1].id;
    }
  };

  const onInitNewRow = (e) => {
    isPublishDateMode.value = false;
    initFormFields(e);

    // Set Faktureringsperiode and Supplier to visible
    e.component.columnOption('billingPeriodId').formItem.visible = true;
    e.component.columnOption('supplierId').formItem.visible = true;
  };

  const onRowPrepared = (e) => {
    if (e.rowType === 'data' && e.data.cancelled) {
      e.rowElement.style.backgroundColor = '#FFFFC4';
    }
  };

  const initPublishFields = (e) => {
    isPublishDateMode.value = true;
    e.component.columnOption('name').formItem.disabled = true;
    const rowIndex = e.component.getRowIndexByKey(e.key);
    if (rowIndex >= 0) {
      e.component.focus(e.component.getCellElement(rowIndex, 'publishingDate'));
    }
  };

  const publishNow = (e) => {
    const rowIndex = e.component.getRowIndexByKey(e.key);
    const dateValue = e.data.publishingDate ? e.data.publishingDate : new Date();
    if (rowIndex >= 0) {
      dataGrid.value.instance.cellValue(rowIndex, 'publishingDate', dateValue);
      dataGrid.value.instance.saveEditData();
    }
  };

  const editCanceled = () => {
    dataGrid.value.instance.refresh();
  };

  const editorPreparing = (e, billingSetTypeList) => {
    if (e.dataField === 'supplierId' && e.parentType === 'dataRow') {
      e.row.data.billingSetTypeId = null;
    }

    if (e.dataField === 'billingSetTypeId' && e.parentType === 'dataRow') {
      const { supplierId } = e.row.data;
      if (supplierId == null) return;

      // Filter by supplier
      const billingSetTypeBySupplier = billingSetTypeList.data.filter((x) => x.supplierId === supplierId);

      // Only show billingSetTypes for selected supplier
      e.editorOptions.dataSource = billingSetTypeBySupplier;
    }
  };

  function setCellValue(newData, value) {
    const column = this;
    column.defaultSetCellValue(newData, value);
  }

  return {
    datasource,
    initFormFields,
    onInitNewRow,
    isPublishDateMode,
    onRowPrepared,
    initPublishFields,
    editCanceled,
    editorPreparing,
    setCellValue,
    publishNow,
  };
}
