<template>
  <div class="distribution-type-form-root">
    <div class="flex">
      <div class="flex-item">
        Fordelingstype
        <dx-radio-group
          :items="radioItems"
          display-expr="text"
          value-expr="id"
          v-model="selectedDistributionType" />
      </div>
      <div class="flex-item">
        <dx-select-box
          :data-source="distributionList"
          display-expr="name"
          value-expr="id"
          :disabled="selectedDistributionType !== DistributionType.DISTRIBUTIONBYKEY"
          v-model="selectedDistribution" />
      </div>
    </div>
  </div>
</template>

<script>
import DistributionType, { DistributionTypeDescription } from '@/enums/distribution-type.js';

export default {
  name: 'SelectionDistributionTypeForm',
  props: {
    modelValue: Object,
    distributionList: Array,
  },
  emits: ['update:modelValue'],
  setup() {
    const radioItems = [
      { text: DistributionTypeDescription.get(DistributionType.DISTRIBUTIONBYKEY), id: DistributionType.DISTRIBUTIONBYKEY },
      { text: DistributionTypeDescription.get(DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERCOUNT), id: DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERCOUNT },
      { text: DistributionTypeDescription.get(DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERPRICE), id: DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERPRICE },
      { text: DistributionTypeDescription.get(DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERPRICEINCLMARKUP), id: DistributionType.DISTRIBUTIONBYPRODUCTIDENTIFIERPRICEINCLMARKUP },
    ];

    return {
      radioItems,
      DistributionType,
    };
  },
  computed: {
    selectedDistributionType: {
      get() {
        return this.modelValue.distributionType;
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, distributionType: value });
      },
    },
    selectedDistribution: {
      get() {
        return this.modelValue.selectedDistribution;
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, selectedDistribution: value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.distribution-type-form-root {
  padding: 20px;
  background-color: #FFF;

  .row {
    padding-bottom: 15px;
  }

  .flex {
    display: flex;
  }

  .flex-item {
    flex-grow: 1;
  }
}
</style>
