<template>
  <div class="client-distribution-history-root datagrid-root">
    <div class="content-block">
      <div class="headline-container">
        <div class="headline-item"><h2>Vis&nbsp;historik</h2></div>
      </div>
      <div><u @click="onBackClick" class="link">Fordelingsnøgler</u> > <u @click="onBackToSecondLEvelClick" class="link">Fordelingsperioder</u> > <u @click="onBackToThirdLevelClick" class="link">Fordelingsperiode</u> > Vis historik</div>
    </div>

    <div class="content-block">
      <div class="back-button-container">
        <dx-button icon="back" text="Tilbage" @click="onBackToThirdLevelClick"></dx-button>
      </div>
      <div class="grid-button-container">
        <div class="flex-align-right">
          <dx-button @click="clearFilterAndSorting" icon="clearfilter" hint="Fjern filter og sortering"></dx-button>
        </div>
      </div>
      <dx-data-grid
        ref="dataGrid"
        id="clientDistributionHistoryDataGrid"
        :data-source="datasource"
        height="75vh"
        :focused-row-key="focusedRowKey"
        :column-auto-width="true"
        column-resizing-mode="widget">

        <dx-selection mode="single"/>
        <dx-export :enabled="true" fileName="Fordelingsperiode" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="false" />
        <dx-grouping :auto-expand-all="false" />
        <dx-group-panel :visible="false" />
        <dx-state-storing :enabled="true" type="sessionStorage" storage-key="client-distribution-history-list" />
        <dx-scrolling mode="virtual" />
        <dx-paging :page-size="50" />

        <dx-column
          data-field="date"
          caption="Dato"
          data-type="date"
          format="dd.MM.yyyy"
          :hiding-priority="7">
        </dx-column>

        <dx-column
          data-field="userName"
          caption="Navn"
          data-type="string"
          :hiding-priority="6">
        </dx-column>

        <dx-column
          data-field="email"
          caption="Email"
          data-type="string"
          :hiding-priority="5">
        </dx-column>

        <dx-column
          data-field="oldValue"
          caption="Værdi før"
          data-type="number"
          sort-order="asc"
          :hiding-priority="4">
        </dx-column>

        <dx-column
          data-field="newValue"
          caption="Værdi efter"
          data-type="number"
          sort-order="asc"
          :hiding-priority="4">
        </dx-column>

        <dx-column
         type="buttons"
         :visible="false" />

        <dx-master-detail
          :enabled="false"
          template="detailTemplate" />

        <template #detailTemplate="{ data: row }">
          <div>
            <DetailView
              :row="row" />
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import DetailView from '@/modules/masterData/clientDistributionHistory/components/detail-view.vue';
import BaseDataGrid from '@/components/common/base-datagrid.vue';
import useDatagrid from '@/modules/masterData/clientDistributionHistory/composables/datagrid.composable.js';

export default {
  name: 'MainDistributionHistoryModule',
  extends: BaseDataGrid,
  components: {
    DetailView,
  },
  props: {
    id: String,
    periodId: String,
  },
  setup(props) {
    const {
      datasource,
      getDatasource,
    } = useDatagrid(props.periodId);

    getDatasource();

    const router = useRouter();

    function onBackClick() {
      router.push({ name: 'MasterDataDistributionKeys' });
    }

    function onBackToSecondLEvelClick() {
      router.push({ name: 'MasterDataDistributionKeyPeriods', params: { id: props.id } });
    }

    function onBackToThirdLevelClick() {
      router.push({ name: 'MasterDataClientDistributions', params: { id: props.id, periodId: props.periodId } });
    }

    return {
      datasource,
      onBackClick,
      onBackToSecondLEvelClick,
      onBackToThirdLevelClick,
    };
  },
};
</script>

<style lang="scss">
.client-distribution-history-root {
  .headline-container {
    display: flex;

    .headline-item {
      flex: 0 0 110px;

      h2 {
        margin-top: 0px;
      }
    }

    .subheadline-item {
      padding-top: 11px; font-size: 20px;
    }
  }

  .link:hover {
    cursor: pointer;
  }

  .back-button-container {
    height: 0px;
    position: relative;
    top: 30px;
    z-index: 100;
    padding-left: 20px;
  }
}
</style>
