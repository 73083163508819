const NumberFormatting = {
  number: '#,##0',
  decimal: '#,##0.####',
  percentage: '#,##0.## \'%\'',
  percentageWithFourteenDecimals: '#,##0.############## \'%\'',
  kronerWithEightDecimals: '#,##0.######## kr.',
  kronerWithFourDecimals: '#,##0.#### kr.',
  kronerWithTwoDecimals: '#,##0.## kr.',
};

export default NumberFormatting;
