import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useCRMRepository() {
  const appsettings = inject('appsettings');

  const updateCrmParametersList = (clientId, payload) => {
    const data = {
      crmValueItemList: payload,
    };
    return ApiService
      .put(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/clients/${clientId}/crmparameters`, data);
  };

  return {
    updateCrmParametersList,
  };
}
