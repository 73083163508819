import { reactive } from 'vue';
import useMasterdataRepository from '@/modules/masterData/suppliers/repositories/masterdata.repository.js';
import SupplierInvoiceType, { SupplierInvoiceTypeDescription } from '@/enums/supplier-invoice-type.js';

export default function useMasterdata() {
  const masterdataRepository = useMasterdataRepository();

  const masterdata = reactive({
    importFormatList: {
      loadingCompleted: false,
      data: null,
    },
    supplierInvoiceTypeList: {
      loadingCompleted: false,
      data: null,
    },
  });

  const loadMasterdata = () => {
    masterdataRepository.getImportFormatList()
      .then((response) => {
        masterdata.importFormatList.data = response.data;
        masterdata.importFormatList.loadingCompleted = true;
      });

    const supplierInvoiceTypeList = [
      {
        id: SupplierInvoiceType.STANDARDINVOICING,
        name: SupplierInvoiceTypeDescription.get(SupplierInvoiceType.STANDARDINVOICING),
      },
      {
        id: SupplierInvoiceType.MCCONNECT,
        name: SupplierInvoiceTypeDescription.get(SupplierInvoiceType.MCCONNECT),
      },
      {
        id: SupplierInvoiceType.DKVEDERLAG,
        name: SupplierInvoiceTypeDescription.get(SupplierInvoiceType.DKVEDERLAG),
      },
      {
        id: SupplierInvoiceType.XPAYS,
        name: SupplierInvoiceTypeDescription.get(SupplierInvoiceType.XPAYS),
      },
    ];

    masterdata.supplierInvoiceTypeList.data = supplierInvoiceTypeList;
    masterdata.supplierInvoiceTypeList.loadingCompleted = true;
  };

  return {
    loadMasterdata,
    masterdata,
  };
}
