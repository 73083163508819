<template>
  <div class="cms-graph-tooltip-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" :colspan="2" />
        <div class="detail-element-container">
          Overskrift:
          <dx-textbox
            :value="row.data.title"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" :colspan="2" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container">
          Tekst:
          <dx-text-area
            :value="row.data.text"
            :read-only="true"
            class="textbox-readonly"
            :height="200" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="2" :col="0" />
        <div class="detail-element-container">
          Graf type:
          <dx-textbox
            :value="getNameById(graphTypeList, row.data.graphTypeId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container padding-left">
          Niveau:
          <dx-textbox
            :value="getNameById(graphLevelList, row.data.graphLevel)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="0" />
        <dx-location screen="xs sm" :row="4" :col="0" />
        <div class="detail-element-container">
          Måned:
          <dx-textbox
            :value="getNameById(monthList, row.data.monthId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="3" :col="1" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container padding-left">
          Brand:
          <dx-textbox
            :value="getNameById(brandList, row.data.brandId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="4" :col="0" />
        <dx-location screen="xs sm" :row="5" :col="0" />
        <div class="detail-element-container">
          Service:
          <dx-textbox
            :value="getServiceNameById(serviceList, row.data.serviceIdentifier)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
          <div class="action-button-wrapper">
            <dx-button icon="trash" text="Slet" @click="$emit('onDeleteClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    brandList: Array,
    graphTypeList: Array,
    monthList: Array,
    serviceList: Array,
    graphLevelList: Array,
  },
  setup() {
    function getNameById(list, id) {
      const item = list.find((x) => x.id === id);
      return get(item, ['name']);
    }

    function getServiceNameById(list, id) {
      const item = list.find((x) => x.identifier === id);
      if (item) {
        return `${item.identifier} - ${item.name}`;
      }
      return '';
    }

    return {
      getNameById,
      getServiceNameById,
    };
  },
};
</script>

<style lang="scss">
.cms-graph-tooltip-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-left {
    padding-left: 15px;
  }
}
</style>
