import { inject } from 'vue';
import ApiService from '@/services/api.service.js';
import SupplierInvoiceType from '@/enums/supplier-invoice-type.js';

export default function useMasterdataRepository() {
  const appsettings = inject('appsettings');

  const getSupplierList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/suppliers?invoicetype=${SupplierInvoiceType.STANDARDINVOICING}`);

  const getBrandList = (supplierId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/masterdata/brands/list/${supplierId}`);

  const getGraphTypeList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/graphtooltips/graphtypes`);

  const getMonthTypeList = () => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/graphtooltips/monthtypes`);

  const getServiceList = (supplierId) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/cms/graphtooltips/suppliers/${supplierId}/servicetypes`);

  return {
    getSupplierList,
    getBrandList,
    getGraphTypeList,
    getMonthTypeList,
    getServiceList,
  };
}
