import CustomStore from 'devextreme/data/custom_store';
import usePricePeriodRepository from '@/modules/pricePeriods/repositories/priceperiod.repository.js';
import store from '@/store/index';

export default function useDatagrid() {
  const pricePeriodRepository = usePricePeriodRepository();

  const datasource = new CustomStore({
    key: 'id',
    load: (loadOptions) => pricePeriodRepository.load(loadOptions)
      .then((response) => ({
        data: response.data.data,
        totalCount: response.data.totalCount,
        summary: response.data.summary,
        groupCount: response.data.groupCount,
      })),
    update: (key, values) => new Promise((resolve, reject) => {
      pricePeriodRepository.update(key, values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Dine ændringer er gemt');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    insert: (values) => new Promise((resolve, reject) => {
      pricePeriodRepository.insert(values)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Prisperiode er nu oprettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
    remove: (key) => new Promise((resolve, reject) => {
      pricePeriodRepository.remove(key)
        .then((response) => {
          if (response.data.success) {
            store.dispatch('addSuccessNotification', 'Prisperioden er nu slettet');
            resolve(response);
          } else {
            if (response.data.messages.length > 0) {
              store.dispatch('addNotifications', { messageList: response.data.messages });
            }
            reject();
          }
        });
    }),
  });

  const onActivateClick = (row) => {
    pricePeriodRepository.toggleActivateState(row.key, true)
      .then((response) => {
        if (response.data.success) {
          // Update datagrid with new data
          row.component.refresh();

          store.dispatch('addSuccessNotification', 'Prisperioden er nu aktiveret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onInactivateClick = (row) => {
    pricePeriodRepository.toggleActivateState(row.key, false)
      .then((response) => {
        if (response.data.success) {
          // Update datagrid with new data
          row.component.refresh();

          store.dispatch('addSuccessNotification', 'Prisperioden er nu inaktiveret');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  const onEditorPreparing = (e) => {
    if (e.dataField === 'supplierId' && e.parentType === 'dataRow' && !e.row.isNewRow) {
      // You are not allowed to update the supplier
      e.editorOptions.disabled = true;
    }
  };

  return {
    onInactivateClick,
    onActivateClick,
    datasource,
    onEditorPreparing,
  };
}
