<template>
  <XPClientDataSetList />
</template>

<script>
import XPClientDataSetList from '@/modules/xpClientDataSet/components/list.vue';

export default {
  name: 'ClientDataSets',
  components: {
    XPClientDataSetList,
  },
};
</script>
