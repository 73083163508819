<template>
  <ClientDistributionHistoryModule />
</template>

<script>
import ClientDistributionHistoryModule from '@/modules/masterData/clientDistributionHistory/components/main.vue';

export default {
  name: 'MasterDataClientDistributionHistory',
  components: {
    ClientDistributionHistoryModule,
  },
};
</script>
