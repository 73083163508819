<template>
  <div class="volumeBasedPriceList-root">
    <div>Pristrappe:</div>
    <dx-data-grid
      :data-source="data">
      <dx-column data-field="minQuantity" caption="Min. antal" :allow-sorting="false" :format="NumberFormatting.number" />
      <dx-column data-field="maxQuantity" caption="Maks. antal" :allow-sorting="false" :format="NumberFormatting.number" />
      <dx-column data-field="basePrice" caption="Basis stykpris" :allow-sorting="false" :format="NumberFormatting.kronerWithFourDecimals" />
      <dx-column data-field="markup" caption="Markup" :allow-sorting="false" :format="NumberFormatting.kronerWithFourDecimals" />
    </dx-data-grid>
  </div>
</template>

<script>
import NumberFormatting from '@/constants/number.formatting.js';

export default {
  name: 'VolumeBasedPriceList',
  props: {
    data: Object,
  },
  setup() {
    return {
      NumberFormatting,
    };
  },
};
</script>

<style lang="scss">
@import '@/themes/variables.base.scss';

.volumeBasedPriceList-root {
  .dx-datagrid {
    background-color: $base-color-2;
  }
}
</style>
