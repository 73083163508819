import { confirm } from 'devextreme/ui/dialog';

const rowRemoveService = {
  onRowRemoving: (e, text, headline) => {
    const res = confirm(text, headline);

    e.cancel = new Promise((resolve) => {
      res.done((dialogResult) => {
        resolve(!dialogResult);
      });
    });
  },
};

export default rowRemoveService;
