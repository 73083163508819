import useClientDataSetRepository from '@/modules/xpClientDataSet/repositories/clientdataset.repository.js';
import store from '@/store/index';
import useJob from '@/modules/shared/composables/job.composable.js';

export default function useClientDataSet(billingSetId) {
  const clientDataSetRepository = useClientDataSetRepository(billingSetId);
  const { startFastJobPolling } = useJob();

  const createClientDataSets = () => {
    clientDataSetRepository.create()
      .then((response) => {
        if (response.data.success) {
          // Start polling JobList at a fast pace
          startFastJobPolling();
          store.dispatch('addSuccessNotification', 'PI-datasæt bliver nu oprettet.');
        } else if (response.data.messages.length > 0) {
          store.dispatch('addNotifications', { messageList: response.data.messages });
        }
      });
  };

  return {
    createClientDataSets,
  };
}
