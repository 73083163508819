import store from '@/store/index';
import useFileExportRepository from '@/modules/dkvExternalDataSet/repositories/fileexport.repository.js';

export default function useFileExport() {
  const fileExportRepository = useFileExportRepository();

  const openFile = (fileContents, fileName) => {
    // Base64 decode content
    const decodedFileContent = Buffer.from(fileContents, 'base64');

    const fileURL = window.URL.createObjectURL(new Blob([decodedFileContent], { type: 'text/csv' }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  const getExternalFile = (fileId, fileName) => {
    store.dispatch('showLoadingPanel', true);

    fileExportRepository.createFileExport(fileId)
      .then((response) => {
        store.dispatch('showLoadingPanel', false);
        const fileContentResult = response.data.fileContents;
        openFile(fileContentResult, fileName);
      });
  };

  const onDownloadFileClick = (fileId, fileName) => {
    getExternalFile(fileId, fileName);
  };

  return {
    onDownloadFileClick,
  };
}
