<template>
  <div class="cms-text-detail-view">
    <dx-responsive-box>
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-row />
      <dx-col />
      <dx-col screen="md lg" />
      <dx-col screen="md lg" />

      <dx-item>
        <dx-location :row="0" :col="0" :colspan="2" />
        <dx-location screen="xs sm" :row="0" :col="0" />
        <div class="detail-element-container">
          Overskrift:
          <dx-textbox
            :value="row.data.title"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="1" :col="0" :colspan="2" />
        <dx-location screen="xs sm" :row="1" :col="0" />
        <div class="detail-element-container">
          Tekst:
          <dx-text-area
            :value="row.data.text"
            :read-only="true"
            class="textbox-readonly"
            :height="200" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="0" />
        <dx-location screen="xs sm" :row="2" :col="0" />
        <div class="detail-element-container padding-right">
          Supplier:
          <dx-textbox
            :value="getSupplierById(row.data.supplierId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="2" :col="1" />
        <dx-location screen="xs sm" :row="3" :col="0" />
        <div class="detail-element-container">
          Område:
          <dx-textbox
            :value="getAreaById(row.data.areaId)"
            :read-only="true"
            class="textbox-readonly" />
        </div>
      </dx-item>

      <dx-item>
        <dx-location :row="0" :col="2" :rowspan="6" />
        <dx-location screen="xs sm" :row="6" :col="0" />
        <div>
          <div class="action-button-wrapper">
            <dx-button icon="edit" text="Rediger" @click="$emit('onEditClick', row)" class="action-button" />
          </div>
        </div>
      </dx-item>
    </dx-responsive-box>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'DetailView',
  props: {
    row: Object,
    supplierList: Array,
    areaTypeList: Array,
  },
  setup(props) {
    function getSupplierById(id) {
      const supplier = props.supplierList.find((x) => x.id === id);

      return get(supplier, ['name']);
    }

    function getAreaById(id) {
      const area = props.areaTypeList.find((x) => x.id === id);

      return get(area, ['name']);
    }

    return {
      getSupplierById,
      getAreaById,
    };
  },
};
</script>

<style lang="scss">
.cms-text-detail-view {
  .action-button-wrapper {
    display: flex;
    justify-content: flex-end;

    .action-button {
      margin-bottom: 5px;
      width: 140px;
    }
  }

  .textbox-readonly {
    background-color: #FAFAFA !important;
  }

  .detail-element-container {
    padding-bottom: 10px;
  }

  .padding-left {
    padding-left: 15px;
  }
}
</style>
