import { inject } from 'vue';
import ApiService from '@/services/api.service.js';

export default function useBCInvoiceRepository() {
  const appsettings = inject('appsettings');

  const getBCInvoiceFile = (id) => ApiService
    .get(`${appsettings.ADMINISTRATION_API}/api/v1/fileexport/export/importedfile/bcfiles/${id}`);

  return {
    getBCInvoiceFile,
  };
}
