const BIRunStatus = {
  IDLE: 0,
  WAITING: 1,
  RUNNING: 2,
  FAILED: 3,
};

export const BIRunStatusDescription = new Map([
  [BIRunStatus.IDLE, 'OK'],
  [BIRunStatus.WAITING, 'Venter i kø'],
  [BIRunStatus.RUNNING, 'Kører'],
  [BIRunStatus.FAILED, 'Fejlet'],
]);

export default BIRunStatus;
